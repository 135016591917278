import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {
  transform(array: any[] | undefined, groupByKey: string, sortOrder: Map<string, number>): any[] {
    if(!array) {
        return [];
    }

   const groupedArray = array.reduce(function(rv, x) {
        (rv[x[groupByKey]] = rv[x[groupByKey]] || []).push(x);
        return rv;
      }, {});

      // this will return an array of objects, each object containing a group of objects
    return Object.keys(groupedArray).map(key => ({ key, value: groupedArray[key], order: sortOrder.get(key) }));

  }

  
}