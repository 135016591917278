import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';
import { LibraryNodeResponse, LibraryService } from '../../data-providers/library.service';
import { LibraryNodeType } from '../../helper/library.constant';
import { LibraryNode } from '../../models/library-node.model';
import { CreateComponent } from '../../questionnaire/create/create.component';
import { ActivityResponse, QuestionnaireService } from '../../questionnaire/data-providers/questionnaire.service';
import { FormControl } from '@angular/forms';
import { RunQuestionnaire } from '../../questionnaire/models/run-questionnaire.model';
@Component({
  selector: 'app-new-activity-dialog',
  templateUrl: './new-activity-dialog.component.html',
  styleUrls: ['./new-activity-dialog.component.scss']
})
export class NewActivityDialogComponent implements OnDestroy {

  loading: boolean = true;
  uploading: boolean = false;
  showError: boolean = false;
  errorMessage?: string;
  parentNode: LibraryNode;
  activityID: string | undefined;
  color: ThemePalette = 'primary';
  @ViewChild(CreateComponent) createActivityComponent: CreateComponent;

  @Output() activityCreatedEvent = new EventEmitter<LibraryNode>();
  @Output() activityUpdatedEvent = new EventEmitter<RunQuestionnaire>();


  constructor(
    private dialog: MatDialog,
    private libraryService: LibraryService,
    public dialogRef: MatDialogRef<NewActivityDialogComponent>,
    private _snackBar: MatSnackBar,
    private questionaireService: QuestionnaireService,
    @Inject(MAT_DIALOG_DATA) public data: any) {


    this.parentNode = data.parentNode;
    if (data.activityID) {
      this.activityID = data.activityID;
    }
    this.createForm();
  }

  tabs = ['First', 'Second', 'Third'];
  selected = new FormControl(0);

  addTab(selectAfterAdding: boolean) {
    this.tabs.push('New');

    if (selectAfterAdding) {
      this.selected.setValue(this.tabs.length - 1);
    }
  }

  removeTab(index: number) {
    this.tabs.splice(index, 1);
  }
  createForm() {

  }

  submitTapped(): void {
     const postData = this.createActivityComponent.validateAndGetPostData();
  
    if (!postData) {
      console.error("Unable to save activity. Invalid form value", postData);
      this.showErrorDialog("Please fix the errors shown in \"*\"");
      return;
    } else {

      const title = postData.name || postData.title;

      if (!title){
        this.showErrorDialog("Please provide activity name to proceed.");
        return;
      }      
    }

    if (this.activityID) {
      this.updateActivity(postData);
    }
    else {
      this.createActivity(postData);
    }


  }

  async createActivity(postData: any) {
    this.uploading = true;
    this.showError = false;
    this.errorMessage = undefined;

    try {

      let postBody = postData;
      if (this.parentNode) {
        postBody = { ...postData, ...{ parent: (this.parentNode && this.parentNode.id) ? this.parentNode.id : undefined } }
      }

      let response: LibraryNodeResponse = await this.questionaireService.createQuestionnaire(postBody);
      if (response.success) {
        const receivedNode: LibraryNode | undefined = response.node;

        if (receivedNode) {

          this.showError = false;

          this.activityCreatedEvent.emit(receivedNode);

          const dialogRef = this.dialog.getDialogById('new_activity_dialog');
          if (dialogRef)
            dialogRef.close();

          this.showSnackBar("Activity has been created", "OK");

        }
        else {
          this.showErrorDialog("Unable to create activity");
          this.showError = true;
        }
      }
      else {
        this.showErrorDialog("Oops,something went wrong. Please try again");
        this.showError = true;
      }

      this.uploading = false;


    } catch (error) {
      console.log("Unable to create activity", error);
      this.uploading = false;
      this.showErrorDialog("Oops,something went wrong. Please try again");
      this.showError = true;
    }
  }


  async updateActivity(postData: any) {

    if (!this.activityID)
      return;

    this.uploading = true;
    this.showError = false;
    this.errorMessage = undefined;

    try {

      let postBody = postData;

      const response: ActivityResponse  = await this.questionaireService.updateQuestionnaire(postData, this.activityID);
      if (response.success) {
        const updatedActivity: RunQuestionnaire | undefined = response.activity;

        if (updatedActivity) {

          this.showError = false;

          this.activityUpdatedEvent.emit(updatedActivity);

          this.showSnackBar("Activity has been updated.", "OK");

        }
        else {
          this.showErrorDialog("Unable to update activity");
          this.showError = true;
        }
      }
      else {
        this.showErrorDialog("Oops,something went wrong. Please try again");
        this.showError = true;
      }

      this.uploading = false;


    } catch (error) {
      console.log("Unable to create activity", error);
      this.uploading = false;
      this.showErrorDialog("Oops,something went wrong. Please try again");
      this.showError = true;
    }
  }

  didLoadQuestionnaireData() {
    this.loading = false;
  }


  showErrorDialog(error: any) {

    const dialogRef = this.dialog.open(InformationDialogComponent, {
      width: '300px',
      id: 'information_dialog'
    })

    dialogRef.componentInstance.content = error
  }

  showSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 10000 });
  }


  ngOnDestroy(): void {

    if (this.activityCreatedEvent)
      this.activityCreatedEvent.unsubscribe();

    if (this.activityUpdatedEvent)
      this.activityUpdatedEvent.unsubscribe();

  }

}
