<div class="library-picker-main" fxLayout="row wrap" fxLayoutWrap="wrap">
    <div class="w-100">
        <mat-toolbar color="primary">
            <ng-container *ngIf="navigationStack && navigationStack.length > 1">
                <button mat-icon-button class="back-icon" aria-label="Back to previous folder" (click)="goBack()">
                    <mat-icon>arrow_back_ios_new</mat-icon>
                </button>
            </ng-container>

            <span class="picker-heading">
                <ng-container *ngIf="currentNavigationItem && !currentNavigationItem.parent">
                    <span class="icon icon-library-blue"></span>
                </ng-container>
                <span class="search-heading single-line" [title]="(currentNavigationItem &&
                currentNavigationItem.title)?currentNavigationItem.title:''">
                    <ng-container *ngIf="currentNavigationItem;else ImportFromLibrary">{{(currentNavigationItem &&
                        currentNavigationItem.title)?currentNavigationItem.title:""}}</ng-container>
                    <ng-template #ImportFromLibrary>
                        {{defaultTitle}}
                    </ng-template>
                </span>

            </span>
            <span class="toolbar-spacer"></span>

            <button class="header-actions" mat-icon-button [matMenuTriggerFor]="pickerActions"
                aria-label="Upload file/Add link">
                <mat-icon>add</mat-icon>
            </button>
            <mat-menu #pickerActions="matMenu">
                <button mat-menu-item (click)="newFolderTapped()" *ngIf="canCreateFolder">
                    <span class="icon icon-folder"></span>
                                        <span class="icon_txt">New Folder</span>
                </button>
                <button mat-menu-item *ngIf="canUploadFile">
                    <input type="file" id="file" ng2FileSelect onclick='value = null' (change)="onFileChange($event)" class="inputfile">
                    <label for="file" class="m-0">
                        <div class="upload-menu p-small-black15 mr-20 cursor-pointer">
                            <span class="icon icon-upload"></span>
                                               <span class="icon_txt">Upload File</span>
                        </div>
                    </label>
                </button>
                <button mat-menu-item (click)="newURLTapped()" *ngIf="canAddLink">
                    <span class="icon icon-addurl"></span>
                    <span class="icon_txt">Add URL</span>
                </button>
                <button mat-menu-item><div class="folder-menu p-small-black15 mr-20 cursor-pointer activity-ls" (click)="newActivityTapped()">
                    <span class="icon icon-newactivity"></span>
                    <span class="inner-ac">Add Activity</span>
                </div></button>
            </mat-menu>
            <button mat-icon-button (click)="closeClick()" aria-label="close library">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar>
    </div>
    <div class="library-search w-100">
        <mat-form-field class="select-box" appearance="outline">
            <input matInput #searchField type="text" (keyup.enter)="searchButtonClicked(searchField.value)"
                placeholder="Search">
            <ng-container *ngIf="keyword; else searchIcon">
                <mat-icon matSuffix (click)="searchField.value = ''; clearSearch()">close</mat-icon>
            </ng-container>
            <ng-template #searchIcon>
                <mat-icon matSuffix (click)="searchButtonClicked(searchField.value)">search</mat-icon>
            </ng-template>
        </mat-form-field>
    </div>

    <div class="w-100" fxLayout="column">
        <div fxLayout="row wrap" fxLayoutWrap="wrap">
            <ng-container *ngIf="fetchingNodes; else LoadingComplete">
                <ng-container [ngSwitch]="viewType">
                    <ng-container *ngSwitchCase="viewTypeEnum.List" [ngTemplateOutlet]="LibraryListViewSkeletonLoading">
                    </ng-container>
                    <ng-container *ngSwitchCase="viewTypeEnum.Grid" [ngTemplateOutlet]="LibraryGridViewSkeletonLoading">
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-template #LibraryListViewSkeletonLoading>
                <div class="libray-list-loading pt-20" fxLayout="row wrap">
                    <div class="star">
                        <ngx-skeleton-loader count="10" [theme]="{ 
                            height: '25px',
                            width: '25px',
                            margin: '0px auto',
                            display: 'table',
                            marginBottom: '30px'
                          }" appearance="circle"></ngx-skeleton-loader>
                    </div>
                    <div class="name">
                        <ngx-skeleton-loader count="10" [theme]="{ 
                            height: '25px',
                            marginBottom: '30px',
                            paddingRight: '20px'
                          }"></ngx-skeleton-loader>
                    </div>
                </div>
            </ng-template>
            <ng-template #LibraryGridViewSkeletonLoading>
                <div class="w-100 scroll-bar">
                    <div class="w-100" fxLayout="row">
                        <div class="pt-20 pr-20" fxFlex="25" fxFlexOffset="75" fxLayoutAlign="end center">
                            <ngx-skeleton-loader count="1" [theme]="{ 
                                    height: '40px',
                                    width: '20vw',
                                    marginBottom: '30px',
                                    paddingRight: '20px'
                                }"></ngx-skeleton-loader>
                        </div>
                    </div>
                    <div class="folder-main w-100 pl-20">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                                height: '30px',
                                width: '100px',
                                marginBottom: '20px',
                                paddingRight: '20px'
                            }"></ngx-skeleton-loader>
                    </div>
                    <div class="grid-loading pl-20 pb-10 w-100" fxLayout="row">
                        <ngx-skeleton-loader count="10" [theme]="{ 
                                height: '40px',
                                marginBottom: '20px'
                            }"></ngx-skeleton-loader>
                    </div>
                    <div class="folder-main w-100 pl-20">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                                height: '30px',
                                width: '100px',
                                marginBottom: '20px',
                                paddingRight: '20px'
                            }"></ngx-skeleton-loader>
                    </div>
                    <div class="grid-loading pl-20 w-100" fxLayout="row">
                        <ngx-skeleton-loader count="5" [theme]="{ 
                                height: '150px',
                                marginBottom: '20px'
                            }"></ngx-skeleton-loader>
                    </div>
                </div>
            </ng-template>

            <ng-template #LoadingComplete>
                <ng-container *ngIf="showError; else showNodes">
                    <ng-container [ngTemplateOutlet]="showMessage"></ng-container>
                </ng-container>
                <ng-template #showNodes>
                    <ng-container [ngSwitch]="viewType">
                        <ng-container *ngSwitchCase="viewTypeEnum.List" [ngTemplateOutlet]="LibraryListView">
                        </ng-container>
                        <ng-container *ngSwitchCase="viewTypeEnum.Grid" [ngTemplateOutlet]="LibraryGridView">
                        </ng-container>
                    </ng-container>
                </ng-template>
            </ng-template>
            <ng-template #LibraryListView>
                <div class="w-100">
                    <app-library-list-view [allowedTypes]="allowedTypes" [disabledNodes]="disabledNodes" [showActions]="false" [nodes]='nodes' [sort]='sort' [loading]='fetchingNodes'
                        [selectedNode]='selectedNode' (nodeClickEvent)="nodeClick($event)"
                        (nodeDoubleClickEvent)="nodeDoubleClick($event)" (sortEvent)="sortClick($event)">
                    </app-library-list-view>
                </div>
            </ng-template>
            <ng-template #LibraryGridView>
                <div class="w-100">
                    <app-library-grid-view [nodes]='nodes' [loading]='fetchingNodes' [selectedNode]='selectedNode'
                        (nodeClickEvent)="nodeClick($event)" (nodeDoubleClickEvent)="nodeDoubleClick($event)">
                    </app-library-grid-view>
                </div>
            </ng-template>
            <ng-template #showMessage>
                <div class="libray-list-loading pl-20 pr-20">
                    <div class="w-100 error-message align-center" fxLayout="row" fxLayoutAlign="center center"
                        *ngIf="showError && !fetchingNodes">
                        {{errorMessage}}
                    </div>
                    <div class="w-100" fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button class="btn-submit" *ngIf="showError"
                            (click)="loadLibrary()">{{errorMessageButtonTitle}}</button>
                    </div>
                </div>
            </ng-template>
        </div>
        <div *ngIf="showMoveToolbar">
            <mat-toolbar class="move-btn" color="primary">
                <span class="toolbar-spacer"></span>
    
                <button mat-raised-button class="btn-submit" [disabled]="canDisableMove" aria-label="Move" (click)="moveTapped()">
                    {{submissionInProgress?'Moving...': 'Move'}}
                </button>
            </mat-toolbar>
        </div>
    </div>
</div>