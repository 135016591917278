<div class="publish-dialog">
  <div class="pb-20" fxLayout="row" fxLayoutAlign="start start">
    <div fxFlex="initial" fxLayoutAlign="start start">
      <h3 class="h3-24-black">You're about to publish this {{displayTitle | lowercase}}!</h3>
    </div>
    <div fxFlex fxLayoutAlign="end start">
      <mat-icon class="close-icon" mat-dialog-close>close</mat-icon>
    </div>
  </div>
  <p class="p-small-black15 pb-20">Select preferred visibility of this {{displayTitle | lowercase}}</p>
  <div class="publish-list">
    <mat-radio-group aria-labelledby="Visibility"
      [(ngModel)]="selectedVisibility">
      <mat-radio-button value="friends">
        Visible to my connections only
      </mat-radio-button>
      <mat-radio-button value="public">
        Make it public
      </mat-radio-button>
    </mat-radio-group>
    <!-- <ul>
      <li>
        <mat-icon>check_circle</mat-icon>
        My Connections
      </li>
      <li>
        <mat-icon>check_circle</mat-icon>
        Shared by Me
      </li>
      <li>
        <mat-icon>check_circle</mat-icon>
        Shared by my connections
      </li>
    </ul> -->
  </div>
  <mat-dialog-actions [ngClass]="[submissionInProgress? 'show-loading' : '', 'pb-20']" align="center">
    <button mat-raised-button [disabled]="submissionInProgress" class="btn-grey" mat-dialog-close>Cancel</button>
    <button mat-raised-button [disabled]="submissionInProgress" class="btn-submit" cdkFocusInitial
      (click)="submitTapped()">Publish</button>
    <mat-spinner *ngIf="submissionInProgress" [color]="color" diameter="25" strokeWidth="3">
    </mat-spinner>
  </mat-dialog-actions>

</div>