import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';
import { LibraryService, LibraryNodeResponse } from './../../data-providers/library.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { LibraryNode } from './../../models/library-node.model';
import {LibraryNodeType} from '../../helper/library.constant';
import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-new-link-dialog',
  templateUrl: './new-link-dialog.component.html',
  styleUrls: ['./new-link-dialog.component.scss']
})
export class NewLinkDialogComponent implements OnDestroy{


  uploading: boolean = false;
  showError: boolean = false;
  errorMessage?: string;
  parentNode: LibraryNode;
  formGroup: any;
  color: ThemePalette = 'primary';

  @Output() linkSavedEvent = new EventEmitter<LibraryNode>();


  constructor(
    private dialog: MatDialog,
    private _fb: FormBuilder,
    private libraryService: LibraryService,
    public dialogRef: MatDialogRef<NewLinkDialogComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {


    this.parentNode = data.parentNode;

    this.createForm();
  }

  

  createForm() {
    const reg = 
    '[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}(.[a-z]{2,4})?\b(/[-a-zA-Z0-9@:%_+.~#?&//=]*)?';
    this.formGroup = this._fb.group({
      url: [null, [Validators.required]],
    });
  }

  submitTapped(post: any): void {

    const url: string = this.formGroup.get("url").value;

    if (this.formGroup && this.formGroup.invalid) {
      console.error("Unable to save url. Invalid form value", post);

      if (!url) {
        this.showErrorDialog("Please provide url");
      }
      else{
        this.showErrorDialog("Please provide valid url");
      }


      return;
    }


    this.saveLink(url);

  }



  async saveLink(url: string) {
    this.uploading = true;
    this.showError = false;
    this.errorMessage = undefined;

    try {
      let response: LibraryNodeResponse = await this.libraryService.createNode(LibraryNodeType.link, undefined, this.parentNode && (this.parentNode.id ? this.parentNode.id : undefined), url)
      if (response.success) {
        const receivedNode: LibraryNode | undefined = response.node;

        if (receivedNode) {

          this.showError = false;

          this.linkSavedEvent.emit(receivedNode);

          const dialogRef = this.dialog.getDialogById('new_link_dialog');
          if (dialogRef)
            dialogRef.close();

          this.showSnackBar("URL has been saved", "OK");

        }
        else {
          this.showErrorDialog("Unable to save url");
          this.showError = true;
        }
      }
      else {
        this.showErrorDialog("Oops,something went wrong. Please try again");
        this.showError = true;
      }

      this.uploading = false;


    } catch (error) {
      console.log("Unable to save url", error);
      this.uploading = false;
      this.showErrorDialog("Oops,something went wrong. Please try again");
      this.showError = true;
    }
  }

  showErrorDialog(error: any) {

    const dialogRef = this.dialog.open(InformationDialogComponent, {
      width: '300px',
      id: 'information_dialog'
    })

    dialogRef.componentInstance.content = error



  }

  showSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 10000 });
  }

  ngOnDestroy(): void {
    if(this.linkSavedEvent)
      this.linkSavedEvent.unsubscribe();
  }


}
