<div class="pl-20 user-row search-connections-profile" fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="initial" fxLayoutAlign="start center" *ngIf="userProfileObject">
        <app-profile-image [viewMode]='true' [userDetails]='userProfileObject' class="cursor-pointer" (click)="showProfile()" [userID]='userID'>
        </app-profile-image>
        <div fxLayout="column">
            <h6 class="h6-15 single-line cursor-pointer" title={{userProfileObject?.displayName}} (click)="showProfile()" >{{userProfileObject?.displayName}}</h6>
            <p class="p-small-grey14">{{userProfileObject.subText}} </p>
        </div>
    </div>
    <div class="pl-20" fxFlex fxLayoutAlign="end center">
        <app-user-profile-connection-actions class="search_b" [profile]="userProfileObject" [screen]="actionScreen" ></app-user-profile-connection-actions>
    </div>
    <!-- <div class="pl-20" fxFlex fxLayoutAlign="end center">
        <button mat-raised-button class="btn-submit">Accept</button>
        <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="search connections more button">
            <span class="icon icon-ellipsis ml-10 mr-10"></span>
        </button>
        <mat-menu #moreMenu="matMenu">
            <button mat-menu-item>
                <span>Unfriend</span>
            </button>
            <button mat-menu-item>
                <span>Block</span>
            </button>
        </mat-menu>
    </div> -->
</div>