import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatusCodes } from 'http-status-codes';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LibraryNodeResponse } from '../../data-providers/library.service';
import { LibraryNodeAdapter } from '../../models/library-node.model';
import { CreateQuestionnaire } from '../models/create-questionnaire.model';
import { RunQuestionnaire, RunQuestionnaireAdapter } from '../models/run-questionnaire.model';

export interface ActivityResponse {
  activity?: RunQuestionnaire;
  success: boolean;
  code?: string;
}

@Injectable({
  providedIn: 'root',
})
export class QuestionnaireService {

  apiUrl = '';

  constructor(
    private questionnaireAdapter: RunQuestionnaireAdapter,
    private nodeAdapter: LibraryNodeAdapter,
    private http: HttpClient
  ) {
    if (environment.hasOwnProperty('apiUrl'))
      this.apiUrl = environment.apiUrl;
  }

  getActivity(activityId: string): Promise<ActivityResponse> {

    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    let url = this.apiUrl;

    if (activityId) {
      url += `/activities/${activityId}`
    }

    return this.http.
      get(url, {
        headers: headers,
        observe: "response"
      }).pipe(
        map(response => {
          let responseStatus = response.status;

          let responseData: any = response.body

          if (responseStatus == StatusCodes.OK) {
            if (responseData != null) {

              let rq = this.questionnaireAdapter.adapt(responseData);

              return { activity: rq, success: true };
            }
            else {
              return { success: false };
            }
          }
          else {

            return { success: false };
          }
        }),
        catchError(err => {
          const responseObject: ActivityResponse = {
            success: false,
            code: err
          }

          return of(responseObject)
        })
      ).toPromise();
  }

  createQuestionnaire(postData: CreateQuestionnaire) : Promise<LibraryNodeResponse> {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    let url = this.apiUrl + '/library/activity-node';

    return this.http.post<any>(url,
      postData, { observe: "response" }).pipe(map((createResponse) => {

        let responseStatus = createResponse.status;

        let responseData: any = createResponse.body

        if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.CREATED) {
          if (responseData != null) {

            let node = this.nodeAdapter.adapt(responseData);

            return { node: node, success: true };
          }
          else {
            return { success: false };
          }
        }
        else {

          return { success: false };
        }

      }, catchError((error: any) => {

        const responseObject: LibraryNodeResponse = {
          success: false,
          code: error
        }

        return of(responseObject)

      }))).toPromise();
  }

  updateQuestionnaire(postData: CreateQuestionnaire, activityId: string) : Promise<ActivityResponse> {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    let url = `${this.apiUrl}/activities/${activityId}`;

    return this.http.put<any>(url,
      postData, { observe: "response" }).pipe(map((response) => {

        let responseStatus = response.status;

          let responseData: any = response.body

          if (responseStatus == StatusCodes.OK) {
            if (responseData != null) {

              let rq = this.questionnaireAdapter.adapt(postData);

              return { activity: rq, success: true };
            }
            else {
              return { success: false };
            }
          }
          else {

            return { success: false };
          }

      }, catchError((error: any) => {

        const responseObject: ActivityResponse = {
          success: false,
          code: error.message
        }

        return of(responseObject)

      }))).toPromise();
  }
}
