import { LibraryURL, LibraryURLAdapter } from "./library-url.model";
import {
  LibraryFileInfo,
  LibraryFileInfoAdapter,
} from "./library-file-info.model";
import {
  UserProfile,
  UserProfileAdapter,
} from "../../my-profile/models/user-profile.model";
import { Injectable } from "@angular/core";
import { Adapter } from "src/app/common/interfaces/adapter";
import { LibraryNodeType } from "../helper/library.constant";
import {
  LibraryActivityInfo,
  LibraryActivityInfoAdapter,
} from "./library-activity-info.model";

export class LibraryNode {
  id: string;
  title: string;
  type: LibraryNodeType;
  parent: string;
  notesCount: number;
  favorite: boolean = false;
  createdBy: UserProfile;
  createdAt: Date;
  fileInfo: LibraryFileInfo;
  urlInfo: LibraryURL;
  activityInfo: LibraryActivityInfo;
  removed: boolean = false;

  get displayType(): string {
    if (this.fileInfo && this.fileInfo.category) {
      return this.fileInfo.category;
    }

    return this.type;
  }

  getBackgroundClass(): string | undefined {
    let bgClass: string | undefined;

    switch (this.type) {
      case LibraryNodeType.link:
        bgClass = "node-background-link";
        break;
      case LibraryNodeType.folder:
        bgClass = "node-background-folder";
        break;
      case LibraryNodeType.file:
        if (this.fileInfo) bgClass = "node-" + this.fileInfo.getBGClassForList();
        break;
      case LibraryNodeType.activity:
        bgClass = "node-background-activity";
        break;
      default:
        bgClass = "node-background-default";
    }

    bgClass += " node-bg";

    return bgClass;
  }

  update(updatedNode: LibraryNode) {
    if (!updatedNode) {
      return;
    }
    this.id = updatedNode.id;
    this.title = updatedNode.title;
    this.type = updatedNode.type;
    this.parent = updatedNode.parent;
    this.notesCount = updatedNode.notesCount;
    this.favorite = updatedNode.favorite;
    this.createdBy = updatedNode.createdBy;
    this.createdAt = updatedNode.createdAt;
    this.fileInfo = updatedNode.fileInfo;
    this.urlInfo = updatedNode.urlInfo;
    this.activityInfo = updatedNode.activityInfo;
  }
}

@Injectable({
  providedIn: "root",
})
export class LibraryNodeAdapter implements Adapter<LibraryNode> {
  adapt(data: any): LibraryNode {
    let node = new LibraryNode();

    try {
      node.id = typeof data.id === "string" ? data.id : null;
      node.title = typeof data.title === "string" ? data.title : null;
      node.type =
        typeof data.type === "string"
          ? (<any>LibraryNodeType)[data.type]
          : null;
      node.parent = typeof data.parent === "string" ? data.parent : null;
      node.notesCount =
        typeof data.notesCount === "number"
          ? data.notesCount
          : parseInt(data.notesCount);
      node.favorite =
        typeof data.favorite === "boolean" ? data.favorite : false;
      if (typeof data.createdAt === "string") {
        node.createdAt = new Date(data.createdAt);
      }

      if (data.creator) {
        let profileAdapter = new UserProfileAdapter();
        node.createdBy = profileAdapter.adapt(data.creator);
      }

      if (data.fileDetails) {
        let fileAdapter = new LibraryFileInfoAdapter();
        node.fileInfo = fileAdapter.adapt(data.fileDetails);
      }

      if (data.linkDetails) {
        let urlAdapter = new LibraryURLAdapter();
        node.urlInfo = urlAdapter.adapt(data.linkDetails);
      }

      if (data.activity) {
        const activityInfoAdapter = new LibraryActivityInfoAdapter();
        node.activityInfo = activityInfoAdapter.adapt(data.activity);
      }
    } catch (error) {
      console.error("Unable to convert library node response data to node", error);
    }

    return node;
  }
}


@Injectable({
  providedIn: "root",
})
export class LibraryNodeParentAdapter implements Adapter<LibraryNode> {
  adapt(data: any): LibraryNode {
    let node = new LibraryNode();
    try {
      node.id = typeof data.id === "string" ? data.id : null;
      node.title = typeof data.title === "string" ? data.title : null;
      node.type =
        typeof data.type === "string"
          ? (<any>LibraryNodeType)[data.type]
          : null;
      node.parent = typeof data.parent === "string" ? data.parent : null;

    } catch (error) {
      console.error("Unable to convert library node response data to node", error);
    }

    return node;
  }

}