import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterExtService, SpacePickerSelectionEventData } from './../../../common/service/common.service';
import { CommonService } from 'src/app/common/service/common.service';
import { Tag } from "./../../../shared/models/tag.model";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { LearningSpace } from "./../models/learning-space.model";
import {
  LS_KEY_SPACE_SORT_ORDER,
  LS_KEY_SPACE_SORT_KEY,
  SpacesViewType,
  AckResponse,
  USER_PRIVILEGES,
  SELECTION_MODE,
  SIDE_NAV_CONTEXT,
} from "./../../../common/constants";
import {
  SpaceSort,
  SpaceContext,
  SpaceSortOrder,
  SpaceSortField,
  SpaceType,
  SpaceFilter,
} from "./../helper/space.constant";
import {
  SpaceService,
  SpaceListResponse,
  SpaceCountResponse,
} from "./../data-providers/space.service";
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MainService } from "../../main.service";
import { MatDialog } from "@angular/material/dialog";
import { CreateLearningSpaceDialogComponent } from "./create-learning-space-dialog/create-learning-space-dialog.component";
import { Subscription } from "rxjs";
import { ThemePalette } from "@angular/material/core";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";
import { AuthenticationService } from "src/app/core/service/authentication.service";
import { LibraryNodeType } from "../../library/helper/library.constant";
import { MatChipList } from "@angular/material/chips";
import { SelectionModel } from "@angular/cdk/collections";
import { CdkDragEnter, CdkDropList, CdkDropListGroup, moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: "app-spaces-list",
  templateUrl: "./spaces-list.component.html",
  styleUrls: ["./spaces-list.component.scss"],
})
export class SpacesListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() showHeaderActions: boolean = true;
  @Input() context: SpaceContext;
  @Input() contextData: any | undefined;
  @Input() keyword: string | undefined;
  @Input("userID") userID: string;
  @Input() isCurrentUser: boolean = true;
  @Input() showActionBar: boolean = true;
  @Input() showViews: boolean = true;
  @Input() showAddSpaceToPath: boolean = false;

  @Output() completeEvent = new EventEmitter<{ count: number; type: string }>();
  @Output() didUpdateSelection = new EventEmitter<{ sCount: number }>();
  @Output() postSpacesToPathCompletionEvent = new EventEmitter<{
    success: boolean;
  }>();
  @Input() viewType: SpacesViewType;
  @Input() multipleTagSelection: boolean = false;
  @Input() displayLearningPathOnly = false;
  @Input() selectionMode = SELECTION_MODE.NONE;
  @Input() showTitle = true;
  @Input() createSpaceType: SpaceType;
  @Input() showDetailAfterSpaceCreation = true;
  @Input() listSpaceType: SpaceType = SpaceType.learningspace;
  @Input() displayTitle: string;
  @Input() defaultSelectedIds: [string] | undefined;
  @Input() canMove = false;
  @Input() canRemove = false;
  @Input() filters: SpaceFilter[] | undefined;
  selection: SelectionModel<string>;
  selectionChangeObserver: Subscription;
  pickerSelectionEventObserver: Subscription;
own:number;
bookmarked:number;
sharedwithme:number;
  typeEnum = SpaceType;
spaceserrormsg:boolean;
  @ViewChild("tagList") tagChipList: MatChipList;

  color: ThemePalette = "primary";
  mode: ProgressSpinnerMode = "indeterminate";
  fetchingSpaces: boolean = false;
  updatingSpaces: boolean = true;
  errorMessage?: string;
  area: any;
  errorMessagesmall?: string;
  errorMessagevsmall?: string;
  errorMessageButtonTitle: string | undefined = "Retry";
  errorMessageButtonActionType: string | undefined = "retry";
  showError: boolean = false;
  sort?: SpaceSort;
  spaces: LearningSpace[] | undefined;
  allSpaces: LearningSpace[] | undefined;
  private paramSubscription?: Subscription;
  scrollDistance = 1;
  scrollUpDistance = 2;
  throttle = 300;
  currentPage: number = 1;
  canMarkLSPForWebsiteAccess: boolean = false;
  tags: Tag[] | undefined;

  target: CdkDropList | undefined;
  targetIndex: number;
  source: CdkDropList | undefined;
  sourceIndex: number;
  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;

  viewTypeEnum = SpacesViewType;
  nodeType = LibraryNodeType;

  @ViewChild("scrollContent") scrollContent: ElementRef;

  constructor(
    private _router: Router,
    public mainService: MainService,
    private _spaceService: SpaceService,
    private route: ActivatedRoute,
    private router: Router,
    public _dialog: MatDialog,
    private _commonService: CommonService,
    private _authenticationService: AuthenticationService,
    private _snackBar: MatSnackBar,
    private routerExtService: RouterExtService
  ) {

  }

  ngOnInit(): void {
    this.getCounts(this.listSpaceType)
    if (!this.displayTitle) {
      this.displayTitle = "Space";
    }

    if (!this.createSpaceType) {
      this.createSpaceType = SpaceType.learningspace;
    }

    this.mainService.learnigSpaceComponent = true;
    this.mainService.mySpaces_lg = 100;
    this.mainService.mySpaces_md = 100;
    this.mainService.serviceCard_lg = 25;
    -
      this.registerForRouteParams();

    if (this.context !== SpaceContext.PUBLIC_SPACE) {
      const _canMarkLSPForWebsiteAccess =
        this._authenticationService.getUserPrivilegesFromLocalStorage(
          USER_PRIVILEGES.CAN_MARK_LSP_FOR_WEBSITE_ACCESS
        );
      if (typeof _canMarkLSPForWebsiteAccess === "boolean") {
        this.canMarkLSPForWebsiteAccess = _canMarkLSPForWebsiteAccess;
      }
    }

    if (this.context === SpaceContext.LEARNING_PATH_SPACE) {
      this.pickerSelectionEventObserver = this._commonService
        .getSpacePickerSubject()
        .subscribe((data: SpacePickerSelectionEventData) => {
          if (
            data &&
            data.pathID === this.contextData.lspID &&
            data.selectedIds.length > 0
          ) {
            // 1 - Check if existing spaces are available, if yes prepare the spaceIds
            let spaceIds: string[] = this.spaces
              ? this.spaces.map((s) => s.id)
              : [];

            spaceIds.push(...data.selectedIds);

            this.postSpacesToPath(spaceIds, data.pathID);
          }
        });
    }

    // setInterval(()=>{

    //   this.scrollRight();
    // },1400)

    if (this.selectionMode && (this.selectionMode == SELECTION_MODE.MULTIPLE || this.selectionMode == SELECTION_MODE.SINGLE)) {
      this.selection = new SelectionModel<string>(
        true,
        this.defaultSelectedIds
      );

      if (this.defaultSelectedIds && this.defaultSelectedIds.length > 0) {
        this.didUpdateSelection.next({
          sCount: this.selection.selected.length,
        });
      }

      this.selectionChangeObserver = this.selection.changed
        .asObservable()
        .subscribe((s) => {
          if (s && s.source && this.didUpdateSelection) {
            this.didUpdateSelection.next({ sCount: s.source.selected.length });
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('test on chnages')
    for (const propName in changes) {
      
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case "filters":
          case "keyword":
            {
              this.getLearningSpaces(
                this.context,
                undefined,
                this.userID,
                1,
                this.listSpaceType
              );
            }
            break;
        }
      }
    }
  }

  registerForRouteParams() {
    this.paramSubscription = this.route.paramMap.subscribe(
      (params: ParamMap) => {
        const url = this.router.url;
        if (url && url.indexOf("/bookmarked") !== -1) {
          this.context = SpaceContext.BOOKMARKED_SPACE;
        } else if (url && url.indexOf("/my-spaces") !== -1) {
          this.context = SpaceContext.MY_SPACE;
        } else if (url && url.indexOf("/shared") !== -1) {
          this.context = SpaceContext.SHARED_SPACE;
        } else if (url && url.indexOf("/public") !== -1) {
          this.context = SpaceContext.PUBLIC_SPACE;
          this.showHeaderActions = false;
        }

        if (this.context !== SpaceContext.PUBLIC_SPACE) {
          this.sort = this.getSortFromLocalStorage();
        } else {
          this.sort = undefined;
        }

        this.getLearningSpaces(
          this.context,
          this.sort,
          this.userID,
          this.currentPage,
          this.listSpaceType
        );
      }
    );
  }

  getSortFromLocalStorage(): SpaceSort | undefined {
    try {
      if (this.keyword && this.keyword.length > 0) {
        // Nothing to do. Defaults to list
        return undefined;
      } else {
        const so = localStorage.getItem(LS_KEY_SPACE_SORT_ORDER);
        const sk = localStorage.getItem(LS_KEY_SPACE_SORT_KEY);

        let _sort: SpaceSort | undefined = undefined;
        if (so && sk) {
          _sort = {
            sortOrder: (<any>SpaceSortOrder)[so],
            sortBy: (<any>SpaceSortField)[sk],
          };
        }
        return _sort;
      }
    } catch (error) {
      console.error("Unable to read from local storage", error);
      return undefined;
    }
  }
  get canSelectCompleteCard(): boolean {
    return (
      this.selectionMode === SELECTION_MODE.SINGLE ||
      this.selectionMode === SELECTION_MODE.MULTIPLE
    );
  }
  get showTags(): boolean {
    return this.context === SpaceContext.PUBLIC_SPACE;
  }

  get canShowHeader(): boolean {
    return !(
      this.context === SpaceContext.USER_PROFILE_SPACE ||
      this.context === SpaceContext.SEARCH_RESULTS_SPACE ||
      this.context === SpaceContext.BYNDR_SPACE ||
      this.context === SpaceContext.PUBLIC_SPACE ||
      this.context === SpaceContext.LEARNING_PATH_SPACE
    );
  }

get searchresults():boolean{
  return this.context === SpaceContext.SEARCH_RESULTS_SPACE
}

  get learningPathContext(): boolean {
    return this.context === SpaceContext.LEARNING_PATH_SPACE;
  }

  get learningListPath(): boolean {

    return this.listSpaceType === SpaceType.learningpath;
  }
  get learningListSpace(): boolean {

    return this.listSpaceType === SpaceType.learningspace;
  }

  get actionBarReadOnly(): boolean {
    return (
      this.context === SpaceContext.PUBLIC_SPACE || this.canSelectCompleteCard
    );
  }

  get spacesCountText(): string {
    
    return this.spaces ? "(" + this.spaces.length + ")" : "";
  }

  get canShowProfileInfo(): boolean {
    return (
      this.context === SpaceContext.BYNDR_SPACE ||
      this.context === SpaceContext.ADD_LEARNING_PATH_SPACE ||
      this.context === SpaceContext.LEARNING_PATH_SPACE ||
      this.context === SpaceContext.PUBLIC_SPACE ||
      this.context === SpaceContext.CONNECTION_SPACE ||
      this.context === SpaceContext.BOOKMARKED_SPACE ||
      this.context === SpaceContext.SHARED_SPACE
    );
  }

  storeSort() {
    if (this.sort) {
      localStorage.setItem(LS_KEY_SPACE_SORT_ORDER, this.sort.sortOrder);
      localStorage.setItem(LS_KEY_SPACE_SORT_KEY, this.sort.sortBy);
    }
  }

  newLearningSpaceDialog() {
    console.log(this.displayTitle)
    const thisReference = this;
    const dialogRef = this._dialog.open(CreateLearningSpaceDialogComponent, {
      width: "700px",
      maxHeight: "80vh",
      id: "create_learning_space_dialog",
      data: {
        spaceType: this.createSpaceType,
        displayTitle: 'Learning '+ this.displayTitle,
      },
    });

    const dialogResultSubscription =
      dialogRef.componentInstance.createSpaceEvent.subscribe(
        (_space: LearningSpace) => {
          console.log("Got the event from create folder popup", _space);
          dialogResultSubscription.unsubscribe();
          if (this.showDetailAfterSpaceCreation) {
            thisReference.redirectToLearningSpaceDetail(_space.id, _space.type);
          }
          else {
            thisReference.refresh();
          }
        }
      );
  }

  raiseCompleteEvent() {
    this.completeEvent.emit({
      count: this.spaces ? this.spaces.length : 0,
      type: "spaces",
    });
  }

  spaceCardTapEvent(_space: LearningSpace, _spaceType: SpaceType) {
    if (this.canMove || _space.isRemoved) return;

    switch (this.selectionMode) {
      case SELECTION_MODE.NONE:
        this.redirectToLearningSpaceDetail(_space.id, _spaceType);
        break;
      case SELECTION_MODE.SINGLE:
        if (this.selection.selected.length > 0) {
          if (_space.id !== this.selection.selected[0]) {
            this.selection.clear();
            this.selection.toggle(_space.id);
          } else {
            this.selection.clear();
          }
        } else {
          this.selection.toggle(_space.id);
        }
        break;
      case SELECTION_MODE.MULTIPLE:
        this.selection.toggle(_space.id);
        break;
      default:
        this.redirectToLearningSpaceDetail(_space.id, _spaceType);
        break;
    }
  }
storeLocationPath()
{
  let currentURL=  this.routerExtService.getCurrentUrl();
if (currentURL.indexOf('/detail/') == -1 )
{
  localStorage.setItem('locationURL', currentURL)
}


}

  redirectToLearningSpaceDetail(spaceId: string, _spaceType: SpaceType) {

   this.storeLocationPath();
    if (!spaceId) return;

    if (_spaceType == SpaceType.learningspace) {
      const openInNewTab = (this.context === SpaceContext.PUBLIC_SPACE || this.context === SpaceContext.ADD_SPACE_TO_LEARNING_PATH);

      if (openInNewTab) {
        const url = window.location.origin + "/space/" + spaceId;
        window.open(url);
      } else {
        if (this.context === SpaceContext.LEARNING_PATH_SPACE) {
          this._router.navigate(["s", spaceId], { relativeTo: this.route });
        } else {
          this._router.navigate(["/main/spaces/detail", spaceId]);
        }
      }
    } else if (_spaceType == SpaceType.learningpath) {
      const openInNewTab = (this.context === SpaceContext.PUBLIC_SPACE || this.context === SpaceContext.ADD_SPACE_TO_LEARNING_PATH);

      if (openInNewTab) {
        const url = window.location.origin + "/learning-path/" + spaceId;
        window.open(url);
      } else {
        this._router.navigate(["/main/learning-paths/detail", spaceId]);
      }
    }
  }

  async openPathPicker(_space: LearningSpace) {
    if (!_space) return;

    const { LearningPathPickerComponent } = await import('./../../learning-path/components/learning-path-picker/learning-path-picker.component');

    const dialogRef = this._dialog.open(LearningPathPickerComponent, {
      width: "600px",
      panelClass: ["customModal", "learningpathModal"],
      data: {
        space: _space,
      },
    });
  }

  addSpaceToLearningPathEvent(_path: LearningSpace) {

  }

  showSidebar() {

    this.mainService.isMySpacesInfo = true;
    this.mainService.sidebarOpened();
    this.mainService.isCompleteSection = false;
    this.mainService.isNotificationInfo = false;
    this.mainService.isMessageInfo = false;
    this.mainService.isInviteInfo = false;
    this.mainService.mySpaces_lg = 70;
    this.mainService.mySpaces_md = 50;
    this.mainService.serviceCard_lg = 33.33;
  }

  errorMessageButtonEvent() {
    this.getLearningSpaces(
      this.context,
      this.sort,
      this.userID,
      this.currentPage,
      this.listSpaceType
    );
  }

  resetError() {
    this.errorMessage = undefined;
    this.errorMessagesmall = undefined;
    this.errorMessagevsmall = undefined;
    this.errorMessageButtonTitle = undefined;
  }

  removeLearningSpace(_spaceId: string, _spaceType: SpaceType) {
    if (!this.spaces) return;

    const index: number = this.spaces?.findIndex((s) => s.id === _spaceId);

    if (index !== -1) this.spaces?.splice(index, 1);
  }

  refresh() {
    this.getLearningSpaces(
      this.context,
      this.sort,
      this.userID,
      this.currentPage,
      this.listSpaceType
    );
  }

  async getLearningSpaces(
    context: SpaceContext,
    _sort: SpaceSort | undefined,
    _userId: string,
    page: number,
    spaceType: SpaceType
  ) {

    
    //page=-1;
    if (page == 1) {
      this.spaces = [];
    }
    this.fetchingSpaces = true;

    this.showError = false;
    this.resetError();

    try {
      let response: SpaceListResponse = await this._spaceService.getSpaces(
        context,
        _userId,
        page,
        _sort,
        this.keyword,
        spaceType,
        this.contextData,
        this.filters
      );
         if (response.success) {
        
        if (this.mainService.pageTitle && response.spaces) {
        //  this.mainService.pageTitle = this.mainService.pageTitle + "(" + response.spaces?.length + ")"
        this.mainService.pageTitle = this.mainService.pageTitle 
        }

        if (page == 1) {
          this.spaces = response.spaces;
          this.allSpaces = response.replicaSpaces;
          
        } else {
          
          
          if (response.spaces) {
            this.spaces?.push(...response.spaces);
          }
        }
        this.sort = response.sort;

        this.storeSort();

        if (
          this.spaces &&
          Array.isArray(this.spaces) &&
          this.spaces.length > 0
        ) {
          this.showError = false;
        } else {
          
          if (page == 1) {
            this.errorMessage =
              this.context === SpaceContext.LEARNING_PATH_SPACE
                ? "Empty Learning Path"
                : `No ${this.displayTitle}s to show`;
            if (this.isCurrentUser) {
              this.errorMessagesmall =
                this.context === SpaceContext.LEARNING_PATH_SPACE
                  ? "Get started by adding Learning Spaces from published collection"
                  : `Looks like there are no ${this.displayTitle}s created by you`;
              this.errorMessagevsmall =
                this.context === SpaceContext.LEARNING_PATH_SPACE
                  ? undefined
                  : "Why don't you create new one now?";
              this.errorMessageButtonTitle =
                this.context === SpaceContext.LEARNING_PATH_SPACE
                  ? `Add Learning Spaces`
                  : `Create ${this.displayTitle}`;
              this.errorMessageButtonActionType =
                this.context === SpaceContext.LEARNING_PATH_SPACE
                  ? `choose_lsp`
                  : "create_lsp";
            } else {
              this.errorMessagesmall = undefined;
              this.errorMessagevsmall = undefined;
              this.errorMessageButtonTitle = undefined;
            }

            this.showError = true;
            

            
            if(context == 'shared_space')
            {
              this.spaceserrormsg=true;
              this.errorMessage = `No shared ${this.displayTitle}s to show`;
            } else if(context == 'bookmarked_space')
            {
              this.spaceserrormsg=true;
              this.errorMessage = `No bookmarked ${this.displayTitle}s to show`;
            } else
            {
              this.spaceserrormsg=false;
            }

          } else {
            this.currentPage -= 1;
          }
        }

        // Check if all tags are returned
        this.tags = response.tags;
      } else {
        if (page == 1) {
          this.errorMessage = "Oops,something went wrong. Please try again";
          this.showError = true;
          
          this.errorMessageButtonTitle = "Retry";
          this.errorMessageButtonActionType = "retry";
        } else {
          this.currentPage -= 1;
        }
      }

      this.raiseCompleteEvent();
      this.fetchingSpaces = false;
    } catch (error) {
      this.fetchingSpaces = false;
      if (page == 1) {
        this.spaces = undefined;
        this.errorMessage = "Oops,something went wrong. Please try again";
        this.showError = true;
        this.errorMessageButtonTitle = "Retry";
        this.errorMessageButtonActionType = "retry";
      } else {
        this.currentPage -= 1;
      }
      this.raiseCompleteEvent();
    }
  }

  actionBarReadOnlyClickEvent(_space: LearningSpace) {
    this.spaceCardTapEvent(_space, _space.type);
  }

  bookmarkCompletionEvent(_space: LearningSpace) {
    if (_space && this.spaces) {
      if (
        this.context === SpaceContext.BOOKMARKED_SPACE &&
        _space.bookmarks == 0
      ) {
        this.removeSpace(_space);
        if (this.spaces.length === 0) {
          this.errorMessageButtonTitle = "Refresh";
          this.errorMessageButtonActionType = "refresh";
          this.errorMessage = "No spaces available";
          this.showError = true;
        }
      }
    }
  }

  removeSpace(_space: LearningSpace) {
    if (_space && this.spaces) {
      const index = this.spaces.indexOf(_space);
      if (index !== -1) {
        this.spaces?.splice(index, 1);
      }
    }
  }

  onScroll() {
    console.log("Did scroll", this.currentPage);
    if (
      this.context === SpaceContext.BYNDR_SPACE ||
      this.context === SpaceContext.ADD_LEARNING_PATH_SPACE
    ) {
      this.currentPage += 1;
      this.getLearningSpaces(
        this.context,
        this.sort,
        this.userID,
        this.currentPage,
        this.listSpaceType
      );
    }
  }

  tagSelection(_tag: Tag) {
    if (!_tag) return;

    if (!this.multipleTagSelection) {
      // Single selection only
      if (_tag.selected) {
        // User is trying to unselect the tag.
        _tag.selected = !_tag.selected;
      } else {
        // User is trying to select the tag. Unselect the existing tags and select this tag
        const selectedTags = this.tags?.filter((_t) => _t.selected);
        selectedTags?.map((_t) => {
          _t.selected = false;
        });
        _tag.selected = !_tag.selected;
      }
    } else {
      // Multiple selection
      _tag.selected = !_tag.selected;
    }

    this.filterSpacesBySelectedTags();

    if (this.spaces) this.sortByUpdatedDate(this.spaces);
  }

  filterSpacesBySelectedTags() {


    if (!this.allSpaces) return;

    const selectedTags = this.tags?.filter((_t) => _t.selected);
    if (!selectedTags || selectedTags.length == 0) {
      this.spaces = this.allSpaces;
    } else {
      const filteredSpaces = this.allSpaces?.filter((_s) => {
        const filteredTags = _s.filterTag(selectedTags);
        return (
          filteredTags && Array.isArray(filteredTags) && filteredTags.length > 0
        );
      });
      this.spaces = filteredSpaces;
    }
  }

  public sortByUpdatedDate(_spaces: LearningSpace[]): void {
    _spaces.sort((a: LearningSpace, b: LearningSpace) => {
      return b.updatedAt.valueOf() - a.updatedAt.valueOf();
    });
  }

  observeTagSelectionChanges() {
    // this.tagChipList.chipSelectionChanges
    //   .pipe(
    //     untilDestroyed(this),
    //     map((event: any) => event.source))
    //   .subscribe((chip: MatChip) => {
    //     if (chip.selected) {
    //       this.value = [...this.value, chip.value];
    //     } else {
    //       this.value = this.value.filter((o) => o !== chip.value);
    //     }
    //   });
  }

  // scrollRight(){
  //   console.log(this.scrollContent);
  //   if(this.scrollContent !=undefined)
  //   {
  //   this.scrollContent.nativeElement.scrollLeft += 200;
  //   }

  // }

  errorMessageButtonClick(contextTxt?:any) {
    if (!this.errorMessageButtonActionType) {
      this.getLearningSpaces(
        this.context,
        this.sort,
        this.userID,
        this.currentPage,
        this.listSpaceType
      );
    } else {
      switch (this.errorMessageButtonActionType) {
        case "create_lsp":
          this.newLearningSpaceDialog();
          break;
        case "choose_lsp":
          this.openSpacePicker();
          break;
         
        default:
          if(contextTxt =='search')
          {
            this.newLearningSpaceDialog();
          } else
          {  
          this.getLearningSpaces(
            this.context,
            this.sort,
            this.userID,
            this.currentPage,
            this.listSpaceType
          );
          }
      }
    }
  }

  openSpacePicker() {
    this._commonService.raiseSideNavigationEvent({
      context: SIDE_NAV_CONTEXT.CONTEXT_SPACE_PICKER,
      data: {
        selectionMode: SELECTION_MODE.MULTIPLE,
        pathID: this.contextData.lspID,
      },
      action: "open",
    });
  }


  async postSpacesToPath(
    spaceIds: string[],
    pathID: string,
    requiresRefresh = true
  ) {
    if (!spaceIds) return;

    const spacesObj: { LSPId: string; position: number }[] = [];

    spaceIds.forEach((s, index) => {
      spacesObj.push({ LSPId: s, position: index });
    });

    this.updatingSpaces = !requiresRefresh;

    this._commonService.updateSpacePickerSubmissionInProgressEvent({
      submissionInProgress: true,
      pathID: pathID,
    });
    try {
      let response: SpaceListResponse =
        await this._spaceService.postSpaceToPath(spacesObj, pathID);

      if (response.success) {
        if (requiresRefresh) {
          this.showSnackBar(`Spaces Added Successfully`, "OK");

          this._commonService.raiseSideNavigationEvent({
            context: SIDE_NAV_CONTEXT.CONTEXT_SPACE_PICKER,
            action: "close",
          });

          this.getLearningSpaces(
            this.context,
            this.sort,
            this.userID,
            this.currentPage,
            this.listSpaceType
          );
        }

        this.postSpacesToPathCompletionEvent.next({ success: true });
      } else {
        this.showSnackBar(
          `Unable to update spaces to learning path. Please try again.`,
          "OK"
        );
        this.postSpacesToPathCompletionEvent.next({ success: false });
      }

      if (this.updatingSpaces) this.updatingSpaces = false;
    } catch (error) {
      this._commonService.updateSpacePickerSubmissionInProgressEvent({
        submissionInProgress: false,
        pathID: pathID,
      });
      this.showSnackBar(`Oops,something went wrong. Please try again`, "OK");

      if (this.updatingSpaces) this.updatingSpaces = false;

      this.postSpacesToPathCompletionEvent.next({ success: false });
    }

    if (requiresRefresh) {
      this._commonService.updateSpacePickerSubmissionInProgressEvent({
        submissionInProgress: false,
        pathID: pathID,
      });
    }
  }

  async appendSpaceToPath(
    spaceId: string,
    pathID: string,
  ) {
    if (!spaceId) return;

    this.updatingSpaces = true;

    try {
      let response: SpaceListResponse =
        await this._spaceService.appendSpaceToPath(spaceId, pathID);

      if (response.success) {
        const snackBar = this._snackBar.open(`Space Added Successfully to Learning Path`, "VIEW PATH", { duration: 5000 });
        const actionSubscription = snackBar.onAction().subscribe(() => {
          if (actionSubscription)
            actionSubscription.unsubscribe();

          this.redirectToLearningSpaceDetail(pathID, SpaceType.learningpath);

        });
        this.postSpacesToPathCompletionEvent.next({ success: true });
      } else {
        this.showSnackBar(
          `Unable to update space to learning path. Please try again.`,
          "OK"
        );
        this.postSpacesToPathCompletionEvent.next({ success: false });
      }

      this.updatingSpaces = false;
    } catch (error) {

      this.showErrorDialog(error && (typeof error === 'string') ? error : `Oops,something went wrong. Please try again`);

      this.updatingSpaces = false;

      this.postSpacesToPathCompletionEvent.next({ success: false });
    }
  }

  showErrorDialog(error: any) {

    const dialogRef = this._dialog.open(InformationDialogComponent, {
      width: '400px',
      id: 'information_dialog'
    })
    dialogRef.componentInstance.content = error
  }

  dragEntered(event: CdkDragEnter<number>) {
    console.log("Test", event);
    if (!this.spaces) return;

    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector(".cdk-drag-placeholder");
    if (phElement) {
      phContainer.removeChild(phElement);
      if (phContainer.parentElement)
        phContainer.parentElement.insertBefore(phElement, phContainer);
    }

    moveItemInArray(this.spaces, dragIndex, dropIndex);
  }

  postSpacesToPathEvent() {
    const spaceIds = this.spaces ? this.spaces.map((s) => s.id) : [];

    this.postSpacesToPath(spaceIds, this.contextData.lspID, false);
  }

  dropComplete(event: CdkDragDrop<LearningSpace[]>) {
    if (!this.spaces) return;

    moveItemInArray(this.spaces, event.previousIndex, event.currentIndex);
  }

  showSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 5000 });
  }

  ngOnDestroy(): void {
    if (this.paramSubscription) this.paramSubscription.unsubscribe();

    if (this.completeEvent) this.completeEvent.unsubscribe();

    if (this.selectionChangeObserver)
      this.selectionChangeObserver.unsubscribe();

    if (this.pickerSelectionEventObserver)
      this.pickerSelectionEventObserver.unsubscribe();

    if (this.postSpacesToPathCompletionEvent)
      this.postSpacesToPathCompletionEvent.unsubscribe();
  }









// new code 
async getCounts(countsSpaceType:SpaceType){
  try {
  let response: SpaceCountResponse = await this._spaceService.spaceCountView(countsSpaceType);
     if (response.success) {
    console.log(response.space)

this.own= (response.space && response.space.own)  ? response.space.own : 0;
this.bookmarked= (response.space && response.space.bookmarked)  ? response.space.bookmarked : 0;
this.sharedwithme= (response.space && response.space.sharedWith)  ? response.space.sharedWith : 0;

    // if(response.space && response.space.own)
    // {
    //   this.own=response.space.own
    // }
    
    // if(response.space && response.space.bookmarked)
    // {
    //   this.bookmarked=response.space.bookmarked
    // }
    
    // if(response.space && response.space.sharedWith)
    // {
    //   this.sharedwithme=response.space.sharedWith
    // }
  } 

}
 catch (error) {
  
}
}
// new code







}
