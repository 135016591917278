import { SpacesListComponent } from './../spaces-list.component';
import { SpacesViewType } from 'src/app/common/constants';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpaceContext } from 'src/app/main/spaces/helper/space.constant';
import { SpaceType } from './../../helper/space.constant';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';


export interface SpaceListEditDialogData {
  LP_SPACE_CONTEXT: SpaceContext;
  LP_SPACE_CONTEXT_DATA: any;
  userID: string;
  listSpaceType: SpaceType;
  viewType: SpacesViewType;
}


@Component({
  selector: 'app-space-list-edit-dialog',
  templateUrl: './space-list-edit-dialog.component.html',
  styleUrls: ['./space-list-edit-dialog.component.scss']
})
export class SpaceListEditDialogComponent implements OnInit {

  submissionInProgress = false;
  @ViewChild("spaceListComponent") spaceListComponent: SpacesListComponent;

  constructor(
    public dialogRef: MatDialogRef<SpaceListEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SpaceListEditDialogData) { }

  ngOnInit(): void {
  }

  onCloseClick(){
    this.dialogRef.close();
  }

  doneClick(): void {
    this.submissionInProgress = true;
    this.spaceListComponent.postSpacesToPathEvent();    
  }


  postSpacesToPathCompletionEvent(data: {success: boolean}) {

    if (data) {
      this.submissionInProgress = false;

      if (data.success) {
        this.dialogRef.close({action: 'done'});
      }
    }

  }

}
