<mat-drawer-container hasBackdrop="true">
  <mat-drawer class="over-panel" #drawer mode="over" position="end">
    <app-library-picker
      *ngIf="_authenticationService.isLoggedIn && space && space.type === typeEnum.learningspace"
      class="add-new-space-library-picker"
      [autoSelectNewlyAddedNode]="true"
      (nodeSelectedEvent)="nodeSelectedForNewBlock($event); drawer.close()"
      (closeEvent)="drawer.close()"
    >
    </app-library-picker>
  </mat-drawer>
  <mat-drawer-content>
    <div class="create-lsp-header" *ngIf="space" fxLayout="row wrap" fxLayoutAlign="start center">
      <ng-container *ngIf="!fetchingSpace; else ShowHeaderLoader">
        <div fxFlex="initial" fxLayoutAlign="start center" *ngIf="space">
          <div *ngIf="space.createdBy && readonly && viewMode === VIEW_MODE_CONSUMER">
          <app-profile-image class="cursor-pointer" [viewMode]='true' [userDetails]='space.createdBy'
          [userID]='space.createdBy.id' (click)="redirectToUserProfile(space.createdBy.id, true)">
      </app-profile-image>
</div>
<div>
  <div class="titlewrap">
<h3 class="inner_title">{{pageTitle}}</h3>
<!-- *ngIf="(headerContext === SPACES_DETAILS || headerContext === LEARNING_PATH_DETAILS) && !mainService.isSearchBar" -->
<div>
  <ng-container *ngIf="_commonService.showSpacesEditOption">
      <button mat-icon-button (click)="openEditLearningSpaceDialog()">
        <!-- (click)="editSpaceClickEvent()" -->
          <mat-icon>edit</mat-icon>
      </button>
  </ng-container>
  <button *ngIf="_commonService.showSpacesMoreOption" mat-icon-button [matMenuTriggerFor]="spaceActions" aria-label="">
      <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #spaceActions="matMenu">
      <button mat-menu-item (click)="openDeleteLearningSpaceDialog()"> 
         <!-- (click)="deleteSpaceClickEvent()" -->
          <mat-icon >delete</mat-icon>
          Delete
      </button>
  </mat-menu>
</div>
</div>



      <ul class="profilelist">
          <li class="profilename" *ngIf="space.createdBy && readonly && viewMode === VIEW_MODE_CONSUMER">
             <a [routerLink]="['/main/profile/u/', space.createdBy.id]">
              {{ space.createdBy.displayName }}</a>
             </li>
          <li class="">
            <span class="iconDates icon_published"></span> {{ space.updatedAt | amTimeAgo }}
          </li>

          <li class="cursor-pointer linkTxt" *ngIf="
                    !readonly && viewMode === VIEW_MODE_EDITOR && space.publishedId
                  " (click)="redirectToLearningSpaceDetail(space.publishedId, space.type, true)">
                  <span class="iconDates icon_round"></span>
            View Published Version
        </li>
        </ul>
        </div>
        </div>
        <div fxFlex fxLayoutAlign="end center">
          <ng-container
            *ngIf="space && !readonly && viewMode === VIEW_MODE_EDITOR && space.type === typeEnum.learningpath && !fetchingSpaces">
            <button class="btn btn-add btn-edit mr-10"
              [disabled]="saving || !this.spacePathListComponent.spaces || (this.spacePathListComponent.spaces && this.spacePathListComponent.spaces.length == 0)"
              (click)="openSpacesEditPicker()" matTooltip="Re-order/Edit Spaces">
    
              <mat-icon>edit</mat-icon>
            </button>
    
    
            <button class="btn btn-add mr-10" [disabled]="saving" (click)="openSpacesPicker()" matTooltip="Add Spaces">
    
              <mat-icon>add</mat-icon>
            </button>
    
          </ng-container>
    
    
          <ng-container *ngIf="readonly">
            <!-- <p class="p-small-grey14 mr-20" *ngIf="space.publishedAt">
              Published: {{ space.publishedAt | amTimeAgo }}
            </p> -->
    
           <span  class="mat-info" (click)="showInfoDialog()"><mat-icon>info_outline</mat-icon></span> 
    
            <app-learning-space-action-bar class="learning-flex" [className]="'learning-flex'" [showNumbers]="true" [showViews]="true" [space]="space">
            </app-learning-space-action-bar>
            <button mat-raised-button class="present-path" *ngIf="space.published && space.type == typeEnum.learningspace" (click)="openPathPicker(space);">
              <img  class="" src="../../../../assets/images/icons/ic_learning_path_black.svg">
            </button>
            <button mat-raised-button class="present-space" [disabled]="fetchingBlocks" (click)="presentSpace()" *ngIf="displayFullscreen">
              <img  class="full_screen" src="../../../../assets/images/icons/ic_fullscreen.png">
            </button>


          </ng-container>
          <button mat-raised-button class="btn btn-red mr-10 roundedBtn" [disabled]="canDisablePublish" *ngIf="
                    !readonly && viewMode === VIEW_MODE_EDITOR && space.publishedId
                  " (click)="discardDraft()">
            Discard
          </button>
          <button mat-raised-button class="btn btn-primary mr-20 roundedBtn" *ngIf="!readonly && viewMode === VIEW_MODE_EDITOR"
            [disabled]="canDisablePublish" (click)="publishSpace()">
            {{ space.publishedId ? "Save & Publish" : "Publish" }}
            <!-- {{ saving? 'Saving...': 'Save' }} -->
          </button>
          <ng-container *ngIf="_authenticationService.isLoggedIn && space.published && !space.publishedId && currentUser">
            <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="more" class="mat_more_vert">
              <mat-icon class="cursor-pointer mr-10 ml-10">more_vert</mat-icon>
            </button>
            <mat-menu #moreMenu="matMenu">
              <button mat-menu-item *ngIf="space.published" (click)="mutateDraftEvent()">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
                <!-- <span> {{space.published && space.draftId? 'View Draft': 'Edit'}} </span> -->
              </button>
              <!-- <button mat-menu-item>
                                    <mat-icon>delete</mat-icon>
                                    <span>Delete</span>
                                </button> -->
            </mat-menu>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #ShowHeaderLoader>
     
    <div fxFlex="initial" fxLayoutAlign="start center"><ngx-skeleton-loader
      count="2"
      [theme]="{
        height: '46px',
        width: '160px',
        marginRight: '10px',
        marginBottom:0
      }"
    ></ngx-skeleton-loader></div>
    <div fxFlex  class="loadheader" fxLayoutAlign="end center"> <ngx-skeleton-loader
      count="8"
      [theme]="{
        height: '46px',
        width: '70px',
        marginRight: '10px',
        marginBottom:0
      }"
    ></ngx-skeleton-loader></div>
    
      </ng-template>
    
    </div>
    <div class="learning-space-detail">
      <ng-container *ngIf="fetchingSpace; else SpaceLoaded">
        <div class="error-message" fxLayout="row" fxLayoutAlign="center center">
          Loading...
        </div>
      </ng-container>
      <ng-template #SpaceLoaded>
        <ng-container *ngIf="space;else RenderNoSpaceView">
          <ng-container [ngSwitch]="space.type">
            <ng-container *ngSwitchCase="typeEnum.learningspace" [ngTemplateOutlet]="SpaceDetailView">
            </ng-container>
            <ng-container *ngSwitchCase="typeEnum.learningpath" [ngTemplateOutlet]="PathDetailView">
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-template #RenderNoSpaceView>
         <div class="nodata"> Unable to fetch space.</div>
        </ng-template>
      </ng-template>

      
      <ng-template #SpaceDetailView>
        <ng-container *ngIf="fetchingBlocks; else LoadingComplete">
          <div class="error-message" fxLayout="row" fxLayoutAlign="center center">
            Loading Blocks...
          </div>
        </ng-container>
        <ng-template #LoadingComplete>
          <ng-container *ngIf="showError; else showBlocks">
            <ng-container [ngTemplateOutlet]="showMessage"></ng-container>
          </ng-container>
          <ng-template #showBlocks>
            <div
              fxLayout="row wrap"
              fxLayoutWrap="wrap"
              fxLayoutAlign="start start"
            >
              <div
                [fxFlex]="learningSpaceLeftSide_md"
                fxLayoutAlign="start start"
              >
                <gridster #gridster [ngClass]="space && path && path.spacesMeta ? 'gridster-with-bottom-bar': ''" [options]="options">
                  <gridster-item
                    [ngClass]="isGridItemSelected(block) ? 'active-gridItem' : ''"
                    [item]="block"
                    *ngFor="let block of blocks;let i = index"
                  >
                    <div class="gridster-item-content-main">
                      <div class="gridster-item-content">
    

                        <ng-container
                          *ngIf="!readonly && viewMode === VIEW_MODE_EDITOR"
                        >
                          <div
                            class="grid-header"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                          >
                            <div fxFlex="initial" fxLayoutAlign="start center">
                              <!--<span
                                                                  class="icon icon-drag-grey block-drag-handler cursor-pointer"></span>-->
                            </div>
                            <div fxFlex fxLayoutAlign="end center" class="rbtn">
                              <button mat-icon-button>
                                <mat-icon
                                  *ngIf="!(block && block.node)"
                                  class="cursor-pointer mr-10 ml-10 circle-plus-icon"
                                  (click)="openRightSidePanel(block)"
                                  >add_circle
                                </mat-icon>
                                <mat-icon
                                  *ngIf="block && block.node"
                                  (click)="openRightSidePanel(block)"
                                  class="cursor-pointer mr-10 ml-10 circle-plus-icon"
                                >
                                  autorenew
                                </mat-icon>
                              </button>
                              <!--<button class="full-screen-icon"
                                                                  [disabled]="!block || !block.node" mat-icon-button>
                                                                  <mat-icon class="cursor-pointer"
                                                                      (click)="openBlockInFullScreen($event, block)">
                                                                      open_in_full
                                                                  </mat-icon>
                                                              </button>
                                                              <button mat-icon-button [matMenuTriggerFor]="moreMenu"
                                                                  aria-label="more">
                                                                  <mat-icon class="cursor-pointer">more_vert</mat-icon>
                                                              </button>
                                                              <mat-menu #moreMenu="matMenu">
                                                                  <button mat-menu-item (click)="removeBlock($event, block)">
                                                                      <mat-icon>delete</mat-icon>
                                                                      <span>Remove</span>
                                                                  </button>
                                                              </mat-menu>-->
                            </div>
                          </div>
                        </ng-container>
                        <div
                          [ngClass]="[                          
                            'gridster-body',
                            (!_authenticationService.isLoggedIn && block && block.node) ? block.node.getBackgroundClass(): 'node-default-background',
                            readonly ? 'gridster-full-body' : '',
                          ]"
                        >
                          <ng-container
                            *ngIf="block && block.node; else EmptyBlock"
                          >
                            <app-library-node-viewer [node]="block.node" [showThumbnailOnly]="!_authenticationService.isLoggedIn">
                            </app-library-node-viewer>

                            <section class="blockbottom" *ngIf="displayFullscreen && readonly">
                    <h2 class="smallTitle">{{block.node.title | titlecase}}</h2>
                              <button [disableRipple]="true" (click)="clickfullnode(i)" mat-icon-button class="smallfullicon"><span class="icon-fullscreensmall"></span></button>


                           

                          <div class="desciption">    {{getTitle(block.node, i)}}</div>
                              <!-- {{block.node.title}}
                              {{block.node.activityInfo.questionsCount}}
                              {{ block.node.fileInfo.mime}} -->
                              <!-- {{ block.node.fileInfo.mime}} -->

                            </section>
                          </ng-container>
                          <ng-template #EmptyBlock>
                            <div
                              class="empty-block"
                              fxLayout="row"
                              fxLayoutAlign="center center"
                            >
                              <p>Add Content by clicking on the "+"</p>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                      <div *ngIf="block.loading" class="gridster-item-loading">
                        <div class="mat-loading">
                          <mat-spinner
                            strokeWidth="3"
                            diameter="50"
                            color="#fff"
                          ></mat-spinner>
                        </div>
                      </div>
                    </div>
                  </gridster-item>
                </gridster>
              </div>
  
              <div
                *ngIf="isRightSidePanel"
                fxFlex="30"
                fxLayoutAlign="start start"
              >
                <app-library-picker
                  [autoSelectNewlyAddedNode]="true"
                  (nodeSelectedEvent)="nodeSelected($event)"
                  (closeEvent)="closeRightPanel()"
                >
                </app-library-picker>
              </div>
            </div>
          </ng-template>
          <!-- <button mat-fab color="primary" #tooltip="matTooltip" matTooltip="New Block" matTooltipPosition="above"
                      id="add-new-btn" aria-label="Add Block"
                      *ngIf="!fetchingBlocks && (drawer?!drawer.opened: true) && (blocks?(blocks.length < maxBlocks):true) && (!readonly && viewMode === VIEW_MODE_EDITOR)"
                      (click)="closeRightPanel();drawer.toggle()">
                      <mat-icon>add</mat-icon>
                  </button>-->
        </ng-template>
      </ng-template>
      
      <ng-template #PathDetailView>
        <app-spaces-list displayTitle="Learning Path" [showHeaderActions]="true" [context]="LP_SPACE_CONTEXT" [userID]="userID"
          [listSpaceType]="typeEnum.learningpath" [contextData]="LP_SPACE_CONTEXT_DATA" #spacePathListComponent
          (completeEvent)="spacesListCompleteEvent($event)"
          >
          <!-- [canMove]="!readonly && viewMode === VIEW_MODE_EDITOR" [canRemove]="!readonly && viewMode === VIEW_MODE_EDITOR" -->
        </app-spaces-list>
      </ng-template>
      <ng-template #showMessage>
        <div class="error-main">
          <div
            class="w-100 error-message"
            fxLayout="row"
            fxLayoutAlign="center center"
          >
            {{ errorMessage }}
          </div>
          <div class="w-100" fxLayout="row" fxLayoutAlign="center center">
            <button
              mat-raised-button
              class="btn-submit"
              *ngIf="showError && showMessageButton"
              (click)="errorMessageButtonEvent()"
            >
              {{ errorMessageButtonTitle }}
            </button>
          </div>
        </div>
      </ng-template>

      <ng-template #showLoadingMessage>
        <div class="error-message" fxLayout="row" fxLayoutAlign="center center">
          Loading Blocks...
        </div>
      </ng-template>      

      <!-- <div class="bottom-toolbar-container" *ngIf="space && path && path.spacesMeta">
        <mat-toolbar class="bottom-toolbar" color="primary">
          <button mat-raised-button [disabled]="fetchingPath || !path.hasPreviousSpace(space.id)" [matTooltip]="path.hasPreviousSpace(space.id) ? path.spacesMeta[path.spaceMetaIndex(space.id) - 1].title : 'No Space'" matTooltipPosition="above" class="prev-btn" aria-label="Previous Space" (click)="previousSpaceEvent(space)">
            <mat-icon>chevron_left</mat-icon> Previous
          </button>
          <span class="toolbar-spacer"></span>
          <div class="lsp-title-block">
            <span class="lsp-title">{{path.title}}</span>
            <span class="lsp-path-count-subtitle">Space {{path.spaceMetaIndex(space.id) + 1}} of {{path.spacesMeta.length}}</span>
          </div>
          <span class="toolbar-spacer"></span>
            <button mat-raised-button [disabled]="fetchingPath || !path.hasNextSpace(space.id)" [matTooltip]="path.hasNextSpace(space.id) ? path.spacesMeta[path.spaceMetaIndex(space.id) + 1].title : 'No Space'" matTooltipPosition="above" class="btn-primary nxt-btn" aria-label="Next Space" (click)="nextSpaceEvent(space)">
              Next <mat-icon>chevron_right</mat-icon>
            </button>
        </mat-toolbar>
      
      </div> -->


    </div>


    <div class="bottom-toolbar-container" *ngIf="tempSpaceId && path && path.spacesMeta">
      
      <mat-toolbar class="bottom-toolbar" color="primary">

        <button mat-raised-button [disabled]="fetchingPath || !path.hasPreviousSpace(tempSpaceId)" [matTooltip]="path.hasPreviousSpace(tempSpaceId) ? path.spacesMeta[path.spaceMetaIndex(tempSpaceId) - 1].title : 'No Space'" matTooltipPosition="above" class="prev-btn roundedBtn" aria-label="Previous Space" (click)="previousSpaceEvent(tempSpaceId)">
          <mat-icon>chevron_left</mat-icon> Previous
        </button>
        <span class="toolbar-spacer"></span>
        <div class="lsp-title-block">
          <span class="lsp-title">{{path.title}}</span>
          <span class="lsp-path-count-subtitle">Space {{path.spaceMetaIndex(tempSpaceId) + 1}} of {{path.spacesMeta.length}}</span>
        </div>
        <span class="toolbar-spacer"></span>
          <button mat-raised-button [disabled]="fetchingPath || !path.hasNextSpace(tempSpaceId)" [matTooltip]="path.hasNextSpace(tempSpaceId) ? path.spacesMeta[path.spaceMetaIndex(tempSpaceId) + 1].title : 'No Space'" matTooltipPosition="above" class="btn-primary nxt-btn roundedBtn" aria-label="Next Space" (click)="nextSpaceEvent(tempSpaceId)">
            Next <mat-icon>chevron_right</mat-icon>
          </button>


      </mat-toolbar>
    
    </div>



  </mat-drawer-content>
</mat-drawer-container>

<!-- <div class="create-box" fxLayout="row" fxLayoutAlign="center center"> -->
<!-- <div fxFlex="30" fxFlex.md="50" fxFlex-sm="70" fxFlex.xs="100"> -->

<!-- <div class="create-box-list" fxLayout="column">
                <div class="mb-20 create-box-row" fxLayout="row" fxLayoutAlign="center center">
                    <div class="box-col" fxLayout="column">
                        <span class="icon icon-library-box"></span>
                        <p>Library</p>
                    </div>
                    <div class="box-col" fxLayout="column">
                        <span class="icon icon-learning-box"></span>
                        <p>Learning Box</p>
                    </div>
                    <div class="box-col" fxLayout="column">
                        <span class="icon icon-upload-box"></span>
                        <p>Upload</p>
                    </div>
                </div>
                <h4 class="h4-20-black pt-10 mb-20 align-center">Select an element to add to space</h4>
                <div class="split-row" fxLayout="row" fxLayoutAlign="center center">
                    <span class="split-icons">
                        <span class="split-text">Split</span>
                        <span class="icon icon-split-horizontal cursor-pointer"></span>
                        <span class="icon icon-split-vertical cursor-pointer"></span>
                    </span>
                    <span class="delete-icon">
                        <span class="icon icon-delete cursor-pointer"></span>
                    </span>
                </div>
            </div> -->
<!-- </div> -->
<!-- </div>
    <div class="learning-space-footer pt-20" fxLayout="row wrap" fxLayoutWrap="wrap" fxLayoutAlign="start center">
        <div fxFlex="initial" fxLayoutAlign="start center">
            <button class="btn-submit" mat-raised-button>Save Privately</button>
            <button class="btn-grey" mat-raised-button>Publish Space</button>
            <div class="last-modified mb-20">
                <p class="p-small-grey-16 mr-20">Last Modified</p>
                <p class="p-small-blue-16 mr-20">Me</p>
                <p class="p-small-grey-16 mr-20">3 mins ago</p>
            </div>
        </div>
        <div fxFlex fxLayoutAlign="end start" class="pb-15">
            <div class="people-settings">
                <span class="icon icon-people-grey cursor-pointer"></span>
                <span class="icon icon-settings-grey cursor-pointer"></span>
            </div>
            <div class="icon-comment">
                <span class="icon icon-comment-grey cursor-pointer"></span>
            </div>
        </div>
    </div>
</div> -->

<!-- <ng-container  *ngIf="isShowLearningSpacePopup"> -->
<!-- <app-learning-space-popup [isShowLearningSpacePopup]="isShowLearningSpacePopup"></app-learning-space-popup> -->
<!-- </ng-container> -->
<section class="tempbackdrop" *ngIf="tempbackdrop"></section>