import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SpaceService, TemplateResponse } from '../../data-providers/space.service';
import { SpaceTemplate } from '../../models/space-template.model';

@Component({
  selector: 'app-choose-template',
  templateUrl: './choose-template.component.html',
  styleUrls: ['./choose-template.component.scss']
})
export class SeleceTemplateComponent implements OnInit, OnDestroy {

  templates?: SpaceTemplate[];
  showError: boolean = false;
  loading: boolean = false;
  errorMessage: string | undefined;
  errorMessageButtonTitle: string;
  selectedTemplate: SpaceTemplate;

  @Output() templatesLoadedEvent = new EventEmitter();
  @Output() templateSelectedEvent = new EventEmitter<SpaceTemplate>();

  constructor(private spaceService: SpaceService) { }



  ngOnInit(): void {
    this.getTemplates();
  }

  async getTemplates() {

    this.templates = [];
    this.loading = true;
    this.showError = false;
    this.errorMessage = undefined;

    try {
      let response: TemplateResponse = await this.spaceService.getTemplates();

      if (response.success) {
        this.templates = response.templates;

        if (this.templates && Array.isArray(this.templates) && this.templates.length > 0) {

          this.showError = false;
        }
        else {
          this.errorMessageButtonTitle = "Refresh";
          this.errorMessage = "No templates available";
          this.showError = true;

        }

      }
      else {
        this.errorMessage = "Unable to load templates. Please try again";
        this.showError = true;
        this.errorMessageButtonTitle = "Retry";
      }

      this.loading = false;
      this.templatesLoadedEvent.next();

    } catch (error) {
      this.templates = []
      this.loading = false;
      this.errorMessage = "Oops,something went wrong. Please try again";
      this.showError = true;
      this.errorMessageButtonTitle = "Retry";
      this.templatesLoadedEvent.next();
    }
  }

  loadTemplates() {
    this.getTemplates();
  }

  chooseTemplate(_template: SpaceTemplate) {
    this.selectedTemplate = _template;
    this.templateSelectedEvent.next(this.selectedTemplate);
  }

  ngOnDestroy(): void {

    if (this.templatesLoadedEvent)
      this.templatesLoadedEvent.unsubscribe();

    if (this.templateSelectedEvent)
      this.templateSelectedEvent.unsubscribe();

    console.log('Template component is destroyed');
  }

}
