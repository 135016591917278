import { LibraryNode } from './../../library/models/library-node.model';
import { LearningSpace } from './../../spaces/models/learning-space.model';
import { CommentResponse, CommentsListResponse, CommentsService, } from './../data-providers/comments.service';
import { SideNavData } from './../../../common/service/common.service';
import { Component, Input, OnInit, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { Comment } from '../model/comment.model';
import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { Router } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { AckResponse } from 'src/app/common/constants';
import { elementAt } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})

export class CommentsComponent implements OnInit, OnChanges {


  @Input() data: SideNavData;

  component: string;
  componentObject: LearningSpace | LibraryNode;

  comments: Comment[] | undefined;
  updatedComments: any;
  fetchingList: boolean = false;
  showError: boolean = false;
  errorMessage: string | undefined;
  commentInProgress: boolean = false;
  newCommentValue = '';
  replyCommentValue: string = '';
  userID: string | undefined;
  color: ThemePalette = 'primary';

  constructor(
    private _commentService: CommentsService,
    //private _deleteService: DeleteService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _authenticationService: AuthenticationService,
    private _router: Router,
     @Inject(MAT_DIALOG_DATA) public dialogdata: any,
  ) { }

  ngOnInit(): void {

    const accessToken = this._authenticationService.accessToken;
    this.userID = this._authenticationService.userID(accessToken)

// console.log(this.dialogdata)

    // if (this.data.data) {
    //   this.component = this.data.data.component;
    //   this.componentObject = this.data.data.componentObject;
    // }


    if (this.dialogdata) {
      this.component = this.dialogdata.component;
      this.componentObject = this.dialogdata.componentObject;
    }
    this.getComments();

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('hai')
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        console.log(propName)
        switch (propName) {
          case 'data': {
            // if (this.data.data) {
            //   this.component = this.data.data.component;
            //   this.componentObject = this.data.data.componentObject;
            // }
            if (this.dialogdata) {
              this.component = this.dialogdata.component;
              this.componentObject = this.dialogdata.componentObject;
            }

            this.getComments();
          }
        }
      }
    }
  }



  async getComments() {

    this.comments = [];
    this.fetchingList = true;
    this.showError = false;
    this.errorMessage = undefined;
console.log('get comments')
    try {
      let response: CommentsListResponse = await this._commentService.getComments(this.component, this.componentObject.id);
console.log(response)

      if (response.success) {
        this.comments = response.comments;

        if (this.comments && Array.isArray(this.comments) && this.comments.length > 0) {

          this.showError = false;
        }
        else {
          this.errorMessage = "No comments available";
          this.showError = true;
        }

      }
      else {
        this.errorMessage = "Oops,something went wrong. Please try again";
        this.showError = true;
      }

      this.fetchingList = false;

    } catch (error) {
      this.comments = []
      this.fetchingList = false;
      this.errorMessage = "Oops,something went wrong. Please try again";
      this.showError = true;

    }
  }

  deleteComment(_comment: Comment, _parentComment: Comment | undefined) {
    if (!_comment || !_comment.id)
      return;

    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '600px',
      id: 'confirmation_dialog',
      panelClass:'customModal',
      data: { primaryButtonTitle: "Delete", title: "Delete Comment", content: "Are you sure you want to delete this comment?" },
    });

    const dialogResultSubscription = dialogRef.componentInstance.primaryButtonClickHandler.subscribe(async () => {

      dialogRef.componentInstance.showLoader = true;

      try {

        let response: AckResponse = await this._commentService.deleteComment(_comment.id);

        if (response.success) {
          this.showSnackBar(`Comment has been deleted.`, "OK");
          this.updateDataSourceForDeleteComment(_comment, _parentComment);
        }
        else {
          this.showSnackBar(`Unable to delete comment. Please try again.`, "OK");
        }
      } catch (error) {
        dialogRef.componentInstance.showLoader = false;
        this.showSnackBar(`Oops,something went wrong. Please try again`, "OK");
      }

      dialogRef.componentInstance.showLoader = false;


      if (dialogRef)
        dialogRef.close();

      dialogResultSubscription.unsubscribe();



    });
  }

  updateDataSourceForDeleteComment(_comment: Comment, _parentComment: Comment | undefined = undefined) {

    if (!this.comments) {
      return;
    }
   
    if (_parentComment && _comment && _parentComment.childs && _parentComment.childs.length > 0) {
      const _index = _parentComment.childs.indexOf(_comment);
      if (_index > -1) {
        _parentComment.childs.splice(_index, 1);
      }  
    } else if (!_parentComment && _comment){

      const _index = this.comments.indexOf(_comment);
      if (_index > -1) {
        this.comments.splice(_index, 1);
      }
    }

    _comment.removed = true;
  
  }

  async addComment(text: string) {

    if (text && text.length > 0) {
      this.commentInProgress = true;

      try {
        let response: CommentResponse = await this._commentService.newComment(text, this.componentObject.id, '', this.component);
        if (response.success) {
          const receivedComment: Comment | undefined = response.comment;

          if (receivedComment) {

            this.showSnackBar("Comment has been posted", "OK");
            this.resetNewComments();
            if (!this.comments)
              this.comments = []

            this.comments.unshift(receivedComment);

            this.updateCommentsCount();
          }
          else {
            this.showErrorDialog("Unable to post comment");
          }
        }
        else {
          this.showErrorDialog("Oops,something went wrong. Please try again");
        }

        this.commentInProgress = false;


      } catch (error) {
        console.log("Unable to post comment", error);
        this.commentInProgress = false;
        this.showErrorDialog("Oops,something went wrong. Please try again");
      }

    }

  }


  showErrorDialog(error: any) {

    const dialogRef = this._dialog.open(InformationDialogComponent, {
      width: '300px',
      panelClass:'customModal',
      id: 'information_dialog'
    })
    dialogRef.componentInstance.content = error
  }

  // editNoteTapped(note: LibraryNote) {

  //   note.editing = true;

  // if(this.noteTextArea.nativeElement) {
  //   this.noteTextArea.nativeElement.focus();
  // }

  // }

  // removeNoteTapped(note: LibraryNote) {

  //   this.removeNote(this.node.id, note);
  // }

  resetNewComments() {
    this.newCommentValue = '';
  }

  updateCommentsCount() {
    if (!this.componentObject) {
      return;
    }

    if (this.componentObject instanceof LearningSpace) {
      // const totalComments = this.comments?.reduce((count, current: Comment) => count + (current.childs?current.childs.length : 1), -5);
      this.componentObject.comments += 1;
    }
  }
  updateDeleteCommentsCount() {
    if (!this.componentObject) {
      return;
    }

    if (this.componentObject instanceof LearningSpace) {
      // const totalComments = this.comments?.reduce((count, current: Comment) => count + (current.childs?current.childs.length : 1), -5);
      this.componentObject.comments -= 1;
    }
  }

  redirectToUserProfile(userId: string, newTab = false) {
    if (!userId)
      return;

    if (newTab) {
      const url = window.location.origin + '/main/profile/u/' + userId;
      window.open(url);
    } else {
      this._router.navigate(['/main/profile/u/', userId]);
    }

  }

  showHideChilds(_comment: Comment) {
    if (_comment.childs && _comment.childs.length > 0) {
      // hiding the childs
      _comment.childs = [];
    } else {
      this.getChilds(_comment);
    }
  }


  async getChilds(_comment: Comment) {

    _comment.fetchingChilds = true;

    try {
      let response: CommentsListResponse = await this._commentService.getChildComments(_comment);

      _comment.fetchingChilds = false;

    } catch (error) {
      _comment.fetchingChilds = false;
    }
  }

  editComment(_comment: Comment) {
    _comment.editDraft = _comment.text;
    _comment.showEditComment = true;
    _comment.showReply = false;
  }

  discardEditComment(_comment: Comment) {
    this.resetNewComments();

    _comment.editDraft = '';
    _comment.showEditComment = false;
  }


  async replyComment(_comment: Comment) {

    this.discardEditComment(_comment);

    this.getChilds(_comment);
    _comment.showReply = true;

  }

  async submitReplyComment(_comment: Comment) {

    if (_comment.replyDraft && _comment.replyDraft.length > 0) {

      _comment.replySubmissionInProgress = true;

      try {
        let response: CommentResponse = await this._commentService.newComment(_comment.replyDraft, this.componentObject.id, _comment.id, this.component);
        if (response.success) {
          const receivedComment: Comment | undefined = response.comment;

          if (receivedComment) {

            this.showSnackBar("Comment has been posted", "OK");
            this.resetNewComments();

            _comment.replyDraft = '';
            _comment.showReply = false;

            if (!_comment.childs)
              _comment.childs = [];

            _comment.childs.push(receivedComment);
            _comment.childCount += 1;

            this.updateCommentsCount();
          }
          else {
            this.showErrorDialog("Unable to post comment");
          }
        }
        else {
          this.showErrorDialog("Oops,something went wrong. Please try again");
        }

        _comment.replySubmissionInProgress = false;


      } catch (error) {
        console.log("Unable to post comment", error);
        _comment.replySubmissionInProgress = false;
        this.showErrorDialog("Oops,something went wrong. Please try again");
      }
    }
  }

  async updateComment(_comment: Comment) {

    if (_comment.editDraft && _comment.editDraft.length > 0) {

      if (_comment.editDraft === _comment.text) {
        this.discardEditComment(_comment);
        return;
      }
      _comment.editSubmissionInProgress = true;

      try {
        let response: AckResponse = await this._commentService.updateComment(_comment);
        if (response.success) {


          this.showSnackBar("Comment has been posted", "OK");
          this.resetNewComments();

          _comment.editDraft = '';
          _comment.showEditComment = false;

        }
        else {
          this.showErrorDialog("Unable to update comment. Please try again");
        }

        _comment.editSubmissionInProgress = false;


      } catch (error) {
        console.log("Unable to post comment", error);
        _comment.editSubmissionInProgress = false;
        this.showErrorDialog("Oops,something went wrong. Please try again");
      }
    }

  }


  showSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 5000 });
  }





}
