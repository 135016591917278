import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  QuestionType,
  QuestionTypeAdapter,
} from './../models/question-type.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionTypeService {
  apiUrl!: string;

  constructor(private questionTypeAdapter: QuestionTypeAdapter) {
    if (environment.hasOwnProperty('apiUrl'))
    this.apiUrl = environment.apiUrl;
  }

  fetchQuestionTypes(): QuestionType[] {
    const optionTypes = [
      {
        label: 'MULTIPLE_CHOICE',
        displayLabel: "Multiple Choice Questions",
        hasDynamicOptions: true,
      },
      {
        label: 'TRUE_OR_FALSE',
        hasDynamicOptions: false,
        displayLabel: "True or False",
        optionValues: [
          {
            value: 1,
            label: 'True',
          },
          {
            value: 0,
            label: 'False',
          },
        ],
      },
      //{
       // label: 'TEXTBOX',
       // displayLabel: "Fill in the Blanks",
       // hasDynamicOptions: false,
     // },
    ];
    return optionTypes.map((optionType) => {
      return this.questionTypeAdapter.adapt(optionType);
    });
  }
}
