import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, of } from 'rxjs';
import { debounceTime, filter, finalize, map, startWith, switchMap, tap } from 'rxjs/operators';
import { TagListResponse, TagService } from 'src/app/common/service/tag.service';
import { Tag } from '../../models/tag.model';

@Component({
  selector: 'app-tag-auto-complete',
  templateUrl: './tag-auto-complete.component.html',
  styleUrls: ['./tag-auto-complete.component.scss']
})
export class TagAutoCompleteComponent implements OnInit {

  _tags: Tag[];
  @Input() viewMode: boolean = false;
  isAutoCompleteLoading = true;
  activeFirstOption = false;


  @Input() set tags(value: Tag[]) {

    if (value) {
      this._tags = value;
    }
  }

  get tags(): Tag[] {

    return this._tags;

  }

  addOnBlur(event: FocusEvent) {
    const target: HTMLElement = event.relatedTarget as HTMLElement;
    if (!target || target.tagName !== 'MAT-OPTION') {
      const matChipEvent: MatChipInputEvent = { input: this.tagInput.nativeElement, value: this.tagInput.nativeElement.value };
      this.add(matChipEvent);
    }
  }

  @Output()
  async finalTags(): Promise<Tag[]> {

    try {
      await this.createTagsIfAny(this.tags)
    } catch (error) {
    }



    return this.tags;

  }

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  tagInputCtrl = new FormControl();
  suggestions: Tag[];
  loading: boolean = false;

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private _tagService: TagService) {
    if (!this.viewMode) {
      this.registerForInputValueChanges();
    }

  }

  registerForInputValueChanges() {
    const thisReference = this;
    this.tagInputCtrl.valueChanges.pipe(
      startWith(null),
      debounceTime(300),
      tap(() => {
        thisReference.isAutoCompleteLoading = true;
      }),
      switchMap(
        (keyword: string) => {

          if (keyword && (typeof keyword === 'string') && keyword !== '') {
            return thisReference._tagService.getTagAutoSuggestions(keyword).pipe(
              finalize(() => {
                thisReference.isAutoCompleteLoading = false;
              })
            );
          } else {
            thisReference.isAutoCompleteLoading = false;
            return of(null);
          }

        })).subscribe((data) => {
          if (!data)
            return;

          thisReference.activeFirstOption = (Array.isArray(data.tags) && data.tags.length > 0)

          thisReference.suggestions = (data.tags) ? data.tags : [];

        },
          (error) => {
            thisReference.suggestions = [];
          }
        );
  }


  ngOnInit(): void {

  }

  add(event: MatChipInputEvent): void {

    if (!this.tags) 
      this.tags = [];

    const input = event.input;
    const value = event.value;

    console.log("Value ", value);

    // Check and add new interest
    if ((value || '').trim()) {

      const existingArray = this._filterTagsWithKeyword(value);

      if (existingArray && existingArray.length > 0) {

        // Already exists. Nothing to do

      }
      else {
        const aoiArray = this._filter(value.trim(), true)

        if (aoiArray && aoiArray.length > 0) {
          this.tags.push(aoiArray[0]);
        }
        else {
          let _tag = new Tag();
          _tag.title = value.trim();
          this.tags.push(_tag);
        }
      }
    }



    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.tagInputCtrl.setValue(null);
  }

  remove(_tag: Tag): void {
    const index = this.tags.indexOf(_tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    event.option.deselect();
    if (event.option.value instanceof Tag) {
      const existingArray = this._filterTagsWithKeyword(event.option.value.title);

      if (existingArray && existingArray.length > 0) {
        // Already exists. Nothing to do
      }
      else {

        if (!this.tags)
          this.tags = [];

        this.tags.push(event.option.value);
      }      
    }      
    
    this.suggestions = [];
    this.tagInput.nativeElement.value = '';
    this.tagInputCtrl.setValue(null);

  }

  private _filter(value: string, exactMatch = false): Tag[] {

    if (!this.suggestions || this.suggestions.length === 0)
      return [];

    const filterValue = value.toLowerCase();

    const filteredArray = this.suggestions.filter(tag => {

      if (exactMatch) {
        return tag.title.toLowerCase() === filterValue;
      }
      else {
        return tag.title.toLowerCase().includes(filterValue);
      }

    });

    return filteredArray;

  }

  private _filterTagsWithKeyword(title: string): Tag[] {

    if (!this.tags)
      return [];


    const filterValue = title.toLowerCase();

    const filteredArray = this.tags.filter(_tag => {

      return _tag.title.toLowerCase() === filterValue;

    });

    return filteredArray;


  }

  async createTagsIfAny(_allTags: Tag[]): Promise<boolean> {

    return new Promise(async (resolve, reject) => {
      if (!_allTags) {
        resolve(false);
        return;
      }

      const newTagsToCreate = _allTags.filter(_t => !_t.id)
      if (!newTagsToCreate || (newTagsToCreate && newTagsToCreate.length == 0)) {
        resolve(true);
        return;
      }

      try {
        const response: TagListResponse = await this._tagService.createTags(newTagsToCreate);

        if (response.success && response.tags && response.tags.length > 0) {

          const newTagsArray = response.tags;

          newTagsToCreate.map((newTagToCreate: Tag) => {

            const filteredTag: Tag | undefined = newTagsArray.find((createdTag: Tag) => {

              return createdTag.title.toLowerCase() === newTagToCreate.title.toLowerCase();

            });

            if (filteredTag) {
              newTagToCreate.id = filteredTag.id;
              newTagToCreate.title = filteredTag.title;
            }

          })

        }

      } catch (error) {

      }
      resolve(true);
      return;


    })


  }

}
