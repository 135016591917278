<ng-container *ngIf="loading; else showActivity"> Loading... </ng-container>
<ng-template #showActivity>
  <!-- <div class="row rowheading">
    <div class="sc-a bt-l">
      <span class="icon icon-url-greyactivity2"></span>
      <span class="activity-name" *ngIf="activity">{{ activity.title }}</span>
    </div>
  </div> -->
  <mat-tab-group
    [selectedIndex]="selectedIndex"
    (selectedTabChange)="selectedTabChangeEvent($event)"
    class="noborder"
    *ngIf="activity"
  >
    <ng-container
      *ngFor="
        let question of activity.questions;
        index as i;
        first as f;
        last as lastOption
      "
      class="matbc"
    >
      <mat-tab [disabled]="!question.isAnswered">
        <ng-template mat-tab-label>
          <button mat-stroked-button>{{ i + 1 }}</button>
        </ng-template>
        <span class="question-title">{{ question.question }}</span>

        <span
          *ngIf="question.answers ? question.answers.length > 1 : false"
          class="answer-hint"
          >Select all that apply
        </span>
        <div class="questionlist">
          <ng-container *ngIf="question.questionType != 'TEXTBOX'">
            <mat-selection-list #optionList [multiple]="false">
              <mat-list-option
                color="primary"
                [disabled]="question.isAnswered"
                [selected]="_option.isAnswered"
                [value]="_option"
                [ngClass]="getBackgroundClass(_option, question)"
                (click)="
                  optionClickEvent(
                    $event,
                    _option,
                    question,
                    optionList.selectedOptions
                  )
                "
                *ngFor="let _option of question.options"
              >
                <div>
                  <span class="option-key">{{ _option.key }}</span
                  ><span>{{ _option.value }}</span>
                </div>
                <ng-container
                  *ngIf="question.isAnswered; else displayQStatusIcon"
                >
                  <mat-icon
                    class="optioncheck correctIcon"
                    *ngIf="_option.isCorrectAnswer"
                    >check_box</mat-icon
                  >
                  <mat-icon
                    class="optioncheck wrongIcon"
                    *ngIf="_option.isAnswered && !_option.isCorrectAnswer"
                    >cancel</mat-icon
                  >
                </ng-container>
                <ng-template #displayQStatusIcon> </ng-template>
              </mat-list-option>
            </mat-selection-list>
            <!-- <mat-radio-group
              class="radio-group"
              (change)="
                validateAnswer($event, $event.value, activity.questions[i])
              "
            >
              <mat-radio-button
                class="radio-button"
                [value]="option.value"
                *ngFor="let option of activity.questions[i].options"
                >{{ option.value }}
              </mat-radio-button>
            </mat-radio-group> -->
          </ng-container>

          <ng-container *ngIf="activity.questions[i].questionType == 'TEXTBOX'">
            <mat-form-field>
              <mat-label>ANSWER</mat-label>
              <textarea matInput></textarea>
            </mat-form-field>
          </ng-container>
          <!-- <div class="color-cl"><b style="color:{{ activity.questions[i].isCorrectAnswer? 'green' : 'red'}}">{{ activity.questions[i].isCorrectAnswer }}</b></div>-->
          <!-- <p>Answer:</p>
                    <p>Answer: Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p> -->
          <div class="p-20 buttons-section">
            <!-- <button
              mat-button
              *ngIf="!f"
              class="mat-focus-indicator btn-grey mat-raised-button mat-button-base back-l"
              click="previousStep()"
            >
              Previous
            </button>
            <button mat-button class="btn-primary" click="nextStep()">
              Next
            </button> -->
            
          <section class="submitwrapper">
            <section class="directionsec left-direction">
              <button mat-stroked-button *ngIf="!f" (click)="previousStep()">
                <mat-icon> navigate_before </mat-icon>
                
              </button></section>
                  <button
              mat-button
              class="btn-primary roundedBtn"
              [disabled]="activity.questions[i].isAnswered"
              (click)="submitQuestion(activity.questions[i])"
            >
              Submit
            </button>
            <section class="directionsec rightdirection">
              <button
                mat-stroked-button
                class="pull-right"
                (click)="nextStep()"
                [disabled]="!question.isAnswered"
              >
                <!-- {{ lastOption ? "Finish" : "Next" }} -->
                <mat-icon> navigate_next </mat-icon>
              </button>
            </section>
          </section>


            <section
              *ngIf="question.isAnswered && question.answerKey"
              class="hint-block"
            >
              <label>Reason</label>
              <p [innerHTML]="question.answerKey"></p>
            </section>
          </div>
        </div>
      </mat-tab>
    </ng-container>
    <mat-tab [disabled]="true">
      <ng-template mat-tab-label>
        <button mat-stroked-button>Score</button>
      </ng-template>
      <ng-container *ngIf="questionnairePreview">
        <section class="viewwrap">
          <div class="gr-c1 gr-block">
            <section
              [ngClass]="[
                'percentageCount',
                questionnairePreview.getPercentageClass()
              ]"
            >
              {{ questionnairePreview.percentage | percent }}
            </section>
            <section class="txt1">Correctly Answered!</section>
            <section class="desctxt">
              You have completed this <br/> Learning Activity.
            </section>
          </div>
          <div class="gr-b gr-c">
            <span> <mat-icon>check_box</mat-icon></span
            ><span class="cc-ans">Correct Answers: </span>
            <span>{{ questionnairePreview.correctAnswersCount }}</span>
          </div>
          <div class="gr-b gr-w">
            <span> <mat-icon>cancel</mat-icon></span>
            <span class="cc-ans"> Incorrect Answers: </span>
            <span>{{ questionnairePreview.wrongAnswersCount }}</span>
          </div>

          <div class="p-20 buttons-section retakequiz">
            <button
              mat-button
              (click)="retakeQuiz()"
              class="btn-primary full-width-btn"
            >
              Re-take this Quiz
            </button>
          </div>
        </section>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</ng-template>
