<div class="learning-space-popup-select-template">
    <div class="select-template-head" fxLayout="row wrap" fxLayoutWrap="wrap" fxLayoutAlign="start center">
        <h4 class="h4-20-black mr-20 align-center">Select Template</h4>
        <p class="p-small-grey14 ml-20 align-center">You can add or remove space split after the
            space is created.</p>
    </div>
</div>

<ng-container *ngIf="loading; else LoadingComplete">
    <div class="learning-space-layouts pt-20" fxLayout="row wrap" fxlayoutWrap="wrap" fxLayoutAlign="start center">
        <div class="template-box skeleton-loading">
            <div fxLayout="column">
                <div class="template-img">
                    <ngx-skeleton-loader count="1" [theme]="{ 
                        height: '60px',
                        width: '100px',
                        marginBottom: '0px'
                      }"></ngx-skeleton-loader>
                </div>
                <ngx-skeleton-loader class="name-loading" count="1" [theme]="{ 
                    height: '20px',
                    width: '100px',
                    marginBottom: '0px',
                    marginTop: '10px'
                  }"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="template-box skeleton-loading">
            <div fxLayout="column">
                <div class="template-img">
                    <ngx-skeleton-loader count="1" [theme]="{ 
                        height: '60px',
                        width: '100px',
                        marginBottom: '0px'
                      }"></ngx-skeleton-loader>
                </div>
                <ngx-skeleton-loader class="name-loading" count="1" [theme]="{ 
                    height: '20px',
                    width: '100px',
                    marginBottom: '0px',
                    marginTop: '10px'
                  }"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="template-box skeleton-loading">
            <div fxLayout="column">
                <div class="template-img">
                    <ngx-skeleton-loader count="1" [theme]="{ 
                        height: '60px',
                        width: '100px',
                        marginBottom: '0px'
                      }"></ngx-skeleton-loader>
                </div>
                <ngx-skeleton-loader class="name-loading" count="1" [theme]="{ 
                    height: '20px',
                    width: '100px',
                    marginBottom: '0px',
                    marginTop: '10px'
                  }"></ngx-skeleton-loader>
            </div>
        </div>
        <div class="template-box skeleton-loading">
            <div fxLayout="column">
                <div class="template-img">
                    <ngx-skeleton-loader count="1" [theme]="{ 
                        height: '60px',
                        width: '100px',
                        marginBottom: '0px'
                      }"></ngx-skeleton-loader>
                </div>
                <ngx-skeleton-loader class="name-loading" count="1" [theme]="{ 
                    height: '20px',
                    width: '100px',
                    marginBottom: '0px',
                    marginTop: '10px'
                  }"></ngx-skeleton-loader>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #LoadingComplete>
    <ng-container *ngIf="showError; else showTemplates">
        <ng-container [ngTemplateOutlet]="showMessage"></ng-container>
    </ng-container>
    <ng-template #showTemplates>



        <div class="learning-space-layouts pt-20" fxLayout="row wrap" fxlayoutWrap="wrap" fxLayoutAlign="start center">
            <ng-container *ngFor="let _template of templates">
                <div [ngClass]="[selectedTemplate ? ((_template.id === selectedTemplate.id) ? 'active-template': '') : '', 'template-box' ]"
                    fxFlex>
                    <div class="cursor-pointer" fxLayout="column" (click)="chooseTemplate(_template)">
                        <div class="template-img">
                            <img src="{{_template?.thumbnail}}" />
                        </div>
                        <p class="p-small-black15 pt-10 align-center">{{_template?.title}}</p>
                    </div>
                </div>
            </ng-container>

            <!-- <button class="blank-space-main">
                <span class="icon icon-blank-space"></span>
                <span class="icon icon-blank-space-active"></span>
            </button>
            <button class="parts2-space-main">
                <span class="icon icon-2parts"></span>
                <span class="icon icon-2parts-active"></span>
            </button>
            <button class="parts3-space-main">
                <span class="icon icon-3parts"></span>
                <span class="icon icon-3parts-active"></span>
            </button>
            <button class="parts4-space-main">
                <span class="icon icon-4parts"></span>
                <span class="icon icon-4parts-active"></span>
            </button> -->

        </div>

    </ng-template>
</ng-template>

<ng-template #showMessage>
    <div class="w-100 error-message" fxLayout="row" fxLayoutAlign="center center" *ngIf="showError && !loading">
        {{errorMessage}}
    </div>
    <div class="w-100" fxLayout="row" fxLayoutAlign="center center">
        <button mat-raised-button class="btn-submit" *ngIf="showError"
            (click)="loadTemplates()">{{errorMessageButtonTitle}}</button>
    </div>
</ng-template>