<!--<div class="w-100">
    <button mat-icon-button newone>
        <mat-icon>visibility</mat-icon>
        {{showNumbers?space.views:''}}Views
    </button>
   
    <button mat-icon-button newone  >
        <label class="ac1">Contains Learning Activity</label>
    </button>-->
<!--</div>-->
<div class="flex-container-icon" [ngClass]="clsname">
  <div class="icons-row-visibility w-100 learning-above-view" *ngIf="showViews">
    <section class="btn-views">
     <!-- <mat-icon class="icon_visibility">visibility</mat-icon>-->
      <img class="img_visibility" src="../../../../assets/images/icons/ic_viewss_new.png"> 
      <span class="view-space">
        {{ showNumbers ? space.views : "" }} <span class="lerning-display">Views</span>
      </span>
    </section>
  </div>
  <div class="icons-row w-100">
    <!-- <button mat-icon-button>
        <mat-icon>info</mat-icon>
    </button> -->
    <div fxFlex fxLayoutAlign="start center" class="fl_center">
    <button class="like_bg" [matTooltip]="space.liked ? 'Unlike' : 'Like'" (click)="readonly?informReadOnlyClickEvent():mutateLike()" mat-icon-button   [ngClass]="likeInProgress ? 'icon-loading' : ''">
      <!--<mat-icon
        [ngClass]="space.liked ? 'higlight-icon' : ''"
        (click)="mutateLike()"
      >
        {{ space.liked ? "favorite" : "favorite_border" }}
      </mat-icon>-->
<span class="space_like"><em class="icon-like" [ngClass]="space.liked ? 'filled': ''"></em> <span class="show-count"> {{ showNumbers ? space.likes : "" }} </span></span>
     <!-- <span class="space_like"> <img class="img_like" *ngIf="!space.liked" src="../../../../assets/images/icons/icon-like2x.png" >
      <img class="img_like"    *ngIf="space.liked" src="../../../../assets/images/icons/icon-like-filled2x.svg" >
    </span> -->
     
   
    </button>
   <!--<mat-icon
      class="mat-over"
      [ngClass]="showNumbers && space.comments > 0 ? 'comment-icon' : ''"
      (click)="openComment()"
      >chat_bubble_outline</mat-icon
    >-->
   <!-- <span class="icon icon-comments mat-over"    [ngClass]="showNumbers && space.comments > 0 ? 'comment-icon' : ''"
      (click)="openComment()"></span>-->
     <button mat-icon-button class="commt_bg" (click)="readonly?informReadOnlyClickEvent():openComment()" matTooltip="Comments">
      <!-- <span class="commt_bg" (click)="readonly?informReadOnlyClickEvent():openComment()"> -->
      <!-- <img class="img_cmt comment-view" *ngIf="!space.comments" src="../../../../assets/images/icons/icon-comment2x.png"   
      >
      <img class="img_cmt comment-view" *ngIf="space.comments" src="../../../../assets/images/icons/icon-comment2x.png"   
      > -->
      <span class="comment_wrap"><em class="icon-comment" [ngClass]="space.comments ? 'filled': ''"></em><span class="show-count"> {{ showNumbers ? space.comments : "" }} </span></span>
      
      <!-- <p class="mat-over">{{ showNumbers ? space.comments : "" }} </p> -->

    <!-- </span> -->
  </button>
    </div>
    <!-- <p class="mat-over-sh">{{ showNumbers ? space.shares : "" }}</p> -->

    <div fxFlex fxLayoutAlign="end center" class="fl_center">
      <button mat-icon-button [matTooltip]="space.bookmarked ? 'Bookmark' : 'Bookmark'" class="bk_bg" (click)="readonly?informReadOnlyClickEvent():mutateBookmark()" [ngClass]="bookmarkLoading ? 'icon-loading' : ''">
       
        <!-- <img class="img_bookmark" src="../../../../assets/images/icons/icon-save2x.png"  *ngIf="!space.bookmarked"  
        >
        <img class="img_bookmark" src="../../../../assets/images/icons/icon-save-filled2x.svg"    *ngIf="space.bookmarked" 
        > -->
        <span class="bookmark_wrap"><em class="icon-bookmark" [ngClass]="space.bookmarked ? 'filled': ''"></em></span>
       
        <!-- {{ showNumbers ? space.bookmarks : "" }} -->
      </button>
      <button mat-icon-button class="bk_bg_share" matTooltip="Share"  (click)="readonly?informReadOnlyClickEvent():openShareDialog()"  [ngClass]="shareInProgress ? 'icon-loading' : ''">
      
 
        <!-- [ngClass]="space.shares > 0 ? 'share-icon' : ''" -->
       <!-- <mat-icon (click)="openShareDialog()">share</mat-icon>-->
       <!-- <img class="img_share" src="../../../../assets/images/icons/icon-share2x.png"> -->
       <span class="share_wrap"><em class="icon-share"></em></span>
     
     </button>

      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="More Options"
        *ngIf="canMarkLSPForWebsiteAccess"  class="moreWrap"
      >
        <mat-icon class="more_cls">more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="$event.stopPropagation()">
          <span>Display on Learning Pods Website</span>
          <mat-slide-toggle
            (click)="$event.stopPropagation()"
            [disabled]="displayOnByndrWebsiteRequestInProgress"
            (change)="readonly?informReadOnlyClickEvent():markDisplayOnWebsiteEvent($event)"
            [checked]="space.displayOnByndrWebsite"
          >
          </mat-slide-toggle>
        </button>
        <button *ngIf="space.displayOnByndrWebsite" mat-menu-item (click)="readonly?informReadOnlyClickEvent():copyPublicLink()">
          <span>Copy Public Link</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
