import { AuthenticationService } from './core/service/authentication.service';

export function appInitializer(authenticationService: AuthenticationService) {
    return () => new Promise(async (resolve, reject) => {

        resolve(true);
        return;
            
        if (authenticationService.isLoggedIn && authenticationService.isTokenExpired) {

            console.log("Token expired");
            // attempt to refresh token on app start up to auto authenticate        
      

            try {
                
                const user =  await authenticationService.raiseRefreshTokenRequest()
                
                resolve(true);


            } catch (error) {
                
                resolve(true);
            }

                        
            
        } 
        else {
            resolve(true);
        }
        
        

    });
}