<div class="library-main" fxLayout="row wrap" fxLayoutWrap="wrap"  [ngClass]="singleSelectedNode ? 'showWrap' : ''" >
   
    <!-- <mat-progress-bar class="loading-progress-bar" mode="indeterminate" *ngIf="fetchingNodes"></mat-progress-bar> -->
    <div class="library-left"  [fxFlex]="mainService.libraryFlex_lg" fxLayoutAlign="start start">
      <section class="libraryflex">
        <div class="w-100" fxLayout="column">
            <div class="library-header" *ngIf="showHeader" fxLayout="row wrap" fxLayoutWrap="wrap">
    
                <div class="library-header-left" fxFlex="initial" fxLayout="column wrap" >
    
    <ul class="title-list"><li class=""><a routerLink="/main/library"><span class="icon icon-library-blue library-blue"></span>All Files</a></li><li *ngFor=" let n of parentsNodeNew; let last = last;" >
      <mat-icon>chevron_right</mat-icon>  <a routerLink="/main/library/folder/{{n.node.id}}" *ngIf="!last">{{ n.node.title }}</a>
        <ng-container *ngIf="last">{{ n.node.title }}</ng-container>
        
        </li></ul>
    
    
        <ul class="flex hideradio" fxLayout="row wrap" fxLayoutWrap="wrap">
          
            
    <!-- <li>
        <mat-radio-group  aria-label="Select an option">
            <mat-radio-button [checked]="sortObj.key ==sortType " *ngFor="let sortObj of sortTypeObj"[disableRipple]="true" [value]="sortObj.key" (change)="sortFiled($event)">{{sortObj.name}} <mat-icon>arrow_upward</mat-icon></mat-radio-button>
          
          </mat-radio-group>
    
    </li> -->
    
    <li class="flexalpha" [ngClass]="sortField=='name' ? 'active' : '' " (click)="sortFiled('name')">Name
        <mat-icon *ngIf="sortNameAsc">arrow_upward</mat-icon>
        <mat-icon  *ngIf="!sortNameAsc">arrow_downward</mat-icon>
       </li>
       <li class="flexalpha" [ngClass]="sortField=='addedDate' ? 'active' : '' " (click)="sortFiled('addedDate')">Date Added
        <mat-icon *ngIf="sortDateAsc">arrow_upward</mat-icon>
        <mat-icon  *ngIf="!sortDateAsc">arrow_downward</mat-icon>
       </li>
    
          <!-- <li class="flexalpha" [ngClass]="sort_order==='asc' ? 'active' : '' "    (click)="sortOrder()">Alphabetical
             <mat-icon *ngIf="sort_order==='asc'">arrow_upward</mat-icon>
             <mat-icon  *ngIf="sort_order==='desc'">arrow_downward</mat-icon>
            </li>
         -->
        </ul>
      
    
                    
                    <!--<p class="file-count p-small-black15 mr-20">
                        <ng-container [ngTemplateOutlet]="parentNodeID?FolderName:FilesCount ">
    
                        </ng-container>
                        <ng-template #FilesCount>
                            <div *ngIf="fileCount && fileCount>0; else NoFiles">
                                {{fileCount}} File(s)
                            </div>
                            <ng-template #NoFiles>
                                <div *ngIf="!keyword">
                                    No Files
                                </div>
                            </ng-template>
                        </ng-template>
                        <ng-template #FolderName>
                            <h3>{{parentNode? parentNode.title: ""}}</h3>
                        </ng-template>
                    </p>-->
    
                    <!-- <button [matMenuTriggerFor]="menu" class="btn-primarynew">
                        <mat-icon>add</mat-icon><span class="new-ls">New</span>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                            <div class="upload-menu">
                                <input type="file" id="file" ng2FileSelect (change)="onFileChange($event)"
                                    onclick='value = null' class="inputfile" #fileUploadInput>
                                <label for="file" class="m-0">
                                    <div class="upload-menu p-small-black15 mr-20 cursor-pointer">
                                        <mat-icon> attach_file</mat-icon>
                                        New File
                                    </div>
                                </label>
                            </div>
                        </button>
    
                        <button mat-menu-item>
                            <div class="folder-menu p-small-black15 mr-20 cursor-pointer" (click)="newFolderTapped()">
                                <mat-icon>folder_special</mat-icon>
                                Folder
                            </div>
                        </button>
                        <button mat-menu-item>
                            <div class="folder-menu p-small-black15 mr-20 cursor-pointer" (click)="newURLTapped()">
                                <mat-icon>link</mat-icon>
                                Add URL
                            </div>
                        </button>
                       
                        <button mat-menu-item>
                            <div class="folder-menu p-small-black15 mr-20 cursor-pointer activity-ls"
                                (click)="newActivityTapped()">
                                <span class="icon icon-greyactivity"></span>
                                <span class="inner-ac"> Activity </span>
                            </div>
                        </button>
    
    
                    </mat-menu> -->
    
                     <!-- <button mat-menu-item><div class="folder-menu p-small-black15 mr-20 cursor-pointer activity-ls" (click)="newActivityTapped()">
                        <span class="icon icon-url-greyactivity2"></span>
                       <span class="inner-ac"> Activity </span>
                    </div></button>-->
                        <!-- <button mat-menu-item>
                        <div class="folder-menu p-small-black15 mr-20 cursor-pointer" (click)="runActivityTapped()">
                            <mat-icon>remove_red_eye</mat-icon>
                            Run Activity
                        </div>
                    </button>-->
                    <!--<div class="upload-menu pl-20">
                        <input type="file" id="file" ng2FileSelect  (change)="onFileChange($event)" onclick='value = null' class="inputfile" #fileUploadInput>
                        <label for="file" class="m-0">
                            <div class="upload-menu p-small-black15 mr-20 cursor-pointer">
                                <span class="icon icon-upload-blue mr-10"></span>
                                New file
                            </div>
                        </label>
                    </div>-->
    
    
                    <!-- <div class="folder-menu p-small-black15 mr-20 cursor-pointer" (click)="newFolderTapped()">
                        <span class="icon icon-add-grey mr-10"></span>
                        Folder
                    </div>                
                    <div class="folder-menu p-small-black15 mr-20 cursor-pointer" (click)="newURLTapped()">
                        <span class="icon icon-url-grey2 mr-10"></span>
                        Add URL
                    </div>
                    <div class="folder-menu p-small-black15 mr-20 cursor-pointer" (click)="newActivityTapped()">
                        <span class="icon icon-add-grey mr-10"></span>
                        Activity1
                    </div>
                    <div class="folder-menu p-small-black15 mr-20 cursor-pointer" (click)="runActivityTapped()">
                        <span class="icon icon-add-grey mr-10"></span>
                        Run Activity
                    </div> -->
                </div>
                <div class="pr-20 library-header-right" fxFlex fxLayoutAlign="end center">
                    <ng-container *ngIf="selectedNode; else FilterOption">
                        <ng-container [ngTemplateOutlet]="NodeActions"></ng-container>
                    </ng-container>
                    <ng-template #FilterOption>
    
    
                        
                        <!-- <div class="sort-by" fxLayout="row" fxLayoutAlign="start center" *ngIf="sort">
                            <div fxFlex="initial" fxLayoutAlign="start center">
                                <p class="show-text p-small-black14 mr-20">Sort By</p>
                            </div>
    
                            <button class="sort_sec" mat-button [matMenuTriggerFor]="menu">{{sort.}} <mat-icon>
                                    keyboard_arrow_down</mat-icon></button>
                            <mat-menu #menu="matMenu">
                                <div class="menu_inner">
                                    <mat-radio-group [(ngModel)]="sortValue" class="tick_radio"
                                        (click)="$event.stopPropagation()">
                                        <mat-radio-button value="Option 1">Option 1</mat-radio-button>
                                    </mat-radio-group>
    
                                    <div class="sticky_menu_bottom">
    
                                        <mat-radio-group class="tick_radio sortingsec" (click)="$event.stopPropagation()">
                                            <mat-radio-button value="1">ASC <mat-icon>arrow_downward</mat-icon>
                                            </mat-radio-button>
                                            <mat-radio-button value="2">Desc <mat-icon>arrow_upward</mat-icon>
                                            </mat-radio-button>
    
                                        </mat-radio-group>
    
    
                                        <div class="btn-border">
                                            <button mat-stroked-button (click)="sortFn()">
                                                APPLY
                                            </button>
    
                                        </div>
                                    </div>
                                </div>
    
                            </mat-menu>
    
                            <mat-form-field appearance="fill" class="select-box">
                                <mat-select [formControl]="authType">
                                    <mat-option *ngFor="let option of sortOptions" [value]="option.value">
                                        {{option.title}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                             <mat-form-field appearance="fill" class="select-box">
                                <mat-select [formControl]="fileTypes" (selectionChange)="changeFilter()">
                                    <mat-option [value]="filter.key" *ngFor="let filter of fileTypeList">
                                        {{filter.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->
                    </ng-template>
                    <ng-template #NodeActions>
                        <!-- <app-library-node-actions *ngIf="selectedNode" [node]="selectedNode"></app-library-node-actions> -->
                    </ng-template>
                 
    
    
                    <span class="list-grid-icons"  *ngIf="!showError">
                        <span
                            [ngClass]="viewType === viewTypeEnum.Grid?'icon icon-list-view-grey': 'icon icon-grid-view-grey'"
                            (click)="changeViewType()"></span>
                    </span>
    
                </div>
            </div>
    
        </div>
        <div class="library-body w-100" fxLayout="row wrap" fxLayoutWrap="wrap">
            <ng-container *ngIf="fetchingNodes; else LoadingComplete">
                <ng-container [ngSwitch]="viewType">
                    <ng-container *ngSwitchCase="viewTypeEnum.List" [ngTemplateOutlet]="LibraryListViewSkeletonLoading">
                    </ng-container>
                    <ng-container *ngSwitchCase="viewTypeEnum.Grid" [ngTemplateOutlet]="LibraryGridViewSkeletonLoading">
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-template #LibraryListViewSkeletonLoading>
                <div class="libray-list-loading pt-20" fxLayout="row wrap">
                    <div class="star">
                        <ngx-skeleton-loader count="10" [theme]="{ 
                            height: '25px',
                            width: '25px',
                            margin: '0px auto',
                            display: 'table',
                            marginBottom: '30px'
                          }" appearance="circle"></ngx-skeleton-loader>
                    </div>
                    <div class="name">
                        <ngx-skeleton-loader count="10" [theme]="{ 
                            height: '25px',
                            marginBottom: '30px',
                            paddingRight: '20px'
                          }"></ngx-skeleton-loader>
                    </div>
                    <div class="actions">
                        <ngx-skeleton-loader count="10" [theme]="{ 
                            height: '25px',
                            marginBottom: '30px',
                            paddingRight: '20px'
                          }"></ngx-skeleton-loader>
                    </div>

                </div>
            </ng-template>
            <ng-template #LibraryGridViewSkeletonLoading>
                <div class="grid-view-loading w-100 scroll-bar">
                    <div class="w-100" fxLayout="row">
                        <div class="pt-20 pr-20" fxFlex="25" fxFlexOffset="75" fxLayoutAlign="end center">
                            <ngx-skeleton-loader count="1" [theme]="{ 
                                height: '40px',
                                width: '20vw',
                                marginBottom: '30px',
                                paddingRight: '20px'
                            }"></ngx-skeleton-loader>
                        </div>
                    </div>
                    <div class="folder-main w-100 pl-20">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                            height: '30px',
                            width: '100px',
                            marginBottom: '20px',
                            paddingRight: '20px'
                        }"></ngx-skeleton-loader>
                    </div>
                    <div class="grid-loading pl-20 pb-10 w-100" fxLayout="row">
                        <ngx-skeleton-loader count="10" [theme]="{ 
                            height: '40px',
                            marginBottom: '20px'
                        }"></ngx-skeleton-loader>
                    </div>
                    <div class="folder-main w-100 pl-20">
                        <ngx-skeleton-loader count="1" [theme]="{ 
                            height: '30px',
                            width: '100px',
                            marginBottom: '20px',
                            paddingRight: '20px'
                        }"></ngx-skeleton-loader>
                    </div>
                    <div class="grid-loading pl-20 w-100" fxLayout="row">
                        <ngx-skeleton-loader count="5" [theme]="{ 
                            height: '150px',
                            marginBottom: '20px'
                        }"></ngx-skeleton-loader>
                    </div>
                </div>
            </ng-template>

            <ng-template #LoadingComplete>
                <ng-container *ngIf="showError; else showNodes">
                    <ng-container [ngTemplateOutlet]="showMessage"></ng-container>
                </ng-container>
                <ng-template #showNodes>
                    <ng-container [ngSwitch]="viewType">
                        <ng-container *ngSwitchCase="viewTypeEnum.List" [ngTemplateOutlet]="LibraryListView">
                        </ng-container>
                        <ng-container *ngSwitchCase="viewTypeEnum.Grid" [ngTemplateOutlet]="LibraryGridView">
                        </ng-container>
                    </ng-container>
                </ng-template>
            </ng-template>
            <ng-template #LibraryListView>
                <div class="w-100">
                    <app-library-list-view [nodes]='nodes' [sort]='sort' [loading]='fetchingNodes'
                        [selectedNode]='selectedNode' (nodeClickEvent)="nodeClick($event)"
                        (nodeDoubleClickEvent)="nodeDoubleClick($event)" (sortEvent)="sortClick($event)">
                    </app-library-list-view>
                </div>
            </ng-template>
            <ng-template #LibraryGridView>
                <div class="w-100">
                    <app-library-grid-view [nodes]='nodes' [loading]='fetchingNodes' [selectedNode]='selectedNode'
                        (nodeClickEvent)="nodeClick($event)" (nodeDoubleClickEvent)="nodeDoubleClick($event)">
                    </app-library-grid-view>
                </div>
            </ng-template>
            <ng-template #showMessage>
                <div class="w-100 error-message nofiles" fxLayout="column" fxLayoutAlign="center center"
                    *ngIf="showError && !fetchingNodes">
                    <!-- {{errorMessage}}
                    -->
                    <p class="align-center">
                        <img class="library_empty_img" src="../../../../assets/images/icons/no-files.png">
                    </p>
                    <h2 class="h2-36  align-center"> No Files in this folder</h2>
                    <p class="p-large-18  align-center">Start adding your files to this folder by using these quick actions.
                    </p>
                    



                    <div class="pt-10" fxLayout="row" fxLayoutAlign="center center">

                        <input type="file" id="file" ng2FileSelect (change)="onFileChange($event)"
                            onclick='value = null' class="inputfile" #fileUploadInput>
                        <label for="file" class="m-0 input_bg roundedBtn uploadBtn">

                            + Upload File

                        </label>

                     
                        <button class="btn-upload btnaddurl roundedBtn" mat-raised-button (click)="newURLTapped()">+ Add URL</button>
                    </div>


                </div>
                <!-- <div class="w-100" fxLayout="row" fxLayoutAlign="center center">
                    <button mat-raised-button class="btn-submit" *ngIf="showError"
                        (click)="loadLibrary()">{{errorMessageButtonTitle}}</button>
                </div> -->
            </ng-template>

            <div class="library-welcome-card" *ngIf="isShowWelcomeCard && !fetchingNodes">
                <div class="library-bg">
                    <!--<span class="icon icon-library-blue"></span>-->
                    <!-- <span class="icon icon-close cursor-pointer" (click)="closeWelcomeCard()"></span>-->
                    <p class="align-center">
                        <img class="library_empty_img" src="../../../../assets/images/icons/LibraryEmptyIcon.png">
                    </p>
                    <h2 class="h2-36 pb-20 pt-10 pb-20 align-center">Your Library is currently empty</h2>
                    <p class="p-large-18 pb-20 align-center">There are no files added to your library.
                    </p>
                    <p class="p-small-grey14 pb-20 align-center small-empty-text"> Why don’t we start now?</p>
                    <div class="pt-10" fxLayout="row" fxLayoutAlign="center center">

                        <input type="file" id="file" ng2FileSelect (change)="onFileChange($event)"
                            onclick='value = null' class="inputfile" #fileUploadInput>
                        <label for="file" class="m-0 input_bg roundedBtn uploadBtn">

                            + Upload File

                        </label>

                        <button class="btn-upload roundedBtn" mat-raised-button (click)="newActivityTapped()">+ Create
                            Activity</button>
                        <button class="btn-upload roundedBtn" mat-raised-button (click)="newURLTapped()">+ Add URL</button>
                    </div>
                </div>
            </div>
        </div>
        </section>
    </div>
    <div class="library-right" fxFlex="30" [@slideInOut]="mainService.menuState">
        <div *ngIf="selectedNode && singleSelectedNode">
            <app-library-info [node]="selectedNode" (nodeDoubleClickEvent)="nodeDoubleClick($event)"
                (closeTapped)="libraryInfoCloseTapped($event)"></app-library-info>
        </div>
    </div>
</div>
