import { LibraryListResponse } from './../../library/data-providers/library.service';
import { LibrarySort } from './../../library/helper/library.constant';
import { StatusCodes } from 'http-status-codes';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Comment, CommentAdapter } from '../model/comment.model';
import { of, Subject } from 'rxjs';
import { map } from "rxjs/operators";
import { catchError } from 'rxjs/operators';
import { AckResponse } from 'src/app/common/constants';

export interface CommentsListResponse {
  comments?: Comment[];
  success: boolean;
  code?: string;
}

export interface CommentResponse {
  comment?: Comment;
  success: boolean;
  code?: string;
}


@Injectable({
  providedIn: 'root',
})
export class CommentsService {


  public apiUrl: string;

  constructor(private http: HttpClient, private _commentAdapter: CommentAdapter) {

    if (environment.hasOwnProperty('apiUrl'))
      this.apiUrl = environment.apiUrl;
  }

  getChildComments(comment: Comment): Promise<CommentsListResponse> {

    if (!comment)
      of({ code: "REQUIRED PARAMS MISSING", success: false });

    let url = `${this.apiUrl}/comments/${comment.id}/sub-comments`;

    return this.http.
      get(url, {
        observe: "response"
      }).pipe(
        map(response => {
          let responseStatus = response.status;

          let responseData: any = response.body

          if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.NO_CONTENT) {
            if (responseData != null && Array.isArray(responseData)) {
              let comments: Comment[] = responseData.map((c: any) => this._commentAdapter.adapt(c));
              // assign to parent child;
              comment.childCount = comments.length;
              comment.childs = comments;
              
              return { comments: comments, success: true };
            }
            else {
              return { success: true };
            }
          }
          else {

            return { success: false };
          }
        }),
        catchError(err => {
          const responseObject: CommentsListResponse = {
            success: false,
            code: err
          }

          return of(responseObject)
        })
      ).toPromise();
  }

  getComments(component: string, componentId: string): Promise<CommentsListResponse> {

    let url = `${this.apiUrl}/comments/component/${component}/${componentId}`;

    return this.http.
      get(url, {
        observe: "response"
      }).pipe(
        map(response => {
          let responseStatus = response.status;

          let responseData: any = response.body

          if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.NO_CONTENT) {
            if (responseData != null && Array.isArray(responseData)) {
              let comments: Comment[] = responseData.map((c: any) => this._commentAdapter.adapt(c));

              return { comments: comments, success: true };
            }
            else {
              return { success: true };
            }
          }
          else {

            return { success: false };
          }
        }),
        catchError(err => {
          const responseObject: CommentsListResponse = {
            success: false,
            code: err
          }

          return of(responseObject)
        })
      ).toPromise();
  }

  newComment(comment: string, componentId: string, parent: string, component: string): Promise<CommentResponse> {

    if (!comment || !component)
      of({ code: "REQUIRED PARAMS MISSING", success: false });


    let apiUrl = this.apiUrl + "/comments";
    const body = {
      commentText: comment,
      componentId: componentId,
      parent: parent,
      component: component
  }

    return this.http.post<any>(apiUrl,
      body, {
        observe: "response"
    }).pipe(map((createResponse) => {

      let responseStatus = createResponse.status;

      let responseData: any = createResponse.body

      if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.CREATED) {
        if (responseData != null) {

          const comment = this._commentAdapter.adapt(responseData);

          return { comment: comment, success: true };
        }
        else {
          return { success: false };
        }
      }
      else {

        return { success: false };
      }

    }, catchError((error: any) => {

      const responseObject: CommentResponse = {
        success: false,
        code: error
      }

      return of(responseObject)

    }))).toPromise();

  }

  updateComment(comment: Comment): Promise<AckResponse> {

    if (!comment)
      of({ code: "REQUIRED PARAMS MISSING", success: false });


    let apiUrl = `${this.apiUrl}/comments/${comment.id}`;
    const body = {
      commentText: comment.editDraft 
    }

    return this.http.put<any>(apiUrl,
      body, {
        observe: "response"
    }).pipe(map((createResponse) => {

      let responseStatus = createResponse.status;

      let responseData: any = createResponse.body

      if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.CREATED) {
        if (responseData != null) {

          // const comment = this._commentAdapter.adapt(responseData);
          comment.text = comment.editDraft ? comment.editDraft: '';

          return { comment: comment, success: true };
        }
        else {
          return { success: false };
        }
      }
      else {

        return { success: false };
      }

    }, catchError((error: any) => {

      const responseObject: AckResponse = {
        success: false,
        code: error
      }

      return of(responseObject)

    }))).toPromise();

  }


  deleteComment(commentID: string): Promise<AckResponse> {

    if (!commentID)
      of({ code: "REQUIRED PARAMS MISSING", success: false });

    let url = `${this.apiUrl}/comments/${commentID}`;

    return this.http.delete<any>(url, {
      observe: "response"
    }).pipe(map((updateResponse) => {

      let responseStatus = updateResponse.status;

      let responseData: any = updateResponse.body

      if (responseStatus == StatusCodes.OK) {
        if (responseData != null) {
          return { message: responseData.message, success: true };
        }
        else {
          return { success: false };
        }
      }
      else {
        return { success: false };
      }

    }, catchError((error: any) => {

      const responseObject: AckResponse = {
        success: false,
        code: error
      }

      return of(responseObject)

    }))).toPromise();
  }

  mutateLike(comment: Comment, action: string): Promise<AckResponse> {

    if (!comment)
      of({ code: "REQUIRED PARAMS MISSING", success: false });

    let apiUrl = `${this.apiUrl}/comments/${comment.id}/${action}`;

    return this.http.put<any>(apiUrl,
      {
      }, {
      observe: "response"
    }).pipe(map((response) => {

      let responseStatus = response.status;

      let responseData: any = response.body

      if (responseStatus == StatusCodes.OK) {
        if (responseData != null) {
          comment.liked = (action == 'like')?true: false;
          return { message: responseData.message, success: true };
        }
        else {
          return { success: false };
        }
      }
      else {
        return { success: false };
      }

    }, catchError((error: any) => {

      const responseObject: AckResponse = {
        success: false,
        code: error
      }

      return of(responseObject)

    }))).toPromise();

  }

}