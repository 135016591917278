import { Subscription } from "rxjs";
import { CommonService } from "src/app/common/service/common.service";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AckResponse, SIDE_NAV_CONTEXT } from "src/app/common/constants";
import { CommentsComponent } from "../../comments/comments/comments.component";
import { ShareDialogComponent } from "../../share/share-dialog/share-dialog.component";
import { SpaceService } from "../data-providers/space.service";
import { LearningSpace } from "../models/learning-space.model";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { Clipboard } from "@angular/cdk/clipboard";
import { AuthenticationService } from "src/app/core/service/authentication.service";
import { SpaceType } from "../helper/space.constant";

@Component({
  selector: "app-learning-space-action-bar",
  templateUrl: "./learning-space-action-bar.component.html",
  styleUrls: ["./learning-space-action-bar.component.scss"],
})
export class LearningSpaceActionBarComponent implements OnInit, OnDestroy, OnChanges {
  private paramSubscription?: Subscription;
  @Input() className : string ="";
    @Input() space: LearningSpace;
  @Input() showNumbers: boolean = true;
  @Input() showViews: boolean = false;
  bookmarkLoading: boolean = false;
  shareInProgress: boolean = false;
  likeInProgress: boolean = false;
  @Input() readonly: boolean = false;
  @Input() canMarkLSPForWebsiteAccess: boolean = false;
  displayOnByndrWebsiteRequestInProgress: boolean = false;
  @Output() bookmarkCompletionEvent = new EventEmitter<LearningSpace>();
  @Output() shareCompletionEvent = new EventEmitter<LearningSpace>();
  @Output() actionBarReadOnlyEvent = new EventEmitter<LearningSpace>();
  clsname:string="";
  constructor(
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _route: ActivatedRoute,
    private _commonService: CommonService,
    private _spaceService: SpaceService,
    private _clipboard: Clipboard,
    public _dialog: MatDialog
  ) {}
  ngOnChanges(){
    this.clsname= this.className
  }

  ngOnInit(): void {

    this.registerForParam();

  }

  informReadOnlyClickEvent(): void {
    this.actionBarReadOnlyEvent.emit(this.space);
  }

  registerForParam() {
    this.paramSubscription = this._route.paramMap.subscribe(
      (params: ParamMap) => {
  
        if (this._router.url.indexOf("/comments") !== -1) {
          this.openComment();
        }
      }
    );
  }

  mutateBookmark() {
    console.log(this.space.bookmarked, this.space);
    if (this.space && this.space.bookmarked) {
      this.removeBookmark();
    } else {
      this.bookmark();
    }
  }

  async mutateLike() {
    if (!this.space.id) {
      return;
    }
    this.likeInProgress = true;

    try {
      let response: AckResponse = await this._spaceService.mutateLike(
        this.space.id,
        this.space.type,
        this.space.liked ? "unlike" : "like"
      );

      if (response.success && response.message && this.space) {
        if (this.space.liked) this.space.likes -= 1;
        else this.space.likes += 1;

        this.space.liked = !this.space.liked;

        this.showSnackBar(response.message, "OK");
      } else {
        this.showSnackBar(`Unable to like. Please try again`, "OK");
      }
      this.likeInProgress = false;
    } catch (error) {
      this.showSnackBar(`Unable to like. Please try again`, "OK");
      this.likeInProgress = false;
    }
  }

  async bookmark() {
    if (!this.space.id) {
      return;
    }
    this.bookmarkLoading = true;
    try {
      let response: AckResponse = await this._spaceService.bookmarkSpace(
        this.space.id,
        this.space.type
      );

      if (response.success && response.message && this.space) {
        this.space.bookmarked = true;
        this.space.bookmarks += 1;
        this.showSnackBar(response.message, "OK");
      } else {
        this.showSnackBar(`Unable to bookmark space. Please try again`, "OK");
      }
      this.bookmarkLoading = false;
    } catch (error) {
      this.showSnackBar(`Unable to bookmark space. Please try again`, "OK");
      this.bookmarkLoading = false;
    }
  }

  async removeBookmark() {
    if (!this.space.id) {
      return;
    }
    this.bookmarkLoading = true;

    try {
      let response: AckResponse = await this._spaceService.removeBookmark(
        this.space.id
      );

      if (response.success && response.message && this.space) {
        this.space.bookmarked = false;
        this.space.bookmarks -= 1;
        this.bookmarkCompletionEvent.emit(this.space);
        this.showSnackBar(response.message, "OK");
      } else {
        this.showSnackBar(`Unable to remove bookmark. Please try again`, "OK");
      }
      this.bookmarkLoading = false;
    } catch (error) {
      this.showSnackBar(`Unable to remove bookmark. Please try again`, "OK");
      this.bookmarkLoading = false;
    }
  }

  openComment() {

    if (!this.space) {
      console.error("Space not available to display comments");
      return;
    }
 
    // this._commonService.raiseSideNavigationEvent({
    //   context: SIDE_NAV_CONTEXT.CONTEXT_COMMENTS,
    //   data: {
    //     component: (this.space.type === SpaceType.learningpath) ? "learningpath" :  "learningspace",
    //     componentObject: this.space,
    //   },
    //   action: "open",
    // });





    const dialogRef = this._dialog.open(CommentsComponent, {
     panelClass:["right_slide_popup", 'animate__animated', 'animate__slideInRight'],
      data: { 
        component: (this.space.type === SpaceType.learningpath) ? "learningpath" :  "learningspace",
        componentObject: this.space,
      },
      position:{ right:'0'}
  });

  dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
  });



  }

  openShareDialog() {
    const dialogRef = this._dialog.open(ShareDialogComponent, {
      width: "450px",
      height: "350px",
      id: "share_dialog",
    });

    const dialogResultSubscription =
      dialogRef.componentInstance.shareEvent.subscribe(
        async (userIDs: string[]) => {
          if (userIDs) {
            this.shareInProgress = true;
            dialogRef.componentInstance.shareInProgress = this.shareInProgress;

            try {
              let response: AckResponse = await this._spaceService.shareSpace(
                this.space.id,
                this.space.type,
                userIDs
              );

              if (response.success && response.message && this.space) {
                this.space.shares += 1;
                // this.space.shared = true;
                this.shareCompletionEvent.emit(this.space);
                this.showSnackBar(response.message, "OK");
              } else {
                this.showSnackBar(
                  `Unable to share space. Please try again`,
                  "OK"
                );
              }
              this.shareInProgress = false;
            } catch (error) {
              this.showSnackBar(
                `Unable to share space. Please try again`,
                "OK"
              );
              this.shareInProgress = false;
              this.space.shared = false;
            }
          }

          dialogRef.componentInstance.shareInProgress = this.shareInProgress;

          if (dialogRef) dialogRef.close();

          dialogResultSubscription.unsubscribe();

          // this.renameDialogCompletionEvent.emit(_node);
        }
      );
  }

  markDisplayOnWebsiteEvent(event: MatSlideToggleChange) {
    console.log("toggle event", event.checked);

    if (this.space.displayOnByndrWebsite !== event.checked) {
      this.mutateDisplayOnWebsite(event.checked);
    }
  }

  async mutateDisplayOnWebsite(_displayOnByndrWesite: boolean) {
    if (!this.space.id) {
      return;
    }
    this.displayOnByndrWebsiteRequestInProgress = true;

    try {
      let response: AckResponse = await this._spaceService.markDisplayOnWebsite(
        this.space.id,
        this.space.type,
        _displayOnByndrWesite
      );

      if (response.success && response.message && this.space) {
        this.space.displayOnByndrWebsite = _displayOnByndrWesite;

        this.showSnackBar(response.message, "OK");
      } else {
        this.space.displayOnByndrWebsite = false;
        this.showSnackBar(`Unable to update. Please try again`, "OK");
      }
      this.displayOnByndrWebsiteRequestInProgress = false;
    } catch (error) {
      this.showSnackBar(`Unable to update. Please try again`, "OK");
      this.displayOnByndrWebsiteRequestInProgress = false;
    }
  }

  showSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 10000 });
  }

  copyPublicLink() {

    if (!this.space)
      return;

    let module = "space";
    if (this.space.type && this.space.type == SpaceType.learningpath) {
      module = "learning-path";
    }
    const copied = this._clipboard.copy(
      `${window.location.origin}/${module}/${this.space.id}`
    );

    this.showSnackBar(
      copied ? "Public link has been copied" : "Unable to copy the link",
      "OK"
    );
  }

  ngOnDestroy(): void {
    if (this.bookmarkCompletionEvent)
      this.bookmarkCompletionEvent.unsubscribe();

    if (this.shareCompletionEvent) this.shareCompletionEvent.unsubscribe();

    if (this.paramSubscription) this.paramSubscription.unsubscribe();
  }
}
