import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { UserProfile } from 'src/app/main/my-profile/models/user-profile.model';
import { InformationDialogComponent } from '../information-dialog/information-dialog.component';

@Component({
  selector: 'app-reauthorize-user',
  templateUrl: './reauthorize-user.component.html',
  styleUrls: ['./reauthorize-user.component.scss']
})
export class ReauthorizeUserComponent implements OnInit, OnDestroy {
  formGroup: any | undefined = FormGroup;
  @Input() userDetails: UserProfile;
  @Input() userID: string | undefined;
  @Input() viewMode = true;
  @Input() currentUser: boolean;
  @Output() validationCompleteEvent = new EventEmitter<string>();
  color: ThemePalette = 'primary';

  loading: boolean = false;
  showPassword = false;

  constructor(private _fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog) { }


  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.formGroup = this._fb.group({
      password: [null, [Validators.required]],
    });
  }

  getErrorPassword() {
    return this.formGroup.get('password').hasError('required') ? 'Password is required' :
      this.formGroup.get('password').hasError('requirements') ? 'The password you’ve entered is incorrect. Please try again' : '';
  }

  async onSubmit(post: any) {
    if (this.formGroup.invalid) {
      return;
    }

    const thisReference = this;
    this.loading = true;

    try {
      let password = this.formGroup.get('password').value;

      const response = await this.authenticationService.validateCurrentPassword(password);
      if (response.responseData && response.responseData.isValid) {

        this.validationCompleteEvent.next(password);

      }
      else {
        thisReference.showError('Password you entered is not correct');
        thisReference.loading = false;
      }

      this.loading = false;


    } catch (error) {
      if (!!error.message)
        this.showError(error.message);
      else
        this.showError(error);
      this.loading = false;
    }


  }

  showError(error: any) {

    const dialogRef = this.dialog.open(InformationDialogComponent, {
      width: '600px',
      id: 'information_dialog'
    })

    dialogRef.componentInstance.content = error
  }


  ngOnDestroy(): void {
    if (this.validationCompleteEvent)
      this.validationCompleteEvent.unsubscribe();
  }

}
