import { LearningSpace } from './../../models/learning-space.model';
import { SpaceService, LearningSpaceMutateResponse } from './../../data-providers/space.service';
import { InformationDialogComponent } from './../../../../shared/components/information-dialog/information-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpaceTemplate } from './../../models/space-template.model';
import { Component, EventEmitter, OnInit, Output, OnDestroy, Input, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SPACE_NAME_MAX_LENGTH, DESCRIPTION_MAX_LENGTH, DESCRIPTION_MAX_LENGTH240 } from '../../../../common/constants';
import { ThemePalette } from '@angular/material/core';
import { Tag } from 'src/app/shared/models/tag.model';
import { TagAutoCompleteComponent } from 'src/app/shared/components/tag-auto-complete/tag-auto-complete.component';
import { SpaceType } from '../../helper/space.constant';

export interface DialogData {
  spaceType: SpaceType;
  displayTitle: string;
}

@Component({
  selector: 'app-create-learning-space-dialog',
  templateUrl: './create-learning-space-dialog.component.html',
  styleUrls: ['./create-learning-space-dialog.component.scss']
})
export class CreateLearningSpaceDialogComponent implements OnInit, OnDestroy {

  createLearningSpaceForm: any | undefined = FormGroup;
  loading: boolean = false;
  mutateInProgress: boolean = false;
  spaceNameMaxLength = SPACE_NAME_MAX_LENGTH;
  descriptionMaxLength = DESCRIPTION_MAX_LENGTH240;
  templateId: string;
  color: ThemePalette = 'primary';
  @ViewChild("tagField", { static: false }) tagField: TagAutoCompleteComponent;

  @Input() spaceId: string;
  displayTitle: string = "Learning Space";
  spaceType: SpaceType = SpaceType.learningspace;
  @Output() createSpaceEvent = new EventEmitter<LearningSpace>();
  templateRequired: boolean = true;
  

  constructor(
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _spaceService: SpaceService, 
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { 
  
      if(data && data.displayTitle)
        this.displayTitle =data.displayTitle;

      if(data && data.spaceType) {
        this.spaceType = data.spaceType;
        this.templateRequired = (this.spaceType == SpaceType.learningspace);
      } 
    }

  ngOnDestroy(): void {
     if (this.createSpaceEvent) 
      this.createSpaceEvent.unsubscribe();
  }

  ngOnInit(): void {
    this.createForm();

    this.loading = (this.spaceType == SpaceType.learningspace);
  }

  createForm() {

    this.createLearningSpaceForm = new FormGroup({
      spaceName: new FormControl('', [Validators.required, Validators.maxLength(SPACE_NAME_MAX_LENGTH)]),
      description: new FormControl('', [Validators.maxLength(DESCRIPTION_MAX_LENGTH)]),
    });

  }

  async onSubmit(post: any) {
    if (this.createLearningSpaceForm.invalid) {
      return;
    }

    if(this.spaceType == SpaceType.learningspace && !this.templateId) {
      this.showErrorDialog('Please choose a template to proceed');
      return;
    }


    try {
      const tags: Tag[] = await this.tagField.finalTags();
      post.tags = tags;
  
  
      let lspObject = new LearningSpace();
      lspObject.title = post.spaceName;
      lspObject.tags = tags;
      lspObject.description = post.description;
      lspObject.type = this.spaceType;

      if (this.spaceType == SpaceType.learningspace) {
        lspObject.templateId = this.templateId;
      }
  
      this.mutateSpace(lspObject);
        
    } catch (error) {
      
    }


  }

  async mutateSpace(_space: LearningSpace) {
    this.mutateInProgress = true;

    try {
      let response: LearningSpaceMutateResponse = await this._spaceService.mutateSpace(_space);
      if (response.success) {
        const receivedSpace: LearningSpace | undefined = response.space;

        if (receivedSpace) {          
            
          this.createSpaceEvent.next(receivedSpace);

          this.closeLearningSpaceDialog();

          this.showSnackBar(`Your ${this.displayTitle} has been created.`, "OK");

        }
        else {
          this.showErrorDialog(`Unable to create ${this.displayTitle}`);
        }
      }
      else {
        this.showErrorDialog("Oops,something went wrong. Please try again");
      }

      this.mutateInProgress = false;


    } catch (error) {
      console.log(`Unable to create ${this.displayTitle}`, error);
      this.mutateInProgress = false;
      this.showErrorDialog("Oops,something went wrong. Please try again");
    }
  }

  showErrorDialog(error: any) {

    const dialogRef = this._dialog.open(InformationDialogComponent, {
      width: '300px',
      id: 'information_dialog'
    })

    dialogRef.componentInstance.content = error



  }

  get remainingLengthForSpaceName(): number {

    if (this.createLearningSpaceForm.get('spaceName').value) {
      return SPACE_NAME_MAX_LENGTH - this.createLearningSpaceForm.get('spaceName').value.length;
    }
    return SPACE_NAME_MAX_LENGTH;

  }

  get remainingLengthForDescription(): number {

    if (this.createLearningSpaceForm.get('description').value) {
      return DESCRIPTION_MAX_LENGTH240 - this.createLearningSpaceForm.get('description').value.length;
    }
    return DESCRIPTION_MAX_LENGTH240;

  }

  templatesLoaded() {
    this.loading = false;
  }

  templateSelected(_template: SpaceTemplate) {

    this.templateId = _template.id;

  }

  showSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 10000 });
  }


  closeLearningSpaceDialog() {
    const dialogRef = this._dialog.getDialogById('create_learning_space_dialog');
    if (dialogRef)
      dialogRef.close();
  }

}
