import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserAvatarsComponent } from './../main/my-profile/avatars/avatars.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatMenuModule } from '@angular/material/menu';
import { ChooseAvatarDialog, ProfileImageComponent } from './../main/my-profile/profile-image/profile-image.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { InformationDialogComponent } from './components/information-dialog/information-dialog.component';
import { UserProfileMetaRowComponent } from '../main/search/components/user-profile-meta-row/user-profile-meta-row.component';
import { UserProfileConnectionActionsComponent } from './components/user-profile-connection-actions/user-profile-connection-actions.component';
import { ReauthorizeUserComponent } from './components/reauthorize-user/reauthorize-user.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TagAutoCompleteComponent } from './components/tag-auto-complete/tag-auto-complete.component';
import { MatIconModule } from '@angular/material/icon';
import { UserAutoCompleteComponent } from './user-auto-complete/user-auto-complete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { UserNotLoggedInComponent } from './components/user-not-logged-in-component/user-not-logged-in-component.component';

@NgModule({
  declarations: [
    InformationDialogComponent,
    ConfirmationDialogComponent,
    UserProfileMetaRowComponent,
    UserAvatarsComponent,
    ChooseAvatarDialog,
    ProfileImageComponent,
    UserProfileConnectionActionsComponent,    
    ReauthorizeUserComponent, TagAutoCompleteComponent, UserAutoCompleteComponent, UserNotLoggedInComponent
  ],
  exports:[
    UserProfileMetaRowComponent,
    ProfileImageComponent,
    UserAvatarsComponent,
    UserProfileConnectionActionsComponent,
    ReauthorizeUserComponent,
    TagAutoCompleteComponent,
    UserAutoCompleteComponent,
    UserNotLoggedInComponent,
  ],
  imports: [    
    MatDialogModule,
    MatSidenavModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    CommonModule,
    NgxSkeletonLoaderModule,
    SharedRoutingModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatChipsModule,
    MatAutocompleteModule,
    FormsModule, ReactiveFormsModule
  ]
})
export class SharedModule { }
