import { Injectable } from "@angular/core";
import { Adapter } from "src/app/common/interfaces/adapter";
import { LibraryNodeType } from "../../library/helper/library.constant";
import { LibraryFileInfoAdapter } from "../../library/models/library-file-info.model";
import { LibraryNode } from "../../library/models/library-node.model";
import { LibraryURLAdapter } from "../../library/models/library-url.model";

export class LearningSpaceBlockMeta {
  node: LibraryNode | undefined;
  blockId: string;
  blockType: string;
}

@Injectable({
  providedIn: "root",
})
export class LearningSpaceBlockMetaAdapter
  implements Adapter<LearningSpaceBlockMeta>
{
  adapt(data: any): LearningSpaceBlockMeta {
    let blockMeta = new LearningSpaceBlockMeta();
    blockMeta.blockId = data.blockId;
    

    try {
      if (data.blockType && typeof data.blockType === "string") {
        blockMeta.blockType = data.blockType;
        let node = new LibraryNode();

        node.type = (<any>LibraryNodeType)[data.blockType];
        if (node.type === LibraryNodeType.file) {
          let fileAdapter = new LibraryFileInfoAdapter();
          node.fileInfo = fileAdapter.adapt(data);
        } else if (node.type === LibraryNodeType.link) {
          let urlAdapter = new LibraryURLAdapter();
          node.urlInfo = urlAdapter.adapt(data);
        }


        blockMeta.node = node;

      }
    } catch (error) {
      console.error("Unable to convert ls block data", error);
    }

    return blockMeta;
  }
}
