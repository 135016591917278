import {
  UserProfile,
  UserProfileAdapter,
} from "src/app/main/my-profile/models/user-profile.model";
import { Injectable } from "@angular/core";
import { Adapter } from "src/app/common/interfaces/adapter";
import { NotificationImageType, NotificationType } from "./../helper/notification.constant";
import { REQUEST_TYPES } from "../../my-profile/helper/user-profile.constant";
export class ByndrNotification {
  id: string;
  sender: UserProfile;
  type: NotificationType;
  read: boolean = false;
  acted: boolean = false;
  title: string;
  clickAction: string;
  showActions: boolean = false;
  message: string;
  createdAt: Date;
  imageURL: string;
  imageType: NotificationImageType = NotificationImageType.other;
  context:
    | ConnectRequestNotification
    | LearningSpaceNotification
    | LearningSpaceCommentNotification
    | undefined;

  get profile(): UserProfile | undefined {
    if (this.context && this.context instanceof ConnectRequestNotification) {
      return this.context.profile;
    }

    return undefined;
  }

  get subTitle(): string | undefined {
    if (this.context && this.context instanceof LearningSpaceCommentNotification) {
      return this.context.comment;
    }
    return undefined;
  }


  update(_notification: ByndrNotification) {

    this.id = _notification.id;
    this.sender = _notification.sender;
    this.type = _notification.type;
    this.read = _notification.read;
    this.acted = _notification.acted;
    this.title = _notification.title;
    this.showActions = _notification.showActions;
    this.message = _notification.message;
    this.createdAt = _notification.createdAt;
    this.imageURL = _notification.imageURL;
    this.imageType = _notification.imageType;
    this.context = _notification.context;
  
  }
}

export class ConnectRequestNotification {
  requestId: string;
  profile: UserProfile;
}

export class LearningSpaceNotification {
  learningSpaceId: string;
}

export class LearningSpaceCommentNotification extends LearningSpaceNotification {
  commentId: string;
  comment: string;
}

@Injectable({
  providedIn: "root",
})
export class NotificationAdapter implements Adapter<ByndrNotification> {
  adapt(data: any): ByndrNotification {
    let notification = new ByndrNotification();

    try {
      notification.id = typeof data.id === "string" ? data.id : null;
      notification.type =
        typeof data.type === "string"
          ? (<any>NotificationType)[data.type]
          : null;
      notification.title = typeof data.title === "string" ? data.title : null;

      if(data.message && typeof data.message === 'string' && data.placeholders && Object.keys(data.placeholders).length > 0) {
        notification.message = this.mapMessageToPlaceHolders(data.message, data.placeholders);
      } else {
        notification.message =
        typeof data.message === "string" ? data.message : null;
      }
      
      notification.message = notification.message.replace(
        new RegExp("\r?\n", "g"),
        "<br />"
      );

      notification.clickAction = typeof data.click_action === 'string' ? data.click_action: false;

      notification.read = typeof data.read === "boolean" ? data.read : false;
      notification.acted = typeof data.acted === "boolean" ? data.acted : false;
      if (typeof data.date === "string") {
        notification.createdAt = new Date(data.date);
      }

      if (data.context) {
        const contextData = data.context;

        if (contextData.image) {
          if (contextData.image.url && typeof contextData.image.url === "string") {
            notification.imageURL = contextData.image.url;
          }

          if(contextData.image.type && typeof contextData.image.type === 'string') {
            notification.imageType = (<any>NotificationImageType)[contextData.image.type];
          } else {
            notification.imageType = NotificationImageType.other;
          }
        
        }

        let context:
          | ConnectRequestNotification
          | LearningSpaceNotification
          | LearningSpaceCommentNotification
          | undefined;
        switch (notification.type) {
          case NotificationType.ConnectionRequest:
            const requestContext = new ConnectRequestNotification();
            requestContext.requestId =
              typeof contextData.requestId === "string"
                ? contextData.requestId
                : null;
            
            let profile = new UserProfile();
            profile.id = data.sender.id;
            profile.requestId = requestContext.requestId;
            profile.requestType = REQUEST_TYPES.REQUEST_TYPE_RECEIVED;
            requestContext.profile = profile;

            context = requestContext;
            notification.showActions = true;
            break;
          case NotificationType.CommentLSP:
          case NotificationType.ReplyComment:
            const commentContext = new LearningSpaceCommentNotification();
            commentContext.learningSpaceId =
              typeof contextData.LSPId === "string" ? contextData.LSPId : null;
            commentContext.commentId =
              typeof contextData.commentId === "string"
                ? contextData.commentId
                : null;
            commentContext.comment =
                typeof contextData.comment === "string"
                  ? contextData.comment
                  : null;
              
            context = commentContext;
            break;
          case NotificationType.LikeLSP:
          case NotificationType.ShareLSP:
            const lsContext = new LearningSpaceNotification();
            lsContext.learningSpaceId =
              typeof contextData.LSPId === "string" ? contextData.LSPId : null;
            context = lsContext;
            break;
          default:
            context = undefined;
            break;
        }

        notification.context = context;
      }

      if (data.sender) {

        let senderObj = data.sender; 

        if (notification.imageType == NotificationImageType.user && notification.imageURL && notification.imageURL.length > 0) {
          senderObj = {...data.sender, profilePicture: notification.imageURL};
        }

        let profileAdapter = new UserProfileAdapter();
        notification.sender = profileAdapter.adapt(senderObj);

        if (!notification.sender.id){
          notification.sender.id = senderObj.id;
        }

      }

    } catch (error) {
      console.error("Unable to convert notification data to model", error);
    }
    return notification;
  }

  mapMessageToPlaceHolders(messageWithPlaceholders: string, placeholders: any) {
    return messageWithPlaceholders.replace(
      /{{(\w+)}}/g,
      (placeholderWithDelimiters, placeholderWithoutDelimiters) =>
        placeholders.hasOwnProperty(placeholderWithoutDelimiters)
          ? `<b class="placeholder_bold">${placeholders[placeholderWithoutDelimiters].value}</b>`
          : placeholderWithDelimiters
    );
  }
}
