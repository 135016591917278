import { AuthenticationService } from './../service/authentication.service';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { StatusCodes } from 'http-status-codes';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      if ([StatusCodes.UNAUTHORIZED, StatusCodes.FORBIDDEN].includes(err.status) && this.authenticationService.isLoggedIn) {
        // auto logout if 401 or 403 response returned from api
        this.authenticationService.logout();
      }

      const error = (err && err.error) || err.statusText;

      if (error.errors && Array.isArray(error.errors)) {

        const array: Array<any> = error.errors;
        if (array.length > 0) {
          const firstError = array[0]
          if (firstError.message) {
            return throwError(firstError.message);
          }
        }
      }
      console.error(error);
      return throwError(error);

    })
    ) as Observable<HttpEvent<any>> ;
  }
}
