// Referenced from https://gist.github.com/JonCatmull/ecdf9441aaa37336d9ae2c7f9cb7289a


import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

const FILE_SIZE_UNITS = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const FILE_SIZE_UNITS_LONG = ['Bytes', 'Kilobytes', 'Megabytes', 'Gigabytes', 'Pettabytes', 'Exabytes', 'Zettabytes', 'Yottabytes'];

@Pipe({
    name: 'formatFileSize'
})
export class FormatFileSizePipe implements PipeTransform {
    deciPipe: DecimalPipe;

    constructor(@Inject(LOCALE_ID) localeId: string) {
        this.deciPipe = new DecimalPipe(localeId);
    }

    transform(bytes: number = 0, precision: number = 2): string {

        if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) { return '?'; }

        let unit = 0;

        while (bytes >= 1024) {
            bytes /= 1024;
            unit++;
        }

        if (unit === 0) {
            precision = 0;
            return bytes + ' ' + FILE_SIZE_UNITS[unit];
        }

        return this.deciPipe.transform(bytes, '1.1-' + precision) + ' ' + FILE_SIZE_UNITS[unit];

    }
}
