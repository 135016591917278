<h2 mat-dialog-title>Edit Sequence
    <span class="dialog-slogan">Select and move spaces to reorder them.</span>
</h2>

<mat-dialog-content class="mat-typography">
    <app-spaces-list #spaceListComponent [showTitle]="false" displayTitle="Learning Path" [showHeaderActions]="false"
        [context]="data.LP_SPACE_CONTEXT" [userID]="data.userID" [listSpaceType]="data.listSpaceType"
        [contextData]="data.LP_SPACE_CONTEXT_DATA" [viewType]="data.viewType" [canMove]="true" [canRemove]="true"
        (postSpacesToPathCompletionEvent)="postSpacesToPathCompletionEvent($event)">
    </app-spaces-list>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button (click)="onCloseClick()" [disabled]="submissionInProgress">Cancel</button>
    <button mat-raised-button (click)="doneClick()" class="btn btn-primary" cdkFocusInitial
        [disabled]="submissionInProgress"> {{submissionInProgress ? 'Saving...': 'Save Changes'}}</button>
        <mat-spinner *ngIf="submissionInProgress" color="primary" diameter="25" strokeWidth="3">
        </mat-spinner>
        
</mat-dialog-actions>