import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MainService {

    menuState: string = 'out';
    cardBox_lg: number = 33.3333;
    cardBox_md: number = 50;
    cardBox_sm: number = 100;
    cardBox_xs: number = 100;
    completeSection_lg: number = 30;
    completeSection_md: number = 40;
    completeSection_sm: number = 50;
    completeSection_xs: number = 100;
    homeLatest_lg: number = 70;
    homeLatest_md: number = 60;
    isOpened: boolean = false;
    isCardInfo: boolean = false;
    isNotificationInfo: boolean = false;
    isMessageInfo: boolean = false;
    isInviteInfo: boolean = false;
    menuItem: string = '';
    pageTitle: string = '';
    isNewDiscovery: boolean = false;
    isMenuItemActive: boolean = false;
    discoveryInfo: boolean = false;
    isSearchBar: boolean = false;
    cardDetails: any;
    isCompleteSection: boolean = true;
    isNewDiscoverySidebar: boolean = false;
    newDiscoveryComponent: boolean = false;
    sidebarFxFlex_lg: number = 100;
    sidebarFxFlex_md: number = 100;
    sidebarFxFlex_sm: number = 100;
    sidebarFxFlex_xs: number = 100;
    listCol_lg: number = 25;
    listCol_md: number = 25;
    listCol_sm: number = 25;
    listCol_xs: number = 25;
    // library related start
    libraryFlex_lg: number = 100;
    libraryFlex_md: number = 70;
    isLibraryInfoSidebar: boolean = false;
    libraryComponent: boolean = false;
    fxFlex_fileCard: number = 20;
    // library related end
    // Topic Feeds related start
    topicFeedsFlex_lg: number = 100;
    topicFeedsFlex_md: number = 70;
    topicsFeedsBox_lg: number = 25;
    topicsFeedsBox_md: number = 33.3333;
    topicsFeedsBox_sm: number = 50;
    topicsFeedsBox_xs: number = 100;
    isTopicsnfoSidebar: boolean = false;
    TopicFeedsComponent: boolean = false;
    isFeedsSuggestedSources: boolean = false;
    TopicsComponent: boolean = false;
    // Topic Feeds related end
    // learning space 
    learnigSpaceComponent: boolean = false;
    isMySpacesInfo: boolean = false;
    serviceCard_lg: number = 25;
    serviceCard_md: number = 33.333;
    serviceCard_sm: number = 50;
    serviceCard_xs: number = 100;
    mySpaces_lg: number = 100;
    mySpaces_md: number = 50;
    spaceCard_lg: number = 20;
    spaceCard_md: number = 25;
    // my profile start
    constructor() { }
    sidebarClosed() {
        this.menuState = 'out';
        this.isOpened = false;
        this.cardBox_lg = 33.3333;
        this.cardBox_md = 50;
        this.cardBox_sm = 100;
        this.cardBox_xs = 100;
        this.completeSection_lg = 30;
        this.completeSection_md = 40;
        this.completeSection_sm = 50.1;
        this.completeSection_xs = 100;
        this.homeLatest_lg = 70;
        this.homeLatest_md = 60;
        this.isCompleteSection = true;
    }
    sidebarOpened() {
        this.menuState = 'in';
        this.isOpened = true;
        this.cardBox_lg = 33.3333;
        this.cardBox_md = 50;
        this.cardBox_sm = 100;
        this.cardBox_xs = 100;
        this.completeSection_lg = 100;
        this.completeSection_md = 100;
        this.completeSection_sm = 100;
        this.completeSection_xs = 100;
        this.homeLatest_lg = 70;
        this.homeLatest_md = 60;
        this.isCompleteSection = false;
    }

}



@Injectable({
    providedIn: 'root',
})

export class ThemeService {

    private currentTheme = new Subject<string>();
    setCurrentTheme(theme: string): void {
        this.currentTheme.next(theme);
      }

    getCurrentTheme():  Subject<string>  {
        return this.currentTheme;
    }
}

@Injectable({
    providedIn: 'root'
})

export class sidebarOpen {



    private sideMenuOpen = new Subject<boolean>();
  
    setSidemenuStatus(value: boolean): void {
      this.sideMenuOpen.next(value);
    }
    
    getSidemenuStatus(): Subject<boolean> {
      return this.sideMenuOpen;
    }





    // private sideMenuOpen: boolean = false;

    // setSidemenuStatus(status: boolean): void {
    //     this.sideMenuOpen = status;
    // }
    // getSidemenuStatus(): boolean {
    //     return this.sideMenuOpen;
    // }

}