import { Router } from '@angular/router';
import { UserProfile } from './../../../my-profile/models/user-profile.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-profile-meta-row',
  templateUrl: './user-profile-meta-row.component.html',
  styleUrls: ['./user-profile-meta-row.component.scss']
})
export class UserProfileMetaRowComponent implements OnInit {

  @Input('userProfile') userProfileObject: UserProfile;
  @Input('userID') userID: string;
  @Input() actionScreen: string;

  constructor(private _router: Router) { }

  ngOnInit(): void {
  }

  showProfile() {

    if (!this.userProfileObject)
      return;

    this._router.navigate(['main/profile/u/',this.userProfileObject.id]);
    

  }

}
