import { LearningSpaceMeta } from './../models/learning-space-meta.model';
import { SpaceListEditDialogComponent } from './../spaces-list/space-list-edit-dialog/space-list-edit-dialog.component';
import { SELECTION_MODE, SpacesViewType } from './../../../common/constants';
import { SpaceContext } from 'src/app/main/spaces/helper/space.constant';
import { NotificationsService } from './../../notifications/data-provider/notifications.service';
import { LearningSpaceBlockMeta } from "./../models/learning-space-block-meta.model";
import { ComponentCanDeactivate } from "./../../../core/guards/pending-changes.guard";
import { InformationDialogComponent } from "./../../../shared/components/information-dialog/information-dialog.component";
import { PublishConfirmationDialogComponent } from "./../dialogs/publish-confirmation-dialog/publish-confirmation-dialog.component";
import { EditLearningSpaceDialogComponent } from "./../spaces-list/edit-learning-space-dialog/edit-learning-space-dialog.component";
import { CommonService } from "src/app/common/service/common.service";
import { MatDrawer } from "@angular/material/sidenav";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  SpaceBlockSaveAction,
  MAX_BLOCKS_PER_SPACE,
  SpaceViewContext,
  SpaceType,
} from "./../helper/space.constant";
import { LearningSpaceBlock } from "./../models/learning-space-block.model";
import {
  SpaceBlockListResponse,
  SpaceService,
  SpaceResponse,
  BlockResponse,
} from "./../data-providers/space.service";
import { ActivatedRoute, ParamMap, Router, Params } from "@angular/router";
import { LibraryNodeType } from "../../library/helper/library.constant";
import { LibraryNode } from "../../library/models/library-node.model";
import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  HostListener,
  Renderer2,
} from "@angular/core";
import { MainService } from "../../main.service";
import {
  DisplayGrid,
  GridsterConfig,
  GridsterItem,
  GridsterItemComponentInterface,
  GridType,
} from "angular-gridster2";
import { MatDialog } from "@angular/material/dialog";
import { LibraryFileViewerDialogComponent } from "../../library/components/library-file-viewer-dialog/library-file-viewer-dialog.component";
import { merge, Observable, Subscription } from "rxjs";
import { LearningSpace } from "../models/learning-space.model";
import { AckResponse, DeleteResponse, SIDE_NAV_CONTEXT } from "src/app/common/constants";
import { AuthenticationService } from "src/app/core/service/authentication.service";
import { PublishErrorDialogComponent } from "../dialogs/publish-error-dialog/publish-error-dialog.component";
import { DeleteConfirmationDialogComponent } from "../dialogs/delete-confirmation-dialog/delete-confirmation-dialog.component";
import { Location } from "@angular/common";
import { SpacesInfoComponent } from "../spaces-info/spaces-info.component";
import { SpacesListComponent } from '../spaces-list/spaces-list.component';

@Component({
  selector: "app-learning-space-detail",
  templateUrl: "./learning-space-detail.component.html",
  styleUrls: ["./learning-space-detail.component.scss"],
})
export class LearningSpaceDetailComponent
  implements OnInit, OnDestroy, ComponentCanDeactivate {
  emptyNode: LibraryNode
  currentUser = false;
  showFullLoader = false;
  fetchingSpace = false;
  fetchingPath = false;
  fetchingSpaces = false;
  fetchingBlocks = false;
  showError = false;
  saving = false;
  tempSpaceId: any;
  errorMessage: string | undefined;
  errorMessageButtonTitle: string = "Retry";
  showMessageButton: boolean = false;
  readonly: boolean = true;
  viewMode: SpaceViewContext;
  spaceId: string | null;
  pathId: string | null;
  space: LearningSpace | undefined;
  path: LearningSpace | undefined;
  options: GridsterConfig;
  blocks: GridsterItem[] | undefined;
  isShowLearningSpacePopup: boolean = true;
  isRightSidePanel: boolean = false;
  learningSpaceLeftSide_md: number = 100;
  selectedBlock: GridsterItem | undefined;
  private paramSubscription?: Subscription;
  private queryParamSubscription?: Subscription;
  maxBlocks = MAX_BLOCKS_PER_SPACE;
  bookmarkLoading: boolean = false;
  pageTitle: string;
  libraryDialogRef: any;
  @ViewChild("drawer", { static: false }) public drawer: MatDrawer;
  @ViewChild("spacePathListComponent") spacePathListComponent: SpacesListComponent;
  private _editSpaceSubscription: Subscription;
  private _deleteSpaceSubscription: Subscription;

  VIEW_MODE_CONSUMER = SpaceViewContext.Consumer;
  VIEW_MODE_EDITOR = SpaceViewContext.Editor;

  typeEnum = SpaceType;
  LP_SPACE_CONTEXT: SpaceContext = SpaceContext.LEARNING_PATH_SPACE;
  LP_SPACE_CONTEXT_DATA: any;
  userID: string;
tempbackdrop:boolean=false;
spaceType:string="";
  constructor(
    private _spaceService: SpaceService,
    public _commonService: CommonService,
    private _notificationService: NotificationsService,
    public _authenticationService: AuthenticationService,
    public mainService: MainService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _location: Location,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,

  ) {

  }
  canDeactivate(): boolean | Observable<boolean> {
    console.log("Can Deactivate called()");
    if (
      this.space &&
      this.space.type === SpaceType.learningspace &&
      !this.readonly &&
      this.viewMode === this.VIEW_MODE_EDITOR &&
      this.viewMode === this.VIEW_MODE_EDITOR &&
      this.space?.publishedId
    ) {
      return false;
    }
    return true;
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener("window:beforeunload", ["$event"])
  unloadNotification($event: any) {
    console.log("unloadNotification called()");
    if (!this.canDeactivate()) {
      $event.returnValue =
        "You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.";
    }
  }

  ngOnInit(): void {

    this.mainService.menuItem = "";
    // this.mainService.menuItem = "Loading...";
    this.pageTitle = "Loading...";
    this.mainService.isMenuItemActive = false;

    this.options = {
      gridType: GridType.Fit,
      displayGrid: DisplayGrid.Always,
      margin: 20,
      pushResizeItems: true,
      disablePushOnDrag: false,
      draggable: {
        enabled: false,
        ignoreContentClass: "gridster-body",
        ignoreContent: true,
        dragHandleClass: "block-drag-handler",
      },
      itemChangeCallback: this.itemChange.bind(this),
      resizable: {
        enabled: false,
      },
      swap: false,
      useTransformPositioning: true,
      swapWhileDragging: true,
      pushItems: false,
    };

    this.registerForParam();

    this._editSpaceSubscription = this._commonService
      .getEditSpaceSubject()
      .subscribe(() => {
        this.openEditLearningSpaceDialog();
      });

    this._deleteSpaceSubscription = this._commonService
      .getDeleteSpaceSubject()
      .subscribe(() => {
        this.openDeleteLearningSpaceDialog();
      });

    // this.blocks = [
    //   {x: 0, y: 0, rows: 90, cols:50, dragEnabled: true, resizeEnabled: true, hasContent: true},
    //   {x: 51, y: 1, rows: 49, cols:50, dragEnabled: true, resizeEnabled: true, hasContent: true},
    //   {x: 51, y: 50, rows: 50, cols:50, dragEnabled: true, resizeEnabled: true, hasContent: true}
    // ]

    // this.blocks = [
    //   { cols: 2, rows: 2, y: 0, x: 0, dragEnabled: true, resizeEnabled: true, hasContent: true },
    //   { cols: 2, rows: 2, y: 0, x: 2, dragEnabled: true, resizeEnabled: true },
    //   { cols: 1, rows: 1, y: 0, x: 4, dragEnabled: true, resizeEnabled: true },
    //   { cols: 5, rows: 1, y: 1, x: 4, dragEnabled: true, resizeEnabled: true },
    //   // {cols: 2, rows: 2, y: 3, x: 5, minItemRows: 2, minItemCols: 2, label: 'Min rows & cols = 2'},
    //   // {cols: 2, rows: 2, y: 2, x: 0, maxItemRows: 2, maxItemCols: 2, label: 'Max rows & cols = 2'},
    //   // {cols: 2, rows: 1, y: 2, x: 2, dragEnabled: true, resizeEnabled: true, label: 'Drag&Resize Enabled'},
    //   // {cols: 1, rows: 1, y: 2, x: 4, dragEnabled: false, resizeEnabled: false, label: 'Drag&Resize Disabled'},
    //   // {cols: 1, rows: 1, y: 2, x: 6}
    // ];
  }

  updateGridOptions() {

    if (this.options.draggable) {
      this.options.draggable.enabled = false; //!this.readonly;
    }

    if (this.options.resizable) {
      this.options.resizable.enabled = false; //!this.readonly;
    }

    this.blocks?.map((b) => {
      b.resizeEnabled = false; //!this.readonly;
      b.dragEnabled = false; //!this.readonly;
    });

    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

  registerForParam() {

    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }

    const thisInstance = this;

    thisInstance.paramSubscription = thisInstance._route.paramMap.subscribe(
      (params: ParamMap) => {
        const _id = params.get("spaceid");
        const _lspid = params.get('lspid');

        let sourceType: SpaceType = SpaceType.learningspace;
        if (_lspid && _id) {
          sourceType = SpaceType.learningspace;
        } else if (_lspid && !_id) {
          sourceType = SpaceType.learningpath;
        } else {
          sourceType = SpaceType.learningspace;
        }

        thisInstance.spaceId = _id ? _id : _lspid;
        thisInstance.pathId = _lspid;
        this.tempSpaceId = thisInstance.spaceId
        if (thisInstance._authenticationService.isLoggedIn) {
          if (thisInstance._router.url.indexOf('/space/') !== -1) {
            thisInstance._router.navigate(["/main/spaces/detail", thisInstance.spaceId]);
          } else if (thisInstance._router.url.indexOf('/learning-path/') !== -1) {
            thisInstance._router.navigate(["/main/learning-paths/detail", thisInstance.spaceId]);
          } else {
            thisInstance.getLearningSpace(thisInstance.spaceId, sourceType);
            if (thisInstance.pathId && (thisInstance.pathId !== thisInstance.spaceId)) {
              thisInstance.getLearningPath();
            }
          }
        } else {
          thisInstance.getLearningSpace(thisInstance.spaceId, sourceType);
        }
      }
    );


    this.queryParamSubscription = this._route.queryParams.subscribe((params: Params) => {

      const notificationId = params.notificationId;

      if (notificationId) {
        this.markNotificationRead(notificationId);
      }

    });

  }

  updateTitle() {
    if (this.space) {
      //  this.mainService.menuItem = this.space.title;
      this.pageTitle = this.space.title;
      this.mainService.isMenuItemActive = true;
    } else {
      // this.mainService.menuItem = "";
      this.pageTitle = "";
    }
  }

  updateUI() {
    this.updateTitle();

    // Check if it is learner or editor view
    const accessToken = this._authenticationService.accessToken;
    const _userid = this._authenticationService.userID(accessToken);
console.log('this is text')
console.log(this.space?.type)
this.spaceType=this.space?.type ? this.space?.type : ''
    if (_userid == this.space?.createdBy.id) {
      this.currentUser = true;

      // Owner has accessed, Check if it is already published. If published mark it as readonly
      if (this.space?.published) {
        this.readonly = true;
        this.viewMode = this.VIEW_MODE_CONSUMER;
      } else {
        this.readonly = false;
        this.viewMode = this.VIEW_MODE_EDITOR;
      }
    } else {
      this.currentUser = false;
      this.readonly = true;
      this.viewMode = this.VIEW_MODE_CONSUMER;
    }

    if (_userid) {
      this.userID = _userid;
    }
    // Update Grid
    this.updateGridOptions();

    // Update Edit Icon On Header
    this._commonService.showSpacesEditOption =
      !this.readonly && this.viewMode === this.VIEW_MODE_EDITOR;
    this._commonService.showSpacesMoreOption = this.currentUser;

    if (this.space?.type === SpaceType.learningspace) {
      if (this.blocks && this.blocks.length > 0) {
        this.showError = false;
        this.errorMessage = undefined;
      } else {
        this.updateBlockAvailabilityMessage();
      }
    }
  }

  async logView(_space: LearningSpace) {
    if (!_space) {
      return;
    }

    if (this._authenticationService.isLoggedIn && _space.published) {
      try {
        let response: AckResponse = await this._spaceService.logView(_space.id, _space.type);

        if (response.success) {
        }
      } catch (error) { }
    }
  }

  async markNotificationRead(_notificationId: string | null) {
    if (!_notificationId) {
      return;
    }

    try {
      await this._notificationService.updateNotificationReadStatus(_notificationId);


    } catch (error) { }
  }

  async getLearningSpace(_spaceId: string | null, _type: SpaceType = SpaceType.learningspace) {

    if (!_spaceId) {
      return;
    }

    this.fetchingSpace = true;

    try {
      let response: SpaceResponse = await this._spaceService.getSpace(
        _spaceId,
        this._authenticationService.isLoggedIn,
        _type
      );

      if (response.success) {
        const _space: LearningSpace | undefined = response.space;

        if (_space) {
          this.space = _space;
          this.logView(this.space);
        }
      }
      else {
        this.space = undefined;
        this.blocks = undefined

        if (this.libraryDialogRef) {

          this.libraryDialogRef.close();
          setTimeout(() => {
            this.showFullScreenDialog(this.emptyNode)
          }, 100);


        }

      }

      this.fetchingSpace = false;
      this.updateUI();



      this.downloadData();


    } catch (error) {
      this.space = undefined;
      this.updateUI();

      this.fetchingSpace = false;

    }
  }

  async getLearningPath() {
    if (!this.pathId) {
      return;
    }

    this.fetchingPath = true;

    try {
      let response: SpaceResponse = await this._spaceService.getSpace(
        this.pathId,
        this._authenticationService.isLoggedIn,
        SpaceType.learningpath
      );

      if (response.success) {
        const _space: LearningSpace | undefined = response.space;

        if (_space) {
          this.path = _space;
        }
      }

      this.fetchingPath = false;
    } catch (error) {
      this.path = undefined;
      this.fetchingPath = false;
    }
  }

  downloadData() {

    if (!this.space)
      return;

    if (this.space.type === SpaceType.learningspace) {
      this.getLearningSpacesBlocks(this.spaceId);
    } else if (this.space.type === SpaceType.learningpath) {
      this.LP_SPACE_CONTEXT_DATA = {
        lspID: this.space.id
      }

      this.fetchingSpaces = true;
    }
  }

  async getLearningSpacesBlocks(_spaceId: string | null) {
    if (!_spaceId) {
      return;
    }

    this.blocks = undefined;
    this.fetchingBlocks = true;
    this.showError = false;
    this.errorMessage = undefined;

    try {
      let response: SpaceBlockListResponse =
        await this._spaceService.getSpaceBlocks(_spaceId, this._authenticationService.isLoggedIn);

      if (response.success) {
        const _spaceBlocks = response.blocks;

        if (
          _spaceBlocks &&
          Array.isArray(_spaceBlocks) &&
          _spaceBlocks.length > 0
        ) {
          this.showError = false;

          // Update blocks from meta data if user is not logged in so that file type and thumbnail info is available
          if (!this._authenticationService.isLoggedIn) {
            if (this.space && this.space.blocksMeta && Array.isArray(this.space.blocksMeta)) {
              const metaBlocks = this.space.blocksMeta;
              _spaceBlocks.map(b => {
                const filteredArray = metaBlocks.filter(mb => (b.id === mb.blockId))
                if (filteredArray && filteredArray.length > 0) {
                  b.node = filteredArray[0].node;
                }
              });
            }
          }

          const gridBlocks: GridsterItem[] = _spaceBlocks.map(
            (_blockObject: LearningSpaceBlock) => {
              return _blockObject.gridMenuItem;
            }
          );
          this.blocks = gridBlocks;

          if (this.libraryDialogRef) {

            this.libraryDialogRef.close();
            setTimeout(() => {
              this.presentSpace();
            }, 100);

          }


        } else {
          this.updateBlockAvailabilityMessage();
        }


        if (!this._authenticationService.isLoggedIn) {
          this._commonService.updateSignInBlurEvent({ display: true, context: 'space_detail', loginReturnURL: this.mainSpaceURL() });
        }

      } else {
        this.errorMessage = "Oops,something went wrong. Please try again";
        this.showError = true;
        this.errorMessageButtonTitle = "Retry";
      }

      this.fetchingBlocks = false;
      this.updateUI();
    } catch (error) {
      this.blocks = undefined;
      this.fetchingBlocks = false;
      this.updateUI();
      this.errorMessage = "Oops,something went wrong. Please try again";
      this.showError = true;
      this.errorMessageButtonTitle = "Retry";
    }
  }


  async editPublishedLearningSpace(_spaceId: string | null) {
    if (!_spaceId || !this.space) {
      return;
    }


    this.showFullLoader = true;

    try {
      let response: SpaceResponse = await this._spaceService.createDraft(_spaceId, this.space.type);


      if (response.success && response.space) {
        this.redirectToLearningSpaceDetail(response.space.id, response.space.type);
      } else {
        this.showErrorDialog(`Unable to edit. Please try again`);
      }

      this.showFullLoader = false;
      // this.updateUI();
    } catch (error) {
      // this.updateUI();
      this.showErrorDialog(`Unable to edit. Please try again`);
      this.showFullLoader = false;
    }
  }

  itemChange(
    item: GridsterItem,
    itemComponent: GridsterItemComponentInterface
  ): void {
    // tslint:disable-next-line:no-console
    console.info("itemChanged", item, itemComponent, this.blocks);

    const _blockObject = LearningSpaceBlock.convertGridMenuItem(item);

    this.updateLearningSpace(
      SpaceBlockSaveAction.MUTATE_BLOCK_POSITION,
      item,
      _blockObject
    );
  }

  changedOptions(): void {
    if (this.options.api && this.options.api.optionsChanged) {
      this.options.api.optionsChanged();
    }
  }

  addItem(_node: LibraryNode): void {
    let newBlock = new LearningSpaceBlock();
    newBlock.x = 0;
    newBlock.y = 0;
    newBlock.rows = 1;
    newBlock.cols = 1;
    newBlock.node = _node;

    this.updateLearningSpace(
      SpaceBlockSaveAction.NEW_BLOCK,
      undefined,
      newBlock
    );
  }

  removeBlock($event: MouseEvent | TouchEvent, _block: GridsterItem) {
    $event.preventDefault();
    // $event.stopPropagation();

    if (!_block || !this.blocks) {
      return;
    }

    this.updateLearningSpace(
      SpaceBlockSaveAction.REMOVE_BLOCK,
      _block,
      undefined
    );
  }

  openBlockInFullScreen($event: MouseEvent | TouchEvent, _block: GridsterItem) {
    $event.preventDefault();
    $event.stopPropagation();

    if (!_block.node) {
      return;
    }

    this.showFullScreenDialog(_block.node);
  }

  showInfoDialog() {
    if (!this.spaceId || !this.space) return;

    const dialogRef = this._dialog.open(SpacesInfoComponent, {
      width: "400px",
      // height: "371px",
      id: "space_info_dialog",
    });

    dialogRef.componentInstance.spaceId = this.spaceId;
    dialogRef.componentInstance.spaceType = this.space.type;


  }

  showFullScreenDialog(_node: LibraryNode, index?:any) {
    this.tempbackdrop=true;
    // Generate node array
    let nodes: LibraryNode[] = [];

    this.blocks?.map((b) => {
      if (b.node) nodes.push(b.node);
    });
    const pathData = this.path ? this.path : '';
    const currentIndex =index? index: nodes?.indexOf(_node);
    console.log(currentIndex)
    this.libraryDialogRef = this._dialog.open(LibraryFileViewerDialogComponent, {
      data: { nodes: nodes, currentIndex: currentIndex, readonly: true, space: this.space, path: pathData, id: this.tempSpaceId, pathId: this.pathId },
      panelClass: "full-screen-dialog",
      hasBackdrop:false
    });

    this.libraryDialogRef.beforeClosed().subscribe((result: any) => {

      this.libraryDialogRef = undefined
      
    });
    this.libraryDialogRef.afterClosed().subscribe(()=>{
      this.tempbackdrop=false

    })

    const dialogResultSubscription =
      this.libraryDialogRef.componentInstance.nodeChangeEvent.subscribe(
        (node: LibraryNode, action: string) => {
          console.log("Got the event from popup", node, action);
          dialogResultSubscription.unsubscribe();
        }
      );
  }

  getNodes(): Node[] {
    if (!this.blocks) return [];

    return this.blocks?.map((b) => b.node);
  }

  openRightSidePanel(_block: GridsterItem) {
    this.isRightSidePanel = true;
    this.learningSpaceLeftSide_md = 70;
    window.dispatchEvent(new Event("resize"));
    this.selectedBlock = _block;
  }

  nodeSelected(_node: LibraryNode) {
    if (_node && _node.type == LibraryNodeType.folder) {
      console.log("Folder cannot be assigned to learning space");
      return;
    }

    if (this.selectedBlock) {
      const _blockObject = LearningSpaceBlock.convertGridMenuItem(
        this.selectedBlock
      );
      _blockObject.node = _node;

      this.updateLearningSpace(
        SpaceBlockSaveAction.MUTATE_BLOCK_NODE,
        this.selectedBlock,
        _blockObject
      );
    }
  }

  nodeSelectedForNewBlock(_node: LibraryNode) {
    if (_node && _node.type == LibraryNodeType.folder) {
      console.log("Folder cannot be assigned to learning space");
      return;
    }
    this.addItem(_node);
  }

  isGridItemSelected(_block: GridsterItem): boolean {
    return _block === this.selectedBlock;
  }

  closeRightPanel() {
    this.isRightSidePanel = false;
    this.learningSpaceLeftSide_md = 100;
    window.dispatchEvent(new Event("resize"));

    this.selectedBlock = undefined;
  }

  updateLearningSpace(
    action: SpaceBlockSaveAction,
    gridItem: GridsterItem | undefined,
    _block: LearningSpaceBlock | undefined
  ) {
    console.log("Update started", action);
    switch (action) {
      case SpaceBlockSaveAction.NEW_BLOCK:
        this.saving = true;
        if (_block instanceof LearningSpaceBlock) {
          this.sendNewBlockRequest(_block);
        }
        break;
      case SpaceBlockSaveAction.MUTATE_BLOCK_NODE:
        this.saving = true;
        if (_block instanceof LearningSpaceBlock && gridItem) {
          this.sendUpdateBlockRequest(action, _block, gridItem);
        }
        break;

      case SpaceBlockSaveAction.REMOVE_BLOCK:
        this.saving = true;
        if (gridItem) {
          this.sendRemoveBlockRequest(gridItem);
        }
        break;
      case SpaceBlockSaveAction.MUTATE_BLOCK_POSITION:
        this.saving = true;
        if (_block instanceof LearningSpaceBlock && gridItem) {
          this.sendUpdateBlockRequest(action, _block, gridItem);
        }
        break;
      default:
        break;
    }
  }

  updateComplete(
    action: SpaceBlockSaveAction,
    gridItem: GridsterItem | undefined,
    _block: LearningSpaceBlock | undefined
  ) {
    console.log("Update completed", action);
    this.saving = false;
    this.updateUI();

    if (this.space) {
      this.space.updatedAt = new Date();
    }
  }

  async sendUpdateBlockRequest(
    action: SpaceBlockSaveAction,
    _block: LearningSpaceBlock,
    gridItem: GridsterItem
  ) {
    if (!this.spaceId || !_block) {
      return;
    }

    gridItem.loading = true;
    gridItem.loadingAction = action;

    try {
      let response: BlockResponse = await this._spaceService.updateBlock(
        this.spaceId,
        _block
      );
      gridItem.loading = false;
      if (response.success && this.blocks) {
        LearningSpaceBlock.updateGridMenuItem(gridItem, _block);
      } else {
        this.showSnackBar(`Unable to add block. Please try again`, "OK");
      }

      this.updateComplete(action, gridItem, _block);
    } catch (error) {
      console.error("Unable to remove block", error);
      this.showSnackBar(`Unable to remove block. Please try again`, "OK");
      gridItem.loading = false;
      this.updateComplete(action, gridItem, _block);
    }
  }

  async sendNewBlockRequest(_block: LearningSpaceBlock) {
    if (!this.spaceId || !_block) {
      return;
    }

    _block.loading = true;
    _block.loadingAction = SpaceBlockSaveAction.NEW_BLOCK;

    try {
      let response: BlockResponse = await this._spaceService.createBlock(
        this.spaceId,
        _block
      );
      _block.loading = false;

      if (!this.blocks) {
        this.blocks = [];
      }

      if (response.success && response.block) {
        this.blocks.push(response.block?.gridMenuItem);

        this.fetchingBlocks = false;
        this.errorMessage = "Oops,something went wrong. Please try again";
        this.showError = true;
      } else {
        this.showSnackBar(`Unable to add block. Please try again`, "OK");
      }

      this.updateComplete(SpaceBlockSaveAction.NEW_BLOCK, undefined, _block);
    } catch (error) {
      console.error("Unable to remove block", error);
      this.showSnackBar(`Unable to remove block. Please try again`, "OK");
      _block.loading = false;
      this.updateComplete(SpaceBlockSaveAction.NEW_BLOCK, undefined, _block);
    }
  }

  async sendRemoveBlockRequest(_gridItem: GridsterItem) {
    if (!this.spaceId || !_gridItem) {
      return;
    }

    _gridItem.loading = true;
    _gridItem.loadingAction = SpaceBlockSaveAction.REMOVE_BLOCK;

    try {
      let response: DeleteResponse = await this._spaceService.deleteBlock(
        this.spaceId,
        _gridItem.id
      );
      _gridItem.loading = false;
      if (response.success && this.blocks) {
        const index = this.blocks.indexOf(_gridItem);
        if (index !== -1) {
          this.blocks.splice(index, 1);
        }
      } else {
        this.showSnackBar(`Unable to remove block. Please try again`, "OK");
      }
      this.updateComplete(
        SpaceBlockSaveAction.REMOVE_BLOCK,
        _gridItem,
        undefined
      );
    } catch (error) {
      console.error("Unable to remove block", error);
      this.showSnackBar(`Unable to remove block. Please try again`, "OK");
      _gridItem.loading = false;
      this.updateComplete(
        SpaceBlockSaveAction.REMOVE_BLOCK,
        _gridItem,
        undefined
      );
    }
  }

  showSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 10000 });
  }

  errorMessageButtonEvent() { }

  updateBlockAvailabilityMessage() {
    this.errorMessage = "No Blocks Available, click + button to add";
    this.showError = true;
    this.errorMessageButtonTitle = "Retry";
  }

  openEditLearningSpaceDialog() {
    if (!this.spaceId || !this.space) return;

    const thisReference = this;
    const dialogRef = this._dialog.open(EditLearningSpaceDialogComponent, {
      width: "700px",
      maxHeight: "80vh",
      id: "edit_learning_space_dialog",
      data: {},
    });

    dialogRef.componentInstance.spaceId = this.spaceId;
    dialogRef.componentInstance.displayTitle = (this.space.type === SpaceType.learningpath) ? "Learning Path" : "Learning Space",
      dialogRef.componentInstance.spaceType = this.space.type;

    const dialogResultSubscription =
      dialogRef.componentInstance.editSpaceEvent.subscribe(
        (_space: LearningSpace) => {
          thisReference.space?.update(_space);
          thisReference.updateTitle();
          dialogResultSubscription.unsubscribe();
        }
      );
  }

  openDeleteLearningSpaceDialog() {
    if (!this.spaceId || !this.space) return;

    const dialogRef = this._dialog.open(DeleteConfirmationDialogComponent, {
      width: "550px",
      id: "delete_conf_dialog",
    });

    dialogRef.componentInstance.spaceId = this.spaceId;
    dialogRef.componentInstance.spaceTitle = this.space.title;
    dialogRef.componentInstance.displayTitle = (this.space.type === SpaceType.learningpath) ? "Learning Path" : "Learning Space",
      dialogRef.componentInstance.spaceType = this.space.type;

    const dialogResultSubscription =
      dialogRef.componentInstance.deleteSpaceEvent.subscribe(
        (_space: LearningSpace) => {
          dialogResultSubscription.unsubscribe();

          this._location.back();
        }
      );
  }
  get displayFullscreen():boolean
  {
    return  this.spaceType == SpaceType.learningspace ? true : false
    
  }

  get isAllBlocksContentFilled(): boolean {
    const filteredArray: GridsterItem[] | undefined = this.blocks?.filter(
      (b) => {
        if (b.node) return false;
        return true;
      }
    );

    if (filteredArray && filteredArray.length > 0) {
      return false;
    }
    return true;
  }

  get hasAtleastOneValidSpace(): boolean {

    if (!this.spacePathListComponent.spaces) {
      return false;
    }

    const filteredSpaces = this.spacePathListComponent.spaces.filter(s => !s.isRemoved);
    return filteredSpaces.length > 0;
  }

  publishSpace(): void {
    if (!this.space) return;

    if (this.space.type === SpaceType.learningspace && !this.isAllBlocksContentFilled) {
      this.showPublishErrorDialog();
      return;
    }

    if (this.space.type === SpaceType.learningpath && !this.hasAtleastOneValidSpace) {
      this.showSnackBar('Please add atleast one valid space to publish this path', "OK");
      return;
    }

    const thisReference = this;
    const dialogRef = this._dialog.open(PublishConfirmationDialogComponent, {
      width: "450px",
      id: "publish_space_conf_dialog",
      data: {
        spaceId: this.space?.id,
        spaceTitle: this.space?.title,
        listSpaceType: this.space.type,
        displayTitle: (this.space.type === SpaceType.learningpath) ? "Learning Path" : "Learning Space",
      },
    });

    const dialogResultSubscription =
      dialogRef.componentInstance.spacePublishedEvent.subscribe(() => {
        console.log("Got the event from publish space");

        if (thisReference.space) {
          thisReference.space.published = true;
          thisReference.updateUI();
        }

        if (thisReference.space && thisReference.space.publishedId) {
          thisReference.redirectToLearningSpaceDetail(
            thisReference.space.publishedId,
            thisReference.space.type,
          );
        }

        dialogResultSubscription.unsubscribe();
      });
  }

  showPublishErrorDialog() {
    this._dialog.open(PublishErrorDialogComponent, {
      width: "450px",
      id: "publish_error_dialog",
      data: {},
    });
  }

  async mutateDraftEvent() {
    if (!this.space || !this.space.published) {
      this.showErrorDialog(
        `${this.space?.title} is not published yet. Please publish and checkback`
      );
      return;
    }

    if (this.space?.draftId) {
      // Draft is already available. Meaning, View draft

      // Currently this case is out of the scope. Draft created should be saved and published if the user traverse to a different screen

      // For now just delete the draft version and redirect to published version

      try {
        let response: AckResponse = await this._spaceService.deleteSpace(
          this.space.draftId,
          this.space.type
        );
        if (response.success) {
          // Create draft
          this.editPublishedLearningSpace(this.space.id);
        } else {
          this.showErrorDialog(`Unable to edit. Please try again`);
        }
      } catch (error) {
        this.showErrorDialog(`Unable to edit. Please try again`);
      }
    } else {
      this.editPublishedLearningSpace(this.space.id);
    }
  }

  async discardDraft() {
    if (!this.space) {
      return;
    }

    const publishedId = this.space.publishedId;
    const spaceType = this.space.type;

    try {
      let response: AckResponse = await this._spaceService.deleteSpace(
        this.space.id,
        this.space.type
      );
      if (response.success) {
        this.space = undefined;

        // Create draft
        this.redirectToLearningSpaceDetail(publishedId, spaceType);

        this.showSnackBar("Your draft has been discarded.", "OK");
      } else {
        this.showErrorDialog(`Unable to discard. Please try again`);
      }
    } catch (error) {
      this.showErrorDialog(`Unable to discard. Please try again`);
    }
  }

  showErrorDialog(error: any) {
    const dialogRef = this._dialog.open(InformationDialogComponent, {
      width: "300px",
      id: "information_dialog",
    });

    dialogRef.componentInstance.content = error;
  }

  redirectToLearningSpaceDetail(spaceId: string, spaceType: SpaceType, newTab = false) {
    if (!spaceId) return;

    let module = "spaces";
    if (spaceType && spaceType == SpaceType.learningpath) {
      module = "learning-paths";
    }

    if (newTab) {
      const url = `${window.location.origin}/main/${module}/detail/${spaceId}`;
      window.open(url);
    } else {
      this._router.navigate([`/main/${module}/detail`, spaceId]);
    }
  }

  infoTapped() { }

  presentSpace() {

    if (this.blocks && this.blocks.length > 0) {

      this.showFullScreenDialog(this.blocks[0].node);

    }

  }

  mainSpaceURL() {

    let url = `main/spaces/detail/${this.spaceId}`;
    if (this.space && this.space.type === SpaceType.learningpath) {
      url = `main/learning-paths/detail/${this.spaceId}`;
    }
    return url;
  }
  mainPathURL() {
    return `main/learning-paths/detail/${this.pathId}`;
  }

  openSpacesPicker() {

    if (!this.space)
      return;

    this._commonService.raiseSideNavigationEvent({
      context: SIDE_NAV_CONTEXT.CONTEXT_SPACE_PICKER,
      data: {
        selectionMode: SELECTION_MODE.MULTIPLE,
        pathID: this.space.id,
      },
      action: "open",
    });

  }

  openSpacesEditPicker() {

    if (!this.space)
      return;

    const dialogRef = this._dialog.open(SpaceListEditDialogComponent, {
      width: '600px',
      panelClass: ['customModal', 'centeredModal'],

      data: {
        LP_SPACE_CONTEXT: SpaceContext.LEARNING_PATH_SPACE,
        LP_SPACE_CONTEXT_DATA: {
          lspID: this.space.id
        },
        userID: this.userID,
        listSpaceType: SpaceType.learningpath,
        viewType: SpacesViewType.List
      },
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result && result.action && result.action === 'done') {
        this.spacePathListComponent.refresh();
      }
    });


  }

  previousSpaceEvent(currentSpace?: any) {

    // Currently only for Learning Path only. In future it might be available for home page feed as well
    if (!this.path || !this.path.spacesMeta) {
      return;
    }

    if (!this.path.hasPreviousSpace(currentSpace)) {
      console.error("No previous space to navigate");
      return;
    }

    const spaceItem = this.path.spacesMeta[this.path.spaceMetaIndex(currentSpace) - 1];

    if (spaceItem && spaceItem.spaceID) {
      //  this.mainService.menuItem = spaceItem.title;
      this.pageTitle = spaceItem.title;
      this._router.navigate([this.mainPathURL(), "s", spaceItem.spaceID]);
    }


  }


  nextSpaceEvent(currentSpace?: any) {
    // Currently only for Learning Path only. In future it might be available for home page feed as well
    if (!this.path || !this.path.spacesMeta) {
      return;
    }

    if (!this.path.hasNextSpace(currentSpace)) {
      console.error("No next space to navigate");
      return;
    }

    const spaceItem: LearningSpaceMeta = this.path.spacesMeta[this.path.spaceMetaIndex(currentSpace) + 1];


    if (spaceItem && spaceItem.spaceID) {
      // this.mainService.menuItem = spaceItem.title;
      this.pageTitle = spaceItem.title;
      this._router.navigate([this.mainPathURL(), "s", spaceItem.spaceID]);
    }


  }



  spacesListCompleteEvent(eventData: { count: number, type: string }) {

    if (!this._authenticationService.isLoggedIn) {
      this._commonService.updateSignInBlurEvent({ display: true, context: 'space_detail', loginReturnURL: this.mainSpaceURL() });
    }

    this.fetchingSpaces = false;

  }

  get canDisablePublish(): boolean {
    if (!this.space)
      return true;

    switch (this.space.type) {
      case SpaceType.learningspace:
        return (this.saving || (this.blocks ? this.blocks.length === 0 : true));
      case SpaceType.learningpath:
        return (this.saving || ((this.spacePathListComponent && this.spacePathListComponent.spaces) ? this.spacePathListComponent.spaces.length === 0 : true));
      default:
        return true;
    }

  }
  async openPathPicker(_space: LearningSpace) {
    if (!_space) return;

    const { LearningPathPickerComponent } = await import('./../../learning-path/components/learning-path-picker/learning-path-picker.component');

    const dialogRef = this._dialog.open(LearningPathPickerComponent, {
      width: "600px",
      panelClass: ["customModal", "learningpathModal"],
      data: {
        space: _space,
      },
    });
  }



  redirectToUserProfile(userId: string, newTab = false) {
    if (!userId)
      return;

    if (newTab) {
      const url = window.location.origin + '/main/profile/u/' + userId;
      window.open(url);
    } else {
      this._router.navigate(['/main/profile/u/', userId]);
    }

  }
  ngOnDestroy(): void {


    this._commonService.updateSignInBlurEvent({ display: false });

    if (this.paramSubscription) this.paramSubscription.unsubscribe();

    if (this.queryParamSubscription) this.queryParamSubscription.unsubscribe();

    if (this._editSpaceSubscription) this._editSpaceSubscription.unsubscribe();

    if (this._deleteSpaceSubscription)
      this._deleteSpaceSubscription.unsubscribe();
  }

/* testing */
clickfullnode(i?:any) {

  if (this.blocks && this.blocks.length > 0) {

    this.showFullScreenDialog(this.blocks[0].node, i);
console.log(this.blocks[0].node)
  }

}


getTitle(blockNode:any, e:any)
{
  


  switch (blockNode.type) {
    case "activity":
      
     return blockNode.activityInfo.questionsCount +'Questions'
      break;
    case "file":

      return blockNode.fileInfo.originalFileName
   
    

      break;


      case "link":
        return blockNode.urlInfo.url;
         break;

    default:
return 'Document'
    break;
  }


 //  blockNode.fileInfo.mime
//   switch (blockNode.fileInfo.mime) {
//     case "image/jpeg":
      
//      return 'Image'
//       break;
//     case "application/pdf":
     
// return 'PDF Document'
//       break;
//     default:
// return 'Activity'
//     break;
//   }
}
/* testing */











}
