<div class="publish-dialog">
    <div class="pb-20" fxLayout="row" fxLayoutAlign="start start">
      <div fxFlex="initial" fxLayoutAlign="start start">
        <mat-icon class="error-icon">error</mat-icon>
      </div>
      <div fxFlex fxLayoutAlign="end start">
        <mat-icon class="close-icon" mat-dialog-close>close</mat-icon>
      </div>
    </div>
    <h3 class="h3-24-black pb-10">Unable to Publish this space!</h3>
    <p class="p-small-black15 pb-20">You have empty containers in your learning space.</p>
    <p class="p-small-black15 complete-step-text pb-10">Complete these steps to publish successfully:</p>
    <div class="publish-list">
      <ul>
        <li>
          <mat-icon>check_circle</mat-icon>
          Add library files to the empty blocks
        </li>
        <li>
          <mat-icon>check_circle</mat-icon>
          Delete the empty blocks
        </li>
      </ul>
    </div>
  </div>