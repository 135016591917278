import { Injectable } from '@angular/core';
import { Adapter } from 'src/app/common/interfaces/adapter';

export class AreaOfInterest {
    id: string;
    title: string;
}

@Injectable({
    providedIn: 'root'
})

export class AreaOfInterestAdapter implements Adapter<AreaOfInterest> {
    adapt(data: any): AreaOfInterest {

        let list = new AreaOfInterest();

        try {

            list.id = (typeof data.id === 'string') ? data.id: null;
            list.title = (typeof data.areaOfInterest === 'string') ? data.areaOfInterest: null;
            
        } catch (error) {
            console.error("Unable to get area of interests", error);
        }

        return list;
    }

}