<div class="grid-view-main">

    <ng-container *ngFor="let groupedType of nodes | groupBy : 'type' : sortOrderArray | orderBy: sortOrder">

        <div class="folder-main">
            <p class="p-small-black15 pb-10">{{groupedType.key | titlecase }}</p>
            <div fxLayout="row wrap" fxLayoutWrap="wrap" fxLayoutAlign="start top" class="gridview_library">
                <ng-container *ngFor="let node of groupedType.value">
                    <ng-container [ngSwitch]="node.type">

                        <ng-container *ngSwitchCase="nodeType.folder" [ngTemplateOutlet]="FolderView"></ng-container>
                        <ng-container *ngSwitchCase="nodeType.file" [ngTemplateOutlet]="FileView"></ng-container>
                        <ng-container *ngSwitchCase="nodeType.link" [ngTemplateOutlet]="LinkView"></ng-container>
                        <ng-container *ngSwitchCase="nodeType.activity" [ngTemplateOutlet]="ActivityView"></ng-container>
                       
                        <ng-template #FolderView>

                            <div [fxFlex]="mainService.fxFlex_fileCard" class="pr-20 pb-20" title='{{node.title}}'>
                                <div [ngClass]="selectedNode?((node.id === selectedNode.id)?'folder-box active-box': 'folder-box'):'folder-box'"
                                    (dblclick)="nodeDoubleClick(node)" (click)='nodeClick(node)'>
                                    <div fxFlex="initial" fxLayoutAlign="start center" class="folder-inside">
                                        <span class="icon icon-move-to-folder mr-10"></span>
                                        <div fxLayout="column" class="titleNode">
                                            <h6 class="h6-15">{{node.title}}</h6>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ng-template>


                        <ng-template #FileView>

                            <div [fxFlex]="mainService.fxFlex_fileCard" class="pr-20 pb-20" title='{{node.title}}'>
                                <div [ngClass]="selectedNode?((node.id === selectedNode.id)?'files-box active-box': 'files-box'):'files-box'"
                                    (dblclick)="nodeDoubleClick(node)" (click)='nodeClick(node)'>
                                    <div class="image-bg two customcls" [ngClass]="[node.fileInfo.getBGClassForList()]" >
                                       
                                        <ng-container
                                            [ngTemplateOutlet]="node.fileInfo && node.fileInfo.thumbnail? ThumbnailView: PlaceholderView"
                                            [ngTemplateOutletContext]="{iconclass:node.fileInfo.getIconClassForList()}">
                                        </ng-container>
                                        <ng-template #PlaceholderView let-iconclass="iconclass">
                                            <span [ngClass]="iconclass">
                                                <ng-template [ngIf]="iconclass === 'icon icon-text'">
                                                    {{node.fileInfo.textForMimeType}}</ng-template>
                                            </span>
                                        </ng-template>
                                        <ng-template #ThumbnailView>

                                        </ng-template>

                                    </div>
                                    <div class="files-image-name one" fxLayout="row" fxLayoutAlign="start center">

                                        <span class="icon icon-bg mr-10 smallIcon"
                                            *ngIf="node.type === nodeType.file && node.fileInfo" [ngClass]="[node.fileInfo.getBGClassForList()]">

                                            <ng-container
                                                [ngTemplateOutlet]="node.fileInfo.getIconClassForList() == 'icon icon-text' ? TextIcon : ImageIcon"
                                                [ngTemplateOutletContext]="{iconclass:node.fileInfo.getIconClassForList()}">
                                            </ng-container>

                                            <ng-template #ImageIcon let-iconclass='iconclass'>
                                                <span [ngClass]="iconclass"></span>
                                            </ng-template>

                                            <ng-template #TextIcon let-iconclass='iconclass'>
                                                <span [ngClass]="iconclass">{{node.fileInfo.textForMimeType}}</span>
                                            </ng-template>

                                        </span>
                                        <div fxLayout="column">
                                            <h6 class="h6-15 single-line title-text" title="{{node.title}}">
                                                {{node.title}}</h6>
                                            <p class="p-small-grey14">
                                                <ng-container [ngTemplateOutlet]="metaInfo"
                                                    [ngTemplateOutletContext]="{node:node}">
                                                </ng-container>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ng-template>

                        <ng-template #LinkView>

                            <div [fxFlex]="mainService.fxFlex_fileCard" class="pr-20 pb-20" title='{{node.title}}'>
                                <div [ngClass]="selectedNode?((node.id === selectedNode.id)?'files-box files-box-linkview active-box': 'files-box files-box-linkview'):'files-box files-box-linkview'"
                                    (dblclick)="nodeDoubleClick(node)" (click)='nodeClick(node)'>
                                    <div class="image-bg thre customcls" [ngClass]="[node.fileInfo.getBGClassForList()]"
                                    [class.urlinfo]="!(node.urlInfo && node.urlInfo.thumbnail)"
                                    >
                                        <ng-container
                                            *ngIf="node.urlInfo && node.urlInfo.thumbnail; else fallbackColor">
                                            <img src="{{node.urlInfo.thumbnail}}" />
                                        </ng-container>
                                        <ng-template #fallbackColor>
                                            <span class="icon icon-background-url icon-url-grey2"></span>
                                        </ng-template>
                                    </div>

                                    <div class="files-image-name two" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="icon icon-bg mr-10" *ngIf="node.type === nodeType.link">
                                            <span class="icon icon-url-grey"></span>
                                        </span>
                                        <div fxLayout="column">
                                            <h6 class="h6-15 single-line title-text">{{node.title}}</h6>
                                            <p class="p-small-grey14 single-line title-text">
                                                <ng-container [ngTemplateOutlet]="metaInfo"
                                                    [ngTemplateOutletContext]="{node:node}">
                                                </ng-container>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ng-template>

                        <ng-template #ActivityView>

                            <div [fxFlex]="mainService.fxFlex_fileCard" class="pr-20 pb-20" title='{{node.title}}'>
                                <div [ngClass]="selectedNode?((node.id === selectedNode.id)?'files-box files-box-linkview active-box': 'files-box files-box-linkview'):'files-box files-box-linkview'"
                                    (dblclick)="nodeDoubleClick(node)" (click)='nodeClick(node)'>
                                    <div class="image-bg one activitybg">
                                        <span class="icon icon-url-greyactivity"></span>
                                    </div>

                                    <div class="files-image-name thr" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="icon icon-bg mr-10" *ngIf="node.type === nodeType.activity">
                                            <span class="icon icon-url-greyactivity2"></span>
                                        </span>
                                        <div fxLayout="column">
                                            <h6 class="h6-15 two-lines">{{node.title}}</h6>
                                            <p class="p-small-grey14">
                                                <ng-container [ngTemplateOutlet]="metaInfo"
                                                    [ngTemplateOutletContext]="{node:node}">
                                                </ng-container>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ng-template>

                    </ng-container>

                </ng-container>
            </div>
        </div>

    </ng-container>

    <ng-template #metaInfo let-node='node'>
        <span title="{{node.createdAt | amDateFormat:'LLL'}}">
            {{node.createdAt | amTimeAgo}}
        </span>
        <ng-template [ngIf]="node.type === nodeType.file && node.fileInfo && (node.fileInfo.fileSize > 0)">
            &nbsp;&bull;
            {{node.fileInfo.fileSize | formatFileSize:1}}
        </ng-template>

        <ng-template [ngIf]="node.type === nodeType.link && node.urlInfo.author && node.urlInfo.author.length > 0">
            &nbsp;&bull;
            {{node.urlInfo.author}}
        </ng-template>
        <ng-template [ngIf]="node.type === nodeType.activity && node.activityInfo">
            &nbsp;&bull;
            {{node.activityInfo.questionsCount}} Questions
        </ng-template>
    </ng-template>


</div>