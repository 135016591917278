import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { UserProfileService } from '../data-providers/user-profile.service';
import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';
import { Avatar } from '../models/avatar.model';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { ThemePalette } from '@angular/material/core';


@Component({
  selector: 'app-avatars',
  templateUrl: './avatars.component.html',
  styleUrls: ['./avatars.component.scss']
})
export class UserAvatarsComponent implements OnInit, OnDestroy, OnChanges {
  loading: boolean = false;
  errorMessage: string;
  avatars?: Avatar[];
  @Input() disable: boolean = false;
  @Input() selectedAvatarId: string;
  selectedAvatar: Avatar | undefined;
  @Output() avatarSelectedEvent =  new EventEmitter<Avatar>();
  

  constructor(private _profileService: UserProfileService,
    private dialog: MatDialog) { }
  

  ngOnInit(): void {
    this.getAvatars();
  }

  async getAvatars() {
    this.loading = true;

    try {
      let response = await this._profileService.getAvatars();

      if (response.success) {
        this.avatars = response.avatars;
        this.updateSelectedAvatar();
        
      } else {
        this.errorMessage = 'Unable to load Avatars';
      }

      this.loading = false;

    } catch (error) {
      this.showErrorDialog("Oops,something went wrong. Please try again");
      this.loading = false;
    }
  }

  avatarSelectionChange(avatar: Avatar) {
    this.avatarSelectedEvent.emit(avatar);
    // if (this.selectedAvatar) {
    //   this.selectedAvatar.loading = false;
    // }
    // avatar.loading = true;
    // this.disable = true;
    // setTimeout(() => {
    //   avatar.loading = false;
    //   this.selectedAvatar = avatar;
    //   this.disable = false;
    // }, 5000);
    
  } 

  updateSelectedAvatar() {
    if (!this.selectedAvatarId) {
      this.selectedAvatar = undefined;
      return;
    }
    
    const filteredAvatars = this.avatars?.filter(a => a.id === this.selectedAvatarId);
    if (filteredAvatars && filteredAvatars.length > 0) {
      this.selectedAvatar = filteredAvatars[0];
    } else {
      this.selectedAvatar = undefined;
    }
  }

  // get checked():boolean {
  //   return (this.)
  // }

  showErrorDialog(error: any) {

    const dialogRef = this.dialog.open(InformationDialogComponent, {
      width: '400px',
      id: 'information_dialog'
    })

    dialogRef.componentInstance.content = error;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'selectedAvatarId': {
            this.updateSelectedAvatar();
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.avatarSelectedEvent) {
      this.avatarSelectedEvent.unsubscribe();
    }
  }

}
