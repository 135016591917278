import { SignInBlurData } from './../../../common/service/common.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-not-logged-in-component',
  templateUrl: './user-not-logged-in-component.component.html',
  styleUrls: ['./user-not-logged-in-component.component.scss']
})
export class UserNotLoggedInComponent implements OnInit {


  @Input() signInData: SignInBlurData | undefined;

  constructor(private _router: Router) { }

  ngOnInit(): void {
  }

  redirectToSignin() {

    if (this.signInData && this.signInData.loginReturnURL) {
      this._router.navigate(['login'], { queryParams: { returnUrl: this.signInData.loginReturnURL } });      
    } else {
      this._router.navigate(['login']);
    }
  }

  redirectToRegister() {
    this._router.navigate(['signup']);
  }

}
