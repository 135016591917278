import { CommonService } from 'src/app/common/service/common.service';
import { APP_PLATFORM } from './../../../common/constants';
import { REQUEST_TYPES } from './../helper/user-profile.constant';
import { ConnectionRequest, ConnectionRequestAdapter } from './../models/connection-request';
import { catchError, map } from 'rxjs/operators';
import { StatusCodes } from 'http-status-codes';
import { of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable,EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserProfile, UserProfileAdapter } from '../models/user-profile.model';
import { Avatar, AvatarAdapter } from '../models/avatar.model';
import { AckResponse, DeleteResponse } from 'src/app/common/constants';


export interface UserProfileListResponse {
  userProfiles?: UserProfile[];
  success: boolean;
  code?: string;
}

export interface UserProfileResponse {
  userProfile?: UserProfile;
  success: boolean;
  code?: string;
}

export interface ProfileImageResponse {
  profilePictureImageurl?: string;
  avatarId?: string;
  success: boolean;
  code?: string;
}

export interface AvatarListResponse {
  avatars?: Avatar[];
  success: boolean;
  code?: string;
}

export interface UserConnectionRequestListResponse {
  received?: ConnectionRequest[];
  raised?: ConnectionRequest[];
  success: boolean;
  code?: string;
}

export interface UserConnectionRequestResponse {
  requestType?: string;
  requestId?: string;
  requester?: string;
  requestee?: string;
  success: boolean;
  code?: string;
}

export interface GenerateOTPResponse {
  otp?: string;
  email?: string;
  message?: string;
  success: boolean;
  code?: string;
}

export class UserProfileListResponse {
  
  profiles?: UserProfile[];
  success: boolean;
  code?: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  apiUrl: string;
  constructor(private http: HttpClient,
    private profileAdapter: UserProfileAdapter,
    private avatarAdapter: AvatarAdapter,
    private requestAdapter: ConnectionRequestAdapter,
    private _commonService: CommonService) {

    if (environment.hasOwnProperty('apiUrl'))
      this.apiUrl = environment.apiUrl;

  }
  


  createUserProfile(userProfile: UserProfile): Promise<UserProfileResponse> {

    if (!userProfile)
      of(null);

    if (!userProfile.id)
      of(null);

    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    let url = this.apiUrl + `/user-profile`;

    const body = userProfile.getCreateProfilePostBody();

    return this.http.
      post(url,
        body,
        {
          headers: headers,
          observe: "response"
        }).pipe(
          map(response => {
            let responseStatus = response.status;

            let responseData: any = response.body

            if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.CREATED) {
              if (responseData != null) {

                let profile: UserProfile = this.profileAdapter.adapt(responseData);

                return { userProfile: profile, success: true };
              }
              else {
                return { success: false };
              }
            }
            else {

              return { success: false };
            }
          }),
          catchError(err => {
            console.log("Unable to reterieve user profile", err);
            const responseObject: UserProfileResponse = {
              success: false,
              code: err
            }

            return of(responseObject)
          })
        ).toPromise();

  }

  getUserProfile(userId: string, currentUser = true): Promise<UserProfileResponse> {

    if (!userId)
      of(null);

    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    let url: string = '';

    if (!currentUser) {
      url = this.apiUrl + `/user-profile/other-user/${userId}`;
    }
    else {
      url = this.apiUrl + `/user-profile`;
    }

    return this.http.
      get(url, {
        headers: headers,
        observe: "response"
      }).pipe(
        map(response => {
          let responseStatus = response.status;

          let responseData: any = response.body

          if (responseStatus == StatusCodes.OK) {
            if (responseData != null) {

              let profile: UserProfile = this.profileAdapter.adapt(responseData);

              if (currentUser && profile) {
                this._commonService.updateNotificationCount(profile.newNotificationsCount);
              }

              return { userProfile: profile, success: true };
            }
            else {
              return { success: false };
            }
          }
          else {

            return { success: false };
          }
        }),
        catchError(err => {
          console.log("Unable to reterieve user profile", err);
          const responseObject: UserProfileResponse = {
            success: false,
            code: err
          }

          return of(responseObject)
        })
      ).toPromise();

  }

  updateUserProfile(userProfile: UserProfile): Promise<UserProfileResponse> {
    if (!userProfile)
      of(null);

    if (!userProfile.id)
      of(null);

    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    let url = this.apiUrl + `/user-profile/`;

    const body = userProfile.getUpdateProfilePostBody();

    return this.http.
      patch(url,
        body,
        {
          headers: headers,
          observe: "response"
        }).pipe(
          map(response => {
            let responseStatus = response.status;

            let responseData: any = response.body

            if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.CREATED) {
              if (responseData != null) {

                let profile: UserProfile = this.profileAdapter.adapt(responseData);

                return { userProfile: profile, success: true };
              }
              else {
                return { success: false };
              }
            }
            else {

              return { success: false };
            }
          }),
          catchError(err => {
            console.log("Unable to update user profile", err);
            const responseObject: UserProfileResponse = {
              success: false,
              code: err
            }

            return of(responseObject)
          })
        ).toPromise();
  }

  // Returns an observable
  uploadProfilePicture(file: File | undefined, avatarId: string | undefined, userID: string | undefined): Promise<ProfileImageResponse> {

    // Create form data
    const formData = new FormData();

    // Store form name as "profilePicture" with file data
    if (file) {
      formData.append('imageType', 'picture');
      formData.append("profilePicture", file);
    }

    if (avatarId) {
      formData.append('imageType', 'avatar');
      formData.append("avatarId", avatarId);
    }


    formData.append("platform", APP_PLATFORM);

    let url = this.apiUrl + `/user-profile/upload-profile-picture`;

    return this.http.post<any>(url, formData, { observe: "response" })
      .pipe(map((uploadResponse) => {

        let responseStatus = uploadResponse.status;

        let responseData: any = uploadResponse.body        
        
        if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.CREATED) {
          if (responseData && responseData.profilePicture) {
            this._commonService.updateProfilePictureMutateSubject();
            return { profilePictureImageurl: responseData.profilePicture, avatarId: responseData.avatarId, success: true };
          }
          else {
            return { success: false };
          }
        }
        else {

          return { success: false };
        }

      }, catchError((error: any) => {

        const responseObject: ProfileImageResponse = {
          success: false,
          code: error
        }

        return of(responseObject)

      }))).toPromise();

  }

  removeProfilePicture(userID: string | undefined): Promise<ProfileImageResponse> {
    if (!userID) {
      console.log("Unable to remove profile image");
      of(null);
    }

    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });
    
    let url = this.apiUrl + `/user-profile/delete-profile-picture`;

    return this.http.
      patch(url, {}, {
        observe: "response",
        headers: headers
      }).pipe(map(removePictureResponse => {

        let responseStatus = removePictureResponse.status;
        if (responseStatus == StatusCodes.OK) {
          this._commonService.updateProfilePictureMutateSubject();
          return { success: true };
          
        } else {
          return { success: false };
        }

      }, catchError((error: any) => {

        const responseObject: ProfileImageResponse = {
          success: false,
          code: error
        }

        return of(responseObject)

      }))).toPromise();
  }


  searchUsers(keyword: string): Promise<UserProfileListResponse> {

    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    let url = this.apiUrl + "/user-profile/search/" + keyword;



    return this.http.
      get(url, {
        headers: headers,
        observe: "response"
      }).pipe(
        map(response => {
          let responseStatus = response.status;

          let responseData: any = response.body

          if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.NO_CONTENT) {
            if (responseData != null && Array.isArray(responseData)) {


              let profiles = responseData.map((user: any) => this.profileAdapter.adapt(user));



              return { userProfiles: profiles, success: true };
            }
            else {
              return { success: true };
            }
          }
          else {

            return { success: false };
          }
        }),
        catchError(err => {
          const responseObject: UserProfileListResponse = {
            success: false,
            code: err
          }

          return of(responseObject)
        })
      ).toPromise();
  }

  getAvatars(): Promise<AvatarListResponse> {

    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    let url = this.apiUrl + "/avatars";

    return this.http.
      get(url, {
        headers: headers,
        observe: "response"
      }).pipe(
        map(response => {
          let responseStatus = response.status;

          let responseData: any = response.body

          if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.NO_CONTENT) {
            if (responseData != null && Array.isArray(responseData)) {

              let avatars = responseData.map((avatar: any) => this.avatarAdapter.adapt(avatar));
              return { avatars: avatars, success: true };
            }
            else {
              return { success: true };
            }
          }
          else {

            return { success: false };
          }
        }),
        catchError(err => {
          const responseObject: AvatarListResponse = {
            success: false,
            code: err
          }

          return of(responseObject)
        })
      ).toPromise();
  }

  getRequests(userID: string): Promise<UserConnectionRequestListResponse> {

    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    let url = this.apiUrl + "/requests/by-user/" + userID;

    return this.http.
      get(url, {
        headers: headers,
        observe: "response"
      }).pipe(
        map(response => {
          let responseStatus = response.status;

          let responseData: any = response.body

          if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.NO_CONTENT) {
            if (responseData != null) {

              let received: ConnectionRequest[] = []
              let raised: ConnectionRequest[] = []
              if (responseData.received && Array.isArray(responseData.received)) {
                received = responseData.received.map((robj: any) => this.requestAdapter.adapt(robj));
              }

              if (responseData.raised && Array.isArray(responseData.raised)) {
                raised = responseData.raised.map((robj: any) => this.requestAdapter.adapt(robj));
              }

              return { received: received, raised: raised, success: true };
            }
            else {
              return { success: true };
            }
          }
          else {

            return { success: false };
          }
        }),
        catchError(err => {
          const responseObject: AvatarListResponse = {
            success: false,
            code: err
          }

          return of(responseObject)
        })
      ).toPromise();
  }


  raiseConnectionRequestToUser(requester: string, requestee: string): Promise<UserConnectionRequestResponse> {

    if (!requester || !requestee) {
      of({ code: "REQUIRED PARAMS MISSING", success: false });
    }


    let apiUrl = this.apiUrl + "/requests";

    let body = {
      requester: requester,
      requestee: requestee,
      requestType: 'Connection'
    }

    return this.http.post<any>(apiUrl,
      body, {
      observe: "response"
    }).pipe(map((response) => {

      let responseStatus = response.status;

      let responseData: any = response.body

      if (responseStatus == StatusCodes.CREATED) {
        if (responseData && responseData.id) {

          const _requestId = responseData.id;
          const _requester = responseData.requester;
          const _requestee = responseData.requestee;

          return { requestId: _requestId, requestType: REQUEST_TYPES.REQUEST_TYPE_RAISED, requester: _requester, requestee: _requestee, success: true };
        }
        else {
          return { success: false };
        }
      }
      else {

        return { success: false };
      }

    }, catchError((error: any) => {

      const responseObject: UserConnectionRequestResponse = {
        success: false,
        code: error
      }

      return of(responseObject)

    }))).toPromise();

  }

  deleteConnection(connectedUserID: string): Promise<DeleteResponse> {

    if (!connectedUserID) {
      of({ code: "REQUIRED PARAMS MISSING", success: false });
    }


    let apiUrl = `${this.apiUrl}/connections/by-connected-user/${connectedUserID}`;

    return this.http.delete<any>(apiUrl,
      {
        observe: "response"
      }).pipe(map((response) => {

        let responseStatus = response.status;

        let responseData: any = response.body;

        if (responseStatus == StatusCodes.OK) {

          const _message = responseData.message;
          return { message: _message, success: true };

        }
        else {
          return { success: false };
        }

      }, catchError((error: any) => {

        const responseObject: DeleteResponse = {
          success: false,
          code: error
        }

        return of(responseObject)

      }))).toPromise();

  }

  acceptRequest(requestID: string): Promise<AckResponse> {

    if (!requestID) {
      of({ code: "REQUIRED PARAMS MISSING", success: false });
    }


    let apiUrl = `${this.apiUrl}/requests/accept/${requestID}`;

    return this.http.patch<any>(apiUrl, {},
      {
        observe: "response"
      }).pipe(map((response) => {

        let responseStatus = response.status;

        let responseData: any = response.body;

        if (responseStatus == StatusCodes.OK) {

          const _message = responseData.message;
          return { message: _message, success: true };

        }
        else {
          return { success: false };
        }

      }, catchError((error: any) => {

        const responseObject: AckResponse = {
          success: false,
          code: error
        }

        return of(responseObject)

      }))).toPromise();

  }

  resetNotificationCount(): Promise<AckResponse> {

    let apiUrl = `${this.apiUrl}/user-profile/reset-notifications-count`;

    return this.http.patch<any>(apiUrl, {},
      {
        observe: "response"
      }).pipe(map((response) => {

        let responseStatus = response.status;

        let responseData: any = response.body;

        if (responseStatus == StatusCodes.OK) {

          this._commonService.updateNotificationCount(0);

          const _message = responseData.message;
          return { message: _message, success: true };

        }
        else {
          return { success: false };
        }

      }, catchError((error: any) => {

        const responseObject: AckResponse = {
          success: false,
          code: error
        }

        return of(responseObject)

      }))).toPromise();

  }

  deleteRequest(requestID: string): Promise<DeleteResponse> {

    if (!requestID) {
      of({ code: "REQUIRED PARAMS MISSING", success: false });
    }


    let apiUrl = `${this.apiUrl}/requests/${requestID}`;

    return this.http.delete<any>(apiUrl,
      {
        observe: "response"
      }).pipe(map((response) => {

        let responseStatus = response.status;

        let responseData: any = response.body;

        if (responseStatus == StatusCodes.OK) {

          const _message = responseData.message;
          return { message: _message, success: true };

        }
        else {
          return { success: false };
        }

      }, catchError((error: any) => {

        const responseObject: DeleteResponse = {
          success: false,
          code: error
        }

        return of(responseObject)

      }))).toPromise();

  }

  generateOTP(email: string): Promise<AckResponse> {

    if (!email) {
      of({ code: "REQUIRED PARAMS MISSING", success: false });
    }


    let apiUrl = `${this.apiUrl}/user-profile/update-email/generate-otp`;

    let body = {
      email: email
    }

    return this.http.post<any>(apiUrl, body,
      {
        observe: "response"
      }).pipe(map((response) => {

        let responseStatus = response.status;

        let responseData: any = response.body;

        if (responseStatus == StatusCodes.CREATED) {

          return { success: true };

        }
        else {
          return { success: false };
        }

      }, catchError((error: any) => {

        const responseObject: AckResponse = {
          success: false,
          code: error
        }

        return of(responseObject)

      }))).toPromise();

  }

  updateEmail(otp: string): Promise<GenerateOTPResponse> {
    if (!otp) {
      of({ code: "REQUIRED PARAMS MISSING", success: false });
    }


    let apiUrl = `${this.apiUrl}/user-profile/update-email/`;

    let body = {
      otp: otp
    }

    return this.http.patch<any>(apiUrl, body,
      {
        observe: "response"
      }).pipe(map((response) => {

        let responseStatus = response.status;

        if (responseStatus == StatusCodes.OK) {

          let profile: UserProfile = this.profileAdapter.adapt(response.body);

          return { email: profile.email, success: true };

        }
        else {
          return { success: false };
        }

      }, catchError((error: any) => {

        const responseObject: GenerateOTPResponse = {
          success: false,
          code: error
        }

        return of(responseObject)

      }))).toPromise();

  }

  verifyEmail(userId: string, verificationToken: string): Promise<AckResponse> {
    if (!userId || !verificationToken) {
      of({ code: "REQUIRED PARAMS MISSING", success: false });
    }

    let apiUrl = `${this.apiUrl}/users/${userId}/verify-email`;

    return this.http.patch<any>(apiUrl, { id: userId, verificationToken },
      {
        observe: "response"
      }).pipe(map((response) => {

        let responseStatus = response.status;

        let responseData: any = response.body

        if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.CREATED) {
          if (responseData != null) {

            return { success: true, data: responseData };
          }
          else {
            return { success: false };
          }
        }
        else {

          return { success: false };
        }

      }, catchError((error: any) => {

        const responseObject: AckResponse = {
          success: false,
          code: error
        }

        return of(responseObject)

      }))).toPromise();

  }

  updateProfileStatus(enabled: boolean): Promise<AckResponse> {


    let apiUrl = `${this.apiUrl}/user-profile/update-account-status`;

    return this.http.patch<any>(apiUrl, { disable: !enabled },
      {
        observe: "response"
      }).pipe(map((response) => {

        let responseStatus = response.status;

        let responseData: any = response.body

        if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.CREATED) {
          if (responseData != null) {

            return { success: true };
          }
          else {
            return { success: false };
          }
        }
        else {

          return { success: false };
        }

      }, catchError((error: any) => {

        const responseObject: AckResponse = {
          success: false,
          code: error
        }

        return of(responseObject)

      }))).toPromise();

  }

  deleteUserProfile(userId: string): Promise<AckResponse> {

    let apiUrl = `${this.apiUrl}/users/` + `${userId}`;

    return this.http.delete<any>(apiUrl,
      {
        observe: "response"
      }).pipe(map((response) => {

        let responseStatus = response.status;

        let responseData: any = response.body

        if (responseStatus == StatusCodes.OK) {
          if (responseData != null) {

            return { success: true };
          }
          else {
            return { success: false };
          }
        }
        else {

          return { success: false };
        }

      }, catchError((error: any) => {

        const responseObject: AckResponse = {
          success: false,
          code: error
        }

        return of(responseObject)

      }))).toPromise();

  }

  getUserProfiles(): Promise<UserProfileListResponse> {

    let url = environment.apiUrl + "/user-profile/list";

    return this.http.
      get(url, {
        observe: "response"
      }).pipe(
        map(response => {
          let responseStatus = response.status;

          let responseData: any = response.body
          if (responseStatus == StatusCodes.OK) {
            if (responseData != null && Array.isArray(responseData)) {

              let profiles = responseData.map((list: any) => this.profileAdapter.adapt(list));

              return { profiles: profiles, success: true };
            }
            else {
              return { success: false };
            }
          }
          else {

            return { success: false };
          }
        }),
        catchError(err => {
          const responseObject: UserProfileListResponse = {
            success: false,
            code: err
          }

          return of(responseObject)
        })
      ).toPromise();

  }


  markTourVisited(visitStatus: string): Promise<AckResponse> {

    if (!visitStatus) {
      of({ code: "REQUIRED PARAMS MISSING", success: false });
    }


    let apiUrl = `${this.apiUrl}/user-profile/update-tour-status`;

    return this.http.patch<any>(apiUrl, {
      "platform": APP_PLATFORM,
      "status": visitStatus
    },
      {
        observe: "response"
      }).pipe(map((response) => {

        let responseStatus = response.status;

        let responseData: any = response.body;

        if (responseStatus == StatusCodes.OK) {

          const _message = responseData.message;
          return { message: _message, success: true };

        }
        else {
          return { success: false };
        }

      }, catchError((error: any) => {

        const responseObject: AckResponse = {
          success: false,
          code: error
        }

        return of(responseObject)

      }))).toPromise();

    // try {

    //   localStorage.setItem('tbvs', "Y");


    // } catch (error) {

    // }


  }

  updateThemeSettings(theme:string,sidebarstatus:boolean ): Promise<any> {
   
   


    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    });

    let url = this.apiUrl + `/user-profile/update-theme`;

    
    const body={
      'theme': theme,
      'expandMenuSidebar':sidebarstatus
    }
     
    return this.http.
      patch(url,
        body,
        {
          headers: headers,
          observe: "response"
        }).pipe(
          map(response => {
            let responseStatus = response.status;

            let responseData: any = response.body

            if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.CREATED) {
              if (responseData != null) {

              //  let profile: UserProfile = this.profileAdapter.adapt(responseData);

                return { success: true };
              }
              else {
                return { success: false };
              }
            }
            else {

              return { success: false };
            }
          }),
          catchError(err => {
            console.log("Unable to update user profile", err);
            const responseObject: UserProfileResponse = {
              success: false,
              code: err
            }

            return of(responseObject)
          })
        ).toPromise();

  }



}
