import { Injectable } from '@angular/core';
import { Adapter } from 'src/app/common/interfaces/adapter';
export class LearningSpaceMeta {
    spaceID: string;
    position: number;
    title: string;
}

@Injectable({
    providedIn: "root",
  })
  export class LearningSpaceMetaAdapter
    implements Adapter<LearningSpaceMeta>
  {
    adapt(data: any): LearningSpaceMeta {
      let spaceMeta = new LearningSpaceMeta();
      if (data.LSPId) {
        spaceMeta.spaceID = data.LSPId;
      }
      spaceMeta.position = typeof data.position === "number" ? data.position : 0;
      spaceMeta.title =
      typeof data.name === "string" ? data.name : null;
        
      return spaceMeta;
    }
  }