import { UserProfileAdapter } from './../../my-profile/models/user-profile.model';
import { UserProfile } from 'src/app/main/my-profile/models/user-profile.model';
import { Injectable } from '@angular/core';
import { Adapter } from 'src/app/common/interfaces/adapter';
export class Comment {

    id: string;
    text: string;
    liked: boolean;
    componentId: string;
    parent: string | undefined;
    commentedOn: Date;
    updatedAt?: Date;
    editing: boolean = false;
    createdBy: UserProfile;
    childCount: number = 0;
    childs: Comment[];
    fetchingChilds: boolean = false;
    showReply: boolean = false;
    replyDraft: string | undefined = '';
    replySubmissionInProgress: boolean = false;
    editDraft: string | undefined = '';
    showEditComment: boolean = false;
    editSubmissionInProgress: boolean = false;
    removed: boolean = false;


    newCommentPostBody(component: string, parent?: string) {
        return {
            commentText: this.text,
            componentId: this.componentId,
            component: component,
            parent: parent
        }
    }
}


@Injectable({
    providedIn: 'root'
})
export class CommentAdapter implements Adapter<Comment> {
    adapt(data: any): Comment  {

        let commentObject = new Comment();

        try {

            commentObject.id = (typeof data.id === 'string') ? data.id: null;
            commentObject.text = (typeof data.commentText === 'string') ? data.commentText: null;
            commentObject.liked = (typeof data.liked === 'boolean')? data.liked: false;
            commentObject.componentId = (typeof data.componentId === 'string') ? data.componentId: null;
            commentObject.parent = (typeof data.parent === 'string') ? data.parent: null;
            commentObject.childCount = (typeof data.replies === 'number')? data.replies: 0;


            if(data.user) {
                let profileAdapter = new UserProfileAdapter();
                commentObject.createdBy = profileAdapter.adapt(data.user);
            }
            
            if((typeof data.commentedOn === 'string')) {
                commentObject.commentedOn = new Date(data.commentedOn);
            }

        } catch (error) {
            console.error("Unable to convert comment object", error);
        }

        return commentObject;
    }

}
