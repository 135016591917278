<div [ngClass]="['article-content','article-full-width', showThumbnailOnly? 'artcile-no-margin': '']">
  <ng-container *ngIf="node && showThumbnailOnly; else filePreviewContainer">
    <div class="icon-preview-bg">
      <ng-container *ngIf="node.type === nodeType.folder">
        <div class="icon-preview-bg-white">
          <span class="icon icon-bg icon-background-folder icon-bg-pading"
            ><span class="icon icon-folder-blue icon-full"></span
          ></span>
        </div>
      </ng-container>
      <ng-container *ngIf="node.type === nodeType.link">
        <ng-container
          *ngIf="node.urlInfo && node.urlInfo.thumbnail; else fallbackIcon"
        >
          <span class="fullthumb">
            <img src="{{ node.urlInfo.thumbnail }}" />
          </span>
        </ng-container>
        <ng-template #fallbackIcon>
          <div class="icon-preview-bg-white">
            <span class="icon icon-bg icon-background-url icon-bg-pading"
              ><span class="icon icon-url-grey icon-lt"></span
            ></span>
          </div>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="node.type === nodeType.activity">
        <div class="icon-preview-bg-white">
          <span class="icon icon-bg icon-background-activity icon-bg-pading"
            ><span class="icon icon-url-activity-with-bg icon-full"></span
          ></span>
        </div>
      </ng-container>

      <ng-container *ngIf="node.type === nodeType.file && node.fileInfo">
        <div class="icon-preview-bg-white">
          <span
            [ngClass]="[
              'icon',
              'icon-bg',
              node.fileInfo.getBGClassForList(),
              'icon-bg-padding'
            ]"
          >
            <ng-container
              [ngTemplateOutlet]="
                node.fileInfo.getIconClassForList() == 'icon icon-text'
                  ? TextIcon
                  : ImageIcon
              "
              [ngTemplateOutletContext]="{
                iconclass: node.fileInfo.getIconClassForList()
              }"
            >
            </ng-container>

            <ng-template #ImageIcon let-iconclass="iconclass">
              <span [ngClass]="iconclass"></span>
            </ng-template>

            <ng-template #TextIcon let-iconclass="iconclass">
              <span [ngClass]="iconclass">{{
                node.fileInfo.textForMimeType
              }}</span>
            </ng-template>
          </span>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #filePreviewContainer>
    <ng-container [ngSwitch]="node.type">
      <ng-container
        *ngSwitchCase="nodeType.folder"
        [ngTemplateOutlet]="FolderViewer"
      >
      </ng-container>
      <ng-container
        *ngSwitchCase="nodeType.file"
        [ngTemplateOutlet]="FileViewer"
      ></ng-container>
      <ng-container
        *ngSwitchCase="nodeType.link"
        [ngTemplateOutlet]="LinkViewer"
      ></ng-container>
      <ng-container
        *ngSwitchCase="nodeType.activity"
        [ngTemplateOutlet]="ActivityViewer"
      ></ng-container>

      <ng-template #FolderViewer> </ng-template>

      <ng-template #ActivityViewer>
        <app-questionnaire-run
          [activityId]="node.activityInfo.id"
        ></app-questionnaire-run>
      </ng-template>

      <ng-template #FileViewer>
        <ng-container [ngSwitch]="node.fileInfo.viewer.viewerSource">
          <ng-container
            *ngSwitchCase="'google'"
            [ngTemplateOutlet]="GoogleViewer"
          ></ng-container>
          <ng-container
            *ngSwitchCase="'office'"
            [ngTemplateOutlet]="OfficeViewer"
          ></ng-container>
          <ng-container
            *ngSwitchCase="'image'"
            [ngTemplateOutlet]="ImageViewer"
          ></ng-container>
          <ng-container
            *ngSwitchCase="'video'"
            [ngTemplateOutlet]="VideoViewer"
          ></ng-container>

          <ng-template #GoogleViewer>
            <ngx-doc-viewer
              *ngIf="node.fileInfo"
              [url]="node.fileInfo.location"
              viewer="google"
              style="width: 100%; height: 100vh"
            ></ngx-doc-viewer>
          </ng-template>

          <ng-template #OfficeViewer>
            <ngx-doc-viewer
              *ngIf="node.fileInfo"
              [url]="node.fileInfo.location"
              viewer="office"
              style="width: 100%; height: 90vh"
            ></ngx-doc-viewer>
          </ng-template>

          <ng-template #ImageViewer>
            <img
              *ngIf="node.fileInfo"
              src="{{ node.fileInfo.location }}"
              class="imageviewer"
            />
          </ng-template>

          <ng-template #VideoViewer>
            <div class="article-center video-fullscreen">
              <video width="100%" min-height="240" controls>
                <source src="{{ node.fileInfo.location }}" />
              </video>
            </div>
          </ng-template>
        </ng-container>
      </ng-template>

      <ng-template #LinkViewer>
        <ng-container *ngIf="node.urlInfo.content && !loading; else noContent">
          <article>
            <div class="article-center">
              <div
                [ngClass]="node.urlInfo.articleClassForFullPreview"
                [innerHTML]="node.urlInfo.content | safe: 'html'"
              ></div>
            </div>
          </article>
        </ng-container>
        <ng-template #noContent>
          <div class="article-center cannot-show pt-20" fxLayout="column">
            <p class="pb-20 align-center">Cannot show preview of this link.</p>
            <button
              *ngIf="!loading"
              mat-raised-button
              (click)="openLink()"
              class="btn-submit"
            >
              Open in new tab
              <mat-icon>open_in_new</mat-icon>
            </button>
          </div>
        </ng-template>
      </ng-template>
    </ng-container>
  </ng-template>
</div>
