<div class="signinwrapper">
  <section class="signinCard">
    <h2>Sign In to get full access</h2>
    <h3>
      You aren't signed into Learning Pods yet. <br />Do it now to explore all the
      resources.
    </h3>
    <button
      mat-raised-button
      class="btn-submit btn-full"
      (click)="redirectToSignin()"
    >
      Sign In
    </button>
    <button
      mat-stroked-button
      class="btn-cancel btn-full"
      (click)="redirectToRegister()"
    >
      Create an Account
    </button>
  </section>
</div>
