import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';
import { LibraryService, LibraryNodeResponse } from './../../data-providers/library.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { LibraryNode } from './../../models/library-node.model';
import {LibraryNodeType} from '../../helper/library.constant';
import { Component, OnInit, Output, EventEmitter, Inject, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-new-folder-dialog',
  templateUrl: './new-folder-dialog.component.html',
  styleUrls: ['./new-folder-dialog.component.scss']
})
export class NewFolderDialogComponent implements OnDestroy {

  uploading: boolean = false;
  showError: boolean = false;
  errorMessage?: string;
  parentNode: LibraryNode;
  formGroup: any;
  color: ThemePalette = 'primary';

  @Output() folderCreatedEvent = new EventEmitter<LibraryNode>();


  constructor(
    private dialog: MatDialog,
    private _fb: FormBuilder,
    private libraryService: LibraryService,
    public dialogRef: MatDialogRef<NewFolderDialogComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {


    this.parentNode = data.parentNode;

    this.createForm();
  }
 

  createForm() {
    this.formGroup = this._fb.group({
      folder: [null, [Validators.required]],
    });
  }

  submitTapped(post: any): void {

    const folderTitle: string = this.formGroup.get("folder").value;

    if (this.formGroup && this.formGroup.invalid) {
      console.error("Unable to save folder. Invalid form value", post);

      if (!folderTitle) {
        this.showErrorDialog("Please provide folder title");
      }

      return;
    }


    this.createFolder(folderTitle);

  }



  async createFolder(folderTitle: string) {
    this.uploading = true;
    this.showError = false;
    this.errorMessage = undefined;

    try {
      let response: LibraryNodeResponse = await this.libraryService.createNode(LibraryNodeType.folder, folderTitle, this.parentNode && (this.parentNode.id ? this.parentNode.id : undefined), undefined)
      if (response.success) {
        const receivedNode: LibraryNode | undefined = response.node;

        if (receivedNode) {

          this.showError = false;

          this.folderCreatedEvent.emit(receivedNode);

          const dialogRef = this.dialog.getDialogById('new_folder_dialog');
          if (dialogRef)
            dialogRef.close();

          this.showSnackBar("Folder has been created", "OK");

        }
        else {
          this.showErrorDialog("Unable to created folder");
          this.showError = true;
        }
      }
      else {
        this.showErrorDialog("Oops,something went wrong. Please try again");
        this.showError = true;
      }

      this.uploading = false;


    } catch (error) {
      console.log("Unable to create folder", error);
      this.uploading = false;
      this.showErrorDialog("Oops,something went wrong. Please try again");
      this.showError = true;
    }
  }

  showErrorDialog(error: any) {

    const dialogRef = this.dialog.open(InformationDialogComponent, {
      width: '300px',
      id: 'information_dialog'
    })

    dialogRef.componentInstance.content = error



  }

  showSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 10000 });
  }


  ngOnDestroy(): void {
    
    if(this.folderCreatedEvent)
      this.folderCreatedEvent.unsubscribe();

  }


}
