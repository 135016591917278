import { InformationDialogComponent } from './../../../../shared/components/information-dialog/information-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Question } from './../models/question.model';
import { Option } from './../models/option.model';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RunQuestionnaire } from '../models/run-questionnaire.model';
import { ActivityResponse, QuestionnaireService } from './../data-providers/questionnaire.service';
import { SelectionModel } from '@angular/cdk/collections/selection-model';
import { MatListOption, MatSelectionListChange } from '@angular/material/list';
import { MatTabChangeEvent } from '@angular/material/tabs';

class QuestionPreview {
  percentage: number = 0;
  correctAnswersCount: number = 0;
  wrongAnswersCount: number = 0;

  getPercentageClass() {

    return (this.percentage < 0.5)? 'red': 'green';
  
  }

}
@Component({
  selector: 'app-questionnaire-run',
  templateUrl: './run.component.html',
  styleUrls: ['./run.component.scss']
})
export class RunComponent implements OnInit, OnChanges {

  loading = false;
  private _selectedIndex: number = 0;
  public get selectedIndex(): number {
    return this._selectedIndex;
  }
  public set selectedIndex(value: number) {
    this._selectedIndex = value;
  }
  activity: RunQuestionnaire | undefined;
  @Input('activityId') activityId: string;

  questionnairePreview: QuestionPreview;
  constructor(private questionnaireService: QuestionnaireService, private _dialog: MatDialog) { }

  async fetchQuestionnaire() {


    if (!this.activityId)
      return;

    this.loading = true;
    try {
      const response: ActivityResponse = await this.questionnaireService.getActivity(this.activityId);
      this.activity = response.activity;
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'activityId': {
          console.log("Activity", this.activityId);

            this.fetchQuestionnaire();
          }
        }
      }
    }
  }

  ngOnInit(): void {
  }

  submitQuestion(_question: Question) {
    

    const answeredOptions = _question.options.filter(o => o.isAnswered);
    if (!answeredOptions || answeredOptions.length == 0) {
      this.showError('Please choose at least one option.');
      return;
    }


    // Determine correct answers
    _question.options.map(o => {
      const filteredAnswers = _question.answers.filter(a => (a.toLowerCase() === o.key.toLowerCase()))
      if (filteredAnswers.length > 0) {
        o.isCorrectAnswer = true;
      } else {
        o.isCorrectAnswer = false;
      }
    })

    const correctAnswers = answeredOptions.filter(o => o.isCorrectAnswer);

    _question.isCorrectAnswer = (correctAnswers && Array.isArray(correctAnswers) && correctAnswers.length > 0);

    //TODO:
    // _question.isCorrectAnswer =  (question.options.filter(o => o.isCorrectAnswer).length > 0);

    _question.isAnswered = true;
  }

  getBackgroundClass(_selectedOption: Option, _question: Question) {
    if (_question.isAnswered) {
      if (_selectedOption.isAnswered) {
        return _selectedOption.isCorrectAnswer? 'neutral-answer-highlight correct-answer': 'neutral-answer-highlight incorrect-answer'
      } else {
        // Display green if it is correct
        return _selectedOption.isCorrectAnswer? 'correct-answer': '';
      }
    } else {
      // Submit hasn't been clicked
      if(_selectedOption.isAnswered) {
        return 'neutral-answer-highlight';
      } else {
        return '';
      }
    }
  }

  optionClickEvent(event: any, _option: Option, _question: Question, _selectedList: SelectionModel<MatListOption>) {

    

    if (_question.answers && Array.isArray(_question.answers) && _question.answers.length > 1) {
      if (!_option.isAnswered) {
        const filtered = _selectedList.selected.filter(ml => (ml.value.key === _option.key))
        if (filtered.length > 0) {
          _option.isAnswered = true;
        } else {
          _option.isAnswered = false;
        }  
      } else {
        _option.isAnswered = false;
      }
    } else {
      // Deselect all as it has single/no answer
      if (_option.isAnswered) {
        _option.isAnswered = false;
      } else {
       
        _question.markAllOptionsUnAnswered();

        const filtered = _selectedList.selected.filter(ml => (ml.value.key === _option.key))
        if (filtered.length > 0) {
          _option.isAnswered = true;
        } else {
          _option.isAnswered = false;
        }  

      }
      
    }

    
    
    
    //this.validateAnswer(event, _option, question)
  }

  validateAnswer(ele:any ,option: Option, question: Question): Boolean | undefined {
    //debugger;
  //   var element = ele.source._elementRef.nativeElement;
    

  //   var allElements = element.parentElement;
  //  for(var i=0; i<allElements.children.length; i++){
  //   allElements.children[i].setAttribute('style', 'color: black;');
  //   }

    const filteredAnswers = question.answers.filter(a => (a.toLowerCase() === option.key.toLowerCase()))
      if (filteredAnswers.length > 0) {
        option.isCorrectAnswer = true;
      } else {
        option.isCorrectAnswer = false;
      }

    question.isCorrectAnswer =  (question.options.filter(o => o.isCorrectAnswer).length > 0);

    // if(question.isCorrectAnswer){
      
    //   element.setAttribute('style', 'color: black; padding-bottom: inherit !important; padding: 8px; background: #28a7454d;');
    // }
    // else{
    //   element.setAttribute('style', 'color: red');
    // }
    return question.isCorrectAnswer;
  }

  calculatePreview() {
    if (!this.activity)
      return;

    this.questionnairePreview = new QuestionPreview();
    let answeredCount = 0;
    this.activity.questions.map(question => {
      
      if (question.isCorrectAnswer) {
        this.questionnairePreview.correctAnswersCount += 1;
        answeredCount += 1;
      } else if (question.isCorrectAnswer === false) {
        this.questionnairePreview.wrongAnswersCount += 1;
        answeredCount += 1;
      }
    });
    this.questionnairePreview.percentage = (this.questionnairePreview.correctAnswersCount / this.activity.questions.length);
    
  }

  nextStep() {

    if (!this.activity)
      return;

    if (this.selectedIndex < this.activity.questions.length) {
      this.selectedIndex += 1;
    } 

    if (this.selectedIndex === this.activity.questions.length) {
      this.calculatePreview();
    }

  }

  previousStep() {
    if (this.selectedIndex > 0) {
      this.selectedIndex -= 1;
    }
    
  }

  selectedTabChangeEvent(_event: MatTabChangeEvent) {    
    this._selectedIndex = _event.index;
  }

  retakeQuiz() {

    if (!this.activity)
      return;
    
    this.activity.resetAnswers();
    this.selectedIndex = 0;
  }

  showError(error: string) {

    const dialogRef = this._dialog.open(InformationDialogComponent, {
      width: '600px',
      id: 'information_dialog'
    })

    dialogRef.componentInstance.content = error;
  }

}
