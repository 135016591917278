<ng-container [ngSwitch]="_space.type">
    <ng-container *ngSwitchCase="typeEnum.learningspace" [ngTemplateOutlet]="BlocksMetaView">
    </ng-container>
    <ng-container *ngSwitchCase="typeEnum.learningpath" [ngTemplateOutlet]="PathMetaView">
    </ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="BlocksMetaView"></ng-container>
  </ng-container>

  <ng-template #BlocksMetaView>


    <div class="blocks-meta cursor-pointer" *ngIf="_space.isRemoved">
      <span class="icon icon-bg dummy-icon"></span> <span class="icon icon-bg dummy-icon"></span>
    </div>

    <div *ngIf="!_space.isRemoved" class="blocks-meta cursor-pointer" [ngClass]="iconCls" [style.visibility]="
          _space.blocksMeta && _space.blocksMeta.length > 0
            ? 'visible'
            : 'hidden'
        " >
      <!-- Block Icons -->
      <ng-container *ngFor="let _meta of _space?.blocksMeta; let i=index">
        <ng-container *ngIf="_meta.node">
          <ng-container *ngIf="_meta.node.type === nodeType.folder">
            <span class="icon icon-bg icon-background-folder icon-bg-pading" [ngClass]="{'active': i==selectedIndex }"  (click)="nodeClick(i)"><span
                class="icon icon-folder-blue icon-full"></span></span>
          </ng-container>
          <ng-container *ngIf="_meta.node.type === nodeType.link">
            <ng-container *ngIf="
                  _meta.node.urlInfo && _meta.node.urlInfo.thumbnail;
                  else fallbackIcon
                ">
              <span class="icon icon-bg"  [ngClass]="{'active': i==selectedIndex }"  (click)="nodeClick(i)">
                <img src="{{ _meta.node.urlInfo.thumbnail }}"
                  onError="this.src='../../../assets/images/icons/no-img.png'" />
              </span>
            </ng-container>
            <ng-template #fallbackIcon>
              <span class="icon icon-bg icon-background-url icon-bg-pading"  [ngClass]="{'active': i==selectedIndex }"  (click)="nodeClick(i)"><span
                  class="icon icon-url-grey icon-lt" [ngClass]="{'active': i==selectedIndex }"></span></span>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="_meta.node.type === nodeType.activity">
            <span class="icon icon-bg icon-background-activity icon-bg-pading"  [ngClass]="{'active': i==selectedIndex }"  (click)="nodeClick(i)"><span
                class="icon icon-url-activity-with-bg icon-full" [ngClass]="{'active': i==selectedIndex }"></span></span>
          </ng-container>

          <span [ngClass]="[
                'icon',
                'icon-bg',
                _meta.node.fileInfo.getBGClassForList(),
                'icon-bg-padding'
              ]" *ngIf="
                _meta.node.type === nodeType.file &&
                _meta.node.fileInfo
              " 
              [class.active] = " i==selectedIndex"
             (click)="nodeClick(i)">
            <ng-container [ngTemplateOutlet]="
                  _meta.node.fileInfo.getIconClassForList() ==
                  'icon icon-text'
                    ? TextIcon
                    : ImageIcon
                " [ngTemplateOutletContext]="{
                  iconclass: _meta.node.fileInfo.getIconClassForList()
                }">
            </ng-container>

            <ng-template #ImageIcon let-iconclass="iconclass">
              <span [ngClass]="iconclass"  [class.active] = " i==selectedIndex"></span>
            </ng-template>

            <ng-template #TextIcon let-iconclass="iconclass">
              <span [ngClass]="iconclass"  [class.active] = " i==selectedIndex">{{
                _meta.node.fileInfo.textForMimeType
                }}</span>
            </ng-template>
          </span>
        </ng-container>
      </ng-container>
    </div>

  </ng-template>
  <ng-template #PathMetaView>
    <div class="blocks-meta">
      <span class="space_count_wrap">
        <span class="space_count" *ngIf="_space.spacesMeta && _space.spacesMeta.length > 0">

          <span class="icon icon-space-count icon-lt"></span>
          <span class="iconTxt">
            {{_space.spacesMeta.length}} Space<ng-container *ngIf="_space.spacesMeta.length > 1">s</ng-container>
          </span></span>
      </span>
    </div>
  </ng-template>