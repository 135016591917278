import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StatusCodes } from "http-status-codes";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Tag, TagAdapter } from "src/app/shared/models/tag.model";
import { environment } from "src/environments/environment";

export interface TagListResponse {
    tags?: Tag[];
    success: boolean;
    code?: string;
}

export interface TagMutateResponse {
    tag?: Tag;
    success: boolean;
    code?: string;
}

@Injectable({
    providedIn: 'root'
})
export class TagService {

    apiUrl: string;
    constructor(
        private http: HttpClient,
        private _tagAdapter: TagAdapter,
    ) {

        if (environment.hasOwnProperty('apiUrl'))
            this.apiUrl = environment.apiUrl;
    }



    getTagAutoSuggestions(keyword: string): Observable<TagListResponse> {

        if (!keyword) {
            of({ code: "REQUIRED PARAMS MISSING", success: false });
        }

        let url = `${this.apiUrl}/tags`;

        let params: HttpParams = new HttpParams();
        params = params.append('searchText', keyword);
        
        return this.http.
            get(url, {
                observe: "response",
                params: params
            }).pipe(
                map(response => {
                    let responseStatus = response.status;

                    let responseData: any = response.body

                    if (responseStatus == StatusCodes.OK || responseStatus === StatusCodes.NO_CONTENT) {
                        if (responseData != null && Array.isArray(responseData)) {

                            let blocks = responseData.map((_tagObj: any) => this._tagAdapter.adapt(_tagObj));
                            return { tags: blocks, success: true };
                        }
                        else {
                            return { success: true };
                        }
                    }
                    else {

                        return { success: false };
                    }
                }),
                catchError(err => {
                    const responseObject: TagListResponse = {
                        success: false,
                        code: err
                    }

                    return of(responseObject)
                })
            );
    }

    createTags(_tagsToCreate: Tag[]): Promise<TagListResponse> {

        if (!_tagsToCreate)
            of({ code: "REQUIRED PARAMS MISSING", success: false });

        let apiUrl = this.apiUrl + "/tags/bulk";

        const newTagString: string[] = _tagsToCreate.map(_nt => _nt.title)

        const body = {
            tags: newTagString
        }

        return this.http.post<any>(apiUrl,
            body, {
            observe: "response"
        }).pipe(map((createResponse) => {

            let responseStatus = createResponse.status;

            let responseData: any = createResponse.body

            if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.CREATED) {
                if (responseData != null && Array.isArray(responseData)) {
                    const newTags: Tag[] = responseData.map((_ntObj: any) => this._tagAdapter.adapt(_ntObj));
                    return { tags: newTags, success: true };
                }
                else {
                    return { success: false };
                }
            }
            else {
                return { success: false };
            }

        }, catchError((error: any) => {

            const responseObject: TagListResponse = {
                success: false,
                code: error
            }
            return of(responseObject)

        }))).toPromise();
    }



}