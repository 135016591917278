import { APP_PLATFORM, AckResponse } from 'src/app/common/constants';
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { User, UserAdapter } from "../models/user.model";
import { environment } from "src/environments/environment";
import jwt_decode, { JwtPayload } from "jwt-decode";
import firebase from "firebase";
import { AngularFireAuth } from "@angular/fire/auth";
import { StatusCodes } from 'http-status-codes';

export interface SignupResponse {
  responseData?: any;
  success: boolean;
  error?: any;
}

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private userSubject: BehaviorSubject<User | null>;
  public user: Observable<User | null>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private userAdapter: UserAdapter,
    public afAuth: AngularFireAuth
  ) {
    this.userSubject = new BehaviorSubject<User | null>(null);
    this.user = this.userSubject.asObservable();
  }

  public get isLoggedIn(): boolean {
    return localStorage.getItem("atk") !== null;
  }

  public get accessToken(): string | undefined | null {
    return localStorage.getItem("atk");
  }

  public get refreshToken(): string | undefined | null {
    return localStorage.getItem("rtk");
  }

  public get isTokenExpired(): boolean {
    let jwtToken = localStorage.getItem("atk");

    if (jwtToken) {
      const decodedToken: JwtPayload = jwt_decode<JwtPayload>(jwtToken);

      if (decodedToken.exp) {
        const expiry = decodedToken.exp;
        return Math.floor(new Date().getTime() / 1000) >= expiry;
      }
    }

    return true;
  }

  public userName(jwtToken: string | undefined | null): string | undefined {
    if (jwtToken) {
      var decodedToken: JwtPayload = jwt_decode<JwtPayload>(jwtToken);

      for (const [key, value] of Object.entries(decodedToken)) {
        if (key == "username") return value;
      }
    }

    return undefined;
  }

  public userID(jwtToken: string | undefined | null): string | undefined {
    if (jwtToken) {
      var decodedToken: JwtPayload = jwt_decode<JwtPayload>(jwtToken);

      for (const [key, value] of Object.entries(decodedToken)) {
        if (key == "userId") return value;
      }
    }

    return undefined;
  }

  // public get userValue(): User | null {

  //     return this.userSubject.value;
  // }

  storeTokenInLocalStorage(jwtToken?: string, refreshToken?: string) {
    if (jwtToken) localStorage.setItem("atk", jwtToken);

    if (refreshToken) localStorage.setItem("rtk", refreshToken);
  }
  resetTokenFromLocalStorage() {
    localStorage.removeItem("atk");
    localStorage.removeItem("rtk");
  }

  storeUserPrivileges(privileges: [{ key: string; value: any }]) {
    if (privileges && Array.isArray(privileges) && privileges.length > 0) {
      privileges.map((t) => {
        if (typeof t.value === "boolean") {
          localStorage.setItem(t.key, t.value ? "true" : "false");
        }
      });
    }
  }

  getUserPrivilegesFromLocalStorage(key: string): string | boolean | undefined {
    const value = localStorage.getItem(key);

    if (!value) return undefined;

    if (value === "true" || value === "false") {
      return value === "true";
    }

    return value;
  }

  resetLocalStorage() {
    this.resetTokenFromLocalStorage();


    Object.keys(localStorage).map(_k => {
      if (_k !== 'registrationToken')
        localStorage.removeItem(_k);
    })

    // Store
    // localStorage.removeItem("lvt");
    // localStorage.removeItem("lso");
    // localStorage.removeItem("lsk");
    // //localStorage.removeItem('tbvs');
    // //localStorage.removeItem('twbvs');

    // localStorage.clear();
  }

  registerAndLogin(
    username: string,
    password: string,
    email: string
  ): Promise<SignupResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const response: SignupResponse = await this.register(
          username,
          password,
          email
        );

        if (response.success) {
          const user: User = await this.login(username, password);
          if (this.isLoggedIn) {
            resolve({ responseData: user, success: true });
            return;
          } else {
            resolve({
              success: false,
              error: { errors: [{ message: "Unable to signup" }] },
            });
          }
        } else {
          reject({ success: false, error: response.error });
          return;
        }
      } catch (error) {
        reject({ success: false, error: error.errors ? error.errors : error });
        return;
      }
    });
  }

  register(
    username: string,
    password: string,
    email: string
  ): Promise<SignupResponse> {
    return this.http
      .post<any>(`${environment.apiUrl}/users`, { username, email, password })
      .pipe(
        map(
          (signupResponse) => {
            if (signupResponse && signupResponse.id) {
              return { responseData: signupResponse, success: true };
            }
            return {
              success: false,
              error: { errors: [{ message: "Unable to signup" }] },
            };
          },
          catchError((error) => {
            return of(error);
          })
        )
      )
      .toPromise();
  }

  login(username: string, password: string): Promise<User> {
    return this.http
      .post<any>(`${environment.apiUrl}/token`, { username, password })
      .pipe(
        map((userResponse) => {
          let adapter = new UserAdapter();
          let user = adapter.adapt(userResponse);
          const userID = this.userID(user.jwtToken);
          if (userID) user.id = userID;
          this.userSubject.next(user);
          this.storeTokenInLocalStorage(user.jwtToken, user.refreshToken);
          this.startRefreshTokenTimer();
          return user;
        })
      )
      .toPromise();
  }

  raiseRefreshTokenRequest(): Promise<User> {
    console.log("Token refresh started");

    const rToken: string | null | undefined = this.refreshToken;
    if (!rToken) {
      console.log("Unable to fetch refreshToken");
      of(null);
    }

    return this.http
      .post<any>(`${environment.apiUrl}/token/refresh`, {
        refreshToken: rToken,
      })
      .pipe(
        map(
          (userResponse) => {
            let adapter = new UserAdapter();
            let user = adapter.adapt(userResponse);
            this.userSubject.next(user);

            this.storeTokenInLocalStorage(user.jwtToken, user.refreshToken);
            this.startRefreshTokenTimer();
            return user;
          },
          catchError((error: any) => {
            this.logout();

            return of(null);
          })
        )
      )
      .toPromise();
  }

  async googleAuth(): Promise<User> {
    let provider = new firebase.auth.GoogleAuthProvider();
    // provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
    provider.addScope("https://www.googleapis.com/auth/userinfo.email");
    provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
    const response = await this.socialLogin(provider);
    const oauthCred = response.credential as firebase.auth.OAuthCredential;
    const idToken = await response.user?.getIdToken();

    const profile = response.additionalUserInfo?.profile as { email: string };
    const email = profile.email;

    return this.getUser(oauthCred, idToken, email, "google");
  }
  async appleAuth(): Promise<User> {
    let provider = new firebase.auth.OAuthProvider("apple.com");
    provider.addScope("email");
    provider.addScope("name");
    const response = await this.socialLogin(provider);
    const oauthCred = response.credential as firebase.auth.OAuthCredential;
    const idToken = await response.user?.getIdToken();

    const profile = response.additionalUserInfo?.profile as { email: string };
    const email = profile.email;

    return this.getUser(oauthCred, idToken, email, "apple");
  }

  async facebookAuth(): Promise<User> {
    let provider = new firebase.auth.OAuthProvider("facebook.com");
    provider.addScope("email");
    provider.addScope("public_profile");
    const response = await this.socialLogin(provider);
    const oauthCred = response.credential as firebase.auth.OAuthCredential;
    const idToken = await response.user?.getIdToken();

    const profile = response.additionalUserInfo?.profile as { email: string };
    const email = profile.email;

    return this.getUser(oauthCred, idToken, email, "facebook");
  }
  async socialLogin(
    provider: firebase.auth.AuthProvider
  ): Promise<firebase.auth.UserCredential> {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await this.afAuth.signInWithPopup(provider);
        resolve(result);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  async getUser(
    oauthCred: firebase.auth.OAuthCredential,
    idToken: any,
    email: any,
    network: any
  ): Promise<User> {
    return this.http
      .post<any>(`${environment.apiUrl}/token/social-token`, {
        network: network,
        socialToken: oauthCred.accessToken,
        idToken: idToken,
        email: email,
      })
      .pipe(
        map((userResponse) => {
          let adapter = new UserAdapter();
          let user = adapter.adapt(userResponse);
          const userID = this.userID(user.jwtToken);
          if (userID) user.id = userID;
          this.userSubject.next(user);
          this.storeTokenInLocalStorage(user.jwtToken, user.refreshToken);
          this.startRefreshTokenTimer();
          return user;
        })
      )
      .toPromise();
  }

  validateCurrentPassword(password: string): Promise<SignupResponse> {
    return this.http
      .post<any>(`${environment.apiUrl}/token/validate-current-password`, {
        currentPassword: password,
      })
      .pipe(
        map(
          (userResponse) => {
            if (userResponse && userResponse.isValid) {
              return { responseData: userResponse, success: true };
            }
            return {
              success: false,
              error: { errors: [{ message: "Unable to verify password" }] },
            };
          },
          catchError((error) => {
            return of(error);
          })
        )
      )
      .toPromise();
  }

  logout(silent = false) {

    const registrationToken = localStorage.getItem("registrationToken");
    if (registrationToken) {
      this.unBindNotificationToken(registrationToken);
    }


    this.resetLocalStorage();

    
    // this.http.post<any>(`${environment.apiUrl}/token/revoke`, {}).subscribe();
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    if (!silent) {
      this.router.navigate(["/login"]);
    }
  }

  addRegistrationToken(registrationToken: string): Promise<any> {
    return this.http
      .post<any>(`${environment.apiUrl}/notification-settings/user-devices`, {
        deviceType: APP_PLATFORM,
        registrationToken,
      })
      .pipe(
        map(
          (response) => {
            return response;
          },
          catchError((error) => {
            return of(error);
          })
        )
      )
      .toPromise();
  }

  unBindNotificationToken(registrationToken: string): Promise<AckResponse> {

    if (!registrationToken) {
      of({ code: "REQUIRED PARAMS MISSING", success: false });
    }

    let apiUrl = `${environment.apiUrl}/notification-settings/user-devices/deactivate`;

    return this.http.patch<any>(apiUrl,{
      deviceType: APP_PLATFORM,
      registrationToken,
    },
      {
        observe: "response"
      }).pipe(map((response) => {

        let responseStatus = response.status;

        let responseData: any = response.body;

        if (responseStatus == StatusCodes.OK) { 
          return { success: true };
        }
        else {
          return { success: false };
        }

      }, catchError((error: any) => {

        const responseObject: AckResponse = {
          success: false,
          code: error
        }

        return of(responseObject)

      }))).toPromise();

  }

  // helper methods

  private refreshTokenTimeout?: ReturnType<typeof setTimeout>;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    let jwtToken = this.accessToken;
    if (jwtToken) {
      const decodedToken: JwtPayload = jwt_decode<JwtPayload>(jwtToken);

      if (decodedToken.exp) {
        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(decodedToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - 60 * 1000;
        this.refreshTokenTimeout = setTimeout(async () => {
          await this.raiseRefreshTokenRequest();
        }, timeout);
      }
    }
  }

  private stopRefreshTokenTimer() {
    if (this.refreshTokenTimeout) clearTimeout(this.refreshTokenTimeout);
  }
}
