import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { UserProfile } from '../../my-profile/models/user-profile.model';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})

export class ShareDialogComponent implements OnInit, OnDestroy {
  @Input() shareInProgress: boolean = false;
  color: ThemePalette = 'primary';
  @Output() shareEvent = new EventEmitter<string[]>();
  @ViewChild("userChild", { static: false }) userChild: any;

  constructor(
  ) { }
 

  ngOnInit(): void {

  }

  async shareTapped() {
    const users: UserProfile[] = await this.userChild.finalUsers();
    if (users && users.length) {
      const userIDs = users.map(u => u.id);
      this.shareEvent.emit(userIDs)
    }
  }

  ngOnDestroy(): void {
    if (this.shareEvent)
      this.shareEvent.unsubscribe();
  }

}
