<div class="list-view-main w-100">

    <table mat-table class="w-100 library-list-view-table" [dataSource]="nodes ? nodes : []"> 

        <!-- matSort
        (matSortChange)="sortData($event)" [matSortActive]="sort?sort.sortBy:'name'"
        [matSortDirection]="sort?sort.sortOrder:'asc'" matSortDisableClear -->

        <ng-container matColumnDef="favorite">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let node">
                <mat-icon>{{(node && node.favorite)? 'star' : 'star_border'}}</mat-icon>
                <!-- <span [ngClass]="node.favorite ? 'icon icon-favourite-active' : 'icon icon-favourite'"></span> -->
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef></th>
            <td [ngClass]="['library-name', isAllowed(node)?'enabled': 'disabled']" mat-cell *matCellDef="let node" (click)="nodeClick(node)">
               
                <div class="library-name-details" fxLayout="row" fxLayoutAlign="start center">
                    <section class="favIcon" (click)="$event.stopPropagation()">
                        <button mat-icon-button class="favorite_icons" [ngClass]="[(node && node.favorite)? 'fav-active' : '', 'mr-10']" (click)="mutateFavorite(node)" [disabled]="mutateFavoriteInProgress" matTooltip="Favorite" aria-label="Favorite">
                            <mat-icon>{{(node && node.favorite)? 'star' : 'star_border'}}</mat-icon>
                        </button>
                        
                        <!-- <mat-icon >{{(node && node.favorite)? 'star' : 'star_border'}}</mat-icon> -->
                    </section>
                    <ng-container *ngIf="node.type === nodeType.folder">
                        <span class="icon icon-bg icon-background-folder icon-bg-pading"><span class="icon icon-folder-blue icon-full"></span></span>
                    </ng-container>
                    <ng-container  *ngIf="node.type === nodeType.link">
                        <ng-container *ngIf="node.urlInfo && node.urlInfo.thumbnail; else fallbackIcon">
                            <span class="icon icon-bg">
                                <img src="{{node.urlInfo.thumbnail}}" />
                            </span>                           
                        </ng-container>
                        <ng-template #fallbackIcon>
                            <span class="icon icon-bg icon-background-url icon-bg-pading"><span class="icon icon-url-grey icon-lt"></span></span>
                        </ng-template>
                    </ng-container>
                    <ng-container  *ngIf="node.type === nodeType.activity">
                        <span class="icon icon-bg icon-background-activity icon-bg-pading"><span class="icon icon-url-activity-with-bg icon-full"></span></span>
                    </ng-container>

                    <span [ngClass]="['icon', 'icon-bg',node.fileInfo.getBGClassForList(),'icon-bg-padding']" *ngIf="node.type === nodeType.file && node.fileInfo">

                        <ng-container
                            [ngTemplateOutlet]="node.fileInfo.getIconClassForList() == 'icon icon-text' ? TextIcon : ImageIcon"
                            [ngTemplateOutletContext]="{iconclass:node.fileInfo.getIconClassForList()}">
                        </ng-container>

                        <ng-template #ImageIcon let-iconclass='iconclass'>
                            <span [ngClass]="iconclass"></span>
                        </ng-template>

                        <ng-template #TextIcon let-iconclass='iconclass'>
                            <span [ngClass]="iconclass">{{node.fileInfo.textForMimeType}}</span>
                        </ng-template>

                    </span>
                    <div class="library-name-row ml-5">
                        <div class="library-name-col" fxLayout="column">
                            <div fxLayout="row">
                                <p class="library-name-text single-line">{{node.title}}
                                    <!-- <mat-icon>{{(node && node.favorite)? 'star' : ''}}</mat-icon> -->
                                </p>
                            </div>
                            <div class="library-details">
                                <div class="" fxLayout="row" fxLayoutAlign="start center">
                                    <div fxFlex="initial" fxLayoutAlign="start center">
                                        <span class="p-small-grey14" title="{{node.createdAt | amDateFormat:'LLL'}}" >
                                            {{node.createdAt | amTimeAgo}}

                                            <ng-template [ngIf]="node.type === nodeType.folder">

                                            </ng-template>

                                            <ng-template
                                                [ngIf]="node.type === nodeType.file && node.fileInfo && (node.fileInfo.fileSize > 0)">
                                                &nbsp;&bull;
                                                {{node.fileInfo.fileSize | formatFileSize:1}}
                                            </ng-template>

                                            <ng-template
                                                [ngIf]="node.type === nodeType.link && node.urlInfo && node.urlInfo.meta.length > 0">
                                                &nbsp;&bull;
                                                <ng-container
                                                    *ngFor="let metaInfo of node.urlInfo.meta ; let last = last">
                                                    <ng-container *ngIf="last;else remainingMeta">
                                                        {{metaInfo}}
                                                    </ng-container>
                                                    <ng-template #remainingMeta>
                                                        {{metaInfo}} &nbsp;&bull;
                                                    </ng-template>
                                                </ng-container>
                                            </ng-template>

                                            <ng-template
                                                [ngIf]="node.type === nodeType.activity && node.activityInfo">
                                                &nbsp;&bull;
                                                {{node.activityInfo.questionsCount}} Questions
                                            </ng-template>

                                            <ng-template [ngIf]="node.notesCount > 0">
                                                &nbsp;&bull;
                                                {{node.notesCount}} Notes
                                            </ng-template>
                                        </span>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>


                </div>
              <section class="actionssec" (click)="$event.stopPropagation()">  <app-library-node-actions  [node]="node"></app-library-node-actions></section>
                <!-- <span (click)="showLibraryView(node.type)">{{node.title}}</span> -->
            </td>
        </ng-container>

        <!-- <ng-container matColumnDef="notesCount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Notes </th>
            <td class="notes-icon-menu" mat-cell *matCellDef="let node">
                <ng-container
                    [ngTemplateOutlet]="node.type !== nodeType.folder && node.notesCount > 0? NotesCount : NoNotes">
                </ng-container>

                <ng-template #NotesCount>
                    <span class="icon icon-notes-grey"></span>
                    <span class="notes-count">{{node.notesCount}}</span>
                </ng-template>
                <ng-template #NoNotes>
                    -
                </ng-template>
            </td>
        </ng-container> -->

        <!-- <ng-container matColumnDef="creator">
            <th mat-header-cell *matHeaderCellDef> Creator </th>
            <td mat-cell *matCellDef="let node"> {{node.createdBy? node.createdBy.displayName: "-"}} </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
            <td mat-cell *matCellDef="let node"> {{node.displayType | titlecase}} </td>
        </ng-container>

        <ng-container matColumnDef="addedDate">
            <th class="added-col" mat-header-cell *matHeaderCellDef mat-sort-header> Added </th>
            <td mat-cell *matCellDef="let node"> {{node.createdAt | date}} </td>
        </ng-container>

        <ng-container matColumnDef="fileSize">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Size </th>
            <td mat-cell *matCellDef="let node">
                <ng-container
                    [ngTemplateOutlet]="node.type === nodeType.file && node.fileInfo && (node.fileInfo.fileSize > 0)? FileSize : NoFileSize">
                </ng-container>

                <ng-template #FileSize>
                    {{node.fileInfo.fileSize | formatFileSize:1}}
                </ng-template>
                <ng-template #NoFileSize>
                    -
                </ng-template>
            </td>
        </ng-container> -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td class="actions-td" mat-cell *matCellDef="let node">
                <!-- <div fxLayoutAlign="end center">
                    <app-library-node-actions 
                        [node]="node"
                        (renameDialogEvent)="renameDialogEvent($event)"
                        ></app-library-node-actions>
                </div> -->
            </td>
        </ng-container>

        <!-- <ng-container matColumnDef="notes_count">
            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
            <td mat-cell *matCellDef="let node">
                <div class="td-notes-count cursor-pointer" fxLayoutAlign="end center" *ngIf="node.notesCount > 0" (click)="notesClicked(node)">
                    <span class="icon icon-notes-grey"></span>
                    <span class="notes-count">{{node.notesCount}}</span>
                </div>
            </td>
        </ng-container> -->


        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row
            [ngClass]="[selectedNode?((node.id === selectedNode.id)?'element-row active-row': 'element-row'):'element-row', isAllowed(node)?'enabled-row': 'disabled-row']"
            class="element-row" *matRowDef="let node; columns: displayedColumns;" (dblclick)="nodeDoubleClick(node)">
        </tr>
    </table>
</div>