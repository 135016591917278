import { CommonService } from 'src/app/common/service/common.service';
import { AckResponse } from 'src/app/common/constants';
import { environment } from "./../../../../environments/environment";
import {
  NotificationAdapter,
  ByndrNotification,
} from "./../models/notification.model";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StatusCodes } from "http-status-codes";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import { NOTIFICATIONS_RESULTS_PER_PAGE } from "../helper/notification.constant";

export interface NotificationListResponse {
  notifications?: ByndrNotification[];
  success: boolean;
  code?: string;
}

export interface NotificationResponse {
  notification?: ByndrNotification;
  success: boolean;
  code?: string;
}

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  apiUrl: string;
  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
    private _notificationAdapter: NotificationAdapter
  ) {
    if (environment.hasOwnProperty("apiUrl")) this.apiUrl = environment.apiUrl;
  }

  getNotifications(page: number): Promise<NotificationListResponse> {
    let url = `${this.apiUrl}/notifications?offset=${
      (page - 1) * NOTIFICATIONS_RESULTS_PER_PAGE
    }&limit=${NOTIFICATIONS_RESULTS_PER_PAGE}`;

    return this._http
      .get(url, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          let responseStatus = response.status;

          let responseData: any = response.body;

          if (
            responseStatus == StatusCodes.OK ||
            responseStatus == StatusCodes.NO_CONTENT
          ) {
            if (responseData) {
              let array = responseData;
              if (!array) {
                array = responseData;
              }
              if (array && Array.isArray(array)) {
                let notifications = array.map((_no: any) =>
                  this._notificationAdapter.adapt(_no)
                );

                return { notifications: notifications, success: true };
              } else {
                return { success: true };
              }
            } else {
              return { success: true };
            }
          } else {
            return { success: false };
          }
        }),
        catchError((err) => {
          const responseObject: NotificationListResponse = {
            success: false,
            code: err,
          };

          return of(responseObject);
        })
      )
      .toPromise();
  }

  

  getNotification(notificationId: string): Promise<NotificationResponse> {
    let url = `${this.apiUrl}/notifications/${notificationId}`;

    return this._http
      .get(url, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          let responseStatus = response.status;

          let responseData: any = response.body;

          if (
            responseStatus == StatusCodes.OK ||
            responseStatus == StatusCodes.NO_CONTENT
          ) {
            if (responseData) {
              const notification =
                this._notificationAdapter.adapt(responseData);

              return { notification: notification, success: true };
            } else {
              return { success: false };
            }
          } else {
            return { success: false };
          }
        }),
        catchError((err) => {
          const responseObject: NotificationResponse = {
            success: false,
            code: err,
          };

          return of(responseObject);
        })
      )
      .toPromise();
  }

  updateNotificationReadStatus(notificationId: string): Promise<AckResponse> {

    if (!notificationId) {
      of({ code: "REQUIRED PARAMS MISSING", success: false });
    }

    let apiUrl = `${this.apiUrl}/notifications/${notificationId}/update-read`;

    return this._http.patch<any>(apiUrl, {},
      {
        observe: "response"
      }).pipe(map((response) => {

        let responseStatus = response.status;

        let responseData: any = response.body;

        if (responseStatus == StatusCodes.OK) {

          const _message = responseData.message;
          const newNotificationsCount = (typeof responseData.notifications === 'number') ? responseData.notifications : parseInt(responseData.notifications);
          this._commonService.updateNotificationCount(newNotificationsCount);
          
          return { message: _message, success: true };

        }
        else {
          return { success: false };
        }

      }, catchError((error: any) => {

        const responseObject: AckResponse = {
          success: false,
          code: error
        }

        return of(responseObject)

      }))).toPromise();

  }

  getNewNotificationsCount(): Promise<{notificationsCount?: number, success: boolean}> {

      
      const url = this.apiUrl + `/user-profile/notifications-count`;
      
      return this._http.
        get(url, {
          observe: "response"
        }).pipe(
          map(response => {
            let responseStatus = response.status;
  
            let responseData: any = response.body
  
            if (responseStatus == StatusCodes.OK) {
              if (responseData != null) {

                const newNotificationsCount = (typeof responseData.notifications === 'number') ? responseData.notifications : parseInt(responseData.notifications);
                
                this._commonService.updateNotificationCount(newNotificationsCount);

                return { notificationsCount: newNotificationsCount, success: true };
              }
              else {
                return { success: false };
              }
            }
            else {
  
              return { success: false };
            }
          }),
          catchError(err => {
            console.log("Unable to reterieve user profile", err);
            const responseObject = {
              success: false,
              code: err
            }
  
            return of(responseObject)
          })
        ).toPromise();
  
  }
}
