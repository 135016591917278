<div class="library-body w-100" fxLayout="row wrap" fxLayoutWrap="wrap">
    <div class="library-welcome-card">
        <div class="library-box">
            <div class="byndr_logo align-center"><span class="icon byndr-logo"></span></div>
            <img class="align-center li-i" src="../../assets/images/rawimages/error.png">
            <p class="align-center">Invalid URL</p>
            <p class="align-center">It looks like this webpage may</p> <p class="align-center">be having issues right now or </p> <p class="align-center">moved perminently to a</p><p class="align-center">Different Address</p>
         
        
    </div>
   </div></div>