import { NotificationsService } from "./../../main/notifications/data-provider/notifications.service";
import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
@Injectable()
export class NotificationMessagingService {
  currentMessage = new BehaviorSubject({});
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private _snackBar: MatSnackBar,
    private _notificationService: NotificationsService
  ) {}
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        localStorage.setItem("registrationToken", token || "");
        console.log("Notification token received");
      },
      (err) => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }
  receiveMessage() {
    console.log("Registered on angular front messaging");
    this.angularFireMessaging.messages.subscribe((payload: any) => {
      console.log("new message received. ", payload);

      if (payload && payload.notification) {

        const notification = payload.notification;

        const NotificationOptions = {
          body: notification.body,
          title: notification.title,
          data: {...payload.data, webCTA: notification.click_action?  notification.click_action: payload.webCTA},
          icon: notification.icon ? notification.icon : '/discover-blue-square.png',
          tag: 'local-notification'
        }
        navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
          if (registration){
            registration.showNotification(payload.notification.title, NotificationOptions);
          }
          else {
            let snackBar = this._snackBar.open(
              notification.body,
              !notification.click_action ? "OK" : "VIEW",
              {
                duration: 10000,
                horizontalPosition: "end",
                verticalPosition: "bottom",
                panelClass: "black-snackbar",
              }
            );
            const actionSubscriber = snackBar.onAction().subscribe(() => {
              if (notification.click_action) {
                window.open(notification.click_action);
              }
    
              actionSubscriber.unsubscribe();
            });
          }

        });        
        this.currentMessage.next(payload);
      }

      // Update notifications count
      try {
        this._notificationService.getNewNotificationsCount();
      } catch (error) {
        console.error(error);
      }
    });
  }
}
