<div [ngClass]="[shareInProgress? 'show-loading' : '', 'share-main']">
    <div class="share-header" fxLayout="row">
        <!-- <div class="pr-10" fxFlex="initial" fxLayoutAlign="start center">
            <mat-icon class="ic-share mr-10">share</mat-icon>
        </div> -->
        <div fxFlex="initial" fxLayoutAlign="start center">
            <div fxLayout="column">
                <h4 class="h4-20-black">Share with</h4>
                <p class="p-small-black14">You can share this with anyone.</p>
            </div>
        </div>
        <div fxFlex fxLayoutAlign="end center">
            <button mat-icon-button>
                <mat-icon mat-dialog-close class="ic-close">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="pl-20 pr-20">
        <app-user-auto-complete #userChild></app-user-auto-complete>
    </div>
    <mat-dialog-actions align="center">
        <button mat-raised-button [disabled]="shareInProgress" class="btn-grey roundedBtn" mat-dialog-close>Cancel</button>
        <button mat-raised-button [disabled]="shareInProgress" class="btn-submit roundedBtn" cdkFocusInitial
            (click)="shareTapped()">Share</button>
        <mat-spinner *ngIf="shareInProgress" [color]="color" diameter="25" strokeWidth="3">
        </mat-spinner>
    </mat-dialog-actions>
</div>