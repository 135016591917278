import { SpaceType } from './../../helper/space.constant';
import { InformationDialogComponent } from './../../../../shared/components/information-dialog/information-dialog.component';
import { AckResponse } from './../../../../common/constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpaceService } from './../../data-providers/space.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';
import { LearningSpace } from './../../models/learning-space.model';
import { Component, Input, OnInit, EventEmitter, Output, Inject, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-publish-confirmation-dialog',
  templateUrl: './publish-confirmation-dialog.component.html',
  styleUrls: ['./publish-confirmation-dialog.component.scss']
})
export class PublishConfirmationDialogComponent implements OnDestroy {

  submissionInProgress: boolean = false;
  showError: boolean = false;
  errorMessage?: string;
  spaceTitle: string;
  spaceId: string;
  formGroup: any;
  color: ThemePalette = 'primary';
  selectedVisibility : string = "friends";
  listSpaceType : SpaceType = SpaceType.learningspace;
  displayTitle: string = "Learning Space";

  @Output() spacePublishedEvent = new EventEmitter();

 
  constructor(
    private dialog: MatDialog,
    private _spaceService: SpaceService,
    public dialogRef: MatDialogRef<PublishConfirmationDialogComponent>,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {


    
      this.spaceTitle = data.spaceTitle;
      this.spaceId = data.spaceId;
      this.listSpaceType = data.listSpaceType;
      this.displayTitle = data.displayTitle;

  }
 
  submitTapped(): void {
    this.publishSpace(this.spaceId, this.selectedVisibility, this.listSpaceType);

  }



  async publishSpace(_spaceId: string, _selectedVisibility: string, _type: SpaceType) {
    this.submissionInProgress = true;
    this.showError = false;
    this.errorMessage = undefined;

    try {
      let response: AckResponse = await this._spaceService.publishSpace(_spaceId, _selectedVisibility, _type);
      if (response.success) {

          this.showError = false;

          this.spacePublishedEvent.emit();

          const dialogRef = this.dialog.getDialogById('publish_space_conf_dialog');
          if (dialogRef)
            dialogRef.close();

          this.showSnackBar(`${this.spaceTitle} has been published.`, "OK");
      }
      else {
        this.showErrorDialog("Oops,something went wrong. Please try again");
        this.showError = true;
      }

      this.submissionInProgress = false;


    } catch (error) {
      console.log("Unable to publish space", error);
      this.submissionInProgress = false;
      this.showErrorDialog("Oops,something went wrong. Please try again");
      this.showError = true;
    }
  }

  showErrorDialog(error: any) {

    const dialogRef = this.dialog.open(InformationDialogComponent, {
      width: '300px',
      id: 'information_dialog'
    })

    dialogRef.componentInstance.content = error
  }

  showSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 10000 });
  }


  ngOnDestroy(): void {
    
    if(this.spacePublishedEvent)
      this.spacePublishedEvent.unsubscribe();

  }
}
