<div class="reauthorize-user-main">
    <form [formGroup]="formGroup" [ngClass]="loading === true? 'show-loading' : ''"
        (ngSubmit)="onSubmit(formGroup.value)">
        <div class="box-row" fxLayout="column" fxLayoutAlign="center center">
            <div class="pl-20">
                <app-profile-image [viewMode]='!currentUser' [userDetails]='userDetails' [userID]='userID'>
                </app-profile-image>
            </div>
            <h5 class="h5-16-black pb-20 pt-20">{{userDetails.displayName}}</h5>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
            <div fxFlex="70" fxFlexOffset="15" fxLayoutAlign="center center" fxFlex.sm="80" fxFlexOffset.sm="10"
                fxFlex.xs="100" fxFlexOffset="0">
                <div fxLayout="column" class="w-100">
                    <mat-form-field class="black-border" appearance="outline">
                        <input matInput [type]="!showPassword ? 'password' : 'text'"
                            placeholder="Enter your current password" formControlName="password">
                        <span matSuffix class="password-icon">
                            <span class="icon icon-hidden-password" [ngClass]="showPassword? 'icon-show-password' : 'icon-hidden-password'"  
                                (click)="showPassword = !showPassword"></span>
                           <!-- <span class="icon icon-show-password" *ngIf="showPassword"
                                (click)="showPassword = !showPassword"></span>-->
                        </span>
                        <mat-error
                            *ngIf="!formGroup.controls['password'].valid && formGroup.controls['password'].touched">
                            <div class="password-error">
                                <span class="exclamation-symbol">
                                    <span>!</span>
                                </span>
                                <p>{{ getErrorPassword() }}</p>
                            </div>
                        </mat-error>
                    </mat-form-field>
                    <div class="stepper-btns pt-10 pb-20" fxLayout="row" fxLayoutAlign="center center">
                        <button mat-raised-button class="btn-submit roundedBtn" [disabled]="loading"
                            matStepperNext>Proceed</button>
                        <mat-spinner *ngIf="loading" [color]="color" diameter="25" strokeWidth="3">
                        </mat-spinner>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>