import { LibraryNode } from './../../main/library/models/library-node.model';
import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import {THEME} from '../constants'
import { NavigationEnd, Router } from '@angular/router';

export interface NotesCount {

  nodeId: string;
  count: number;


}

export interface SignInBlurData {

  display: boolean;
  context?: string;
  loginReturnURL?: string;
}

export interface SideNavData {

  context: string;
  data?: any;
  action?: string;
}

export interface SpacePickerSelectionEventData {
  selectedIds: string[];
  pathID: string;
}

export interface SpacePickerSubmissionInProgressData {
  submissionInProgress: boolean;
  pathID: string;
}

export interface ConnectionChangeData {

  context?: string;
  externalID?: string;
  data?: any;
  action: string;
}

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  themes: String[] = [THEME.THEME_DARK, THEME.THEME_LIGHT];
  private _notificationCountSubject: Subject<number> = new Subject<number>();
  private _noteCountSubject: Subject<NotesCount> = new Subject<NotesCount>();
  private _sideNavigationSubject: Subject<SideNavData> = new Subject<SideNavData>();
  private _connectionChangesSubject: Subject<ConnectionChangeData> = new Subject<ConnectionChangeData>();
  private _spacePickerEventSubject: Subject<SpacePickerSelectionEventData> = new Subject<SpacePickerSelectionEventData>();
  private _spacePickerSubInProgressSubject: Subject<SpacePickerSubmissionInProgressData> = new Subject<SpacePickerSubmissionInProgressData>();

  private _theme = new BehaviorSubject(THEME.THEME_LIGHT);
  private _profileStatusSubject: Subject<boolean> = new Subject<boolean>();
  private _showSignInBlurSubject: Subject<SignInBlurData> = new Subject<SignInBlurData> ();
  private _editSpaceSubject: Subject<void> = new Subject();
  private _deleteSpaceSubject: Subject<void> = new Subject();
  private _deleteNodeSubject: Subject<LibraryNode> = new Subject();
  private _profilePictureMutateSubject: Subject<any> = new Subject();
private _onFileChangeSubject:Subject<any> = new Subject<any>();
private _newFolderTappedSubject: Subject<void> = new Subject();
private _newURLTappedSubject : Subject<void> = new Subject();
private _newActivityTappedSubject : Subject<void> = new Subject();

  showSpacesEditOption: boolean = false;
  showSpacesMoreOption: boolean = false;


  constructor() {
    this.registerForTheme();
  }


  registerForTheme() {
    const darkModeOn =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;

    // If dark mode is enabled then directly switch to the dark-theme
    if (darkModeOn) {
      this._theme.next(THEME.THEME_DARK);
    }

    // Watch for changes of the preference
    window.matchMedia("(prefers-color-scheme: dark)").addListener(e => {
      const turnOn = e.matches;
      this._theme.next(turnOn ? THEME.THEME_DARK : THEME.THEME_LIGHT);

    });
  }

  getThemeSubject(): Observable<string>{
    return this._theme.asObservable();
  }

  updateNotificationCount(count: number) {
    this._notificationCountSubject.next(count);
  }

  updateLibraryNotesCount(nodeId: string, count: number) {

    this._noteCountSubject.next({ nodeId: nodeId, count: count });

  }
  
  raiseSideNavigationEventNew(sideNavObject: SideNavData) {
    this._sideNavigationSubject.next(sideNavObject);
  }
  raiseSideNavigationEvent(sideNavObject: SideNavData) {
    this._sideNavigationSubject.next(sideNavObject);
  }

  getNotificationSubject(): Observable<number> {
    return this._notificationCountSubject.asObservable();
  }

  getNoteCountSubject(): Observable<NotesCount> {

    return this._noteCountSubject.asObservable();

  }

  getSideNavigationSubject(): Observable<SideNavData> {
    return this._sideNavigationSubject.asObservable();

  }

  updateConnectionChangeEvent(_data: ConnectionChangeData) {
    this._connectionChangesSubject.next(_data);
  }

  updateSpacePickerEvent(_data: SpacePickerSelectionEventData) {
    this._spacePickerEventSubject.next(_data);
  }

  updateSpacePickerSubmissionInProgressEvent(_data: SpacePickerSubmissionInProgressData) {
    this._spacePickerSubInProgressSubject.next(_data);
  }

  getConnectionChangeSubject(): Observable<ConnectionChangeData> {
    return this._connectionChangesSubject.asObservable();
  }
  
  getSpacePickerSubject(): Observable<SpacePickerSelectionEventData> {
    return this._spacePickerEventSubject.asObservable();
  }

  getSpacePickerSubmissionInProgressSubject(): Observable<SpacePickerSubmissionInProgressData> {
    return this._spacePickerSubInProgressSubject.asObservable();
  }

  getSignInBlurSubject(): Observable<SignInBlurData> {
    return this._showSignInBlurSubject.asObservable();
  }

  updateSignInBlurEvent(data: SignInBlurData) {
    this._showSignInBlurSubject.next(data);
  }

  getProfileStatusSubject(): Observable<boolean> {
    return this._profileStatusSubject.asObservable();
  }

  updateProfileStatusEvent(enabled: boolean) {
    this._profileStatusSubject.next(enabled);
  }

  getEditSpaceSubject(): Observable<void> {
    return this._editSpaceSubject.asObservable();
  }

  updateEditSpaceEvent() {
    this._editSpaceSubject.next();
  }

  getDeleteSpaceSubject(): Observable<void> {
    return this._deleteSpaceSubject.asObservable();
  }

  updateDeleteSpaceEvent() {
    this._deleteSpaceSubject.next();
  }

  getDeleteNodeSubject(): Observable<LibraryNode> {
    return this._deleteNodeSubject.asObservable();
  }

  updateDeleteNodeEvent(node: LibraryNode) {
    this._deleteNodeSubject.next(node);
  }

  getProfilePictureMutateSubject(): Observable<any> {
    return this._profilePictureMutateSubject.asObservable();
  }

  updateProfilePictureMutateSubject() {
    this._profilePictureMutateSubject.next();
  }

  
  getOnFileChangeSubject(): Observable<any> {
    return this._onFileChangeSubject.asObservable();
  }

  onFileChangeEvent(event:any) {
    this._onFileChangeSubject.next(event);
  }

newFolderTappedEvent() {
  this._newFolderTappedSubject.next();
}
getNewFolderTappedSubject(): Observable<void>{
  return this._newFolderTappedSubject.asObservable();
}

newURLTappedEvent()
{
  this._newURLTappedSubject.next();
  
}
getNewURLTappedSubject(): Observable<void>{
  return this._newURLTappedSubject.asObservable();
}

newActivityTappedEvent()
{
  this._newActivityTappedSubject.next();
}
getNewActivityTappedSubject(): Observable<void>{
  return this._newActivityTappedSubject.asObservable();
}

}

@Injectable()
export class RouterExtService {
  
  private previousUrl: string = '';
  private currentUrl: string = '';

  constructor(public router : Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  public getPreviousUrl(){
    return this.previousUrl;
  } 
  public getCurrentUrl(){
    return this.currentUrl;
  }   
}