// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 // apiUrl: 'http://localhost:5445/api/v1',
  apiUrl: 'https://dev.spark.byndr.com/api/v1',
  firebaseConfig: {
    apiKey: "AIzaSyCq1f6AKysfjVY9CH7_px9v0KBLJvx5vBI",
    authDomain: "byndr-dev-v2.firebaseapp.com",
    projectId: "byndr-dev-v2",
    storageBucket: "byndr-dev-v2.appspot.com",
    messagingSenderId: "11861730746",
    appId: "1:11861730746:web:3ef4f984a9fe9c2ffa2983",
    measurementId: "G-2QF38NJVS2"
  },
  clarityID: '9hhc0y43om',
  gtagID: 'G-3QPNEJ108N' // Change in index.html as well if this needs to be altered
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
