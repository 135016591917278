import { Adapter } from './../../../common/interfaces/adapter';
import { Injectable } from '@angular/core';


export enum ProfileLinkType {
    Facebook = "Facebook",
    Twitter = "Twitter", 
    Linkedin = "Linkedin", 
    Instagram = "Instagram"
}


export class UserProfileLink {

    type: ProfileLinkType;
    link: string;
    placeholder: string;
    iconClass: string;



    getIconClass(): string {

        switch (this.type) {
            case ProfileLinkType.Facebook:
                return "ic-facebook-blue";
            case ProfileLinkType.Twitter:
                return "ic-twitter-blue";
            case ProfileLinkType.Instagram:
                return "ic-instagram-blue";
            case ProfileLinkType.Linkedin:
                return "ic-linkedin-blue";
            default:
                return "";
        }
        

    }

    getIconDisabledClass(): string {

        switch (this.type) {
            case ProfileLinkType.Facebook:
                return "icon-facebook-grey";
            case ProfileLinkType.Twitter:
                return "ic-twitter-grey";
            case ProfileLinkType.Instagram:
                return "icon-instagram-grey";
            case ProfileLinkType.Linkedin:
                return "icon-linkedIn-grey";
            default:
                return "";
        }
        

    }


    getPlaceholder(): string{

        switch (this.type) {
            case ProfileLinkType.Facebook:
                return "Enter Facebook url";
            case ProfileLinkType.Twitter:
                return "Enter Twitter url";
            case ProfileLinkType.Instagram:
                return "Enter Instagram url";
            case ProfileLinkType.Linkedin:
                return "Enter Linkedin url";
            default:
                return "Enter url";
        }

    }
    
    getUpdateLinkPostBody() {

        let body: any = {};

        if (this.link) {
            body['link'] = this.link;
        }

        if (this.type) {
            body['linkType'] = this.type;
        }

        return body;
    
    }

}


@Injectable({
    providedIn: 'root'
})
export class UserProfileLinkAdapter implements Adapter<UserProfileLink> {
    adapt(profileLinkObject: any): UserProfileLink {

        let linkObject: UserProfileLink = new UserProfileLink();

        try {

            linkObject.type = (typeof profileLinkObject.linkType === 'string') ? profileLinkObject.linkType : null;
            linkObject.link = (typeof profileLinkObject.link === 'string') ? profileLinkObject.link : null;



        } catch (error) {
            console.error("Unable to convert profile link ", error);
        }

        return linkObject;
    }

}