import { Injectable } from "@angular/core";
import { Adapter } from "src/app/common/interfaces/adapter";

export class Option{
    value: string | undefined;
    key: string='';
    isCorrectAnswer: Boolean | undefined = undefined;
    isAnswered = false;
    toPostAPI: any = (formInput: any)=>{
        return {
            key: formInput.oKey,
            value: formInput.label
        }
    }
}

@Injectable({
    providedIn: "root"
})

export class OptionAdapter implements Adapter<Option> {

    adapt(item: any): Option {
        let option = new Option();
        option.value = item.value;
        option.key = item.key;
        return option;
    }
}