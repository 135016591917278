import { BLOCK_SCALE, SpaceBlockSaveAction } from './../helper/space.constant';
import { Adapter } from './../../../common/interfaces/adapter';
import { Injectable } from '@angular/core';
import { GridsterItem } from 'angular-gridster2';
import { LibraryNode, LibraryNodeAdapter } from './../../library/models/library-node.model';
export class LearningSpaceBlock {

    id: string;
    x: number;
    y: number;
    rows: number;
    cols: number;
    node: LibraryNode | undefined;
    loading = false;
    loadingAction: SpaceBlockSaveAction;

    static convertGridMenuItem(gridItem: GridsterItem): LearningSpaceBlock {

        let _block = new LearningSpaceBlock();
        _block.id = gridItem.id;

        _block.x = gridItem.x / BLOCK_SCALE;
        _block.y = gridItem.y / BLOCK_SCALE;
        _block.cols = gridItem.cols / BLOCK_SCALE;
        _block.rows = gridItem.rows / BLOCK_SCALE;

        _block.node = gridItem.node;
        _block.loading = gridItem.loading;
        _block.loadingAction = gridItem.loadingAction;

        return _block;

    }

    static updateGridMenuItem(gridItem: GridsterItem, _block: LearningSpaceBlock) {
        gridItem.x = _block.x * BLOCK_SCALE, 
        gridItem.y = _block.y * BLOCK_SCALE, 
        gridItem.rows = _block.rows * BLOCK_SCALE, 
        gridItem.cols = _block.cols * BLOCK_SCALE,  
        gridItem.node =_block.node,
        gridItem.id = _block.id,
        gridItem.loading = _block.loading,
        gridItem.loadingAction = _block.loadingAction
    }

    get gridMenuItem(): GridsterItem {
        const item: GridsterItem = {
            x: this.x * BLOCK_SCALE, 
            y: this.y * BLOCK_SCALE, 
            rows: this.rows * BLOCK_SCALE, 
            cols: this.cols * BLOCK_SCALE,  
            dragEnabled: true,
            resizeEnabled: true,
            node: this.node,
            id: this.id,
            loading: this.loading,
            loadingAction: this.loadingAction
        }
        return item;
    } 

    get postData(): any {

        let postBody: any = {
            id: this.id
        }

        if (this.node) {
            postBody["libraryNodeId"] = this.node.id
        }

        postBody['position'] = {
            x: this.x,
            y: this.y,
            columns: this.cols,
            rows: this.rows
        }

        return postBody;

    }
}

@Injectable({
    providedIn: 'root'
})
export class LearningSpaceBlockAdapter implements Adapter<LearningSpaceBlock> {
    adapt(data: any): LearningSpaceBlock {

        let block = new LearningSpaceBlock();

        try {

            block.id = (typeof data.id === 'string') ? data.id: undefined;

            if (data.position) {
                block.x = (typeof data.position.x === 'number') ? data.position.x: parseInt(data.position.x);
                block.y = (typeof data.position.y === 'number') ? data.position.y: parseInt(data.position.y);
                block.cols = (typeof data.position.columns === 'number') ? data.position.columns: parseInt(data.position.columns);
                block.rows = (typeof data.position.rows === 'number') ? data.position.rows: parseInt(data.position.rows);
            }


            if (data.libraryNode && data.libraryNode.id && data.libraryNode.id.length > 0) {
                let nodeAdapter = new LibraryNodeAdapter();                
                block.node = nodeAdapter.adapt(data.libraryNode);
            }

            
            
        } catch (error) {
            console.error("Unable to convert ls block data", error);
        }

        return block;
    }

}
