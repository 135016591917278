import { NotificationsService } from './main/notifications/data-provider/notifications.service';
import { NotificationMessagingService } from './core/service/notification-messaging.service';
import { NavigationEnd, Router } from "@angular/router";
import { Component, OnDestroy, OnInit, Renderer2, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject, Subscription } from "rxjs";
import { ProfileEnableConfirmationDialogComponent } from "./common/components/profile-enable-confirmation-dialog/profile-enable-confirmation-dialog.component";
import { CommonService, SignInBlurData } from "./common/service/common.service";
import { filter } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { OnPageVisible } from 'angular-page-visibility';
import { ThemeService } from './main/main.service';
declare var gtag: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  profileStatusSubscription: Subscription;
  signInSubjectSubscription: Subscription;
  signInBlurEffectData: SignInBlurData | undefined;
  routerSubscription: Subscription;
  // loading = false;
  message: BehaviorSubject<{}>;
  constructor(
    public dialog: MatDialog,
    private commonService: CommonService,
    private _router: Router,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private _notificationService: NotificationsService,
    private messagingService: NotificationMessagingService,
    private themeService: ThemeService
  ) {}
  ngOnDestroy(): void {
    if (this.signInSubjectSubscription)
      this.signInSubjectSubscription.unsubscribe();

    if (this.profileStatusSubscription)
      this.profileStatusSubscription.unsubscribe();

    if (this.routerSubscription) this.routerSubscription.unsubscribe();
  }

  ngOnInit() {
    let script = this._renderer2.createElement("script");
    script.type = `text/javascript`;
    script.text = `
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${environment.clarityID}");
      `;

    this._renderer2.appendChild(this._document.body, script);

    this.observeRouter();

    this.profileStatusSubscription = this.commonService
      .getProfileStatusSubject()
      .subscribe((enabled: boolean) => {
        if (!enabled) {
          this.openProfileStatusDialog();
        }
      });

    this.signInSubjectSubscription = this.commonService
      .getSignInBlurSubject()
      .subscribe((data: SignInBlurData) => {
        if (data) {
          this.signInBlurEffectData = data;
        }
      });

    this.registerForNotifications();


   // const currentTheme = this.themeService.getCurrentTheme();
   // document.body.classList.add(currentTheme);


  }

  registerForNotifications() {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
  }


  @OnPageVisible()
  logWhenPageVisible (): void {
    console.log( 'OnPageVisible => visible' );
    
    try {
      this._notificationService.getNewNotificationsCount();
    } catch (error) {
      console.error(error);
    }
  }
  


  observeRouter() {
    // this._router.events.subscribe(event => {
    //   if (event instanceof GuardsCheckStart) {
    //     this.loading = true;
    //     console.log("GuardStart", event);
    //   }
    //   if (event instanceof GuardsCheckEnd) {
    //     this.loading = false;
    //     console.log("GuardEnd", event);
    //   }
    // });

    this.routerSubscription = this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        gtag("config", environment.gtagID, {
          page_path: event.urlAfterRedirects,
        });
      });
  }

  openProfileStatusDialog() {
    this.dialog.open(ProfileEnableConfirmationDialogComponent, {
      width: "500px",
      height: "auto",
      id: "profile_enable_confirmation_dialog",
    });
  }
}
