<div class="library-body w-100" fxLayout="row wrap" fxLayoutWrap="wrap">
    <div class="library-welcome-card">
        <div class="library-box">
            <div class="byndr_logo align-center"><span class="icon byndr-logo"></span></div>
            <img class="align-center li-i" src="../../assets/images/rawimages/nointernet.png">
            <p class="align-center">No Internet</p>
            <p class="align-center">There may be something</p> <p class="align-center">went wrong with your internet</p> <p class="align-center">connecting right now.</p>
         
        
    </div>
   </div></div>