import { Adapter } from 'src/app/common/interfaces/adapter';
import { Injectable } from '@angular/core';
export class LibraryURL {

    title: string;
    url: string;
    images: string[];
    author: string;
    source: string;
    ttr: number;
    content: string;


    get thumbnail(): string | undefined {

        if (this.images && Array.isArray(this.images) && this.images.length > 0 && this.images[0].length > 0) {
            return this.images[0];
        }

        return undefined;

    }

    get meta(): string[] {
        let meta: string[] = [];
        if (this.author && this.author.length > 0)
            meta.push("By " + this.author);

        if (this.source && this.source.length > 0)
            meta.push(this.source)
        
        if(this.ttr > 0)
            meta.push(Math.ceil(this.ttr/60) + " min");

        return meta;
         
    }

    get articleClass(): string {



        if (this.source && this.source.length > 0 && this.videoPlatforms.indexOf(this.source.toLowerCase()) > -1) {

            return "library-video";
            
        }
        

        return "library-article";


    }

    get articleClassForFullPreview(): string {

        if (this.source && this.source.length > 0 && this.videoPlatforms.indexOf(this.source.toLowerCase()) > -1) {

            return "library-video-full";
            
        }
        

        return "library-article-full";

    }

    get videoPlatforms(): string[] {
        return [

            "youtube",
            "vimeo"

        ];
    }

}

@Injectable({
    providedIn: 'root'
})
export class LibraryURLAdapter implements Adapter<LibraryURL> {
    adapt(data: any): LibraryURL  {

        let urlObject = new LibraryURL();

        try {

            urlObject.title = (typeof data.title === 'string') ? data.title: null;
            urlObject.url = (typeof data.url === 'string') ? data.url: null;
            urlObject.images = (Array.isArray(data.images))? data.images: [];
            urlObject.author = (typeof data.author === 'string') ? data.author: null;
            urlObject.source = (typeof data.source === 'string') ? data.source: null;
            urlObject.ttr = (typeof data.ttr === 'number') ? data.ttr: null;

            urlObject.content = (typeof data.content === 'string') ? data.content: null;

            
        } catch (error) {
            console.error("Unable to convert url object", error);
        }

        return urlObject;
    }

}