import { AuthenticationService } from './core/service/authentication.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authenticationService: AuthenticationService){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      const isUserLoggedIn = this.authenticationService.isLoggedIn;
      if (isUserLoggedIn) {
          return true;
      }
      else {
        this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        return false;
      }
  
  }
  
}
