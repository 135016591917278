import { Injectable } from "@angular/core";
import { Adapter } from "src/app/common/interfaces/adapter";
import { LearningSpaceBlock, LearningSpaceBlockAdapter } from "./learning-space-block.model";

export class SpaceTemplate {
    id: string;
    title: string;
    thumbnail: string;
    blocks: LearningSpaceBlock[];
}

@Injectable({
    providedIn: 'root'
})

export class SpaceTemplateAdapter implements Adapter<SpaceTemplate> {
    adapt(data: any): SpaceTemplate {

        let template = new SpaceTemplate();

        try {

            template.id = (typeof data.id === 'string') ? data.id: null;
            template.title = (typeof data.name === 'string') ? data.name: null;
            template.thumbnail = (typeof data.previewImage === 'string') ? data.previewImage: null;
            
            if(data.blocks && Array.isArray(data.blocks) && data.blocks.length > 0) {
                let blockAdapter = new LearningSpaceBlockAdapter();
                const blocks = data.blocks.map((_blockData: any) => blockAdapter.adapt(_blockData));
                template.blocks = blocks;
            }
            
        } catch (error) {
            console.error("Unable to convert template", error);
        }

        return template;
    }

}