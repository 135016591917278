export enum NotificationType {
    ConnectionRequest = "ConnectionRequest",
    ConnectionAccept = "ConnectionAccept",
    ShareLSP = "ShareLSP",
    CommentLSP = "CommentLSP",
    LikeLSP = "LikeLSP",
    ReplyComment = "ReplyComment"
}
export enum NotificationImageType {
    user = "user",
    other = "other"
}

export const NOTIFICATIONS_RESULTS_PER_PAGE : number = 20;