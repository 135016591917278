<ng-container *ngIf="!loading; else avatarSkeletonLoading">
    <div class="user-images-list scroll-bar mt-20">
        <div [ngClass]="disable? 'avatar-loading': ''" fxLayout="row wrap" fxLayoutWrap="wrap"
            fxLayoutAlign="start center">
            <div fxFlex="50" class="pr-20 pb-20" fxLayoutAlign="start center" *ngFor="let avatar of avatars">
                <div (click)="avatarSelectionChange(avatar)"
                    [ngClass]="[(selectedAvatar && (selectedAvatar.id === avatar.id))? 'selected': '', avatar.loading? 'img-loading': '', 'avatar-img']">
                    <img src="{{avatar.url}}" />
                    <span class="icon icon-tickmark-white"></span>
                    <div class="mat-loading spinner-white">
                        <mat-spinner strokeWidth="3" diameter="50" color="#fff"></mat-spinner>
                    </div>
                </div>
            </div>
            <!-- <mat-radio-group aria-labelledby="avatars" class="" >
                <mat-radio-button class="" *ngFor="let avatar of avatars" [value]="avatar" [checked]="selectedAvatar?(selectedAvatar.id === avatar.id): false">
                    <div class="avatar-img">
                        <img src="{{avatar.url}}" />
                        <ng-container *ngIf="avatar.loading">
                            <div class="avatar-loading">
                                loading...
                            </div>
                        </ng-container>
                    </div>
                </mat-radio-button>
            </mat-radio-group> -->
        </div>
    </div>
</ng-container>
<ng-template #avatarSkeletonLoading>
    <div class="user-images-list scroll-bar mt-20">
        <div fxLayout="row wrap" fxLayoutWrap="wrap" fxLayoutAlign="start center">
            <ngx-skeleton-loader count="4" [theme]="{ 
                    minHeight: '130px',
                    minWidth: '100px',
                    width: 'calc(50% - 20px)',
                    marginBottom: '20px',
                    marginRight: '20px',
                    borderRadius: '10px'
                  }"></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>