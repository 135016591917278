import { UserProfileLink, ProfileLinkType } from './../models/user-profile-link.model';



const facebookLink = new UserProfileLink();
facebookLink.type = ProfileLinkType.Facebook
facebookLink.link = ""
facebookLink.placeholder = facebookLink.getPlaceholder()
facebookLink.iconClass = facebookLink.getIconClass();

const twitterLink = new UserProfileLink();
twitterLink.type = ProfileLinkType.Twitter
twitterLink.link = ""
twitterLink.placeholder = twitterLink.getPlaceholder()
twitterLink.iconClass = twitterLink.getIconClass();

const linkedInLink = new UserProfileLink();
linkedInLink.type = ProfileLinkType.Linkedin;
linkedInLink.link = ""
linkedInLink.placeholder = linkedInLink.getPlaceholder()
linkedInLink.iconClass = linkedInLink.getIconClass()

const instagramLink = new UserProfileLink();
instagramLink.type = ProfileLinkType.Instagram;
instagramLink.link = "";
instagramLink.placeholder = instagramLink.getPlaceholder()
instagramLink.iconClass = instagramLink.getIconClass()


export const ALL_LINKS : UserProfileLink[] = [
    facebookLink,
    twitterLink,
    instagramLink,
    linkedInLink
    
]

export const REQUEST_TYPES = {
    REQUEST_TYPE_RECEIVED: "received",
    REQUEST_TYPE_RAISED: "raised"
}