<div class="learning-space-popup-main">
    <div class="learning-space-popup-box" fxLayout="row" fxLayoutAlign="center center">
        <div class="learning-space-popup-body w-100 pt-20">
            <span class="icon icon-close cursor-pointer cancelIcon" (click)="closeLearningSpaceDialog()"></span>
            <div fxLayout="column">
                <form [formGroup]="createLearningSpaceForm" [ngClass]="loading || mutateInProgress? 'show-loading' : ''"
                    (ngSubmit)="onSubmit(createLearningSpaceForm.value)">
                    <div class="learning-space-popup-header-main">
                        <div class="learning-space-popup-header">
                            
                            <h4 class="h4-24-black mb-10 align-center poptitle">Create {{displayTitle}}</h4>
                            <p class="p-small-grey14 mb-10 align-center">Fill in the details and select your template to get started!</p>
                            <div class="pt-20" fxLayout="row wrap" fxLayoutWrap="wrap" fxLayoutAlign="start center">
                                <div fxFlex="100" class="pr-20" fxLayoutAlign="start center">
                                    <div fxLayout="column" class="w-100">
                                        <mat-form-field appearance="fill">
                                            <mat-label>{{displayTitle}} Name</mat-label>
                                            <input #spaceNameInput matInput placeholder="{{displayTitle}} Name"
                                                formControlName="spaceName" autocomplete="off"
                                                maxlength="{{spaceNameMaxLength}}" required>
                                            <span matSuffix
                                                class="black-text-suffix">{{remainingLengthForSpaceName}}</span>
                                            <mat-error
                                                *ngIf="!createLearningSpaceForm.controls['spaceName'].valid && createLearningSpaceForm.controls['spaceName'].touched">
                                                <div class="password-error">
                                                    <span class="exclamation-symbol">
                                                        <span>!</span>
                                                    </span>
                                                    <p>Name is required</p>
                                                </div>
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field  appearance="fill">
                                            <mat-label>Enter Description</mat-label>
                                            <textarea #descriptionTextarea matInput placeholder="Enter Description"
                                                maxlength="{{descriptionMaxLength}}"
                                                formControlName="description"></textarea>
                                            <span matSuffix
                                                class="black-text-suffix">{{remainingLengthForDescription}}</span>
                                        </mat-form-field>
                                        <app-tag-auto-complete #tagField [viewMode]="false">
                                        </app-tag-auto-complete>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="learning-space-popup-select-template-main" *ngIf="templateRequired">
                            <app-choose-template (templatesLoadedEvent)="templatesLoaded()"
                                (templateSelectedEvent)="templateSelected($event)"></app-choose-template>
                        </div>
                    </div>
                    <div mat-dialog-actions position="bottom" class="learning-space-buttons" fxLayout="row"
                    fxLayout="center center">
                    <div class="buttons-row">
                        <button mat-raised-button type="button" class="btn-grey roundedBtn" (click)="closeLearningSpaceDialog(); $event.stopPropagation()"
                            [disabled]="loading || mutateInProgress">Cancel</button>
                        <button type="submit" mat-raised-button
                            [disabled]="loading || mutateInProgress || createLearningSpaceForm.invalid"
                            class="btn-submit roundedBtn">Save & Continue</button>
                        <mat-spinner *ngIf="mutateInProgress" [color]="color" diameter="25" strokeWidth="3">
                        </mat-spinner>
                    </div>
                </div>
                </form>
            </div>
        </div>
    </div>
</div>