import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { LibraryNodeType } from '../../library/helper/library.constant';
import { SpaceType } from '../helper/space.constant';
import { LearningSpace } from '../models/learning-space.model';

@Component({
  selector: 'app-metablock',
  templateUrl: './metablock.component.html',
  styleUrls: ['./metablock.component.scss']
})
export class MetablockComponent implements OnInit, OnDestroy, OnChanges {
  _space:any;
  typeEnum = SpaceType;
  @Input() space: LearningSpace;
  @Input() fullpopup:boolean = false;
  nodeType = LibraryNodeType;
  iconCls:string="";
  selectedIndex:number;

  @Output() nodeIconClickEvent = new EventEmitter<number>();
  @Input() setActiveIndex:any;
  
  constructor() { 


  }
  ngOnChanges(): void {
  
    if(this.setActiveIndex)
    {
      this.selectedIndex= this.setActiveIndex
    }
  }

  ngOnInit(): void {
    this._space= this.space;
    
    if(this.setActiveIndex)
    {
      this.selectedIndex= this.setActiveIndex
    } else
    {
      this.selectedIndex=0;
    }
    this.iconCls= this.fullpopup ? "popIcons" : "bgIcons";
  }

  nodeClick(index:number)
  {
    this.selectedIndex=index;
    this.nodeIconClickEvent.emit(index)
    
  }
  ngOnDestroy(): void {
this.nodeIconClickEvent.unsubscribe();
  }
}
