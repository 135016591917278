export const HEADER_CONTEXT = {

    MY_PROFILE: 'my-profile',
    OTHER_PROFILE: 'other-profile',
    EDIT_PROFILE: 'edit-profile',
    LIBRARY: 'library',
    CONNECTIONS: 'connections',
    SPACES: 'spaces',
    PATHES:'my-paths',
    HOME: 'home',
    LEARNING_PATHS_MINE: 'my-paths',
    SPACES_SHARED: 'shared',
    SPACES_BOOKMARKED: 'bookmarked',
    SEARCH_SPACES: 'search-spaces',
    SPACES_DETAILS: 'spaces-details',
    LEARNING_PATH_DETAILS: 'learningpath-details',
    LEARNING_PATHS_SHARED: 'paths-shared',
    LEARNING_PATHS_BOOKMARKED: 'paths-bookmarked',
}

export enum LibraryViewType {
    List = "List",
    Grid = "Grid",
}

export enum SpacesViewType {
    List = "List",
    Grid = "Grid",
}

export const PROFILE_DATA_TAB_TITLE = {
    CONNECTIONS: 'Connections',
    MUTUAL_CONNECTIONS: 'Mutual Connections',
    SPACES: 'Spaces',
    PATHS: 'Paths'
}

export const PROFILE_DATA_TAB_TYPE = {
    CONNECTIONS: 'connections',
    MUTUAL_CONNECTIONS: 'mutual_connections',
    SPACES: 'spaces',
    PATHS: 'paths'
}

export const CONNECTION_ACTIONS_SCREEN = {
    SCREEN_USER_PROFILE: "user_profile",
    SCREEN_SEARCH_CONNECTION: "search_connections",
    SCREEN_REQUESTS: "search_request",
    SCREEN_CONNECTIONS: "connections"
}

export const SIGNIN_SECURITY_CONTEXT = {
    EMAIL: 'email',
    MOBILE: 'mobile',
    PASSWORD: 'password'
}

export const PRIVACY_CONTEXT = {
    TEMPORARILY: 'temporarily',
    PERMANENTLY: 'permanently'
}

export const THEME = {
    THEME_DARK: 'dark',
    THEME_LIGHT: 'light'
}

export const SIDE_NAV_CONTEXT = {
    CONTEXT_CONN_REQUEST: 'connection-requests',
    CONTEXT_COMMENTS: 'comments',
    CONTEXT_Invite: 'one',
    CONTEXT_Notifications: 'notifications',
    CONTEXT_SPACE_PICKER: 'space-picker'
}
export const full_invite = {
    CONTEXT_CONN_REQUEST: 'connection-requests',
    CONTEXT_COMMENTS: 'comments',
   CONTEXT_Invite: 'one'
}

export const TOUR_STATUS = {
    NotVisited: 'NotVisited',
    Skipped: 'Skipped',
    Visited: 'Visited'
};

export const SELECTION_MODE = {
    NONE: 'none',
    SINGLE: 'single',
    MULTIPLE: 'multiple'
}
export const LS_KEY_SPACE_SORT_ORDER = "spso"
export const LS_KEY_SPACE_SORT_KEY = "spsk"

export const ERROR_OOPS_SOMETHING = "Oops,something went wrong. Please try again";
export const REGEX_EMAIL: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const REGEX_PASSWORD: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/

export const SPACE_NAME_MAX_LENGTH = 60;
export const DESCRIPTION_MAX_LENGTH = 140;
export const DESCRIPTION_MAX_LENGTH240 = 240;

export const APP_PLATFORM = "web"

export const USER_PRIVILEGES = {
    CAN_MARK_LSP_FOR_WEBSITE_ACCESS: 'canMarkLSPForWebsiteAccess'
}

export interface DeleteResponse {
    message?: string;
    success: boolean;
    code?: string;
}

export interface AckResponse {
    data?: string;
    message?: string;
    success: boolean;
    code?: string;
}

