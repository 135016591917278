import { Injectable } from "@angular/core";
import { Question } from "./question.model";
import { Option } from "./option.model";
import { Adapter } from "src/app/common/interfaces/adapter";
import { APP_PLATFORM } from "src/app/common/constants";

export class CreateQuestionnaire{

    title: string = "";
    platform: string = APP_PLATFORM;
    questions: Question[]=[];

    transform: any = (data: any): CreateQuestionnaire =>{
        let questionnaire = new CreateQuestionnaire();
        data.questions.map((question: { question: string; questionType: string; options: any[]; })=>{
            let questionObj = new Question().toPostAPI(question);
            questionnaire.questions.push(questionObj);
        });
        data.platform = this.platform;
        return questionnaire;
    }
}

@Injectable({
    providedIn: "root"
})

export class CreateQuestionnaireAdapter implements Adapter<CreateQuestionnaire> {

    adapt(item: any): CreateQuestionnaire {
        let questionnaire = new CreateQuestionnaire();
        let questionObj = new Question();
        questionObj.question = item.question;
        questionObj.questionType = item.questionType;
        questionObj.answers = item.answer;
        item.options.map((option: any)=>{
            let optionObj = new Option();
            optionObj.key = option.key;
            optionObj.value = option.value;
            questionObj.options.push(optionObj);
        });
        questionnaire.questions.push(questionObj);
        return questionnaire;
    }
}