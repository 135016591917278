import { LibrarySortOrder, LibrarySortField, LibrarySort, LibraryNodeType } from './../../helper/library.constant';
import { Subscription } from 'rxjs';
import { LibraryNode } from './../../models/library-node.model';
import { Category } from './../../models/library-file-info.model'
import { Component, OnInit, ViewChild, AfterViewInit, Input, EventEmitter, Output, OnDestroy } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LibraryService } from '../../data-providers/library.service';
import { MainService } from '../../../main.service';
import { CommonService } from 'src/app/common/service/common.service';
import { MatDialog } from '@angular/material/dialog';
import { RenameDialogComponent } from '../rename-dialog/rename-dialog.component';
import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'app-library-list-view',
  templateUrl: './library-list-view.component.html',
  styleUrls: ['./library-list-view.component.scss']
})
export class LibraryListViewComponent implements OnDestroy{

  @Input() nodes?: LibraryNode[];
  @Input() loading: boolean = false;
  @Input() selectedNode?: LibraryNode;
  @Input() sort?: LibrarySort;
  @Input() showActions: boolean = true;
  @Input() allowedTypes = [LibraryNodeType.folder, LibraryNodeType.file, LibraryNodeType.link, LibraryNodeType.activity]
  @Input() disabledNodes: string[] = []



  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource();

  nodeType = LibraryNodeType;
  nodeFileCategoryType = Category;
  clickTimer: any;
selectedNodeAttr:any
mutateFavoriteInProgress = false;
  @Output() nodeClickEvent = new EventEmitter<LibraryNode>();
  @Output() nodeDoubleClickEvent = new EventEmitter<LibraryNode>();
  @Output() sortEvent = new EventEmitter<LibrarySort>();
  @ViewChild(MatSort) tableSort: MatSort;


  constructor(
    public libraryService: LibraryService,
    public mainService: MainService,
    private _commonService: CommonService,
    private _snackBar: MatSnackBar,
    ) { 

      this.selectedNodeAttr=this.selectedNode ? this.selectedNode : []
    }


  ngOnDestroy(): void {
     if (this.nodeClickEvent)
      this.nodeClickEvent.unsubscribe();


     if (this.nodeDoubleClickEvent)
      this.nodeDoubleClickEvent.unsubscribe();

     if (this.sortEvent)
      this.sortEvent.unsubscribe();

   
  }


  ngOnInit(): void {

    this.prepareDisplayedColumns();

    //this.dataSource.data = this.nodes;


    // this.libraryService.libraryFilter.subscribe((r: any) => {
    //   console.log(r.authType, r.fileTypes);
    //   if (r.fileTypes != 'All') {
    //     this.dataSource.data = ELEMENT_DATA.filter((res: any) => {
    //       if (res.type == r.fileTypes)
    //         return res
    //     });
    //   } else if (r.fileTypes == 'All') {
    //     this.dataSource.data = ELEMENT_DATA;
    //   }

    //   console.log(this.dataSource.data);
    // });

  }

  prepareDisplayedColumns() {
    this.displayedColumns = ['name'];
    // if (this.showActions) {
    //   this.displayedColumns = ['name', 'notes_count'];;
    // }
    // else {
    //   this.displayedColumns = ['name'];
    // }
  }

  
  deleteRow(index: number) {
    let data = this.dataSource.data;
    data.splice(index, 1);
    this.dataSource.data = data;
  }

  nodeClick(node: LibraryNode) {
this.selectedNodeAttr=node;
    if (this.isAllowed(node)) {
      this.clickTimer = setTimeout(() => {
        this.emitClickEvent(node);
      }, 300);  // Just to handle double click
    }
  }

  emitClickEvent(node: LibraryNode) {

    if (this.isAllowed(node)) {
      if (!this.clickTimer) return;
      this.nodeClickEvent.emit(node);
    }
  }

  nodeDoubleClick(node: LibraryNode) {

    if (this.isAllowed(node)) {
      console.log("Double click");
      clearTimeout(this.clickTimer);
      this.clickTimer = undefined;
      this.nodeDoubleClickEvent.emit(node);
    }
  }

  isAllowed(node: LibraryNode): boolean {
    const typeAllowed =  this.allowedTypes.some(a => (a === node.type))
    const nodeAllowed =  !(this.disabledNodes.some(nid => (nid === node.id)));

    return (typeAllowed && nodeAllowed);
    
  }

  sortData(sort: Sort) {

    if (!sort.active || sort.direction === '') {
      return;
    }

    let _librarySort: LibrarySort = {
      sortBy: (<any>LibrarySortField)[sort.active],
      sortOrder: (<any>LibrarySortOrder)[sort.direction]
    };


    this.sortEvent.emit(_librarySort)

  }

  notesClicked(node: LibraryNode) {
    this.nodeClick(node);
  }

  showSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 10000 });
  }
  async mutateFavorite(node:any) {

    try {

      this.mutateFavoriteInProgress = true;

      let response = null;

      if (node.favorite) {
        response = await this.libraryService.removeFavorite(node.id);
      }
      else {
        response = await this.libraryService.markFavorite(node.id);
      }


      if (response.success) {

        if (node.favorite) {
          this.showSnackBar(`"${node.title}" removed to favorite.`, "OK");
        }
        else {
          this.showSnackBar(`"${node.title} has been added to favorite."`, "OK");
        }
        node.favorite = !node.favorite


      }
      else {
        this.showSnackBar(`Unable to mark/remove from favorite`, "OK");
      }

      this.mutateFavoriteInProgress = false;


    } catch (error) {
      this.mutateFavoriteInProgress = false;
    }


  }

}
