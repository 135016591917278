import { SpaceType } from './../helper/space.constant';
import { AuthenticationService } from "src/app/core/service/authentication.service";
import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MainService } from "../../main.service";
import { SpaceResponse, SpaceService } from "../data-providers/space.service";
import { LearningSpace } from "../models/learning-space.model";

@Component({
  selector: "app-spaces-info",
  templateUrl: "./spaces-info.component.html",
  styleUrls: ["./spaces-info.component.scss"],
})
export class SpacesInfoComponent implements OnInit {
  @Input() spaceId: string | undefined;
  @Input() spaceType: SpaceType = SpaceType.learningspace;
  space: LearningSpace | undefined;
  fetchingSpace: boolean = false;

  constructor(
    public mainService: MainService,
    private _spaceService: SpaceService,
    private _dialog: MatDialog,
    private _authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    if (this.spaceId) {
      this.getLearningSpace(this.spaceId);
    }
  }

  async getLearningSpace(_spaceId: string | null) {
    if (!_spaceId) {
      return;
    }

    this.fetchingSpace = true;

    try {
      let response: SpaceResponse = await this._spaceService.getSpace(
        _spaceId,
        this._authenticationService.isLoggedIn,
        this.spaceType
      );

      if (response.success) {
        const _space: LearningSpace | undefined = response.space;
        if (_space) {
          this.space = _space;
        }
      }

      this.fetchingSpace = false;
    } catch (error) {
      this.space = undefined;
      this.fetchingSpace = false;
    }
  }

  closeSpacesInfo() {
    const dialogRef = this._dialog.getDialogById("space_info_dialog");
    if (dialogRef) dialogRef.close();
  }
}
