import { Injectable } from "@angular/core";
import { Adapter } from "src/app/common/interfaces/adapter";
// import {bytes} from '../../../../../node_modules/bytes/index.js';



const FILE_VIEWER_PACKAGE = "ngx-document-viewer";
const IMAGE_VIEWER_PACKAGE = "html-img";
const VIDEO_VIEWER_PACKAGE = "videogular";

export interface ViewerInterface {
    viewerSource: string;
    viewerPackage: string;
}

export class LibraryFileInfo {

    mime: string;
    category: Category;
    originalFileName: string;
    fileSize: number; // In Bytes
    location: string;

    get viewer(): ViewerInterface {

        switch (this.category) {
            case Category.document:
                return this.documentViewer;
            
            case Category.audio:
                return this.documentViewer; //TODO:
            
            case Category.video:
                return this.videoViewer; //TODO:

            case Category.image:
                return this.imageViewer; //TODO:

            default:
                return this.documentViewer; //TODO:
        }
        

    }

    get videoViewer(): ViewerInterface {
        let viewerSource: string = "video"
        let viewerPackage: string = VIDEO_VIEWER_PACKAGE

        return {viewerSource: viewerSource, viewerPackage: viewerPackage};
    }

    get imageViewer(): ViewerInterface {

        let viewerSource: string = "image";
        let viewerPackage: string = ""

        switch (this.mime) {
            case 'image/jpeg':                
            case 'image/png':
            case 'image/bmp':
            case 'image/gif':
                viewerSource = "image";
                viewerPackage = IMAGE_VIEWER_PACKAGE;
                break;
            case 'image/tiff':
            case 'application/postscript':
            case 'image/svg+xml':
                viewerSource = "google";
                viewerPackage = FILE_VIEWER_PACKAGE;
                break;

            default:
                viewerSource = "google";
                viewerPackage = FILE_VIEWER_PACKAGE;
                break;
            
        }

        return {viewerSource: viewerSource, viewerPackage: viewerPackage};

    }

    get documentViewer(): ViewerInterface { 

        let viewerSource: string;
        let viewerPackage: string;

        switch (this.mime) {
            case 'application/msword':                
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
                viewerSource = "office";
                viewerPackage = FILE_VIEWER_PACKAGE;
                break;
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
                viewerSource = "office";
                viewerPackage = FILE_VIEWER_PACKAGE;
                break;
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'application/vnd.openxmlformats-officedocument.presentationml.template':
                viewerSource = "google";
                viewerPackage = FILE_VIEWER_PACKAGE;
                break;
            case 'application/pdf':
                viewerSource = "google";
                viewerPackage = FILE_VIEWER_PACKAGE;
                break;
            case 'application/x-mspublisher':
                viewerSource = "google";
                viewerPackage = FILE_VIEWER_PACKAGE;
                break;
            case 'text/plain':
                viewerSource = "google";
                viewerPackage = FILE_VIEWER_PACKAGE;
                break;
            case 'application/zip':
                viewerSource = "google";
                viewerPackage = FILE_VIEWER_PACKAGE;
                break;
            case 'application/rtf':
                viewerSource = "google";
                viewerPackage = FILE_VIEWER_PACKAGE;
                break;
            case 'text/csv':
                viewerSource = "google";
                viewerPackage = FILE_VIEWER_PACKAGE;
                break;
            case 'application/vnd.apple.keynote':
                viewerSource = "google";
                viewerPackage = FILE_VIEWER_PACKAGE;
                break;
            case 'application/vnd.apple.numbers':
                viewerSource = "google";
                viewerPackage = FILE_VIEWER_PACKAGE;
                break;
            case 'application/vnd.apple.pages':                                                                            
                viewerSource = "google";
                viewerPackage = FILE_VIEWER_PACKAGE;
                break;
            default:
                viewerSource = "google";
                viewerPackage = FILE_VIEWER_PACKAGE;
                break;
        }

        return {viewerSource: viewerSource, viewerPackage: viewerPackage};

       

    }

    get textForMimeType(): string {

        let text = "??";
        switch (this.mime) {
            case 'application/msword':                
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
                text = "W"
                break;
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
                text = "XLS"
                break;
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'application/vnd.openxmlformats-officedocument.presentationml.template':
                text = "PPT"
                break;
            case 'application/pdf':
                text = "PDF";
                break;
            case 'application/x-mspublisher':
                text = "PUB";
                break;
            case 'text/plain':
                text = "TXT"
                break;
            case 'application/zip':
                text = "ZIP";
                break;
            case 'application/rtf':
                text = "RTF";
                break;
            case 'text/csv':
                text = "CSV";
                break;
            case 'application/vnd.apple.keynote':
                text = "KEY";
                break;
            case 'application/vnd.apple.numbers':
                text = "NUM";
                break;
            case 'application/vnd.apple.pages':                                                                            
                text = "PAG";
                break;
            case 'text/rtf':
                text = "RTF";
                break;
            default:
                break;
        }

        return text;
        

    }

    get iconBGClassForDocumentType(): string {

        let documentClass = "??";
        switch (this.mime) {
            case 'application/msword':                
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
                documentClass = "icon-msword-bg";
                break;
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
                documentClass = "icon-excel-bg";
                break;
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'application/vnd.openxmlformats-officedocument.presentationml.template':
                documentClass = "icon-ppt-bg";
                break;
            // case 'text/plain':
            // case 'text/rtf':
            //     documentClass = "icon icon-notes"
            //     break;
            case 'application/pdf':
                documentClass = "icon-pdf-bg";
                break;
            case 'application/zip':
                documentClass = "icon-zip-bg";
                break;
            default:
                documentClass = "icon-default-bg";
                break;
        }

        return documentClass;
        
    }

    get iconClassForDocumentType(): string {

        let documentClass = "??";
        switch (this.mime) {
            case 'application/msword':                
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
                documentClass = "icon icon-msword icon-full";
                break;
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
                documentClass = "icon icon-excel icon-full";
                break;
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'application/vnd.openxmlformats-officedocument.presentationml.template':
                documentClass = "icon icon-ppt icon-full";
                break;
            case 'text/plain':
            case 'text/rtf':
            case 'application/rtf':
                documentClass = "icon icon-notes icon-full"
                break;
            // case 'application/pdf':
            //     documentClass = "icon icon-pdf";
            //     break;
            case 'application/zip':
                documentClass = "icon icon-zip-grey icon-full";
                break;
            default:
                documentClass = "icon icon-text";
                break;
        }

        return documentClass;
        
    }

    getBGClassForList(): string | undefined{

        let bgClass: string;

        switch (this.category) {
            case Category.image:
                bgClass =  'icon-image-bg';            
                break;
            case Category.audio:
                bgClass =  'icon-audio-bg';   
                break;         
            case Category.video:
                bgClass =  'icon-video-bg';
                break;                            
            case Category.document:
                bgClass = this.iconBGClassForDocumentType;              
                break;
            default:
                bgClass = 'icon-default-bg';
        }

        return bgClass;

    }

    getIconClassForList(): string | undefined{

        let iconClass: string;

        switch (this.category) {
            case Category.image:
                iconClass =  'icon icon-image-grey icon-lt';            
                break;
            case Category.audio:
                iconClass =  'icon icon-audio-grey icon-lt';   
                break;         
            case Category.video:
                iconClass =  'icon icon-video-with-bg icon-full';
                break;                            
            case Category.document:
                iconClass = this.iconClassForDocumentType;              
                break;
            default:
                iconClass = 'icon icon-text';
        }

        return iconClass;

    }
    get thumbnail(): string | undefined {
        
        // if (this.images && Array.isArray(this.images) && this.images.length > 0 && this.images[0].length > 0) {
        //     return this.images[0];
        // }

        return undefined;

    }

}

export enum Category {
    image = "image",
    document = "document", 
    audio = "audio", 
    video = "video",
    other = "other"
}


@Injectable({
    providedIn: 'root'
})
export class LibraryFileInfoAdapter implements Adapter<LibraryFileInfo> {
    adapt(data: any): LibraryFileInfo {

        let fileInfo = new LibraryFileInfo();

        try {

            fileInfo.mime = (typeof data.mime === 'string') ? data.mime: null;
            fileInfo.category = (typeof data.category === 'string') ? (<any>Category)[data.category]: null;
            fileInfo.originalFileName = (typeof data.originalFileName === 'string') ? data.originalFileName: null;
            fileInfo.location = (typeof data.location === 'string') ? data.location: null;
            fileInfo.fileSize = (typeof data.fileSize === 'number') ? data.fileSize: parseInt(data.fileSize);

            
        } catch (error) {
            console.error("Unable to convert library file info ", error);
        }

        return fileInfo;
    }

}