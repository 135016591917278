import { Adapter } from 'src/app/common/interfaces/adapter';
import { Injectable } from '@angular/core';
export class LibraryActivityInfo {

    id: string;
    name: string;
    questionsCount: number;


    

}

@Injectable({
    providedIn: 'root'
})
export class LibraryActivityInfoAdapter implements Adapter<LibraryActivityInfo> {
    adapt(data: any): LibraryActivityInfo  {

        let activityInfo = new LibraryActivityInfo();

        try {

            activityInfo.id = (typeof data.id === 'string') ? data.id: null;
            activityInfo.name = (typeof data.name === 'string') ? data.name: null;
            activityInfo.questionsCount = (typeof data.questionsCount === 'number') ? data.questionsCount: null;

            
        } catch (error) {
            console.error("Unable to convert url object", error);
        }

        return activityInfo;
    }

}