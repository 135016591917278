import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { UserProfileService } from 'src/app/main/my-profile/data-providers/user-profile.service';
import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';

@Component({
  selector: 'app-profile-enable-confirmation-dialog',
  templateUrl: './profile-enable-confirmation-dialog.component.html',
  styleUrls: ['./profile-enable-confirmation-dialog.component.scss']
})
export class ProfileEnableConfirmationDialogComponent implements OnInit {
  loading: boolean = false;

  constructor(private _dialog: MatDialog,
    private _authenticationService: AuthenticationService,
    private _profileService: UserProfileService,
    private _snackBar: MatSnackBar,
    private _router: Router,) { }

  ngOnInit(): void {
  }

  cancelProfileConfirmation() {
    this._authenticationService.logout();
    this.closeDialog();
  }

  closeDialog() {
    const dialogRef = this._dialog.getDialogById('profile_enable_confirmation_dialog');
    if (dialogRef)
      dialogRef.close();
  }

  async enableProfileConfirmation() {
    try {

      this.loading = true;
      const response = await this._profileService.updateProfileStatus(true);

      if (response.success) {
        this.showSnackBar(`Account enabled successfully`, "OK");
        this.closeDialog();
        this._router.navigate(['/main/home']).then(() => {
          this.loading = false;
        })
      }

      else {
        this.showError("Unable to activate your account. Please try again");
        this.loading = false;
      }

    } catch (error) {
      this.showError(error);
      this.loading = false;

    }
  }

  showSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 10000 });
  }

  showError(error: string) {

    const dialogRef = this._dialog.open(InformationDialogComponent, {
      width: '600px',
      id: 'information_dialog'
    })

    dialogRef.componentInstance.content = error;
  }

}
