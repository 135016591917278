import { CommonService } from 'src/app/common/service/common.service';
import { Subscription } from 'rxjs';
import { LibrarySortOrder, LibrarySortField, LibrarySort } from './../../helper/library.constant';
import { LibraryNode } from './../../models/library-node.model';
import {LibraryNodeType} from '../../helper/library.constant';
import { Component, Input, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { MainService } from 'src/app/main/main.service';
import { Category } from '../../models/library-file-info.model';

@Component({
  selector: 'app-library-grid-view',
  templateUrl: './library-grid-view.component.html',
  styleUrls: ['./library-grid-view.component.scss']
})
export class LibraryGridViewComponent implements OnInit, OnDestroy {

  @Input() nodes?: LibraryNode[];
  @Input() loading: boolean = false;
  @Input() selectedNode?: LibraryNode;
  @Input() sort?: LibrarySort;
  
  fxFlex_fileCard: number = 20;

  nodeType = LibraryNodeType;
  nodeFileCategoryType = Category;
  clickTimer: any;

  sortOrderArray: Map<string, number>= new Map<string, number>([
    ["folder", 1],
    ["activity", 2],
    ["file", 3],
    ["link", 4]
  ]);

  sortOrder = 'order';

  @Output() nodeClickEvent = new EventEmitter<LibraryNode>();
  @Output() nodeDoubleClickEvent = new EventEmitter<LibraryNode>();
  @Output() sortEvent = new EventEmitter<LibrarySort>();

  constructor(public mainService: MainService,
    private _commonService: CommonService) { }
  
  
  ngOnDestroy(): void {
    
    if (this.nodeClickEvent)
      this.nodeClickEvent.unsubscribe();
    
    if (this.nodeDoubleClickEvent)
      this.nodeDoubleClickEvent.unsubscribe();

    if (this.sortEvent)
      this.sortEvent.unsubscribe();
  }

  ngOnInit(): void {



  }
  showLibraryView() {
    // this.libraryService.libraryType = type;
    this.mainService.isLibraryInfoSidebar = true;
    this.mainService.sidebarOpened();
    this.mainService.isCardInfo = false;
    this.mainService.isMessageInfo = false;
    this.mainService.isInviteInfo = false;
    this.mainService.isNotificationInfo = false;
    this.mainService.libraryFlex_lg = 70;
    this.mainService.fxFlex_fileCard = 33.3333;
  }

  nodeClick(node: LibraryNode) {

    this.clickTimer = setTimeout( () => {    
      this.emitClickEvent(node);
    }, 300); 
    

  }

  emitClickEvent(node: LibraryNode) {
    if (!this.clickTimer) return;
    this.nodeClickEvent.emit(node);
  }

  nodeDoubleClick(node: LibraryNode) {

    console.log("Double click");
    clearTimeout(this.clickTimer);
    this.clickTimer = undefined;
    this.nodeDoubleClickEvent.emit(node);
  }



}
