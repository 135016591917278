<div class="card-info-main">

  <section class="topheader">
  <app-library-node-actions  [node]="_node"></app-library-node-actions>



  <div class="heading-right-icons" >
    <!--<button mat-icon-button class="mr-10" matTooltip="Rename" aria-label="Rename">
              <mat-icon>drive_file_rename_outline
              </mat-icon>
          </button>-->
    <button mat-icon-button (click)="nodeDoubleClick(node)">
      <mat-icon>open_in_full</mat-icon>
    </button>

    <!-- <app-library-node-actions [node]="node"></app-library-node-actions> -->

    <button mat-icon-button class="panelClose" (click)="closeSidebar()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</section>
  <div
    class="card-info-header"
    fxLayout="row wrap"
    fxLayoutWrap="wrap"
    fxLayoutAlign="start center"
  >
    <div class="heading" fxLayoutAlign="start center">
      <!-- <div class="favorite-icon mr-20">
                <span class="icon icon-favourite-dark"></span>
            </div> -->

      <h3 class="h3-22-black two-lines" title="{{ node ? node.title : '' }}">
        {{ node ? node.title : "" }}
      </h3>

      <!-- <div class="favorite-icon mr-20">
                <span class="icon icon-favourite-dark"></span>
            </div> -->
      <!-- <div class="library-icon mr-20">
                <span class="icon icon-library-border-grey"></span>
            </div> -->
      <!-- <div class="copy-clipboard-icon mr-20">
                <span class="icon icon-copy-clipboard-grey"></span>
            </div>
            <div class="plus-icon mr-20 cursor-pointer">
                <span class="icon icon-plus-border-grey"></span>
                <div class="notes-number">
                    <span>12</span>
                </div>
            </div> -->
      <!-- <div class="url-icon mr-20">
                <span class="icon icon-copy-link-grey"></span>
            </div>
            <div class="share-icon mr-20">
                <span class="icon icon-share-grey"></span>
            </div> -->
    </div>
   
  </div>

  <!-- <div class="preview-header pb-10">
        <h3 class="h3-24-black pl-20 two-lines" title="{{node?node.title:''}}">{{node?node.title:''}}</h3>
    </div> -->

  <div class="card-info-body" *ngIf="node">
  
    <ng-container [ngSwitch]="node.type">
      <ng-container
      *ngSwitchCase="nodeType.file"
      [ngTemplateOutlet]="FileViewer1"
    ></ng-container>
      </ng-container>

      <ng-template #FileViewer1>
        <ng-container [ngSwitch]="node.fileInfo.viewer.viewerSource">
          <ng-container
          *ngSwitchCase="'image'"
          [ngTemplateOutlet]="ImageViewer"
        ></ng-container>
        <ng-template #ImageViewer>
          <img
            *ngIf="node.fileInfo && !loading"
            src="{{ node.fileInfo.location }}"
            class="imageviewer mainimgwrapper"
          />
        </ng-template>
          </ng-container>
          </ng-template>
  
    <mat-tab-group>
      <mat-tab label="Details">
        <div class="pt-20 pb-20" fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex="initial" fxLayoutAlign="start center">
            <span class="p-small-grey14">
              {{ node.createdAt | amDateFormat: "LLL" }}

              <ng-template
                [ngIf]="
                  node.type === nodeType.file &&
                  node.fileInfo &&
                  node.fileInfo.fileSize > 0
                "
              >
                &nbsp;&bull;
                {{ node.fileInfo.fileSize | formatFileSize: 1 }}
              </ng-template>

              <ng-template
                [ngIf]="
                  node.type === nodeType.link &&
                  node.urlInfo &&
                  node.urlInfo.meta.length > 0
                "
              >
                &nbsp;&bull;
                <ng-container
                  *ngFor="let metaInfo of node.urlInfo.meta; let last = last"
                >
                  <ng-container *ngIf="last; else remainingMeta">
                    {{ metaInfo }}
                  </ng-container>
                  <ng-template #remainingMeta>
                    {{ metaInfo }} &nbsp;&bull;
                  </ng-template>
                </ng-container>
              </ng-template>
            </span>
          </div>
        </div>

        <ng-container [ngSwitch]="node.type">
          <ng-container
            *ngSwitchCase="nodeType.folder"
            [ngTemplateOutlet]="FolderViewer"
          >
          </ng-container>
          <ng-container
            *ngSwitchCase="nodeType.file"
            [ngTemplateOutlet]="FileViewer"
          ></ng-container>
          <ng-container
            *ngSwitchCase="nodeType.link"
            [ngTemplateOutlet]="LinkViewer"
          ></ng-container>
          <ng-container
            *ngSwitchCase="nodeType.activity"
            [ngTemplateOutlet]="ActivityViewer"
          ></ng-container>

          <ng-template #FolderViewer> </ng-template>

          <ng-template #ActivityViewer>
            <app-questionnaire-run
              [activityId]="node.activityInfo.id"
            ></app-questionnaire-run>
          </ng-template>

          <ng-template #FileViewer>
            <ng-container [ngSwitch]="node.fileInfo.viewer.viewerSource">
              <ng-container
                *ngSwitchCase="'google'"
                [ngTemplateOutlet]="GoogleViewer"
              ></ng-container>
              <ng-container
                *ngSwitchCase="'office'"
                [ngTemplateOutlet]="OfficeViewer"
              ></ng-container>
              <!-- <ng-container
                *ngSwitchCase="'image'"
                [ngTemplateOutlet]="ImageViewer"
              ></ng-container> -->
              <ng-container
                *ngSwitchCase="'video'"
                [ngTemplateOutlet]="VideoViewer"
              ></ng-container>

              <div class="card-post">
                <ng-template #GoogleViewer>
                  <ngx-doc-viewer
                    *ngIf="node.fileInfo && !loading"
                    [url]="node.fileInfo.location"
                    viewer="google"
                    style="width: 100%; height: 70vh"
                  ></ngx-doc-viewer>
                </ng-template>

                <ng-template #OfficeViewer>
                  <ngx-doc-viewer
                    *ngIf="node.fileInfo && !loading"
                    [url]="node.fileInfo.location"
                    viewer="office"
                    style="width: 100%; height: 70vh"
                  ></ngx-doc-viewer>
                </ng-template>

                <!-- <ng-template #ImageViewer>
                  <img
                    *ngIf="node.fileInfo && !loading"
                    src="{{ node.fileInfo.location }}"
                    class="imageviewer"
                  />
                </ng-template> -->
                <!-- krishna code-->

                <ng-template #VideoViewer>
                  <div class="video w-100" *ngIf="node.fileInfo && !loading">
                    <video autoplay preload="none" width="100%" controls>
                      <source [src]="node.fileInfo.location" />
                    </video>
                  </div>
                </ng-template>
              </div>
            </ng-container>
          </ng-template>

          <ng-template #LinkViewer>
            <ng-container *ngIf="node.urlInfo.content; else noContent">
              <article *ngIf="!loading">
                <div
                  [ngClass]="node.urlInfo.articleClass"
                  [innerHTML]="node.urlInfo.content | safe: 'html'"
                ></div>
              </article>
            </ng-container>
            <ng-template #noContent>
              <div class="cannot-show">
                <p class="pb-10 align-center">
                  <span class="icon-nopreview"></span>
                  Cannot show preview of this link.
                </p>
                <button
                  *ngIf="!loading"
                  mat-raised-button
                  (click)="openLink()"
                  class="btn-submit roundedBtn"
                >
                  Open in new tab
                  <mat-icon>open_in_new</mat-icon>
                </button>
              </div>
            </ng-template>
          </ng-template>
        </ng-container>
      </mat-tab>
      <ng-container *ngIf="node.type !== nodeType.folder">
        <mat-tab label="Notes">
          <div class="pt-20">
            <app-notes [node]="node"></app-notes>
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>
</div>
