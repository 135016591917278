import { Component, OnInit, Output, EventEmitter, OnDestroy, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit, OnDestroy {


  title: string;
  content: string;
  showCancelButton : boolean = true;
  showLoader: boolean = false;
  primaryButtonTitle: string; 
  cancelButtonTitle?: string = "Cancel"; // TODO: This has to be changed to Language variable
  primaryButtonClickHandler : EventEmitter<any> = new EventEmitter<any>(); 

  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { 

      this.primaryButtonTitle = data.primaryButtonTitle;
      this.title = data.title;
      this.content = data.content;

    }
  
  
  ngOnDestroy(): void {
    if (this.primaryButtonClickHandler)
      this.primaryButtonClickHandler.unsubscribe();
  }

  ngOnInit() {
  }

  

  primaryButtonClicked() {

    if (this.primaryButtonClickHandler != null) {
      this.primaryButtonClickHandler.emit(null);
    }

  }

  close() {
    const dialogRef = this.dialog.getDialogById('confirmation_dialog');
    if (dialogRef)
      dialogRef.close();
  }
  

}
