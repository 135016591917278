import { Adapter } from '../../../common/interfaces/adapter';
import { Injectable } from '@angular/core';

export class Avatar {
    id: string;
    url: string;
    loading: boolean = false;
}

@Injectable({
    providedIn: 'root'
})

export class AvatarAdapter implements Adapter<Avatar> {
    adapt(avatarObject: any): Avatar {

        let avatarObj: Avatar = new Avatar();

        try {

            avatarObj.id = (typeof avatarObject.id === 'string') ? avatarObject.id : null;
            avatarObj.url = (typeof avatarObject.link === 'string') ? avatarObject.link : null;



        } catch (error) {
            console.error("Unable to convert avatar ", error);
        }

        return avatarObj;
    }

}