import { NewFolderDialogComponent } from './../new-folder-dialog/new-folder-dialog.component';
import { LibraryListResponse, LibraryService } from './../../data-providers/library.service';
import { LibraryNode } from './../../models/library-node.model';
import { LibrarySort, LibrarySortOrder, LibrarySortField, LibraryNodeType } from './../../helper/library.constant';
import { LibraryViewType } from './../../../../common/constants';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewLinkDialogComponent } from '../new-link-dialog/new-link-dialog.component';
import { UploadFilesDialog } from '../library.component';
import { NewActivityDialogComponent } from '../new-activity-dialog/new-activity-dialog.component';

export class LibraryPickerNavigationItem {
  parent?: string
  title: string
  pageType?: string
}


@Component({
  selector: 'app-library-picker',
  templateUrl: './library-picker.component.html',
  styleUrls: ['./library-picker.component.scss']
})

export class LibraryPickerComponent implements OnInit, OnDestroy {

  @Input('keyword') keyword: string;
  @Output() nodeSelectedEvent = new EventEmitter<LibraryNode>();
  @Output() moveEvent = new EventEmitter<string | null>();
  @Output() closeEvent = new EventEmitter();
  @Input() autoSelectNewlyAddedNode : boolean = false;
  @Input() showMoveToolbar: boolean = false;
  @Input() disabledNodes: string[] = [];
  @Input() targetNodeId: string;
  @Input() canCreateFolder: boolean = true;
  @Input() canUploadFile: boolean = true;
  @Input() canAddLink: boolean = true;

  @Input() allowedTypes = [LibraryNodeType.folder, LibraryNodeType.file, LibraryNodeType.link, LibraryNodeType.activity];


  defaultTitle = "Import from Library";
  navigationStack: LibraryPickerNavigationItem[] = []
  currentNavigationItem: LibraryPickerNavigationItem | undefined;

  viewType: LibraryViewType;

  viewTypeEnum = LibraryViewType;
  fetchingNodes: boolean = false;
  submissionInProgress: boolean = false;


  errorMessage?: string;
  errorMessageButtonTitle: string = "Retry";
  showError: boolean = false;

  nodes?: LibraryNode[];
  selectedNode?: LibraryNode;
  sort?: LibrarySort;

  parentNode: LibraryNode;

  constructor(
    private libraryService: LibraryService,
    private _dialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.viewType = LibraryViewType.List;
    this.sort = this.getSortFromLocalStorage();
    this.openRoot();
  }

  openRoot() {
    this.clearNavigationStack();
    const navigationItem = new LibraryPickerNavigationItem();
    navigationItem.parent = undefined;
    navigationItem.title = this.defaultTitle;

    this.push(navigationItem);
  }


  newActivityTapped() {
    const thisReference = this;
    const dialogRef = this._dialog.open(NewActivityDialogComponent, {
        width: '600px',
        height: '90vh',
        id: "new_activity_dialog",
        data: { parentNode: this.parentNode }
    });

    const dialogResultSubscription = dialogRef.componentInstance.activityCreatedEvent.subscribe(result => {
        console.log('Got the event from create folder popup', result);
        dialogResultSubscription.unsubscribe();

        thisReference.loadLibrary();

        if (this.autoSelectNewlyAddedNode && result instanceof LibraryNode) {
          this.nodeClick(result);
        }
    });


}

  sortClick(_sort: LibrarySort) {
    if (_sort) {
      this.sort = _sort;
      this.loadLibrary();
    }
  }

  loadLibrary() {

    if (this.keyword && this.keyword.length > 0) {
      this.searchLibrary(this.keyword);
    }
    else {
      this.getLibrary(this.currentNavigationItem?.parent, this.sort);
    }
  }

  getSortFromLocalStorage(): LibrarySort | undefined {

    if (this.keyword && this.keyword.length > 0) {
      // Nothing to do. Defaults to list
      return undefined;
    }
    else {
      const lso = localStorage.getItem('lso');
      const lsk = localStorage.getItem('lsk');

      let _sort: LibrarySort | undefined = undefined;
      if (lso && lsk) {
        _sort = { sortOrder: (<any>LibrarySortOrder)[lso], sortBy: (<any>LibrarySortField)[lsk] }
      }
      return _sort;
    }
  }

  getViewType(): LibraryViewType {

    if (this.viewType) {
      return this.viewType;
    }


    let lViewType: LibraryViewType = LibraryViewType.List;
    if (this.keyword && this.keyword.length > 0) {
      // Nothing to do. Defaults to list
    }
    else {
      let vt = localStorage.getItem('lvt');
      if (vt) {
        lViewType = (<any>LibraryViewType)[vt];
      }
    }

    return lViewType;
  }

  push(item: LibraryPickerNavigationItem) {

    this.currentNavigationItem = item;
    this.navigationStack.push(item);

    console.log("Stack", this.navigationStack);

    this.loadLibrary();
  }

  pop() {

    if (this.navigationStack.length == 1) {
      console.error("Nothing to remove from stack", this.navigationStack);
      return;
    }

    this.navigationStack.splice((this.navigationStack.length - 1), 1);
    this.currentNavigationItem = this.navigationStack[this.navigationStack.length - 1];

    console.log("Stack", this.navigationStack);

    this.loadLibrary();
  }

  clearNavigationStack() {
    this.navigationStack = []
    this.currentNavigationItem = undefined;
  }

  async getLibrary(parent: string | undefined, _sort: LibrarySort | undefined) {

    this.nodes = [];
    this.fetchingNodes = true;
    this.showError = false;
    this.errorMessage = undefined;

    try {
      let response: LibraryListResponse = await this.libraryService.getNodes(parent, _sort);

      if (response.success) {
        this.nodes = response.nodes;
        this.sort = response.sort;

        this.storeSort();

        if (this.nodes && Array.isArray(this.nodes) && this.nodes.length > 0) {

          this.showError = false;
        }
        else {
          this.errorMessageButtonTitle = "Refresh";
          this.errorMessage = "No files/links/folders available";
          this.showError = true;
        }
      }
      else {
        this.errorMessage = "Oops,something went wrong. Please try again";
        this.showError = true;
        this.errorMessageButtonTitle = "Retry";
      }

      this.fetchingNodes = false;


    } catch (error) {
      this.nodes = []
      this.fetchingNodes = false;
      this.errorMessage = "Oops,something went wrong. Please try again";
      this.showError = true;
      this.errorMessageButtonTitle = "Retry";
    }
  }

  async searchLibrary(_keyword: string) {

    this.nodes = [];
    this.fetchingNodes = true;
    this.showError = false;
    this.errorMessage = undefined;

    try {
      let response: LibraryListResponse = await this.libraryService.searchNodes(_keyword);

      if (response.success) {
        this.nodes = response.nodes;
        this.sort = response.sort;

        if (this.nodes && Array.isArray(this.nodes) && this.nodes.length > 0) {

          this.showError = false;
        }
        else {

          this.errorMessageButtonTitle = "Refresh";
          this.errorMessage = "No files/links/folders matched your search";
          this.showError = true;

        }



      }
      else {
        this.errorMessage = "Oops,something went wrong. Please try again";
        this.showError = true;
        this.errorMessageButtonTitle = "Retry";
      }

      this.fetchingNodes = false;


    } catch (error) {
      this.nodes = []
      this.fetchingNodes = false;
      this.errorMessage = "Oops,something went wrong. Please try again";
      this.showError = true;
      this.errorMessageButtonTitle = "Retry";
    }
  }


  nodeClick(_selectedNode: LibraryNode) {

    this.selectedNode = _selectedNode;

    this.nodeSelectedEvent.next(this.selectedNode);

  }

  nodeDoubleClick(_selectedNode: LibraryNode) {

    if (_selectedNode.type == LibraryNodeType.folder) {
      // redirect to next component
      const navigationItem = new LibraryPickerNavigationItem();
      navigationItem.parent = _selectedNode.id;
      navigationItem.title = _selectedNode.title;

      this.push(navigationItem);

    }
    else if (_selectedNode.type == LibraryNodeType.link || _selectedNode.type == LibraryNodeType.file) {

      //TODO: May be a preview


    }
  }

  storeSort() {
    if (this.sort) {
      localStorage.setItem('lso', this.sort.sortOrder);
      localStorage.setItem('lsk', this.sort.sortBy);
    }

  }

  goBack() {
    this.pop();
  }

  closeClick() {
    this.closeEvent.next();
  }

  // folder popup
  newFolderTapped() {
    const thisReference = this;
    const dialogRef = this._dialog.open(NewFolderDialogComponent, {
        width: '600px',
        id: "new_folder_dialog",
        data: { parentNode: this.parentNode }
    });

    const dialogResultSubscription = dialogRef.componentInstance.folderCreatedEvent.subscribe(result => {
        console.log('Got the event from create folder popup', result);
        dialogResultSubscription.unsubscribe();

        thisReference.loadLibrary();
    });


}


  newURLTapped() {

    const thisReference = this;
    const dialogRef = this._dialog.open(NewLinkDialogComponent, {
      width: '600px',
      id: "new_link_dialog",
      data: { parentNode: this.parentNode }
    });

    const dialogResultSubscription = dialogRef.componentInstance.linkSavedEvent.subscribe(result => {
      console.log('Got the event from save link popup', result);
      dialogResultSubscription.unsubscribe();
      thisReference.loadLibrary();

      if (this.autoSelectNewlyAddedNode && result instanceof LibraryNode) {
        this.nodeClick(result);
      }
      
    });

  }

  onFileChange(event: any) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];


      this.openUploadFilesPopup(file);
    }

  }

  // upload files popup
  openUploadFilesPopup(file: File) {

    if (file) {

      const thisReference = this;

      const dialogRef = this._dialog.open(UploadFilesDialog, {
        width: '600px',
        id: "upload_file_dialog",
        data: { file: file, parentNode: this.parentNode }
      });

      const dialogResultSubscription = dialogRef.componentInstance.fileUploadedEvent.subscribe(result => {
        console.log('Got the event from upload popup', result);
        dialogResultSubscription.unsubscribe();

        thisReference.loadLibrary();

        if (this.autoSelectNewlyAddedNode && result instanceof LibraryNode) {
          this.nodeClick(result);
        }
      });

    }
    else {
      console.error("No files to upload");
    }

  }

  searchButtonClicked(value: string) {
    this.keyword = value;
    this.clearNavigationStack();

    const navigationItem = new LibraryPickerNavigationItem();
    navigationItem.parent = undefined;
    navigationItem.title = "Search Results for " + this.keyword;
    navigationItem.pageType = "s_r"
    this.push(navigationItem);
    
  }

  clearSearch() {
    this.keyword = '';
    this.openRoot();
  }
  
  get canDisableMove(): boolean {

    const isSearch = (this.currentNavigationItem && this.currentNavigationItem.pageType)?this.currentNavigationItem.pageType === 's_r':false;
    const hasNode = (this.nodes && this.nodes.length > 0)? this.nodes.some(n => (n.id === this.targetNodeId)): false;

    return (this.selectedNode && this.selectedNode.id)?this.submissionInProgress: (isSearch || hasNode || this.submissionInProgress || this.fetchingNodes);

  }

  moveTapped() {

    if (!this.selectedNode && !this.currentNavigationItem) 
      return;

    this.moveEvent.next((this.selectedNode && this.selectedNode.id)?this.selectedNode.id: this.currentNavigationItem? this.currentNavigationItem.parent: null);

  }


  public ngOnDestroy(): void {
    console.log("Destroying library component");

    if (this.nodeSelectedEvent)
      this.nodeSelectedEvent.unsubscribe();

    if (this.moveEvent)
      this.moveEvent.unsubscribe();

    if (this.closeEvent)
      this.closeEvent.unsubscribe();

    if (this.navigationStack)
      this.navigationStack = []
  }

}
