import { TagAdapter } from "src/app/shared/models/tag.model";
import { Tag } from "./../../../shared/models/tag.model";
import {
  LearningSpace,
  LearningSpaceAdapter,
  LearningSpaceCount,
  LearningSpaceCountAdapter,
} from "./../models/learning-space.model";
import {
  HOME_SPACE_RESULTS_PER_PAGE,
  SpaceContext,
  SpaceFilter,
  SpaceSort,
  SpaceType,
} from "./../helper/space.constant";
import { StatusCodes } from "http-status-codes";
import { map } from "rxjs/operators";
import { catchError } from "rxjs/operators";
import { of } from "rxjs";
import {
  LearningSpaceBlock,
  LearningSpaceBlockAdapter,
} from "./../models/learning-space-block.model";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  SpaceTemplate,
  SpaceTemplateAdapter,
} from "../models/space-template.model";
import { AckResponse, DeleteResponse } from "src/app/common/constants";
import { E } from "@angular/cdk/keycodes";

export interface SpaceListResponse {
  spaces?: LearningSpace[];
  replicaSpaces?: LearningSpace[];
  sort?: SpaceSort;
  success: boolean;
  code?: string;
  tags?: Tag[];
}

export interface SpaceResponse {
  space?: LearningSpace;
  success: boolean;
  code?: string;
}

export interface BlockResponse {
  block?: LearningSpaceBlock;
  success: boolean;
  code?: string;
}

export interface SpaceBlockListResponse {
  blocks?: LearningSpaceBlock[];
  success: boolean;
  code?: string;
}

export interface TemplateResponse {
  templates?: SpaceTemplate[];
  success: boolean;
  code?: string;
}

export interface LearningSpaceMutateResponse {
  space?: LearningSpace;
  success: boolean;
  code?: string;
}
export interface SpaceCountResponse {
  space?: LearningSpaceCount;
  success: boolean;
  code?: string;
}

@Injectable({
  providedIn: "root",
})
export class SpaceService {
  apiUrl: string;
  constructor(
    private http: HttpClient,
    private _spaceAdapter: LearningSpaceAdapter,
    private _blockAdapter: LearningSpaceBlockAdapter,
    private _templateAdapter: SpaceTemplateAdapter,
    private _spaceCountAdapter: LearningSpaceCountAdapter
  ) {
    if (environment.hasOwnProperty("apiUrl")) this.apiUrl = environment.apiUrl;
  }

  getSpaceBlocks(
    spaceID: string,
    isUserLoggedIn: boolean
  ): Promise<SpaceBlockListResponse> {
    let url = `${this.apiUrl}/learning-spaces`;

    if (isUserLoggedIn) {
      url += `/${spaceID}/blocks`;
    } else {
      url += `/public/${spaceID}/blocks`;
    }

    return this.http
      .get(url, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          let responseStatus = response.status;

          let responseData: any = response.body;

          if (
            responseStatus == StatusCodes.OK ||
            responseStatus === StatusCodes.NO_CONTENT
          ) {
            if (responseData != null && Array.isArray(responseData)) {
              let blocks = responseData.map((_block: any) =>
                this._blockAdapter.adapt(_block)
              );
              return { blocks: blocks, success: true };
            } else {
              return { success: true };
            }
          } else {
            return { success: false };
          }
        }),
        catchError((err) => {
          const responseObject: SpaceBlockListResponse = {
            success: false,
            code: err,
          };

          return of(responseObject);
        })
      )
      .toPromise();
  }

  getTemplates(): Promise<TemplateResponse> {
    let url = `${this.apiUrl}/learning-spaces/templates`;

    return this.http
      .get(url, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          let responseStatus = response.status;

          let responseData: any = response.body;

          if (
            responseStatus == StatusCodes.OK ||
            responseStatus == StatusCodes.NO_CONTENT
          ) {
            if (responseData != null && Array.isArray(responseData)) {
              let templates = responseData.map((_template: any) =>
                this._templateAdapter.adapt(_template)
              );
              return { templates: templates, success: true };
            } else {
              return { success: true };
            }
          } else {
            return { success: false };
          }
        }),
        catchError((err) => {
          const responseObject: TemplateResponse = {
            success: false,
            code: err,
          };

          return of(responseObject);
        })
      )
      .toPromise();
  }

  getSpaces(
    context: SpaceContext,
    userId: string,
    page: number,
    sort?: SpaceSort,
    keyword?: string,
    spaceType?: SpaceType,
    contextData?: any,
    filters?: SpaceFilter[]
  ): Promise<SpaceListResponse> {
    let module = "learning-spaces";
    if (spaceType && spaceType == SpaceType.learningpath) {
      module = "learning-paths";
    } else if (
      context === SpaceContext.BYNDR_SPACE ||
      context === SpaceContext.ADD_LEARNING_PATH_SPACE ||
      context === SpaceContext.PUBLIC_SPACE
    ) {
      module = "home-feed";
    }

    let url = `${this.apiUrl}/${module}`;
    let queryParams: HttpParams | undefined;
console.log(context)
    switch (context) {
      case SpaceContext.MY_SPACE:
      case SpaceContext.ADD_SPACE_TO_LEARNING_PATH:
        url += "/mine";
        break;
      case SpaceContext.SHARED_SPACE:
        url += "/shared-with-me";
        break;
      case SpaceContext.BOOKMARKED_SPACE:
        url += "/bookmarked";
        break;
      case SpaceContext.USER_PROFILE_SPACE:
        url += `/of-user/${userId}`;
        break;
      case SpaceContext.SEARCH_RESULTS_SPACE:
        url += `/search/${keyword}`;
        break;
      case SpaceContext.BYNDR_SPACE:
      case SpaceContext.ADD_LEARNING_PATH_SPACE:
        url += `?offset=${
          (page - 1) * HOME_SPACE_RESULTS_PER_PAGE
        }&limit=${HOME_SPACE_RESULTS_PER_PAGE}`;
        if (filters && filters.length > 0) {
          queryParams = new HttpParams();
          filters.forEach((f, index, a) => {
            if (queryParams) {
              queryParams = queryParams.set(f.type, f.data);
            }
          });
        }
        break;
      case SpaceContext.PUBLIC_SPACE:
        url += `/public-website`;
        break;
      case SpaceContext.LEARNING_PATH_SPACE:
        url += `/${contextData.lspID}/spaces`;
        break;

      default:
        break;
    }

    let paramString: string | undefined = undefined;

    if (sort) {
      paramString = "?";
    }

    // Append Sort
    if (sort && sort.sortOrder) {
      paramString +=
        "sortBy=" + sort.sortBy + "&" + "sortOrder=" + sort.sortOrder;
    }

    if (paramString) {
      url += paramString;
    }

    return this.http
      .get(url, {
        observe: "response",
        params: queryParams,
      })
      .pipe(
        map((response) => {
          let responseStatus = response.status;

          let responseData: any = response.body;

          if (
            responseStatus == StatusCodes.OK ||
            responseStatus == StatusCodes.NO_CONTENT
          ) {
            if (responseData) {
              let array = responseData.learningSpaces
                ? responseData.learningSpaces
                : responseData.items;
              if (!array) {
                array = responseData;
              }
              if (array && Array.isArray(array)) {
                let spaces = array.map((_spaceObj: any) =>
                  this._spaceAdapter.adapt(_spaceObj)
                );
                let replicaSpaces: LearningSpace[] | undefined;

                if (context === SpaceContext.PUBLIC_SPACE) {
                  replicaSpaces = array.map((_spaceObj: any) =>
                    this._spaceAdapter.adapt(_spaceObj)
                  );
                }

                let sort: SpaceSort | undefined = undefined;
                if (responseData.meta) {
                  sort = {
                    sortBy: responseData.meta.sortBy,
                    sortOrder: responseData.meta.sortOrder,
                  };
                }

                // Parse Tags
                let overAllTags: Tag[] | undefined;
                if (
                  responseData.tags &&
                  Array.isArray(responseData.tags) &&
                  responseData.tags.length > 0
                ) {
                  let tagAdapter = new TagAdapter();
                  const tags = responseData.tags.map((tagData: any) =>
                    tagAdapter.adapt(tagData)
                  );
                  overAllTags = tags;
                }

                return {
                  spaces: spaces,
                  replicaSpaces: replicaSpaces,
                  sort: sort,
                  success: true,
                  tags: overAllTags,
                };
              } else {
                return { success: true };
              }
            } else {
              return { success: true };
            }
          } else {
            return { success: false };
          }
        }),
        catchError((err) => {
          const responseObject: SpaceListResponse = {
            success: false,
            code: err,
          };

          return of(responseObject);
        })
      )
      .toPromise();
  }

  getSpace(
    spaceID: string,
    isUserLoggedIn: boolean,
    _type: SpaceType
  ): Promise<SpaceResponse> {
    if (!spaceID) of({ code: "REQUIRED PARAMS MISSING", success: false });

    let module = "learning-spaces";
    if (_type && _type == SpaceType.learningpath) {
      module = "learning-paths";
    }
    let url = `${this.apiUrl}/${module}`;

    if (isUserLoggedIn) {
      url += `/${spaceID}`;
    } else {
      url += `/public/${spaceID}`;
    }

    return this.http
      .get(url, {
        observe: "response",
      })
      .pipe(
        map((response) => {
          let responseStatus = response.status;

          let responseData: any = response.body;

          if (
            responseStatus == StatusCodes.OK ||
            responseStatus == StatusCodes.NO_CONTENT
          ) {
            if (responseData) {
              let _space: LearningSpace =
                this._spaceAdapter.adapt(responseData);

              return { space: _space, success: true };
            } else {
              return { success: true };
            }
          } else {
            return { success: false };
          }
        }),
        catchError((err) => {
          const responseObject: SpaceResponse = {
            success: false,
            code: err,
          };

          return of(responseObject);
        })
      )
      .toPromise();
  }

  updateSpace(_space: LearningSpace): Promise<LearningSpaceMutateResponse> {
    if (!_space) of({ code: "REQUIRED PARAMS MISSING", success: false });

    let module = "learning-spaces";
    if (_space.type && _space.type == SpaceType.learningpath) {
      module = "learning-paths";
    }
    let url = `${this.apiUrl}/${module}/${_space.id}`;

    let body = _space.postDataForUpdate;

    return this.http
      .put<any>(url, body, {
        observe: "response",
      })
      .pipe(
        map(
          (updateResponse) => {
            let responseStatus = updateResponse.status;

            let responseData: any = updateResponse.body;

            if (responseStatus == StatusCodes.OK) {
              if (responseData != null) {
                return { space: _space, success: true };
              } else {
                return { success: false };
              }
            } else {
              return { success: false };
            }
          },
          catchError((error: any) => {
            const responseObject: LearningSpaceMutateResponse = {
              success: false,
              code: error,
            };

            return of(responseObject);
          })
        )
      )
      .toPromise();
  }

  createDraft(
    _publishedSpaceId: string,
    _type: SpaceType
  ): Promise<SpaceResponse> {
    if (!_publishedSpaceId)
      of({ code: "REQUIRED PARAMS MISSING", success: false });

    let module = "learning-spaces";
    if (_type && _type == SpaceType.learningpath) {
      module = "learning-paths";
    }

    let url = `${this.apiUrl}/${module}/${_publishedSpaceId}/edit`;

    return this.http
      .put<any>(
        url,
        {},
        {
          observe: "response",
        }
      )
      .pipe(
        map(
          (updateResponse) => {
            let responseStatus = updateResponse.status;

            let responseData: any = updateResponse.body;

            if (
              responseStatus == StatusCodes.OK ||
              responseStatus == StatusCodes.NO_CONTENT
            ) {
              if (responseData) {
                let _space: LearningSpace =
                  this._spaceAdapter.adapt(responseData);

                return { space: _space, success: true };
              } else {
                return { success: true };
              }
            } else {
              return { success: false };
            }
          },
          catchError((error: any) => {
            const responseObject: AckResponse = {
              success: false,
              code: error,
            };

            return of(responseObject);
          })
        )
      )
      .toPromise();
  }

  publishSpace(
    _spaceId: string,
    _selectedVisibility: string,
    _type: SpaceType
  ): Promise<AckResponse> {
    if (!_spaceId || !_selectedVisibility)
      of({ code: "REQUIRED PARAMS MISSING", success: false });

    let module = "learning-spaces";
    if (_type && _type == SpaceType.learningpath) {
      module = "learning-paths";
    }

    let url = `${this.apiUrl}/${module}/${_spaceId}/publish`;

    let queryParams = new HttpParams();

    // Begin assigning parameters
    queryParams = queryParams.append("publishType", _selectedVisibility);

    return this.http
      .put<any>(
        url,
        {},
        {
          observe: "response",
          params: queryParams,
        }
      )
      .pipe(
        map(
          (updateResponse) => {
            let responseStatus = updateResponse.status;

            let responseData: any = updateResponse.body;

            if (responseStatus == StatusCodes.OK) {
              if (responseData != null) {
                return { message: responseData.message, success: true };
              } else {
                return { success: false };
              }
            } else {
              return { success: false };
            }
          },
          catchError((error: any) => {
            const responseObject: AckResponse = {
              success: false,
              code: error,
            };

            return of(responseObject);
          })
        )
      )
      .toPromise();
  }

  deleteSpace(_spaceId: string, _type: SpaceType): Promise<AckResponse> {
    if (!_spaceId) of({ code: "REQUIRED PARAMS MISSING", success: false });

    let module = "learning-spaces";
    if (_type && _type == SpaceType.learningpath) {
      module = "learning-paths";
    }

    let url = `${this.apiUrl}/${module}/${_spaceId}`;

    return this.http
      .delete<any>(url, {
        observe: "response",
      })
      .pipe(
        map(
          (updateResponse) => {
            let responseStatus = updateResponse.status;

            let responseData: any = updateResponse.body;

            if (responseStatus == StatusCodes.OK) {
              if (responseData != null) {
                return { message: responseData.message, success: true };
              } else {
                return { success: false };
              }
            } else {
              return { success: false };
            }
          },
          catchError((error: any) => {
            const responseObject: AckResponse = {
              success: false,
              code: error,
            };

            return of(responseObject);
          })
        )
      )
      .toPromise();
  }

  mutateSpace(_space: LearningSpace): Promise<LearningSpaceMutateResponse> {
    if (!_space) of({ code: "REQUIRED PARAMS MISSING", success: false });

    let module = "learning-spaces";
    if (_space.type && _space.type == SpaceType.learningpath) {
      module = "learning-paths";
    }

    let url = `${this.apiUrl}/${module}`;

    let body = _space.postDataForMutation;

    return this.http
      .post<any>(url, body, {
        observe: "response",
      })
      .pipe(
        map(
          (createResponse) => {
            let responseStatus = createResponse.status;

            let responseData: any = createResponse.body;

            if (
              responseStatus == StatusCodes.OK ||
              responseStatus == StatusCodes.CREATED
            ) {
              if (responseData != null) {
                let _space = this._spaceAdapter.adapt(responseData);

                return { space: _space, success: true };
              } else {
                return { success: false };
              }
            } else {
              return { success: false };
            }
          },
          catchError((error: any) => {
            const responseObject: LearningSpaceMutateResponse = {
              success: false,
              code: error,
            };

            return of(responseObject);
          })
        )
      )
      .toPromise();
  }

  createBlock(
    _learningSpaceID: string,
    _block: LearningSpaceBlock
  ): Promise<BlockResponse> {
    if (!_learningSpaceID || _block) {
      of({ code: "REQUIRED PARAMS MISSING", success: false });
    }

    let apiUrl = `${this.apiUrl}/learning-spaces/${_learningSpaceID}/blocks`;

    return this.http
      .post<any>(apiUrl, _block.postData, {
        observe: "response",
      })
      .pipe(
        map(
          (response) => {
            let responseStatus = response.status;

            let responseData: any = response.body;

            if (responseStatus == StatusCodes.CREATED) {
              if (responseData != null) {
                let _block: LearningSpaceBlock =
                  this._blockAdapter.adapt(responseData);

                return { block: _block, success: true };
              } else {
                return { success: false };
              }
            } else {
              return { success: false };
            }
          },
          catchError((error: any) => {
            const responseObject: BlockResponse = {
              success: false,
              code: error,
            };

            return of(responseObject);
          })
        )
      )
      .toPromise();
  }

  updateBlock(
    _learningSpaceID: string,
    _block: LearningSpaceBlock
  ): Promise<AckResponse> {
    if (!_learningSpaceID || _block) {
      of({ code: "REQUIRED PARAMS MISSING", success: false });
    }

    let apiUrl = `${this.apiUrl}/learning-spaces/${_learningSpaceID}/blocks/${_block.id}`;

    return this.http
      .put<any>(apiUrl, _block.postData, {
        observe: "response",
      })
      .pipe(
        map(
          (response) => {
            let responseStatus = response.status;

            let responseData: any = response.body;

            if (responseStatus == StatusCodes.OK) {
              if (responseData != null) {
                const _message = responseData.message;
                return { message: _message, success: true };
              } else {
                return { success: false };
              }
            } else {
              return { success: false };
            }
          },
          catchError((error: any) => {
            const responseObject: AckResponse = {
              success: false,
              code: error,
            };

            return of(responseObject);
          })
        )
      )
      .toPromise();
  }

  deleteBlock(
    _learningSpaceID: string,
    _blockID: string
  ): Promise<DeleteResponse> {
    if (!_learningSpaceID || !_blockID) {
      of({ code: "REQUIRED PARAMS MISSING", success: false });
    }

    let apiUrl = `${this.apiUrl}/learning-spaces/${_learningSpaceID}/blocks/${_blockID}`;

    return this.http
      .delete<any>(apiUrl, {
        observe: "response",
      })
      .pipe(
        map(
          (response) => {
            let responseStatus = response.status;

            let responseData: any = response.body;

            if (responseStatus == StatusCodes.OK) {
              const _message = responseData.message;
              return { message: _message, success: true };
            } else {
              return { success: false };
            }
          },
          catchError((error: any) => {
            const responseObject: DeleteResponse = {
              success: false,
              code: error,
            };

            return of(responseObject);
          })
        )
      )
      .toPromise();
  }

  logView(_spaceId: string, _type: SpaceType): Promise<AckResponse> {
    if (!_spaceId) of({ code: "REQUIRED PARAMS MISSING", success: false });

    let module = "learning-spaces";
    if (_type && _type == SpaceType.learningpath) {
      module = "learning-paths";
    }

    let apiUrl = `${this.apiUrl}/${module}/${_spaceId}/view`;

    return this.http
      .put<any>(
        apiUrl,
        {},
        {
          observe: "response",
        }
      )
      .pipe(
        map(
          (response) => {
            let responseStatus = response.status;

            let responseData: any = response.body;

            if (responseStatus == StatusCodes.OK) {
              if (responseData != null) {
                return { message: responseData.message, success: true };
              } else {
                return { success: false };
              }
            } else {
              return { success: false };
            }
          },
          catchError((error: any) => {
            const responseObject: AckResponse = {
              success: false,
              code: error,
            };

            return of(responseObject);
          })
        )
      )
      .toPromise();
  }

  markDisplayOnWebsite(
    _spaceId: string,
    _spaceType: SpaceType,
    displayOnWebsite: boolean
  ): Promise<AckResponse> {
    if (!_spaceId) of({ code: "REQUIRED PARAMS MISSING", success: false });

    let module = "learning-spaces";
    // if (_spaceType && _spaceType == SpaceType.learningpath) {
    //   module = "learning-paths";
    // }

    let apiUrl = `${this.apiUrl}/${module}/${_spaceId}/display-in-website`;

    return this.http
      .patch<any>(
        apiUrl,
        {
          displayInWebsite: displayOnWebsite,
        },
        {
          observe: "response",
        }
      )
      .pipe(
        map(
          (response) => {
            let responseStatus = response.status;

            let responseData: any = response.body;

            if (responseStatus == StatusCodes.OK) {
              if (responseData != null) {
                return {
                  message: responseData.message,
                  success: true,
                  displayOnWebsite: displayOnWebsite,
                };
              } else {
                return { success: false };
              }
            } else {
              return { success: false };
            }
          },
          catchError((error: any) => {
            const responseObject: AckResponse = {
              success: false,
              code: error,
            };

            return of(responseObject);
          })
        )
      )
      .toPromise();
  }

  mutateLike(
    _spaceId: string,
    _spaceType: SpaceType,
    action: string
  ): Promise<AckResponse> {
    if (!_spaceId) of({ code: "REQUIRED PARAMS MISSING", success: false });

    let module = "learning-spaces";
    if (_spaceType && _spaceType == SpaceType.learningpath) {
      module = "learning-paths";
    }

    let apiUrl = `${this.apiUrl}/${module}/${_spaceId}/${action}`;

    return this.http
      .put<any>(
        apiUrl,
        {},
        {
          observe: "response",
        }
      )
      .pipe(
        map(
          (response) => {
            let responseStatus = response.status;

            let responseData: any = response.body;

            if (responseStatus == StatusCodes.OK) {
              if (responseData != null) {
                return { message: responseData.message, success: true };
              } else {
                return { success: false };
              }
            } else {
              return { success: false };
            }
          },
          catchError((error: any) => {
            const responseObject: AckResponse = {
              success: false,
              code: error,
            };

            return of(responseObject);
          })
        )
      )
      .toPromise();
  }

  bookmarkSpace(_spaceId: string, _spaceType: SpaceType): Promise<AckResponse> {
    if (!_spaceId) of({ code: "REQUIRED PARAMS MISSING", success: false });

    let url = `${this.apiUrl}/bookmarks`;

    return this.http
      .post<any>(
        url,
        {
          component:
            _spaceType === SpaceType.learningpath
              ? "learningpath"
              : "learningspace",
          componentId: _spaceId,
        },
        {
          observe: "response",
        }
      )
      .pipe(
        map(
          (bookmarkResponse) => {
            let responseStatus = bookmarkResponse.status;

            let responseData: any = bookmarkResponse.body;

            if (responseStatus == StatusCodes.OK) {
              if (responseData != null) {
                return { message: responseData.message, success: true };
              } else {
                return { success: false };
              }
            } else {
              return { success: false };
            }
          },
          catchError((error: any) => {
            const responseObject: AckResponse = {
              success: false,
              code: error,
            };

            return of(responseObject);
          })
        )
      )
      .toPromise();
  }

  removeBookmark(_spaceId: string): Promise<AckResponse> {
    if (!_spaceId) of({ code: "REQUIRED PARAMS MISSING", success: false });

    let url = `${this.apiUrl}/bookmarks/component/${_spaceId}`;

    return this.http
      .delete<any>(url, {
        observe: "response",
      })
      .pipe(
        map(
          (removeBookmarkResponse) => {
            let responseStatus = removeBookmarkResponse.status;

            let responseData: any = removeBookmarkResponse.body;

            if (responseStatus == StatusCodes.OK) {
              if (responseData != null) {
                return { message: responseData.message, success: true };
              } else {
                return { success: false };
              }
            } else {
              return { success: false };
            }
          },
          catchError((error: any) => {
            const responseObject: AckResponse = {
              success: false,
              code: error,
            };

            return of(responseObject);
          })
        )
      )
      .toPromise();
  }

  shareSpace(
    _spaceId: string,
    _spaceType: SpaceType,
    userIDs: string[]
  ): Promise<AckResponse> {
    if (!_spaceId || !userIDs)
      of({ code: "REQUIRED PARAMS MISSING", success: false });

    let url = `${this.apiUrl}/shares`;

    return this.http
      .post<any>(
        url,
        {
          component:
            _spaceType === SpaceType.learningpath
              ? "learningpath"
              : "learningspace",
          componentId: _spaceId,
          shareWith: userIDs,
        },
        {
          observe: "response",
        }
      )
      .pipe(
        map(
          (shareSpaceResponse) => {
            let responseStatus = shareSpaceResponse.status;

            let responseData: any = shareSpaceResponse.body;

            if (responseStatus == StatusCodes.OK) {
              if (responseData != null) {
                return { message: responseData.message, success: true };
              } else {
                return { success: false };
              }
            } else {
              return { success: false };
            }
          },
          catchError((error: any) => {
            const responseObject: AckResponse = {
              success: false,
              code: error,
            };

            return of(responseObject);
          })
        )
      )
      .toPromise();
  }

  appendSpaceToPath(spaceId: string, pathId: string): Promise<AckResponse> {
    if (!spaceId || !pathId)
      of({ code: "REQUIRED PARAMS MISSING", success: false });

    let url = `${this.apiUrl}/learning-paths/${pathId}/spaces/${spaceId}?autopublish=true`;

    return this.http
      .patch<any>(url, undefined, {
        observe: "response",
      })
      .pipe(
        map(
          (response) => {
            let responseStatus = response.status;

            let responseData: any = response.body;

            if (responseStatus == StatusCodes.OK) {
              if (responseData != null) {
                return { message: responseData.message, success: true };
              } else {
                return { success: false };
              }
            } else {
              return { success: false };
            }
          },
          catchError((error: any) => {
            const responseObject: AckResponse = {
              success: false,
              code: error,
            };

            return of(responseObject);
          })
        )
      )
      .toPromise();
  }

  postSpaceToPath(
    spaceIds: { LSPId: string; position: number }[],
    pathId: string
  ): Promise<AckResponse> {
    if (!spaceIds || !pathId)
      of({ code: "REQUIRED PARAMS MISSING", success: false });

    let url = `${this.apiUrl}/learning-paths/${pathId}/spaces-metadata`;

    return this.http
      .put<any>(url, spaceIds, {
        observe: "response",
      })
      .pipe(
        map(
          (shareSpaceResponse) => {
            let responseStatus = shareSpaceResponse.status;

            let responseData: any = shareSpaceResponse.body;

            if (responseStatus == StatusCodes.OK) {
              if (responseData != null) {
                return { message: responseData.message, success: true };
              } else {
                return { success: false };
              }
            } else {
              return { success: false };
            }
          },
          catchError((error: any) => {
            const responseObject: AckResponse = {
              success: false,
              code: error,
            };

            return of(responseObject);
          })
        )
      )
      .toPromise();
  }
















//  new code //

spaceCountView(countsSpaceType:SpaceType ): Promise<SpaceCountResponse> {
  

let url:string ='';
if(countsSpaceType == SpaceType.learningpath)
{
  url = `${this.apiUrl}/learning-paths/stats`;
}
else if( countsSpaceType == SpaceType.learningspace)
{
  url = `${this.apiUrl}/learning-spaces/stats`;
}
  
  

  return this.http
    .get(url, {
      observe: "response",
    })
    .pipe(
      map((response) => {
        let responseStatus = response.status;

        let responseData: any = response.body;
        if (responseData) {
          let _space: LearningSpaceCount =
            this._spaceCountAdapter.adapt(responseData);

          return { space: _space, success: true };
        } else {
          return { success: true };
        }
        return { success: true  };
      }),
      catchError((err) => {
        const responseObject: SpaceBlockListResponse = {
          success: false,
          code: err,
        };

        return of(responseObject);
      })
    )
    .toPromise();
}

// new code //


getTags(): Promise<any> {
  let url = `${this.apiUrl}/tags`;

  return this.http
    .get(url, {
      observe: "response",
    })
    .pipe(
      map((response) => {

        return { responseData: response, success: true };
      
      }),
      catchError((err) => {
        const responseObject: TemplateResponse = {
          success: false,
          code: err,
        };

        return of(responseObject);
      })
    )
    .toPromise();
}




}
