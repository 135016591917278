import { ConnectionChangeData } from './../../../common/service/common.service';
import { CommonService } from 'src/app/common/service/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InformationDialogComponent } from './../information-dialog/information-dialog.component';
import { UserProfileService } from 'src/app/main/my-profile/data-providers/user-profile.service';
import { UserConnectionRequestResponse } from './../../../main/my-profile/data-providers/user-profile.service';
import { REQUEST_TYPES } from './../../../main/my-profile/helper/user-profile.constant';
import { Router } from '@angular/router';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { UserProfile } from 'src/app/main/my-profile/models/user-profile.model';
import { AckResponse, CONNECTION_ACTIONS_SCREEN, DeleteResponse } from 'src/app/common/constants';

export const ACTION_TYPE_CONNECT = "connect";
export const ACTION_TYPE_ACCEPT_REQUEST = "accept_request";
export const ACTION_TYPE_CANCEL_REQUEST = "cancel_request";
export const ACTION_TYPE_DELETE_CONNECTION = "delete_connection";

@Component({
  selector: 'app-user-profile-connection-actions',
  templateUrl: './user-profile-connection-actions.component.html',
  styleUrls: ['./user-profile-connection-actions.component.scss']
})
export class UserProfileConnectionActionsComponent implements OnInit, OnDestroy {

  @Input() profile: UserProfile;
  @Input() screen: string;
  @Input() externalID: string | undefined;
  @Input() context: string | undefined;

  REQUEST_TYPE_RECEIVED = REQUEST_TYPES.REQUEST_TYPE_RECEIVED;
  REQUEST_TYPE_RAISED = REQUEST_TYPES.REQUEST_TYPE_RAISED;

  SCREEN_PROFILE = CONNECTION_ACTIONS_SCREEN.SCREEN_USER_PROFILE;
  SCREEN_CONNECTIONS = CONNECTION_ACTIONS_SCREEN.SCREEN_CONNECTIONS;
  SCREEN_SEARCH_CONNECTIONS = CONNECTION_ACTIONS_SCREEN.SCREEN_SEARCH_CONNECTION;
  SCREEN_REQUESTS = CONNECTION_ACTIONS_SCREEN.SCREEN_REQUESTS;

  actionSubmissionInProgress = false;
  actionType: string | undefined;
  showError = false;
  errorMessage: string | undefined;

  constructor(
    private _authenticationService: AuthenticationService,
    private _profileService: UserProfileService,
    private _snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _router: Router,
    private _commonService: CommonService
  ) { }
  

  ngOnInit(): void {


  }

  get layoutClass(): string {
    switch (this.screen) {
      case this.SCREEN_PROFILE:
        return "actions-column-profile";
      case this.SCREEN_SEARCH_CONNECTIONS:
      case this.SCREEN_REQUESTS:
      case this.SCREEN_CONNECTIONS:
        return "actions-row";
      default:
        break;
    }
    return "";
  }

  get currentUser(): boolean {

    if (!this.profile)
      return true;

    const accessToken = this._authenticationService.accessToken;
    const _userid = this._authenticationService.userID(accessToken)

    if (this.profile.id && !(this.profile.id === 'my-profile')) {
      if (_userid == this.profile.id) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  redirectToEditProfile() {

    if (this.currentUser) {
      this._router.navigate(['/main/profile/edit-profile']);
    }
  }

  redirectToProfile() {

    if (this.profile && this.profile.id) {
      this._router.navigate(['/main/profile/u/',this.profile.id]);
    }

  }


  connectTapped() {

    this.postConnectUser();

  }

  cancelConnectionRequest() {
    this.cancelRequest('cancel');
  }

  acceptConnectionRequest(event: Event) {
    event.stopPropagation();

    this.acceptRequest();

  }

  declineConnectionRequest(event: Event) {
    event.stopPropagation();
    this.cancelRequest('decline');
  }

  disconnectUser() {

    this.deleteConnection();

  }

  blockUser() {
    this.showErrorDialog("Coming soon....");
  }


  async postConnectUser() {

    const accessToken = this._authenticationService.accessToken;
    const _userid = this._authenticationService.userID(accessToken)

    if (!_userid || !this.profile.id) {
      return;
    }

    this.actionSubmissionInProgress = true;
    this.actionType = ACTION_TYPE_CONNECT;
    this.showError = false;
    this.errorMessage = undefined;


    try {
      let response: UserConnectionRequestResponse = await this._profileService.raiseConnectionRequestToUser(_userid, this.profile.id);
      if (response.success) {
        const requestObject: UserConnectionRequestResponse = response;

        if (requestObject.requestId) {

          this.profile.requestId = requestObject.requestId;
          this.profile.requestType = requestObject.requestType;

          this.showError = false;

          this.showSnackBar(`Request has been sent to ${this.profile.displayName}`, "OK");
 

          this.raiseEvent(ACTION_TYPE_CONNECT, this.externalID, this.context);

        }
        else {
          this.showErrorDialog(`Unable to raise request to ${this.profile.displayName}. Please try again`);
          this.showError = true;
        }
      }
      else {
        this.showErrorDialog("Oops,something went wrong. Please try again");
        this.showError = true;
      }

      this.actionSubmissionInProgress = false;
      this.actionType = undefined;

    } catch (error) {
      console.log("Unable to send connection request", error);
      this.actionSubmissionInProgress = false;
      this.actionType = undefined;
      this.showErrorDialog("Oops,something went wrong. Please try again");
      this.showError = true;
    }
  }

  async acceptRequest() {

    if (!this.profile.requestId) {
      return;
    }

    this.actionSubmissionInProgress = true;
    this.actionType = ACTION_TYPE_ACCEPT_REQUEST;
    this.showError = false;
    this.errorMessage = undefined;


    try {
      let response: AckResponse = await this._profileService.acceptRequest(this.profile.requestId);
      if (response.success) {

        this.profile.connected = true;
        this.profile.requestId = undefined;
        this.profile.requestType = undefined;

        this.showError = false;

        this.showSnackBar(`You and ${this.profile.displayName} are now connected.`, "OK");

        this.raiseEvent(ACTION_TYPE_CONNECT, this.externalID, this.context);

      }
      else {
        this.showErrorDialog("Unable to accept the request. Please try again");
        this.showError = true;
      }

      this.actionSubmissionInProgress = false;
      this.actionType = undefined;


    } catch (error) {
      console.log("Unable to send connection request", error);
      this.actionSubmissionInProgress = false;
      this.actionType = undefined;
      this.showErrorDialog("Oops,something went wrong. Please try again");
      this.showError = true;
    }

  }

  async deleteConnection() {

    if (!this.profile.id) {
      return;
    }

    this.actionSubmissionInProgress = true;
    this.actionType = ACTION_TYPE_DELETE_CONNECTION;
    this.showError = false;
    this.errorMessage = undefined;


    try {
      let response: DeleteResponse = await this._profileService.deleteConnection(this.profile.id);
      if (response.success) {

        this.profile.requestId = undefined;
        this.profile.requestType = undefined;
        this.profile.connected = false;

        this.showError = false;

        this.showSnackBar(`You and ${this.profile.displayName} are now disconnected`, "OK");

        this.raiseEvent(ACTION_TYPE_DELETE_CONNECTION, this.externalID, this.context);

      }
      else {
        this.showErrorDialog("Unable to disconnect. Please try again");
        this.showError = true;
      }

      this.actionSubmissionInProgress = false;
      this.actionType = undefined;


    } catch (error) {
      console.log("Unable to cancel connection request", error);
      this.actionSubmissionInProgress = false;
      this.actionType = undefined;
      this.showErrorDialog("Oops,something went wrong. Please try again");
      this.showError = true;
    }

  }


  async cancelRequest(context: string) {

    if (!this.profile.requestId) {
      return;
    }

    this.actionSubmissionInProgress = true;
    this.actionType = ACTION_TYPE_CANCEL_REQUEST;
    this.showError = false;
    this.errorMessage = undefined;


    try {
      let response: DeleteResponse = await this._profileService.deleteRequest(this.profile.requestId);
      if (response.success) {

        this.profile.requestId = undefined;
        this.profile.requestType = undefined;
        this.profile.connected = false;

        this.showError = false;

        if (context === "cancel"){
          this.showSnackBar(`Request has been cancelled.`, "OK");
        }
        else if (context === "decline"){
          this.showSnackBar(`Request has been declined.`, "OK");
        }

        this.raiseEvent(ACTION_TYPE_CANCEL_REQUEST, this.externalID, this.context);

      }
      else {
        if (context === "cancel"){
          this.showErrorDialog("Unable to cancel the request. Please try again");
        }
        else if (context === "decline"){
          this.showErrorDialog("Unable to decline the request. Please try again");
        }
        this.showError = true;
      }

      this.actionSubmissionInProgress = false;
      this.actionType = undefined;


    } catch (error) {
      console.log("Unable to cancel connection request", error);
      this.actionSubmissionInProgress = false;
      this.actionType = undefined;
      this.showErrorDialog("Oops,something went wrong. Please try again");
      this.showError = true;
    }

  }

  showErrorDialog(error: any) {

    const dialogRef = this.dialog.open(InformationDialogComponent, {
      width: '300px',
      id: 'information_dialog'
    })

    dialogRef.componentInstance.content = error

  }

  showSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, { duration: 10000 });
  }

  raiseEvent(eventType: string, externalID: string | undefined, context: string | undefined) {
    const data : ConnectionChangeData= {
      action: eventType,
      externalID: externalID,
      context: context
    }
    this._commonService.updateConnectionChangeEvent(data)
  }

  ngOnDestroy(): void {
    
  }



}
