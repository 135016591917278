import { Injectable } from "@angular/core";
import { Adapter } from "src/app/common/interfaces/adapter";

export class User {

    id: string;
    username: string;
    password: string;
    email: string;
    firstName: string;
    lastName: string;
    jwtToken?: string;
    refreshToken?: string;
}

@Injectable({
    providedIn: 'root'
})
export class UserAdapter implements Adapter<User> {
    adapt(data: any): User {

        let user = new User();


        user.jwtToken = (typeof data.token === 'string') ? data.token : null;
        user.refreshToken = (typeof data.refreshToken === 'string') ? data.refreshToken : null;
        user.username = (typeof data.username === 'string') ? data.username : null;



        return user;

    }
}