<!--<mat-form-field appearance="fill">
  <mat-label>Selected tab index</mat-label>
  <input matInput type="number" [formControl]="selected">
</mat-form-field>

<div>
  <button mat-raised-button
          class="example-add-tab-button"
          (click)="addTab(selectAfterAdding.checked)">
    Add new tab
  </button>
  <mat-checkbox #selectAfterAdding> Select tab after adding </mat-checkbox>
</div>

<mat-tab-group [selectedIndex]="selected.value"
               (selectedIndexChange)="selected.setValue($event)">
  <mat-tab *ngFor="let tab of tabs; let index = index" [label]="tab">
    Contents for {{tab}} tab

    <button mat-raised-button
            class="example-delete-tab-button"
            [disabled]="tabs.length === 1"
            (click)="removeTab(index)">
      Delete Tab
    </button>
  </mat-tab>
</mat-tab-group>-->
<div class="upload-files-main">
  <div [ngClass]="uploading === true? 'show-loading' : ''">
    <div class="pl-20 pt-10 pb-10 pr-20 filename-section" fxLayout="column" fxLayoutAlign="start center">

      <div fxLayout="row" class="w-100 block-section">
        <div fxLayout="column" class="w-100">
          <app-questionnaire-create [activityID]="activityID" (loadingComplete)="didLoadQuestionnaireData()"></app-questionnaire-create>
        </div>
      </div>

    </div>


  </div>
  <div *ngIf="!loading" class="p-20 buttons-section p-52 fixed-footer" >
    <div fxFlex fxLayoutAlign="end center">
      <button mat-raised-button class="btn-grey roundedBtn" mat-dialog-close [disabled]="uploading">Cancel</button>
      <button mat-raised-button class="btn-submit roundedBtn" (click)="submitTapped()" [disabled]="uploading">{{activityID?
        'Update': 'Create'}}</button>
      <mat-spinner *ngIf="uploading" [color]="color" diameter="25" strokeWidth="3"></mat-spinner>
    </div>
  </div>

</div>