import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToAlphabet'
})
export class NumberToAlphabetPipe implements PipeTransform {
  transform(numericValue: number, capital = true): string {

    const alphabetStartCharCode = capital ? 65: 97;

    // This will return A-Z or a-z
    return String.fromCharCode(alphabetStartCharCode + (numericValue - 1));

  }

  
}