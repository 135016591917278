import { AckResponse } from 'src/app/common/constants';
import { APP_PLATFORM } from './../../../common/constants';
import { LibrarySort } from './../helper/library.constant';
import { environment } from './../../../../environments/environment';
import { LibraryNode, LibraryNodeAdapter, LibraryNodeParentAdapter } from './../models/library-node.model';
import { LibraryNodeType } from '../helper/library.constant'
import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
    StatusCodes,
} from 'http-status-codes';
import { catchError } from 'rxjs/operators';

export interface LibraryListResponse {
    nodes?: LibraryNode[];
    sort?: LibrarySort;
    parent?: LibraryNode | undefined;
    success: boolean;
    code?: string;
}

export interface LibraryNodeResponse {
    node?: LibraryNode;
    success: boolean;
    code?: string;
}

export interface LibraryFileCountResponse {
    count?: number;
    success: boolean;
    code?: string;
}



@Injectable({
    providedIn: 'root',
})
export class LibraryService {
    libraryFilter = new Subject();
    libraryType: string = "";

    public apiUrl: string;

    constructor(private http: HttpClient, private nodeAdapter: LibraryNodeAdapter, private nodeParentAdapter: LibraryNodeParentAdapter) {

        if (environment.hasOwnProperty('apiUrl'))
            this.apiUrl = environment.apiUrl;
    }

    // Returns an observable
    uploadFile(file: File, title: string, parent?: string): Promise<LibraryNodeResponse> {


        if (!file || !title) {
            console.log("Unable to upload file", file, title);
            of(null);
        }

        // Create form data
        const formData = new FormData();

        // Store form name as "fileContent" with file data
        formData.append("fileContent", file, file.name);

        formData.append("platform", APP_PLATFORM);

        formData.append("title", title);

        if (parent) {
            formData.append("parent", parent);
        }

        return this.http.post<any>(`${environment.apiUrl}/library/node/upload`, formData, { observe: "response" })
            .pipe(map((uploadResponse) => {

                let responseStatus = uploadResponse.status;

                let responseData: any = uploadResponse.body

                if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.CREATED) {
                    if (responseData != null) {

                        let node = this.nodeAdapter.adapt(responseData);

                        return { node: node, success: true };
                    }
                    else {
                        return { success: false };
                    }
                }
                else {

                    return { success: false };
                }

            }, catchError((error: any) => {

                const responseObject: LibraryNodeResponse = {
                    success: false,
                    code: error
                }

                return of(responseObject)

            }))).toPromise();


    }

    createNode(nodeType: LibraryNodeType, title?: string, parent?: string, url?: string): Promise<LibraryNodeResponse> {

        let headers = new HttpHeaders({
            "Access-Control-Allow-Origin": "*",
        });

        let apiUrl = this.apiUrl + "/library/node";

        let body = {
            type: nodeType,
            platform: APP_PLATFORM
        }

        switch (nodeType) {
            case LibraryNodeType.link:
                body = { ...body, ...{ url: url } }
                break;
            case LibraryNodeType.folder:
                body = { ...body, ...{ title: title } }
                break;
            default:
                break;
        }

        if (parent) {
            body = { ...body, ...{ parent: parent } }
        }


        return this.http.post<any>(apiUrl,
            body, {
            headers: headers, observe: "response"
        }).pipe(map((createResponse) => {

            let responseStatus = createResponse.status;

            let responseData: any = createResponse.body

            if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.CREATED) {
                if (responseData != null) {

                    let node = this.nodeAdapter.adapt(responseData);

                    return { node: node, success: true };
                }
                else {
                    return { success: false };
                }
            }
            else {

                return { success: false };
            }

        }, catchError((error: any) => {

            const responseObject: LibraryNodeResponse = {
                success: false,
                code: error
            }

            return of(responseObject)

        }))).toPromise();



    }
    setParentsNodes(nodeData: any) {
        let node = this.nodeParentAdapter.adapt(nodeData);
        return { node: node, success: true };
    }


    getNode(nodeId: string): Promise<LibraryNodeResponse> {

        let headers = new HttpHeaders({
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json"
        });

        let url = this.apiUrl + "/library";

        if (nodeId) {
            url += "/node/" + nodeId
        }

        return this.http.
            get(url, {
                headers: headers,
                observe: "response"
            }).pipe(
                map(response => {
                    let responseStatus = response.status;

                    let responseData: any = response.body

                    if (responseStatus == StatusCodes.OK) {
                        if (responseData != null) {

                            let node = this.nodeAdapter.adapt(responseData);

                            return { node: node, success: true };
                        }
                        else {
                            return { success: false };
                        }
                    }
                    else {

                        return { success: false };
                    }
                }),
                catchError(err => {
                    const responseObject: LibraryNodeResponse = {
                        success: false,
                        code: err
                    }

                    return of(responseObject)
                })
            ).toPromise();
    }

    getFileCount(): Promise<LibraryNodeResponse> {

        let headers = new HttpHeaders({
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json"
        });

        let url = this.apiUrl + "/library/file/count";


        return this.http.
            get(url, {
                headers: headers,
                observe: "response"
            }).pipe(
                map(response => {
                    let responseStatus = response.status;

                    let responseData: any = response.body

                    if (responseStatus == StatusCodes.OK) {
                        if (responseData != null && responseData.hasOwnProperty("count")) {

                            let count = responseData.count;

                            return { count: count, success: true };
                        }
                        else {
                            return { success: false };
                        }
                    }
                    else {

                        return { success: false };
                    }
                }),
                catchError(err => {
                    const responseObject: LibraryNodeResponse = {
                        success: false,
                        code: err
                    }

                    return of(responseObject)
                })
            ).toPromise();
    }
    getNodesFolder(parent: string | undefined): Promise<LibraryListResponse> {

        let url = this.apiUrl + "/library";
        let params: [string: string];



        let paramString: string | undefined = undefined;

        paramString = "?nodeType=folder&"

        // Append Parent
        if (parent) {
            paramString += "parent=" + parent
        }



        if (paramString) {
            url += paramString;
        }


        return this.http.
            get(url, {
                observe: "response"
            }).pipe(
                map(response => {
                    let responseStatus = response.status;

                    let responseData: any = response.body

                    if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.NO_CONTENT) {
                        if (responseData != null && responseData.nodes && Array.isArray(responseData.nodes)) {


                            let nodes = responseData.nodes.map((node: any) => this.nodeAdapter.adapt(node));
                            let parentTitle = "";
                            let sort: LibrarySort | undefined = undefined;
                            let metaParent: any = "";
                            if (responseData.meta) {
                                sort = { sortBy: responseData.meta.sortBy, sortOrder: responseData.meta.sortOrder }
                            }

                            if (responseData.meta.parent) {
                                metaParent = responseData.meta.parent == undefined ? "" : responseData.meta.parent.id
                            }
                            if (metaParent) {
                                parentTitle = responseData.meta.parent.title
                            }

                            return { nodes: nodes, sort: sort, parent: metaParent, parentTitle: parentTitle, success: true };
                        }
                        else {
                            return { success: true };
                        }
                    }
                    else {

                        return { success: false };
                    }
                }),
                catchError(err => {
                    const responseObject: LibraryListResponse = {
                        success: false,
                        code: err
                    }

                    return of(responseObject)
                })
            ).toPromise();




    }
    getNodes(parent: string | undefined, sort?: LibrarySort): Promise<LibraryListResponse> {

        let url = this.apiUrl + "/library";
        let params: [string: string];



        let paramString: string | undefined = undefined;

        if (parent || sort) {
            paramString = "?"
        }

        // Append Parent
        if (parent) {
            paramString += "parent=" + parent
        }

        // Append Sort
        if (sort && sort.sortOrder) {
            if (parent) {
                paramString += "&"
            }
            paramString += "sortBy=" + sort.sortBy + "&" + "sortOrder=" + sort.sortOrder
        }

        if (paramString) {
            url += paramString;
        }


        return this.http.
            get(url, {
                observe: "response"
            }).pipe(
                map(response => {
                    let responseStatus = response.status;

                    let responseData: any = response.body

                    if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.NO_CONTENT) {
                        if (responseData != null && responseData.nodes && Array.isArray(responseData.nodes)) {


                            let nodes = responseData.nodes.map((node: any) => this.nodeAdapter.adapt(node));

                            let sort: LibrarySort | undefined = undefined;
                            let metaParent: LibraryNode | undefined = undefined;
                            if (responseData.meta) {
                                sort = { sortBy: responseData.meta.sortBy, sortOrder: responseData.meta.sortOrder }
                            }

                            if (responseData.meta.parent) {
                                metaParent = responseData.parent
                            }

                            return { nodes: nodes, sort: sort, parent: metaParent, success: true };
                        }
                        else {
                            return { success: true };
                        }
                    }
                    else {

                        return { success: false };
                    }
                }),
                catchError(err => {
                    const responseObject: LibraryListResponse = {
                        success: false,
                        code: err
                    }

                    return of(responseObject)
                })
            ).toPromise();




    }

    searchNodes(keyword: string): Promise<LibraryListResponse> {

        let url = this.apiUrl + "/library/search";



        return this.http.
            post(url, {
                keyword: keyword
            }, {
                observe: "response"
            }).pipe(
                map(response => {
                    let responseStatus = response.status;

                    let responseData: any = response.body

                    if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.NO_CONTENT) {
                        if (responseData != null && responseData.nodes && Array.isArray(responseData.nodes)) {


                            let nodes = responseData.nodes.map((node: any) => this.nodeAdapter.adapt(node));

                            let sort: LibrarySort | undefined = undefined;
                            if (responseData.meta) {
                                sort = { sortBy: responseData.meta.sortBy, sortOrder: responseData.meta.sortOrder }
                            }


                            return { nodes: nodes, sort: sort, success: true };
                        }
                        else {
                            return { success: true };
                        }
                    }
                    else {

                        return { success: false };
                    }
                }),
                catchError(err => {
                    const responseObject: LibraryListResponse = {
                        success: false,
                        code: err
                    }

                    return of(responseObject)
                })
            ).toPromise();

    }

    markFavorite(id: string): Promise<AckResponse> {

        let apiUrl = this.apiUrl + "/library/node/" + id + '/favorite';

        return this.http.put<any>(apiUrl,
            {}, {
                observe: "response"
        }).pipe(map((response) => {

            let responseStatus = response.status;

            let responseData: any = response.body

            if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.CREATED) {
                if (responseData != null && responseData.status != null && responseData.status) {
                    return { success: true };
                }
                else {
                    return { success: false };
                }
            }
            else {

                return { success: false };
            }

        }, catchError((error: any) => {

            const responseObject: AckResponse = {
                success: false,
                code: error
            }

            return of(responseObject)

        }))).toPromise();



    }

    removeFavorite(id: string): Promise<AckResponse> {

        let apiUrl = this.apiUrl + "/library/node/" + id + '/favorite';

        return this.http.delete<any>(apiUrl,
            {
                observe: "response"
            }).pipe(map((response) => {

                let responseStatus = response.status;

                let responseData: any = response.body

                if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.CREATED) {
                    if (responseData != null && responseData.status != null && responseData.status) {
                        return { success: true };
                    }
                    else {
                        return { success: false };
                    }
                }
                else {

                    return { success: false };
                }

            }, catchError((error: any) => {

                const responseObject: AckResponse = {
                    success: false,
                    code: error
                }

                return of(responseObject)

            }))).toPromise();



    }


    renameTitle(title: string, id: string): Promise<LibraryNodeResponse> {

        let apiUrl = this.apiUrl + "/library/node/" + id;

        return this.http.put<any>(apiUrl,
            { title: title }, {
                observe: "response"
        }).pipe(map((response) => {

            let responseStatus = response.status;

            let responseData: any = response.body

            if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.CREATED) {
                if (responseData != null) {

                    let node = this.nodeAdapter.adapt(responseData);

                    return { node: node, success: true };
                }
                else {
                    return { success: false };
                }
            }
            else {

                return { success: false };
            }

        }, catchError((error: any) => {

            const responseObject: LibraryNodeResponse = {
                success: false,
                code: error
            }

            return of(responseObject)

        }))).toPromise();



    }

    moveNode(nodeId: string, targedNodeId: string | null): Promise<LibraryNodeResponse> {

        let apiUrl = this.apiUrl + "/library/node/" + nodeId;

        const body = {
            parent: targedNodeId ? targedNodeId : null
        }


        return this.http.put<any>(apiUrl,
            body, {
                observe: "response"
        }).pipe(map((response) => {

            let responseStatus = response.status;

            let responseData: any = response.body

            if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.CREATED) {
                if (responseData != null) {

                    let node = this.nodeAdapter.adapt(responseData);

                    return { node: node, success: true };
                }
                else {
                    return { success: false };
                }
            }
            else {

                return { success: false };
            }

        }, catchError((error: any) => {

            const responseObject: LibraryNodeResponse = {
                success: false,
                code: error
            }

            return of(responseObject)

        }))).toPromise();



    }


    removeNode(id: string): Promise<AckResponse> {

        let apiUrl = this.apiUrl + "/library/node/" + id;

        return this.http.delete<any>(apiUrl,
            {
                observe: "response"
            }).pipe(map((response) => {

                let responseStatus = response.status;

                let responseData: any = response.body

                if (responseStatus == StatusCodes.OK || responseStatus == StatusCodes.CREATED) {
                    if (responseData != null && responseData.status != null && responseData.status) {
                        return { success: true };
                    }
                    else {
                        return { success: false };
                    }
                }
                else {

                    return { success: false };
                }

            }, catchError((error: any) => {

                const responseObject: AckResponse = {
                    success: false,
                    code: error
                }

                return of(responseObject)

            }))).toPromise();



    }



}