import { Option } from './option.model';
import { QuestionType } from './question-type.model';

export class Question {
    type: QuestionType | undefined;
    question: string = "";
    questionType: string = "";
    answers: string[] = [];
    options: Option[] = [];
    canAnswer: boolean = false;
    isAnswered: boolean = false;
    isCorrectAnswer: Boolean | undefined;
    answerKey: string = "";
    toPostAPI: any = (formInput: any)=>{
        let returnObj: any = {
            description: formInput.question,
            type: (formInput.questionType === 'MULTIPLE_CHOICE' || formInput.questionType === 'TRUE_OR_FALSE') ? "MCQ" : "FIB",
            answerReason: formInput.answerKey
        };
        if(formInput.questionType != 'TEXTBOX'){
            returnObj.options = [];
            formInput.options.map((option: { label: string; isCorrectAnswer: Boolean; answer: string; oKey: string})=>{
                let optionObj = new Option().toPostAPI(option);
                returnObj.options.push(optionObj);
                if(option.isCorrectAnswer){
                    if (returnObj.answer && Array.isArray(returnObj.answer) && returnObj.answer.length > 0) {
                        returnObj.answer.push(option.oKey);   
                    } else {
                        returnObj.answer = [option.oKey];
                    }
                }
            });
        }
        return returnObj;
    }

    markAllOptionsUnAnswered() {
        this.options.map(o => {
            o.isAnswered = false;
          })          
    }

}