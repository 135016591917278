<div class="comments-main">
    <div class="comments-header" fxLayout="row">
        <div fxFlex="initial" fxLayoutAlign="start center">
            <mat-icon class="ic-comment mr-10">chat_bubble</mat-icon>
            <p class="p-small-black15">Comments</p>
        </div>
        <div fxFlex fxLayoutAlign="end center">
            <!-- <button mat-icon-button (click)="closeComment()">
                <mat-icon class="ic-close">close</mat-icon>
            </button> -->
        </div>
    </div>
    <div class="comment-section-main scroll-bar">
        <ng-container *ngFor="let comment of comments">
            <div class="" fxLayout="row" fxLayoutAlign="start start">
                <div class="mr-10 pt-10 comments-profile" fxFlex="initial" fxLayoutAlign="start start">
                    <app-profile-image class="cursor-pointer" [viewMode]='true' [userDetails]='comment.createdBy'
                        [userID]='userID' (click)="redirectToUserProfile(comment.createdBy.id, true)">
                    </app-profile-image>
                </div>
                <div fxFlex fxLayoutAlign="start center">
                    <div fxLayout="column" class="w-100">
                        <div class="comment-section-row w-100 pr-20" fxLayout="row" fxLayoutAlign="start center">
                            <div class="comment-section-left pr-10" fxFlex="initial" fxLayoutAlign="start center">
                                <div fxLayout="column" class="w-100">
                                    <h6 class="h6-15-blue cursor-pointer"
                                        (click)="redirectToUserProfile(comment.createdBy.id, true)">
                                        {{comment.createdBy.displayName}}</h6>

                                    <ng-container *ngIf="!comment.showEditComment; else editCommentSection">
                                        <p class="p-small-black14 text-format" title="{{comment.text}}">{{comment.text}}
                                        </p>
                                    </ng-container>
                                    <ng-template #editCommentSection>
                                        <div class="pt-10 text-area-main">
                                            <textarea #editCommentTextarea class="comment-textarea reply-textarea"
                                                name="editCommentValue" [(ngModel)]="comment.editDraft" autosize
                                                placeholder="Edit Comment" [minRows]="1" [maxRows]="3"
                                                autofocus></textarea>
                                            <button class="edit-textarea-btn" mat-icon-button
                                                (click)="updateComment(comment)"
                                                [disabled]="comment.editSubmissionInProgress">
                                                <mat-icon>send</mat-icon>
                                            </button>
                                        </div>
                                    </ng-template>
                                    <div class="comment-actions-row" fxLayout="row" fxLayoutAlign="start center">
                                      <!--  <button mat-icon-button>
                                            <mat-icon class="ic-favorite">favorite_border</mat-icon>
                                        </button>-->
                                        <ng-container *ngIf="userID === comment.createdBy.id">
                                            <button class="reply-btn" mat-raised-button
                                                [disabled]="comment.editSubmissionInProgress"
                                                (click)="comment.showEditComment?discardEditComment(comment):editComment(comment)">{{comment.showEditComment? 'Discard': 'Edit'}}</button>
                                        </ng-container>
                                        <ng-container *ngIf="!comment.showEditComment">
                                            <button class="reply-btn" mat-raised-button
                                            (click)="replyComment(comment)">Reply</button>
                                        </ng-container>  
                                    </div>
                                </div>
                            </div>
                            <div class="comment-section-right" fxFlex fxLayoutAlign="end center">
                                <div fxLayout="column" fxLayoutAlign="center end">
                                    <p class="p-small-grey14">{{comment.commentedOn | amTimeAgo}}</p>
                                    <ng-container *ngIf="userID === comment.createdBy.id">
                                        <button class="btn-delete-icon" mat-icon-button (click)="deleteComment(comment, undefined)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="all-replies-row">
                            <ng-container *ngIf="(comment.childs && comment.childs.length > 0);else ViewAllReplies">
                                <button mat-raised-button (click)="showHideChilds(comment)"
                                [disabled]="comment.fetchingChilds">
                                {{comment.childs && comment.childs.length > 0 ? 'Hide All Replies': 'View All Replies'}}
                            </button>
                            </ng-container>
                            <ng-template #ViewAllReplies>
                                <button *ngIf="comment.childCount > 0" mat-raised-button (click)="showHideChilds(comment)"
                                [disabled]="comment.fetchingChilds">
                                {{'View All ' + comment.childCount + ' Replies'}}
                                </button>
                            </ng-template>
                            
                            <mat-spinner *ngIf="comment.fetchingChilds" [color]="color" diameter="20" strokeWidth="2">
                            </mat-spinner>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="comment.childs && comment.childs.length > 0">
                <div class="reply-comment-section-main pt-10">

                    <div *ngFor="let child of comment.childs" class="reply-comment-section" fxLayout="row"
                        fxLayoutAlign="start start">
                        <div class="mr-10 pt-10 comments-profile" fxFlex="initial" fxLayoutAlign="start start">
                            <app-profile-image class="cursor-pointer" [viewMode]='true' [userDetails]='child.createdBy'
                                [userID]='userID' (click)="redirectToUserProfile(child.createdBy.id, true)">
                            </app-profile-image>
                        </div>
                        <div fxFlex fxLayoutAlign="start center">
                            <div fxLayout="column" class="w-100">
                                <div class="comment-section-row w-100 pr-20" fxLayout="row"
                                    fxLayoutAlign="start center">
                                    <div class="comment-section-left pr-10" fxFlex="initial"
                                        fxLayoutAlign="start center">
                                        <div fxLayout="column" class="w-100">
                                            <h6 class="h6-15-blue cursor-pointer"
                                                (click)="redirectToUserProfile(comment.createdBy.id, true)">
                                                {{child.createdBy.displayName}}</h6>
                                            <ng-container *ngIf="!child.showEditComment; else editChildSection">
                                                <p class="p-small-black14 text-format" title="{{child.text}}">{{child.text}}
                                                </p>
                                            </ng-container>
                                            <ng-template #editChildSection>
                                                <div class="pt-10 text-area-main">
                                                    <textarea class="comment-textarea reply-textarea"
                                                        [(ngModel)]="child.editDraft" autosize
                                                        placeholder="Edit Comment" [minRows]="1" [maxRows]="3"
                                                        autofocus></textarea>
                                                    <button class="edit-textarea-btn" mat-icon-button
                                                        (click)="updateComment(child)"
                                                        [disabled]="child.editSubmissionInProgress">
                                                        <mat-icon>send</mat-icon>
                                                    </button>
                                                </div>
                                            </ng-template>
                                            <div class="comment-actions-row" fxLayout="row"
                                                fxLayoutAlign="start center">
                                                <!-- <button mat-icon-button>
                                                    <mat-icon class="ic-favorite">favorite_border</mat-icon>
                                                </button> -->
                                                <ng-container *ngIf="userID === child.createdBy.id">
                                                    <button class="reply-btn" [disabled]="child.editSubmissionInProgress" (click)="child.showEditComment?discardEditComment(child):editComment(child)"
                                                    mat-raised-button>{{child.showEditComment? 'Discard': 'Edit'}}</button>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="comment-section-right" fxFlex fxLayoutAlign="end center">
                                        <div fxLayout="column" fxLayoutAlign="center end">
                                            <p class="p-small-grey14">{{child.commentedOn | amTimeAgo}}</p>
                                            <ng-container *ngIf="userID === child.createdBy.id">
                                                <button class="btn-delete-icon" mat-icon-button (click)="deleteComment(child, comment)">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="comment.showReply">
                <div class="ml-55 text-area-main">
                    <textarea #replyCommentTextarea class="comment-textarea reply-textarea" name="replyCommentValue"
                        [(ngModel)]="comment.replyDraft" autosize placeholder="Reply Comment" [minRows]="1"
                        [maxRows]="3" autofocus></textarea>
                    <button class="reply-textarea-btn" mat-icon-button (click)="submitReplyComment(comment)"
                        [disabled]="comment.replySubmissionInProgress">
                        <mat-icon>send</mat-icon>
                    </button>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <div class="comment-input text-area-main pt-20 pl-20 pr-20 pb-10">
        <textarea #addCommentTextArea name="newCommentValue" [(ngModel)]="newCommentValue" class="comment-textarea"
            autosize placeholder="Add Comment" [minRows]="1" [maxRows]="3"></textarea>
        <button mat-icon-button (click)="addComment(addCommentTextArea.value)" [disabled]="commentInProgress">
            <mat-icon>send</mat-icon>
        </button>
    </div>
</div>