import { LearningSpaceDetailComponent } from './main/spaces/learning-space-detail/learning-space-detail.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { NotFoundComponent } from './error/not-found/not-found.component';
import { BrowserComponent } from './browser/browser.component';
import { NointComponent } from './browser/noint/noint.component';
import { InvalidComponent } from './browser/invalid/invalid.component';
import { NofilesComponent } from './browser/nofiles/nofiles.component';
import { SpacesListComponent } from './main/spaces/spaces-list/spaces-list.component';


const routes: Routes = [
  { path: '', loadChildren: () => import('./user-actions/user-actions.module').then(m => m.UserActionsModule) },
  { path: 'space/:spaceid', component: LearningSpaceDetailComponent},
  { path: 'learning-path/:spaceid', component: LearningSpaceDetailComponent},
  { path: 'spaces/public', component: SpacesListComponent}, 
  { path: 'tour', canActivate: [AuthGuard], loadChildren: () => import('./tour/tour.module').then(m => m.TourModule)},
  { path: 'main', canActivate: [AuthGuard],loadChildren: () => import('./main/main.module').then(m => m.MainModule)},
  { path: 'not-found', component: NotFoundComponent },
  { path: 'browser/:url',canActivate: [AuthGuard], component: BrowserComponent },
  { path: 'nointernet',canActivate: [AuthGuard], component: NointComponent },
  { path: 'nofiles',canActivate: [AuthGuard], component: NofilesComponent },
  { path: 'invalid',canActivate: [AuthGuard], component: InvalidComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
