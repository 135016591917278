import { THEME } from './../../../../common/constants';
import { CommonService } from './../../../../common/service/common.service';
import { LibraryService, LibraryNodeResponse } from './../../data-providers/library.service';
import { LibraryNodeType } from './../../helper/library.constant';
import { LibraryNode } from './../../models/library-node.model';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-library-node-viewer',
  templateUrl: './library-node-viewer.component.html',
  styleUrls: ['./library-node-viewer.component.scss']
})
export class LibraryNodeViewerComponent implements OnInit, OnChanges {

  @Input() node: LibraryNode;
  nodeType = LibraryNodeType;
  loading = false;
  isDarkMode: boolean = true;
  themeSubscription: Subscription;
  @Input() showThumbnailOnly = true;

  constructor(
    public libraryService: LibraryService,
    private _commonService: CommonService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'node': {
            this.setNode();
          }
        }
      }
    }
    
  }




  ngOnInit(): void {
    this.themeSubscription = this._commonService.getThemeSubject().subscribe(_theme => {

      if (_theme) {
        this.isDarkMode = (_theme === THEME.THEME_DARK ? true : false);
      }

    });
  }



  async setNode() {
    this.loading = true;

    // Download content for link type
    if (this.node.type == LibraryNodeType.link) {

      try {
        this.node = await this.getNodeDetail(this.node.id)
      } catch (error) {
      }
      this.loading = false;
    }


  }

  openLink() {

    let url: string = '';
    if (!/^http[s]?:\/\//.test(this.node.urlInfo.url)) {
      url += 'https://';
    }

    url += this.node.urlInfo.url;

    window.open(url);


  }

  async getNodeDetail(nodeId: string): Promise<LibraryNode> {

    return new Promise(async (resolve, reject) => {

      try {
        let response: LibraryNodeResponse = await this.libraryService.getNode(nodeId);

        if (response.success && response.node) {
          resolve(response.node);
          return;
        }
        reject(null);
        return;

      } catch (error) {
        reject(null);
        return;
      }

    })


  }

  

 

}
