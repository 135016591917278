<div fxLayout="column">
    <div class="profile-img pr-20 " fxFlex="initial" fxLayoutAlign="start center">
        <ng-container
            *ngIf="userDetails && userDetails.profileImage && userDetails.profileImage.imageUrl; else displayName">
            <img class="user-img {{avatarSize}}-profile-img"  alt="{{userDetails?userDetails.displayNamePlaceholder:''}}"
                src="{{userDetails.profileImage.imageUrl}}" onError="this.src='../../../assets/images/icons/no-profile-img.png'">
        </ng-container>
        <ng-template #displayName>
            <div class="placeholder-name {{avatarSize}}-placeholder-name">
                <h2>
                    <ng-container *ngIf="userDetails.displayNamePlaceholder; else noPlaceHolder">
                        {{userDetails.displayNamePlaceholder}}
                    </ng-container>
                    <ng-template #noPlaceHolder>
                        -
                    </ng-template>
                </h2>
            </div>
        </ng-template>
        <div class="edit-profileImage" *ngIf="!viewMode">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <span class="icon ic-edit-blue"></span>
            </button>
            <mat-menu #menu="matMenu" class="profile-image-matMenu">
                <button mat-menu-item (click)="chooseAvatar()" *ngIf="!avatarMode">
                    <span class="icon icon-upload-grey"></span>
                    <span class="choose-avatar-text">Choose Avatar</span>
                </button>
                <button mat-menu-item>
                    <span>
                        <input type="file" id="file" ng2FileSelect (change)="profileImageUpload($event)"
                            class="inputfile">
                        <label for="file" class="m-0 cursor-pointer choose-computer-label">
                            <span class="icon icon-upload-grey"></span>
                            Choose from computer
                        </label>
                    </span>
                </button>
                <button class="remove-picture"
                    *ngIf="userDetails && userDetails.profileImage && userDetails.profileImage.imageUrl" mat-menu-item
                    (click)="removePicture()">
                    <span class="icon icon-delete"></span>
                    <span>Remove Picture</span>
                </button>
            </mat-menu>
        </div>
    </div>
    <ng-container *ngIf="!viewMode && avatarMode">
        <h5 class="h4-20-black align-center choose-avatars-head">Choose from Avatar</h5>
        <app-avatars [disable]="uploading" (avatarSelectedEvent)="avatarSelected($event)"
            [selectedAvatarId]="(userDetails && userDetails.profileImage && userDetails.profileImage.avatarId)? userDetails.profileImage.avatarId: ''">
        </app-avatars>
    </ng-container>
</div>