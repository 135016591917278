import { LearningSpaceMeta, LearningSpaceMetaAdapter } from './learning-space-meta.model';
import { LearningSpaceBlockMeta, LearningSpaceBlockMetaAdapter } from './learning-space-block-meta.model';
import { TagAdapter } from "./../../../shared/models/tag.model";
import { UserProfileAdapter } from "./../../my-profile/models/user-profile.model";
import { Adapter } from "./../../../common/interfaces/adapter";
import { Injectable } from "@angular/core";
import { Tag } from "src/app/shared/models/tag.model";
import { UserProfile } from "../../my-profile/models/user-profile.model";
import { SpaceType } from '../helper/space.constant';

export class LearningSpace {
  id: string;
  title: string;
  description: string | undefined;
  liked: boolean;
  likes: number;
  shares: number;
  views: number;
  bookmarked: boolean;
  bookmarks: number;
  comments: number;
  createdAt: Date;
  updatedAt: Date;
  publishedId: string;
  published: boolean;
  publishedAt: Date;
  publishType: string;
  draftId: string;
  templateId: string;
  thumbnailURL: string;
  createdBy: UserProfile;
  tags: Tag[];
  shared: boolean;
  spacesMeta: LearningSpaceMeta[] | undefined;
  blocksMeta: LearningSpaceBlockMeta[] | undefined;
  displayOnByndrWebsite: boolean = false;
  type: SpaceType = SpaceType.learningspace;
  isRemoved: boolean = false;
 
  get postDataForMutation() {
    return {
      name: this.title,
      description: this.description,
      templateId: this.templateId,
      tags: this.tags ? this.tags.map((_nt) => _nt.title) : [],
    };
  }

  get postDataForUpdate() {
    return {
      name: this.title,
      description: this.description,
      tags: this.tags ? this.tags.map((_nt) => _nt.title) : [],
    };
  }

  filterTag(_selectedTags: Tag[]): Tag[] {
    if (!_selectedTags)
      return [];

    if(this.tags && Array.isArray(this.tags) && this.tags.length > 0) {
       const filteredTags = this.tags.filter((_t : Tag) => {
        const filteredSelectedTags = _selectedTags.filter((_st : Tag) => _st.title === _t.title)

        if (filteredSelectedTags && Array.isArray(filteredSelectedTags) && filteredSelectedTags.length > 0) {
          return true;
        }

        return false;

      })

      return filteredTags;

    } 
    return [];
  }

  spaceMetaIndex(spaceID: string): number {

    if (!this.spacesMeta)
      return -1;

    return this.spacesMeta.findIndex(s =>  s.spaceID == spaceID);

  }

  hasPreviousSpace(spaceID: string) : boolean {
    if (!this.spacesMeta)
      return false;

    const index = this.spaceMetaIndex(spaceID);

    if (index !== -1) {
      if ((index - 1) >= 0) {
        return true;
      }
    }

    return false;
  }

  hasNextSpace(spaceID: string) : boolean {
    if (!this.spacesMeta)
      return false;

    const index = this.spaceMetaIndex(spaceID);

    if (index !== -1) {
      if ((index + 1) < this.spacesMeta.length) {
        return true;
      }
    }

    return false;
  }

  nextSpaceMeta(spaceID: string): LearningSpaceMeta | undefined{

    if (!this.spacesMeta)
      return undefined;

    const index = this.spaceMetaIndex(spaceID);

    if (index !== -1) {
      if ((index + 1) <= this.spacesMeta.length) {
        return this.spacesMeta[index + 1];
      }
    }

    return undefined;

  }

  update(updatedSpace: LearningSpace) {
    if (!updatedSpace) {
      return;
    }
    this.id = updatedSpace.id;
    this.title = updatedSpace.title;
    this.description = updatedSpace.description;
    this.tags = updatedSpace.tags;
    this.type = updatedSpace.type;
  }
}

@Injectable({
  providedIn: "root",
})
export class LearningSpaceAdapter implements Adapter<LearningSpace> {
  adapt(data: any): LearningSpace {
    let space = new LearningSpace();

    try {
      space.id = typeof data.id === "string" ? data.id : null;
      space.title = typeof data.name === "string" ? data.name : null;
      space.description =
        typeof data.description === "string" ? data.description : null;
      space.liked = typeof data.liked === "boolean" ? data.liked : false;
      space.likes = typeof data.likes === "number" ? data.likes : 0;
      space.shares = typeof data.shares === "number" ? data.shares : 0;
      space.views = typeof data.views === "number" ? data.views : 0;
      space.bookmarked =
        typeof data.bookmarked === "boolean" ? data.bookmarked : false;
      space.isRemoved = typeof data.isDeleted === "boolean" ? data.isDeleted: false;
      space.bookmarks = typeof data.bookmarks === "number" ? data.bookmarks : 0;
      space.comments =
        typeof data.comments === "number"
          ? data.comments
          : parseInt(data.comments);
      if (typeof data.createdAt === "string") {
        space.createdAt = new Date(data.createdAt);
      }
      if (typeof data.lastModifiedAt === "string") {
        space.updatedAt = new Date(data.lastModifiedAt);
      }

      if (data.author) {
        let profileAdapter = new UserProfileAdapter();
        space.createdBy = profileAdapter.adapt(data.author);
      }

      space.displayOnByndrWebsite = typeof data.displayInWebsite === 'boolean' ? data.displayInWebsite: false;

      space.published =
        typeof data.published === "boolean" ? data.published : false;
      if (typeof data.publishedOn === "string") {
        space.publishedAt = new Date(data.publishedOn);
      }
      space.publishType = data.publishType;


      space.draftId = typeof data.draftId === "string" ? data.draftId : null;
      space.publishedId =
        typeof data.publishedId === "string" ? data.publishedId : null;

      space.thumbnailURL =
        typeof data.thumbnail === "string" ? data.thumbnail : null;

      if (data.tags && Array.isArray(data.tags) && data.tags.length > 0) {
        let tagAdapter = new TagAdapter();
        const tags = data.tags.map((tagData: any) => tagAdapter.adapt(tagData));
        space.tags = tags;
      }

      if (data.spacesMetadata && Array.isArray(data.spacesMetadata) && data.spacesMetadata.length > 0) {
        let spaceMetaAdapter = new LearningSpaceMetaAdapter();
        const spacesMetaArray : Array<LearningSpaceMeta> = data.spacesMetadata.map((sm: any) => spaceMetaAdapter.adapt(sm));

        const sortedArray = spacesMetaArray.sort((a , b) => {
          return a.position - b.position
        });
        
        space.spacesMeta =  sortedArray;
        
      }

      if (data.blocksMetadata && Array.isArray(data.blocksMetadata) && data.blocksMetadata.length > 0) {
          let blocksMetaAdapter = new LearningSpaceBlockMetaAdapter();
          const blocksMeta = data.blocksMetadata.map((bm: any) => blocksMetaAdapter.adapt(bm));
          space.blocksMeta = blocksMeta;
      }

      if (data.type) {
        space.type =
        typeof data.type === "string"
          ? (<any>SpaceType)[data.type]
          : null;
      }

    } catch (error) {
      console.error("Unable to convert learning space", error);
    }

    return space;
  }
}



export class LearningSpaceCount {
  own: number;
  bookmarked: number;
  sharedWith: number;
}


@Injectable({
  providedIn: "root",
})
export class LearningSpaceCountAdapter implements Adapter<LearningSpaceCount> {
  adapt(data: any): LearningSpaceCount {
    let space = new LearningSpaceCount();

    try {
      space.own = typeof data.own === "number" ? data.own : null;
      space.bookmarked = typeof data.bookmarked === "number" ? data.bookmarked : null;
      space.sharedWith =
        typeof data.sharedWith === "number" ? data.sharedWith : null;
     


    } catch (error) {
      console.error("No Counts", error);
    }

    return space;
  }
}
