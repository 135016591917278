import { NotificationMessagingService } from './core/service/notification-messaging.service';
import { MatButtonModule } from '@angular/material/button';
import { CommonService, RouterExtService } from './common/service/common.service';
import { SharedModule } from './shared/shared.module';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { appInitializer } from './app.initializer';
import { AuthenticationService } from './core/service/authentication.service';
import { NotFoundComponent } from './error/not-found/not-found.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ProfileEnableConfirmationDialogComponent } from './common/components/profile-enable-confirmation-dialog/profile-enable-confirmation-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserComponent } from './browser/browser.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { FormsModule } from '@angular/forms';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AsyncPipe } from '../../node_modules/@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ProfileEnableConfirmationDialogComponent,
    BrowserComponent
  ],
  imports: [
    AngularFireMessagingModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    MatButtonModule,
    MatSnackBarModule,
    MatTabsModule,
    NgxSkeletonLoaderModule.forRoot(),
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatListModule,
    FormsModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    NotificationMessagingService,
    CommonService,
    RouterExtService,
    AsyncPipe
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }