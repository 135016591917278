<ng-container *ngIf="!loading; else tagsSkeletonLoading">
    <ng-container *ngIf="viewMode; else editMode">
        <mat-chip-list #chipList aria-label="Tag List">
            <mat-chip *ngFor="let tag of tags" class="mat-diffcolor" [selectable]="false" [removable]="false">
                {{tag.title}}
            </mat-chip>
        </mat-chip-list>
    </ng-container>
    <ng-template #editMode appearance="outline">
        <mat-form-field appearance="fill">
            <mat-label class="tg">Tags</mat-label>
            <mat-chip-list #chipList aria-label="Tag Selection">
                <mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable"
                    (removed)="remove(tag)">
                    {{tag.title}}
                    <span class="icon ic-close-black2 cursor-pointer" matChipRemove *ngIf="removable"></span>
                </mat-chip>

                <input class="bt" placeholder="New Tag..." #tagInput [matChipInputAddOnBlur]="false" (blur)="addOnBlur($event)"
                    [formControl]="tagInputCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let tagObject of suggestions" [value]="tagObject">
                    {{tagObject.title}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </ng-template>
</ng-container>
<ng-template #tagsSkeletonLoading>
    <div [ngClass]="viewMode === false? 'pt-20': ''">
        <ngx-skeleton-loader count="10" [theme]="{ 
        height: '25px',
        width: '100px',
        marginBottom: '10px',
        marginRight: '10px',
        borderRadius: '16px'
      }"></ngx-skeleton-loader>
    </div>
</ng-template>