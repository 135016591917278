<div class="p-20 information-dialog">
<h3 class="h3-24-black pb-20">{{title}}</h3>
<div mat-dialog-content>

    <ng-container *ngIf="showLoader">
            <mat-progress-spinner mode="indeterminate">
            
            </mat-progress-spinner>
    </ng-container>

    <ng-container *ngIf="!showLoader">
        <p class="pb-20 p-large-18">{{content}}</p>
    </ng-container>
    
</div>
<mat-dialog-actions align="end">
    <ng-container *ngIf="showCancelButton">    
        <button mat-button mat-dialog-close [disabled]="showLoader" cdkFocusInitial>{{cancelButtonTitle}}</button>
    </ng-container>
  
  <button  mat-raised-button color="primary" (click)="primaryButtonClicked()" [disabled]="showLoader" >{{primaryButtonTitle}}</button>
</mat-dialog-actions>
</div>
