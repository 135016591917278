import { AuthenticationService } from './../service/authentication.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { switchMap } from 'rxjs/operators';



@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.indexOf('token/refresh') !== -1 || request.url.indexOf('spaces/public') !== -1) {
      console.log("Interceptor: Ignoring token refresh");
      return next.handle(request);
  }


    const isLoggedIn = this.authenticationService.isLoggedIn;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    const token = this.authenticationService.accessToken;
    


    if (isLoggedIn && isApiUrl && token) {
      const tokenExpired = this.authenticationService.isTokenExpired;
      if (tokenExpired) {
        console.log("Token expired in interceptor");
        return from(this.authenticationService.raiseRefreshTokenRequest())
          .pipe(switchMap(user => {
            const headers = request.headers
              .set('Authorization', 'Bearer ' + this.authenticationService.accessToken)
              .append('Content-Type', 'application/json');
            
            console.log("Updated Headers with new token", headers);
            const reqClone = request.clone({
              headers
            });
            return next.handle(reqClone);
          }));

      }
      else {
        request = request.clone({
          setHeaders: {
            'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
            'Access-Control-Allow-Headers': 'Content-Type, Origin, Authorization',
            'Access-Control-Allow-Credentials': '*',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${token}`
          }
        });
      }


    }
    else {
      request = request.clone({
        setHeaders: {
          'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
          'Access-Control-Allow-Headers': 'Content-Type, Origin, Authorization',
          'Access-Control-Allow-Credentials': '*',
          'Access-Control-Allow-Origin': '*'
        }
      });
    }


    return next.handle(request);


  }

}