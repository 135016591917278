<div class="spaces-info-main">
    <div class="enter-space-row" fxlayout="row wrap" fxLayoutWrap="wrap">
        <!-- <div fxFlex="initial" fxLayoutAlign="start center">
            <button mat-raised-button class="btn-submit">Enter Space</button>
        </div> -->
        <div fxFlex fxLayoutAlign="start center">
            <span class="icon icon-lsp-active lsp-active"></span>
            <span class="sp-l"><b>About this space</b></span>
        </div>
        <div fxFlex fxLayoutAlign="end center">
            <span class="icon icon-close" (click)="closeSpacesInfo()"></span>
        </div>
    </div>
    <div class="spaces-info-body" *ngIf="space">
        <!-- <img class="mb-10 post-img" alt="" src="../../../../assets/images/rawimages/card-2.jpg" /> -->
        <div class="card-details mb-10 mr-20 ml-20 mt-10">
            <div class="author-details" fxLayout="row wrap" fxLayoutWrap="wrap">
                <div fxFlex="initial" fxLayoutAlign="start center">
                    <app-profile-image class="mr-10 author-pic" [viewMode]='true' [userDetails]='space.createdBy'
                        [routerLink]="['/main/profile/u/',space.createdBy.id]">
                    </app-profile-image>
                    <div fxLayout="column" class="space-ls">
                        <span class="h6-15 single-line-new">
                            {{space.createdBy.displayName}}</span>
                          <!--  <a class="h6-15 single-line-new" [routerLink]="['/main/profile/u/',space.createdBy.id]">
                                {{space.createdBy.displayName}}</a>-->
                        <!-- <p class="p-small-blue-16">Satish Nampally</p> -->
                        <p class="p-small-grey14 single-line" title="{{space.updatedAt | date}}">
                            Published {{space.updatedAt | amTimeAgo}}</p>
                    </div>
                </div>
                <!-- <div fxFlex fxLayoutAlign="end center">
                    <span class="icon icon-view mr-10"></span>
                    <p class="p-small-grey14">1.2K Views</p>
                </div> -->
            </div>
            <h3 class="h3-24-black pt-10 spacecard-title">{{space.title}}</h3>
            <!-- <p class="p-small-blue-12 pb-10">Learning Space</p> -->

            <p class="p-small-black14 pt-10 pb-20">
                <ng-container *ngIf="space.description; else noDescription">
                    {{space.description}}
                </ng-container>
                <ng-template #noDescription>
                    No Description Available.
                </ng-template>
            </p>
            <app-tag-auto-complete #tagField [tags]="space && space.tags? space.tags : []" [viewMode]="true">
            </app-tag-auto-complete>
        </div>
        <div *ngIf="space.published" class="views-row" fxLayout="row" fxLayoutAlign="start center">
            <app-learning-space-action-bar [space]='space'></app-learning-space-action-bar>
        </div>
        <!-- <div class="card-list">
            <ul>
                <li>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="initial" fxLayoutAlign="start center">
                            <p class="p-small-black15">Cited in <span class="p-small-blue-15">5 Paths</span></p>
                        </div>
                    </div>
                </li>
                <li>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="initial" fxLayoutAlign="start center">
                            <p class="p-small-black15">Branches of philosophy</p>
                        </div>
                        <div fxFlex fxLayoutAlign="end center">
                            <p class="p-small-blue-15 mr-20">Vinay Chaganti</p>
                            <span class="icon icon-right-arrow-blue"></span>
                        </div>
                    </div>
                </li>
                <li>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="initial" fxLayoutAlign="start center">
                            <p class="p-small-black15">Full Stack Development</p>
                        </div>
                        <div fxFlex fxLayoutAlign="end center">
                            <p class="p-small-blue-15 mr-20">Vinay Chaganti</p>
                            <span class="icon icon-right-arrow-blue"></span>
                        </div>
                    </div>
                </li>
                <li>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="initial" fxLayoutAlign="start center">
                            <p class="p-small-black15">Data Structures & Algorithms</p>
                        </div>
                        <div fxFlex fxLayoutAlign="end center">
                            <p class="p-small-blue-15 mr-20">Vinay Chaganti</p>
                            <span class="icon icon-right-arrow-blue"></span>
                        </div>
                    </div>
                </li>
                <li>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="initial" fxLayoutAlign="start center">
                            <p class="p-small-black15">Branches of philosophy</p>
                        </div>
                        <div fxFlex fxLayoutAlign="end center">
                            <p class="p-small-blue-15 mr-20">Vinay Chaganti</p>
                            <span class="icon icon-right-arrow-blue"></span>
                        </div>
                    </div>
                </li>
                <li>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="initial" fxLayoutAlign="start center">
                            <p class="p-small-black15">Full Stack Development</p>
                        </div>
                        <div fxFlex fxLayoutAlign="end center">
                            <p class="p-small-blue-15 mr-20">Vinay Chaganti</p>
                            <span class="icon icon-right-arrow-blue"></span>
                        </div>
                    </div>
                </li>
            </ul>
        </div> -->
    </div>
</div>