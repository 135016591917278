<div class="error-main" fxLayout="column" fxLayoutAlign="center center">
    <div class="not-found-header pb-20" fxLayout="row" fxLayoutAling="center center">
        <span class="icon icon-bug-grey"></span>
        <h1>404</h1>
    </div>
    <h1 class="not-found-text pb-20">Page Not Found</h1>
    <p class="p-small-grey-16 pb-20">
        We're sorry, the page you are requested could not be found. Please go back to the <a
            routerLink="/main/home">home page</a>
    </p>
</div>