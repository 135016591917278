<div class="library-body w-100" fxLayout="row wrap" fxLayoutWrap="wrap">
    <div class="library-welcome-card">
        <div class="library-box">
            <div class="byndr_logo align-center"><span class="icon byndr-logo"></span></div>
            <div class="in-ls"><img class="inn-img" src="../../../assets/images/rawimages/folder.png"><input type="text" class="myinput align-center"></div>
            <div class="mat-lib">
                <img src="../../assets/images/rawimages/library.png"> <span class="innericon"><b>Library</b></span> 
               </div>
               <div class="to-l1"> 
            <div class="byndr_logo align-center">
                 <img src="../../assets/images/rawimages/icnofolder.png"> 
                </div>
              <h5><p class="align-center no-ls">You have</p>
               <p class="align-center no-ls">No Folders in Library</p></h5>
               <h6><p class="align-center">Create a New Folder  </p>
               <p class="align-center">or</p>
               <p class="align-center">Save it to Library directly.</p></h6></div>

               <div fxLayoutAlign="end start" class="p-20 buttons-section ">
 
              </div>
    </div>
   
</div></div>