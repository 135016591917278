import { CommonService, NotesCount } from 'src/app/common/service/common.service';
import { InformationDialogComponent } from 'src/app/shared/components/information-dialog/information-dialog.component';
import { ThemePalette } from '@angular/material/core';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { LibraryFileCountResponse } from './../data-providers/library.service';
import { LibraryNodeType, LibrarySort, LibrarySortField, LibrarySortOrder } from './../helper/library.constant';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Component, Inject, OnInit, EventEmitter, Output, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { MainService } from '../../main.service';
import { LibraryListResponse, LibraryNodeResponse, LibraryService } from '../data-providers/library.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { LibraryNode } from '../models/library-node.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { observable, Subscription } from 'rxjs';
import { LibraryFileViewerDialogComponent } from './library-file-viewer-dialog/library-file-viewer-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewLinkDialogComponent } from './new-link-dialog/new-link-dialog.component';
import { NewFolderDialogComponent } from './new-folder-dialog/new-folder-dialog.component';
import { LibraryViewType } from 'src/app/common/constants';
import { NewActivityDialogComponent } from './new-activity-dialog/new-activity-dialog.component';
import { RunComponent } from '../questionnaire/run/run.component';

@Component({
    selector: 'app-library',
    templateUrl: './library.component.html',
    styleUrls: ['./library.component.scss'],
    animations: [
        trigger('slideInOut', [
            state('in', style({
                transform: 'translate3d(0,0,0)'
            })),
            state('out', style({
                transform: 'translate3d(100%, 0, 0)'
            })),
            transition('in => out', animate('400ms ease-in-out')),
            transition('out => in', animate('400ms ease-in-out'))
        ]),
    ]
})
export class LibraryComponent implements OnInit, OnDestroy {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public mainService: MainService,
        private libraryService: LibraryService,
        private authenticationService: AuthenticationService,
        private commonService: CommonService,
        private _dialog: MatDialog) { }



    @Input('showHeader') showHeader: boolean = true;
    @Input('keyword') keyword: string;
    @Input() viewType: LibraryViewType;
    @Output() searchCompleteEvent = new EventEmitter<number>();


    viewTypeEnum = LibraryViewType;


    fetchingNodes: boolean = false;
    parentNodeID: string | undefined;
    sortType!: string;
    sort_order: string;
    // sortTypeObj: Array<any> = [
    //     { key: 'name', name: 'Name' },
    //     { key: 'addedDate', name: 'Date Added' },
    // ];
    private paramSubscription?: Subscription;

    fileCount?: number = -1;
    errorMessage?: string;
    errorMessageButtonTitle: string = "Retry";
    noteCountSubscription: Subscription;
    deleteNodeSubscription: Subscription;
    pageTitle: string = '';
    parentsNodeNew: Array<any> = [];
    isShowWelcomeCard: boolean = false;
    showError: boolean = false;
    authType: FormControl = new FormControl("All");
    fileTypes: FormControl = new FormControl("All");
    fileTypeList: Array<any> = [
        { key: 'All', name: 'All' },
        { key: 'File Type', name: 'File Type' },

        { key: 'Folder', name: 'Folders' },

        { key: 'Doc', name: 'Documents' },

        { key: 'Image', name: 'Images' },

        { key: 'Video', name: 'Videos' },

        { key: 'MP3', name: 'Audios' },

        { key: 'Url', name: 'URLs' }]

    nodes?: LibraryNode[];
    parentNode?: LibraryNode;
    selectedNode?: LibraryNode;
    singleSelectedNode?: boolean;
    sort?: LibrarySort;

    sortTitles: any;

    sortOptions = [
        {
            value: LibrarySortField.name,
            title: 'Name'
        },
        {
            value: LibrarySortField.notesCount,
            title: 'Notes'
        },
        {
            value: LibrarySortField.type,
            title: 'Type'
        },
        {
            value: LibrarySortField.addedDate,
            title: 'Added On'
        },
        {
            value: LibrarySortField.fileSize,
            title: 'File Size'
        }
    ]

sortNameAsc:boolean=false;
sortDateAsc:boolean=false;
sortField:string="addedDate";
   

    ngOnInit(): void {
        this.mainService.menuItem = 'Library';
        this.mainService.libraryComponent = true;
        this.mainService.libraryFlex_lg = 100;
        this.mainService.TopicsComponent = false;
        this.mainService.learnigSpaceComponent = false;
        this.mainService.isMenuItemActive = true;

        this.viewType = this.getViewType();
        this.sort = this.getSortFromLocalStorage();


        this.commonService.getOnFileChangeSubject().subscribe((e) => {

            this.onFileChange(e)
        })
        this.commonService.getNewFolderTappedSubject().subscribe(() => {
            this.newFolderTapped();
        })
        this.commonService.getNewURLTappedSubject().subscribe(() => {
            this.newURLTapped();
        })
        this.commonService.getNewActivityTappedSubject().subscribe(() => {
            this.newActivityTapped();
        })

        this.noteCountSubscription = this.commonService.getNoteCountSubject().subscribe(countObject => {

            if (countObject) {
                this.updateNoteCount(countObject);
            }

        });

        this.deleteNodeSubscription = this.commonService.getDeleteNodeSubject().subscribe(nodeObject => {
            if (nodeObject) {
                this.removeNode(nodeObject);
            }
        })



        this.paramSubscription = this.route.paramMap.subscribe((params: ParamMap) => {
            this.parentsNodeNew = [];
            if (this.router.url.indexOf('/folder') !== -1) {
                const _parent = params.get('parent')
                if (_parent) {
                    this.parentNodeID = _parent;
                }
            }
            else {
                this.parentNodeID = undefined;
                this.parentNode = undefined;
            }

            this.viewType = this.getViewType();

            this.loadLibrary();
        });

    }

    ngOnChanges(changes: SimpleChanges): void {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'keyword': {
                        this.loadLibrary();
                        break;
                    }
                }
            }
        }
    }

    initializeSortTitles() {

        this.sortTitles = {
            name: 'Name',
            notesCount: 'Notes',

        }

        // {
        //     value:LibrarySortField.name,
        //     title: 'Name'
        //   },
        //   {
        //     value:LibrarySortField.notesCount,
        //     title:'Notes'
        //   },
        //   {
        //     value: LibrarySortField.type,
        //     title: 'Type'
        //   },
        //   {
        //     value: LibrarySortField.addedDate,
        //     title: 'Added On' 
        //   },
        //   {
        //     value: LibrarySortField.fileSize,
        //     title: 'File Size'
        //   }
    }



    onFileChange(event: any) {

        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.openUploadFilesPopup(file);
        }


    }

    /*
    initFileUploader() {
        this.fileUploader = new FileUploader(
            {url: this.libraryService.apiUrl + '/library/node/upload', 
            itemAlias: 'fileContent',
            headers: [
                {name: "Access-Control-Allow-Credentials", value:"*"},
                {name: "Access-Control-Allow-Origin", value:'*'}
            ]
        })
        this.fileUploader.onAfterAddingFile = (fileItem: any) => { 

            

            // Clear if there any previous uploads. For current release it would be a single file
            if (this.fileUploader.queue.length > 1) {
                this.fileUploader.removeFromQueue(this.fileUploader.queue[0]);
            }

            fileItem.withCredentials = false;
            console.log("onAfterAddingFile", fileItem, this.fileUploader.queue);

            
         };

        
    } */

    loadLibrary() {
        this.parentsNodeNew = [];
        if (this.keyword && this.keyword.length > 0) {
            this.searchLibrary(this.keyword);
        }
        else {
            if (this.parentNodeID) {
                this.getParentNode(this.parentNodeID);
            }
            else {
                this.getFileCount();
            }
            console.log(this.parentNode) 
            console.log(this.sort)
            this.sortField=this.sort?.sortBy ? this.sort?.sortBy : ''
            this.sortDateAsc = this.sort?.sortOrder == 'asc' ? true  : false
            this.getLibrary(this.parentNodeID, this.sort);
        }


    }

    closeWelcomeCard() {
        this.isShowWelcomeCard = false;
    }

    changeViewType() {

        if (this.viewType == LibraryViewType.Grid)
            this.viewType = LibraryViewType.List;
        else if (this.viewType == LibraryViewType.List)
            this.viewType = LibraryViewType.Grid;
        else
            throw new Error("Invalid Library View Type" + this.viewType);


        this.storeViewType();

        this.dismissNodeInfo();

    }

    storeViewType() {
        localStorage.setItem('lvt', this.viewType);
    }
    storeSort() {
        if (this.sort) {
            localStorage.setItem('lso', this.sort.sortOrder);
            localStorage.setItem('lsk', this.sort.sortBy);
        }

    }

    getSortFromLocalStorage(): LibrarySort | undefined {

        if (this.keyword && this.keyword.length > 0) {
            // Nothing to do. Defaults to list
            return undefined;
        }
        else {
            const lso = localStorage.getItem('lso');
            const lsk = localStorage.getItem('lsk');

            this.sortType = lsk ? lsk : 'addedDate';
            this.sort_order = lso ? lso : 'desc';
            let _sort: LibrarySort | undefined = undefined;
            if (lso && lsk) {
                _sort = { sortOrder: (<any>LibrarySortOrder)[lso], sortBy: (<any>LibrarySortField)[lsk] }
            }
            return _sort;
        }
    }

    getViewType(): LibraryViewType {

        if (this.viewType) {
            return this.viewType;
        }


        let lViewType: LibraryViewType = LibraryViewType.List;
        if (this.keyword && this.keyword.length > 0) {
            // Nothing to do. Defaults to list
        }
        else {
            let vt = localStorage.getItem('lvt');
            if (vt) {
                lViewType = (<any>LibraryViewType)[vt];
            }
        }

        return lViewType;
    }

    changeFilter() {
        let controls = {
            authType: this.authType.value,
            fileTypes: this.fileTypes.value
        }
        this.libraryService.libraryFilter.next(controls)
    }

    async getParentNode(nodeId: string) {

        try {
            let response: LibraryNodeResponse = await this.libraryService.getNode(nodeId);

            if (response.success) {
                this.parentNode = response.node;
                // Start Get the Page Titles for breadcrumbs
                let parentNodeResposne;
                if (this.parentNode && this.parentNode.parent) {
                    parentNodeResposne = this.libraryService.setParentsNodes(this.parentNode)
                    this.parentsNodeNew.unshift(parentNodeResposne)
                   // console.log(this.parentsNodeNew)
                    this.getParentNode(this.parentNode.parent);
                }
                else {
                    parentNodeResposne = this.libraryService.setParentsNodes(this.parentNode)
                    this.parentsNodeNew.unshift(parentNodeResposne)
                    //console.log(this.parentsNodeNew)
                }
                // End  Get the Page Titles for breadcrumbs

            }
        } catch (error) {
            this.parentNode = undefined;
        }

    }

    async getFileCount() {
        try {
            let response: LibraryFileCountResponse = await this.libraryService.getFileCount();

            if (response.success) {
                this.fileCount = response.count;
            }



        } catch (error) {
            this.parentNode = undefined;
        }
    }

    async getLibrary(parent: string | undefined, _sort: LibrarySort | undefined) {

        this.nodes = [];
        this.fetchingNodes = true;
        this.showError = false;
        this.errorMessage = undefined;

        try {
            let response: LibraryListResponse = await this.libraryService.getNodes(parent, _sort);

            if (response.success) {
                this.nodes = response.nodes;
                this.sort = response.sort;

                this.storeSort();

                if (this.nodes && Array.isArray(this.nodes) && this.nodes.length > 0) {
                    this.isShowWelcomeCard = false;
                    this.errorMessage = undefined;
                    this.showError = false;
                }
                else {

                    if (!this.parentNodeID) {
                        this.isShowWelcomeCard = true;
                        this.errorMessage = undefined;
                        this.showError = false;
                    }
                    else {
                        this.isShowWelcomeCard = false;
                        this.errorMessageButtonTitle = "Refresh";
                        this.errorMessage = "No files/links/folders available";
                        this.showError = true;

                    }


                }



            }
            else {
                this.errorMessage = "Oops,something went wrong. Please try again";
                this.showError = true;
                this.errorMessageButtonTitle = "Retry";
            }

            this.fetchingNodes = false;


        } catch (error) {
            this.nodes = []
            this.fetchingNodes = false;
            this.errorMessage = "Oops,something went wrong. Please try again";
            this.showError = true;
            this.errorMessageButtonTitle = "Retry";
        }
    }

    async searchLibrary(_keyword: string) {

        this.nodes = [];
        this.fetchingNodes = true;
        this.showError = false;
        this.errorMessage = undefined;

        try {
            let response: LibraryListResponse = await this.libraryService.searchNodes(_keyword);

            if (response.success) {
                this.nodes = response.nodes;
                this.sort = response.sort;

                if (this.nodes && Array.isArray(this.nodes) && this.nodes.length > 0) {

                    this.showError = false;
                }
                else {

                    this.errorMessageButtonTitle = "Refresh";
                    this.errorMessage = "No files/links/folders matched your search";
                    this.showError = true;

                }



            }
            else {
                this.errorMessage = "Oops,something went wrong. Please try again";
                this.showError = true;
                this.errorMessageButtonTitle = "Retry";
            }

            this.fetchingNodes = false;
            this.raiseSearchCompleteEvent();

        } catch (error) {
            this.nodes = []
            this.fetchingNodes = false;
            this.errorMessage = "Oops,something went wrong. Please try again";
            this.showError = true;
            this.errorMessageButtonTitle = "Retry";
            this.raiseSearchCompleteEvent();
        }
    }


    newURLTapped() {

        const thisReference = this;
        const dialogRef = this._dialog.open(NewLinkDialogComponent, {
            width: '600px',
            id: "new_link_dialog",
            data: { parentNode: this.parentNode }
        });

        const dialogResultSubscription = dialogRef.componentInstance.linkSavedEvent.subscribe(result => {
            console.log('Got the event from save link popup', result);
            dialogResultSubscription.unsubscribe();

            thisReference.loadLibrary();
        });

    }

    // upload files popup
    openUploadFilesPopup(file: File) {

        if (file) {

            const thisReference = this;

            const dialogRef = this._dialog.open(UploadFilesDialog, {
                width: '600px',
                id: "upload_file_dialog",
                data: { file: file, parentNode: this.parentNode }
            });

            const dialogResultSubscription = dialogRef.componentInstance.fileUploadedEvent.subscribe(result => {
                console.log('Got the event from upload popup', result);
                dialogResultSubscription.unsubscribe();

                thisReference.loadLibrary();
            });

        }
        else {
            console.error("No files to upload");
        }

    }

    // folder popup
    newFolderTapped() {
        const thisReference = this;
        const dialogRef = this._dialog.open(NewFolderDialogComponent, {
            width: '500px',
            id: "new_folder_dialog",
            data: { parentNode: this.parentNode }
        });

        const dialogResultSubscription = dialogRef.componentInstance.folderCreatedEvent.subscribe(result => {
            console.log('Got the event from create folder popup', result);
            dialogResultSubscription.unsubscribe();

            thisReference.loadLibrary();
        });


    }

    newActivityTapped() {
        const thisReference = this;
        const dialogRef = this._dialog.open(NewActivityDialogComponent, {
            width: '600px',
            height: '90vh',
            id: "new_activity_dialog",
            data: { parentNode: this.parentNode }
        });

        const dialogResultSubscription = dialogRef.componentInstance.activityCreatedEvent.subscribe(result => {
            console.log('Got the event from create folder popup', result);
            dialogResultSubscription.unsubscribe();

            thisReference.loadLibrary();
        });


    }

    runActivityTapped() {
        const thisReference = this;
        const dialogRef = this._dialog.open(RunComponent, {
            width: '500px',
            id: "new_folder_dialog",
            data: { parentNode: this.parentNode }
        });


    }

    showNodeInfo() {
        this.singleSelectedNode = true
        //this.libraryService.libraryType = type;
        // this.mainService.isLibraryInfoSidebar = true;
        this.mainService.sidebarOpened();
        // this.mainService.isCardInfo = false;
        // this.mainService.isMessageInfo = false;
        // this.mainService.isInviteInfo = false;
        // this.mainService.isNotificationInfo = false;
        this.mainService.libraryFlex_lg = 70;
        this.mainService.fxFlex_fileCard = 33.333;

    }

    dismissNodeInfo() {
        setTimeout(() => {

            this.mainService.sidebarClosed();
            //   this.mainService.isMessageInfo = false;
            //   this.mainService.isInviteInfo = false;
            //   this.mainService.isNotificationInfo = false;
            //   this.mainService.isLibraryInfoSidebar = false;
            this.mainService.libraryFlex_lg = 100;
            this.mainService.fxFlex_fileCard = 20;

            //  this.selectedNode = undefined;
            this.singleSelectedNode = false;
        }, 100);



    }

    libraryInfoCloseTapped(_selectedNode: LibraryNode) {


        this.dismissNodeInfo()


    }

    updateSelectedNode(_selectedNode: LibraryNode) {

        this.selectedNode = _selectedNode;
        if (this.selectedNode) {
            this.showNodeInfo();
        }
        else {
            this.dismissNodeInfo();
        }

    }

    nodeClick(_selectedNode: LibraryNode) {

        this.selectedNode = _selectedNode;
        if (this.selectedNode) {
            this.showNodeInfo();
        }
        else {
            this.selectedNode = undefined;
        }

    }

    nodeDoubleClick(selectedNode: LibraryNode) {
        this.dismissNodeInfo();
        this.parentsNodeNew = []
        this.selectedNode = selectedNode;
        if (selectedNode.type == LibraryNodeType.folder) {

            this.router.navigate(['/main/library/folder', selectedNode.id]); //TODO: 

        }
        else if (selectedNode.type == LibraryNodeType.link || selectedNode.type == LibraryNodeType.file || selectedNode.type == LibraryNodeType.activity) {
            this.openFilePreviewDialog(selectedNode);
        }
    }

    sortChangeEvent() {

    }
    
    sortOrder() {
        if (this.sort) {
            if (this.sort.sortOrder == LibrarySortOrder.asc) {
                localStorage.setItem('lso', LibrarySortOrder.desc);
            }
            else {
                localStorage.setItem('lso', LibrarySortOrder.asc);
            }

            this.sort = this.getSortFromLocalStorage();
            this.loadLibrary();
        }
    }

    // sortFiled(valFiled: any) {
    //     let value = valFiled.value
    //     console.log(value)
    //      if (this.sort) {
    //         localStorage.setItem('lsk', value);

    //         this.sort = this.getSortFromLocalStorage();
    //         this.loadLibrary();
    //     }
    // }



    sortFiled(valFiled: any) {
        let value = valFiled
        
if(value=='name')
{
   
   
    
    if(this.sortField=="name")
    this.sortNameAsc= !this.sortNameAsc;
    this.sortField="name";
    

    if(this.sortNameAsc)
    localStorage.setItem('lso', LibrarySortOrder.asc);
    else
    localStorage.setItem('lso', LibrarySortOrder.desc);
   
    
}
else if(value=='addedDate')
{
    if(this.sortField=="addedDate")
    this.sortDateAsc = !this.sortDateAsc;
    this.sortField="addedDate";
    
    
    if(this.sortDateAsc)
    localStorage.setItem('lso', LibrarySortOrder.asc);
    else
    localStorage.setItem('lso', LibrarySortOrder.desc);
}

         if (this.sort) {
            localStorage.setItem('lsk', value);

            this.sort = this.getSortFromLocalStorage();
            this.loadLibrary();
        }
    }



    sortClick(_sort: LibrarySort) {
        if (_sort) {
            this.sort = _sort;
            this.loadLibrary();
        }
    }

    openFilePreviewDialog(selectedNode: LibraryNode): void {


        // Generate node array
        const _qualifiedNodes = this.nodes?.filter(n => (n.type != LibraryNodeType.folder))

        const filterNodes = _qualifiedNodes?.filter(_n => _n.id === selectedNode.id)

        if (filterNodes) {
            const currentIndex = _qualifiedNodes?.indexOf(filterNodes[0]);


            const dialogRef = this._dialog.open(LibraryFileViewerDialogComponent, {
                panelClass: 'full-screen-dialog',
                data: { nodes: _qualifiedNodes, currentIndex: currentIndex },
            });

            dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed');
            });
        }


    }


    renameDialogCompletionEvent(_node: LibraryNode) {

        console.log("Rename completed");
        if (_node) {
            this.updateNode(_node.id, _node);
        }

        if (_node && _node.type === LibraryNodeType.activity) {
            console.log("Rename completed for activity");
            this.dismissNodeInfo();

        }
    }

    removeNodeCompletionEvent(_node: LibraryNode) {
        if (_node) {
            this.removeNode(_node);
            this.dismissNodeInfo();
        }
    }


    filterNodes(type: LibraryNodeType): LibraryNode[] {

        if (this.nodes) {
            const filteredArray: LibraryNode[] = this.nodes.filter(node => (node.type == type))

            return filteredArray;
        }

        return [];

    }

    updateNoteCount(countObject: NotesCount) {

        if (this.nodes && countObject && countObject.nodeId) {
            const filteredNodes = this.nodes.filter(nodeObject => countObject.nodeId === nodeObject.id)

            filteredNodes.map(nodeObject => {
                nodeObject.notesCount = countObject.count;
            })

        }
    }
    raiseSearchCompleteEvent() {

        this.searchCompleteEvent.next(this.nodes ? this.nodes.length : 0)

    }

    updateNode(nodeId: string, updatedNode: LibraryNode) {
        if (!this.nodes) {
            return;
        }
        const filteredNodes: LibraryNode[] | undefined = this.findNode(nodeId);
        filteredNodes?.map(_node => {
            if (this.nodes) {
                const index: number = this.nodes.indexOf(_node);
                if (index !== -1) {
                    this.nodes[index].update(updatedNode);
                }
            }
        })
    }

    removeNode(node: LibraryNode) {

        if (!this.nodes) {
            return;
        }

        node.removed = true;

        if (this.selectedNode && (node.id == this.selectedNode.id)) {
            this.dismissNodeInfo();
        }

        const nodeIndex = this.nodes.indexOf(node);
        if (nodeIndex > -1) {
            this.nodes = this.nodes.filter(n => !n.removed)
        }
        if (this.nodes.length == 0) {
            this.loadLibrary();
        }

    }

    findNode(nodeId: string): LibraryNode[] | undefined {
        return this.nodes?.filter(_node => (_node.id === nodeId))
    }



    public ngOnDestroy(): void {

        console.log("Destroying library component");

        this.dismissNodeInfo();

        if (this.noteCountSubscription)
            this.noteCountSubscription.unsubscribe();

        if (this.deleteNodeSubscription)
            this.deleteNodeSubscription.unsubscribe();

        if (this.paramSubscription)
            this.paramSubscription.unsubscribe();

        if (this.searchCompleteEvent)
            this.searchCompleteEvent.unsubscribe();


    }



}

@Component({
    selector: 'app-upload-files-dialog',
    templateUrl: './upload-files-dialog/upload-files-dialog.component.html',
    styleUrls: ['./upload-files-dialog/upload-files-dialog.component.scss'],
})
export class UploadFilesDialog implements OnDestroy {

    file: File;
    uploading: boolean = false;
    showError: boolean = false;
    errorMessage?: string;
    parentNode: LibraryNode;
    formGroup: any;
    color: ThemePalette = 'primary';

    @Output() fileUploadedEvent = new EventEmitter<LibraryNode>();


    constructor(
        private dialog: MatDialog,
        private _fb: FormBuilder,
        private libraryService: LibraryService,
        public dialogRef: MatDialogRef<UploadFilesDialog>,
        private _snackBar: MatSnackBar,

        @Inject(MAT_DIALOG_DATA) public data: any) {


        this.file = data.file;
        this.parentNode = data.parentNode;

        this.createForm();


    }
    ngOnDestroy(): void {

        if (this.fileUploadedEvent)
            this.fileUploadedEvent.unsubscribe();
    }

    createForm() {
        this.formGroup = this._fb.group({
            title: [null, [Validators.required]],
        });
    }

    submitTapped(post: any): void {

        const title: string = this.formGroup.get("title").value;

        if (this.formGroup && this.formGroup.invalid) {
            console.error("Unable to upload file. Invalid form value", post);

            if (!title) {
                this.showErrorDialog("Please provide title");
            }

            return;
        }


        this.uploadFile(title);

    }



    async uploadFile(title: string) {
        this.uploading = true;
        this.showError = false;
        this.errorMessage = undefined;

        try {
            let response: LibraryNodeResponse = await this.libraryService.uploadFile(this.file, title ? title : "Untitled", this.parentNode && (this.parentNode.id ? this.parentNode.id : undefined))

            if (response.success) {
                const receivedNode: LibraryNode | undefined = response.node;

                if (receivedNode) {

                    this.showError = false;

                    this.fileUploadedEvent.emit(receivedNode);

                    const dialogRef = this.dialog.getDialogById('upload_file_dialog');
                    if (dialogRef)
                        dialogRef.close();

                    this.showSnackBar(receivedNode.title + " has been uploaded", "OK");

                }
                else {
                    this.showErrorDialog("Unable to upload file");
                    this.showError = true;
                }
            }
            else {
                this.showErrorDialog("Oops,something went wrong. Please try again");
                this.showError = true;
            }

            this.uploading = false;


        } catch (error) {
            console.log("Unable to upload", error);
            this.uploading = false;
            this.showErrorDialog("Oops,something went wrong. Please try again");
            this.showError = true;
        }
    }

    showErrorDialog(error: any) {

        const dialogRef = this.dialog.open(InformationDialogComponent, {
            width: '300px',
            id: 'information_dialog'
        })

        dialogRef.componentInstance.content = error



    }

    showSnackBar(message: string, action: string) {
        this._snackBar.open(message, action, { duration: 10000 });
    }

}

