<mat-progress-bar mode="indeterminate" *ngIf="fetchingSpaces"></mat-progress-bar>
<!-- 
<mat-chip-list class="all-tags"  #tagList aria-label="All Tags" selectable multiple>
  <mat-chip #tagChip *ngFor="let _tag of tags" [value]="_tag" [selected]="_tag.selected"
    (click)="tagSelection(_tag)">
    {{_tag | json}}
    {{ _tag.title }}
  </mat-chip>
</mat-chip-list> -->
<div class="spaces-main">
  <div fxLayout="row wrap" fxLayoutWrap="wrap">
    <div fxLayout="row wrap" fxLayoutWrap="wrap" [fxFlex]="100" [fxFlex.md]="mainService.mySpaces_md"
      fxLayoutAlign="start start">
      <div class="all-spaces-row w-100" fxLayout="row wrap" fxLayoutWrap="wrap" *ngIf="canShowHeader">
        <div fxFlex="initial" fxLayoutAlign="start center">
          <!-- <h4 class="h4-20-black mr-20" *ngIf="showTitle">
            {{displayTitle}}s {{ spaces && spaces.length ? spacesCountText : "" }}
          </h4> -->
          <button mat-raised-button *ngIf="showHeaderActions" class="btn-primary addBtn roundedBtn" (click)="newLearningSpaceDialog()">
            + Create New
          </button>
        </div>

        <div *ngIf="showHeaderActions && canShowHeader && learningListSpace" fxFlex="initial" fxLayoutAlign="center center"
          class="header-list">
          <ul class="tabsWrap pagetabswrap">
            <li>
              <a routerLink="/main/spaces/v/my-spaces" routerLinkActive="active">
                <span>My Spaces</span> <span *ngIf="!fetchingSpaces"><ng-container *ngIf="own>=0"> (</ng-container>{{own}}<ng-container *ngIf="own>=0">)</ng-container></span>
              </a>
            </li>
            <li>
              <a routerLink="/main/spaces/v/shared" routerLinkActive="active">
                <span>Shared with Me</span> <span *ngIf="!fetchingSpaces"><ng-container *ngIf="sharedwithme>=0"> (</ng-container>{{sharedwithme}}<ng-container *ngIf="sharedwithme>=0">)</ng-container></span>
              </a>
            </li>
            <li>
              <a routerLink="/main/spaces/v/bookmarked" routerLinkActive="active">
                <span>Bookmarks</span> <span *ngIf="!fetchingSpaces"><ng-container *ngIf="bookmarked>=0"> (</ng-container>{{bookmarked}}<ng-container *ngIf="bookmarked>=0">)</ng-container> </span>
              </a>
            </li>
          </ul>
        </div>
        <div *ngIf="showHeaderActions && canShowHeader && learningListPath" fxFlex="initial" fxLayoutAlign="center center"
          class="header-list">
          <ul class="tabsWrap pagetabswrap">
            <li>
              <a routerLink="/main/learning-paths/my-paths" routerLinkActive="active">
                <span>My Paths</span> <span *ngIf="!fetchingSpaces"><ng-container *ngIf="own>=0"> (</ng-container>{{own}}<ng-container *ngIf="own>=0">)</ng-container></span>
              </a>
            </li>
            <li>
              <a routerLink="/main/learning-paths/shared" routerLinkActive="active">
                <span>Shared with Me</span> <span *ngIf="!fetchingSpaces"><ng-container *ngIf="sharedwithme>=0"> (</ng-container>{{sharedwithme}}<ng-container *ngIf="sharedwithme>=0">)</ng-container></span>
              </a>
            </li>
            <li>
              <a routerLink="/main/learning-paths/bookmarked" routerLinkActive="active">
                <span>Bookmarks</span> <span *ngIf="!fetchingSpaces"><ng-container *ngIf="bookmarked>=0"> (</ng-container>{{bookmarked}}<ng-container *ngIf="bookmarked>=0">)</ng-container> </span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <!-- <div class="pr-20" fxFlex fxLayoutAlign="end center">
                    <div class="sort-by mr-20" fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="initial" fxLayoutAlign="start center">
                            <p class="p-small-black14 mr-20">Show</p>
                        </div>
                        <mat-form-field appearance="fill" class="select-box mr-20">
                            <mat-select>
                                <mat-option value="Published">Published</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="sort-by" fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="initial" fxLayoutAlign="start center">
                            <p class="sort-by-text p-small-black14 mr-20">Sort By</p>
                        </div>
                        <mat-form-field appearance="fill" class="select-box">
                            <mat-select>
                                <mat-option value="Recent">Recent</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div> -->





      <mat-chip-list class="all-tags" *ngIf="showTags" #tagList aria-label="All Tags" selectable multiple>
        <mat-chip #tagChip *ngFor="let _tag of tags" [value]="_tag" [selected]="_tag.selected"
          (click)="tagSelection(_tag)">
          {{ _tag.title }}
        </mat-chip>
      </mat-chip-list>

      <div></div>

      <div [ngClass]="['spaces-cards', 'w-100', context]">
        <ng-container *ngIf="fetchingSpaces && currentPage === 1; else LoadingComplete">



          <ng-container [ngSwitch]="viewType">
            <ng-container *ngSwitchCase="viewTypeEnum.List" [ngTemplateOutlet]="SpacesListViewLoader">
            </ng-container>

            <ng-container *ngSwitchDefault [ngTemplateOutlet]="SpacesDefaultLoader">
            </ng-container>
          </ng-container>


          <ng-template #SpacesListViewLoader>
            <div class="list-items">
              <ngx-skeleton-loader count="4" [theme]="{
              height: '60px',
              width: '100%',
              marginBottom: '20px'
            }"></ngx-skeleton-loader>
            </div>


          </ng-template>


          <!-- loading -->
          <ng-template #SpacesDefaultLoader>
            <div class="ptl-20 card-main" fxLayout="row wrap" fxLayoutWrap="wrap">
              <div class="pr-20 pb-20 space-card" [fxFlex]="mainService.serviceCard_lg"
                [fxFlex.md]="mainService.serviceCard_md" [fxFlex.sm]="mainService.serviceCard_sm"
                [fxFlex.xs]="mainService.serviceCard_xs">
                <div class="card-box">
                  <ngx-skeleton-loader count="1" [theme]="{
                    height: '120px',
                    width: '100%',
                    marginBottom: '0px'
                  }"></ngx-skeleton-loader>

                  <div class="body-main pt-10">
                    <ngx-skeleton-loader count="2" [theme]="{
                      height: '22px',
                      width: '100%',
                      marginBottom: '2px'
                    }"></ngx-skeleton-loader>
                    <ng-container *ngIf="canShowProfileInfo; else UpdatedDateView">
                      <div class="source-row pt-10" fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="initial" fxLayoutAlign="start center">
                          <ngx-skeleton-loader count="1" [theme]="{
                            height: '40px',
                            width: '40px',
                            marginRight: '10px'
                          }" appearance="circle"></ngx-skeleton-loader>
                          <div class="source-profile-row" fxLayout="column">
                            <ngx-skeleton-loader count="2" [theme]="{
                              height: '16px',
                              width: '160px',
                              marginBottom: '5px'
                            }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #UpdatedDateView>
                      <div class="source-profile-row pt-10" fxLayout="column">
                        <ngx-skeleton-loader count="1" [theme]="{
                          height: '20px',
                          width: '160px',
                          marginBottom: '5px'
                        }"></ngx-skeleton-loader>
                      </div>
                    </ng-template>

                    <div class="views-row pt-10" fxLayout="row" fxLayoutAlign="center center">
                      <ngx-skeleton-loader count="5" [theme]="{
                        height: '24px',
                        marginLeft: '10px',
                        marginRight: '10px'
                      }"></ngx-skeleton-loader>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pr-20 pb-20 space-card" [fxFlex]="mainService.serviceCard_lg"
                [fxFlex.md]="mainService.serviceCard_md" [fxFlex.sm]="mainService.serviceCard_sm"
                [fxFlex.xs]="mainService.serviceCard_xs">
                <div class="card-box">
                  <ngx-skeleton-loader count="1" [theme]="{
                    height: '120px',
                    width: '100%',
                    marginBottom: '0px'
                  }"></ngx-skeleton-loader>

                  <div class="body-main">
                    <ngx-skeleton-loader count="2" [theme]="{
                      height: '22px',
                      width: '100%',
                      marginBottom: '2px'
                    }"></ngx-skeleton-loader>
                    <ng-container *ngIf="canShowProfileInfo; else UpdatedDateView">
                      <div class="source-row pt-10" fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="initial" fxLayoutAlign="start center">
                          <ngx-skeleton-loader count="1" [theme]="{
                            height: '40px',
                            width: '40px',
                            marginRight: '10px'
                          }" appearance="circle"></ngx-skeleton-loader>
                          <div class="source-profile-row" fxLayout="column">
                            <ngx-skeleton-loader count="2" [theme]="{
                              height: '16px',
                              width: '160px',
                              marginBottom: '5px'
                            }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #UpdatedDateView>
                      <div class="source-profile-row pt-10" fxLayout="column">
                        <ngx-skeleton-loader count="1" [theme]="{
                          height: '20px',
                          width: '160px',
                          marginBottom: '5px'
                        }"></ngx-skeleton-loader>
                      </div>
                    </ng-template>

                    <div class="views-row pt-10" fxLayout="row" fxLayoutAlign="center center">
                      <ngx-skeleton-loader count="5" [theme]="{
                        height: '24px',
                        marginLeft: '10px',
                        marginRight: '10px'
                      }"></ngx-skeleton-loader>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pr-20 pb-20 space-card" [fxFlex]="mainService.serviceCard_lg"
                [fxFlex.md]="mainService.serviceCard_md" [fxFlex.sm]="mainService.serviceCard_sm"
                [fxFlex.xs]="mainService.serviceCard_xs">
                <div class="card-box">
                  <ngx-skeleton-loader count="1" [theme]="{
                    height: '120px',
                    width: '100%',
                    marginBottom: '0px'
                  }"></ngx-skeleton-loader>

                  <div class="body-main">
                    <ngx-skeleton-loader count="2" [theme]="{
                      height: '22px',
                      width: '100%',
                      marginBottom: '2px'
                    }"></ngx-skeleton-loader>
                    <ng-container *ngIf="canShowProfileInfo; else UpdatedDateView">
                      <div class="source-row pt-10" fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="initial" fxLayoutAlign="start center">
                          <ngx-skeleton-loader count="1" [theme]="{
                            height: '40px',
                            width: '40px',
                            marginRight: '10px'
                          }" appearance="circle"></ngx-skeleton-loader>
                          <div class="source-profile-row" fxLayout="column">
                            <ngx-skeleton-loader count="2" [theme]="{
                              height: '16px',
                              width: '160px',
                              marginBottom: '5px'
                            }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #UpdatedDateView>
                      <div class="source-profile-row pt-10" fxLayout="column">
                        <ngx-skeleton-loader count="1" [theme]="{
                          height: '20px',
                          width: '160px',
                          marginBottom: '5px'
                        }"></ngx-skeleton-loader>
                      </div>
                    </ng-template>

                    <div class="views-row pt-10" fxLayout="row" fxLayoutAlign="center center">
                      <ngx-skeleton-loader count="5" [theme]="{
                        height: '24px',
                        marginLeft: '10px',
                        marginRight: '10px'
                      }"></ngx-skeleton-loader>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pr-20 pb-20 space-card" [fxFlex]="mainService.serviceCard_lg"
                [fxFlex.md]="mainService.serviceCard_md" [fxFlex.sm]="mainService.serviceCard_sm"
                [fxFlex.xs]="mainService.serviceCard_xs">
                <div class="card-box">
                  <ngx-skeleton-loader count="1" [theme]="{
                    height: '120px',
                    width: '100%',
                    marginBottom: '0px'
                  }"></ngx-skeleton-loader>

                  <div class="body-main">
                    <ngx-skeleton-loader count="2" [theme]="{
                      height: '22px',
                      width: '100%',
                      marginBottom: '2px'
                    }"></ngx-skeleton-loader>
                    <ng-container *ngIf="canShowProfileInfo; else UpdatedDateView">
                      <div class="source-row pt-10" fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="initial" fxLayoutAlign="start center">
                          <ngx-skeleton-loader count="1" [theme]="{
                            height: '40px',
                            width: '40px',
                            marginRight: '10px'
                          }" appearance="circle"></ngx-skeleton-loader>
                          <div class="source-profile-row" fxLayout="column">
                            <ngx-skeleton-loader count="2" [theme]="{
                              height: '16px',
                              width: '160px',
                              marginBottom: '5px'
                            }"></ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #UpdatedDateView>
                      <div class="source-profile-row pt-10" fxLayout="column">
                        <ngx-skeleton-loader count="1" [theme]="{
                          height: '20px',
                          width: '160px',
                          marginBottom: '5px'
                        }"></ngx-skeleton-loader>
                      </div>
                    </ng-template>

                    <div class="views-row pt-10" fxLayout="row" fxLayoutAlign="center center">
                      <ngx-skeleton-loader count="5" [theme]="{
                        height: '24px',
                        marginLeft: '10px',
                        marginRight: '10px'
                      }"></ngx-skeleton-loader>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <!-- loading end -->
        </ng-container>

        <ng-template #LoadingComplete>
          <ng-container *ngIf="showError; else SpacesView">
            <ng-container [ngTemplateOutlet]="showMessage"></ng-container>
          </ng-container>
          <ng-template #SpacesView>
            <ng-container [ngSwitch]="viewType">
              <ng-container *ngSwitchCase="viewTypeEnum.List" [ngTemplateOutlet]="SpacesListView">
              </ng-container>
              <ng-container *ngSwitchCase="viewTypeEnum.Grid" [ngTemplateOutlet]="SpacesGridView">
              </ng-container>
              <ng-container *ngSwitchDefault [ngTemplateOutlet]="SpacesGridView">
              </ng-container>
            </ng-container>
          </ng-template>
          <!-- <ng-container *ngIf="fetchingSpaces">
                        <mat-progress-spinner
                                [color]="color"
                                [mode]="mode"
                                value="50">
                        </mat-progress-spinner>
                    </ng-container> -->
        </ng-template>
      </div>

      <ng-template #SpacesGridView>

        <ng-container *ngIf="!canMove;else DragAndDropGridView">

          <div #scrollContent class="ptl-20 card-main rowwrap" infiniteScroll (scrolled)="onScroll()"
            [scrollWindow]="false" fxLayout="row wrap" fxLayoutWrap="wrap">
            <!-- [infiniteScrollDistance]="scrollDistance"
                      [infiniteScrollUpDistance]="scrollUpDistance"
                      [infiniteScrollThrottle]="throttle" -->
            <!-- <pre>{{spaces | json}}</pre>-->
            <ng-container *ngFor="let _space of spaces">
              
              <div class="pr-20 pb-20 space-card" [fxFlex]="mainService.serviceCard_lg"
                [fxFlex.md]="mainService.serviceCard_md" [fxFlex.sm]="mainService.serviceCard_sm"
                [fxFlex.xs]="mainService.serviceCard_xs" [class.disabled-card]="_space.isRemoved">
                <ng-container [ngTemplateOutlet]="SpaceCard" [ngTemplateOutletContext]="{space: _space}"></ng-container>
              </div>
            </ng-container>
          </div>

        </ng-container>
        <ng-template #DragAndDropGridView>

          <div cdkDropListGroup class="drop-list-group">
            <div class="pr-20 pb-20 space-card-new" [fxFlex]="mainService.spaceCard_lg"
              [fxFlex.md]="mainService.spaceCard_md" [fxFlex.sm]="mainService.serviceCard_sm"
              [fxFlex.xs]="mainService.serviceCard_xs" *ngFor="let _space of spaces; let i = index" cdkDropList
              [cdkDropListData]="i">

              <div cdkDrag [cdkDragData]="i" (cdkDragEntered)="dragEntered($event)">
                <ng-container [ngTemplateOutlet]="SpaceCard" [ngTemplateOutletContext]="{space: _space}"></ng-container>
              </div>

            </div>

          </div>
        </ng-template>

        <ng-template #SpaceCard let-_space="space">
          <div [ngClass]="[
                  _space.published ? 'published-space' : 'draft-space',
                  'card-box',
                  (selection && selection.isSelected(_space.id)) ? 'selected' : ''
                ]" (click)="canSelectCompleteCard ? spaceCardTapEvent(_space, _space.type) : false">
         <div (click)="canSelectCompleteCard ? false : spaceCardTapEvent(_space, _space.type)" class="cursor-pointer">
                <div class="body-header cursor-pointer"
              >
              
              <div class="source-row profilerow" fxLayout="row" fxLayoutAlign="start center">
                <ng-container *ngIf="canShowProfileInfo; else UpdatedDateView">
                  <div fxFlex="initial" fxLayoutAlign="start center" class="profile_sec">
                    <app-profile-image [viewMode]="true" [userDetails]="_space.createdBy" [routerLink]="[
                            '/main/profile/u/',
                            _space.createdBy.id
                          ]">
                    </app-profile-image>
                    <div fxLayout="column" class="name_space">
                      <a class="h6-15 single-line" [routerLink]="[
                              '/main/profile/u/',
                              _space.createdBy.id
                            ]">
                        {{ _space.createdBy.displayName }}</a>
                      <p class="p-small-grey14 single-line" title="{{ _space.updatedAt | date }}"
                        *ngIf="!_space.isRemoved">
                        {{ _space.updatedAt | amTimeAgo }}
                      </p>
                      <p class="p-small-grey14 single-line" *ngIf="_space.isRemoved">
                        Space Removed
                      </p>

                    </div>
                  </div>
                </ng-container>
                <ng-template #UpdatedDateView>
                  <div [ngClass]="!_space.published ? 'draft-time' : ''" fxLayout="column">
                    <div class="publish-status" fxLayout="row"  fxFlexFill
                      fxLayoutAlign="center center" fxLayoutGap="10px">
                      <mat-icon
                        [matTooltip]="_space.publishType === 'public' ? 'Visible To All Learning Pods Users' : 'Visible To Your Connections Only'"
                        class="publish-status-icon" *ngIf="_space.published">{{
                        _space.publishType === "public"
                        ? "public"
                        : "people"
                        }}</mat-icon>
                      <mat-icon [matTooltip]="displayTitle + ' is not published yet. Visible To Your account only.'"
                        class="publish-status-icon" *ngIf="!_space.published">visibility_off</mat-icon>
                      <p [ngClass]="[
                              _space.published
                                ? 'p-small-black15'
                                : 'p-small-black15',
                              'single-line'
                            ]">
                        <!-- {{ !_space.published ? "Last modified" : "" }} -->
                        {{ _space.updatedAt | amTimeAgo }}
                      </p>
                    </div>
                  </div>
                </ng-template>


                <div class="more-horiz"
                  *ngIf="_space.published && _space.type == typeEnum.learningspace && !learningPathContext">
                  <span [matMenuTriggerFor]="action_menu" (click)="$event.stopPropagation()">
                    <mat-icon>more_horiz</mat-icon>
                  </span>

                  <mat-menu #action_menu="matMenu" class="more-horiz-menu">
                    <button mat-menu-item (click)="openPathPicker(_space);">
                      <span class="icon icon-lp-black"></span>
                      Add to Learning Path
                    </button>

                  </mat-menu>
                </div>

              </div>
              <h4 class="h4-20-black three-lines" title="{{ _space.title }}">
                {{ _space.title }}
              </h4>
            </div>
            <app-metablock [space]="_space"></app-metablock>
            <!-- <ng-container [ngSwitch]="_space.type">
              <ng-container *ngSwitchCase="typeEnum.learningspace" [ngTemplateOutlet]="BlocksMetaView">
              </ng-container>
              <ng-container *ngSwitchCase="typeEnum.learningpath" [ngTemplateOutlet]="PathMetaView">
              </ng-container>
              <ng-container *ngSwitchDefault [ngTemplateOutlet]="BlocksMetaView"></ng-container>
            </ng-container>

            <ng-template #BlocksMetaView>


              <div class="blocks-meta cursor-pointer" *ngIf="_space.isRemoved">
                <span class="icon icon-bg dummy-icon"></span> <span class="icon icon-bg dummy-icon"></span>
              </div>

              <div *ngIf="!_space.isRemoved" class="blocks-meta cursor-pointer" [style.visibility]="
                    _space.blocksMeta && _space.blocksMeta.length > 0
                      ? 'visible'
                      : 'hidden'
                  " (click)="canSelectCompleteCard ? false : spaceCardTapEvent(_space, _space.type)">
                
                <ng-container *ngFor="let _meta of _space.blocksMeta">
                  <ng-container *ngIf="_meta.node">
                    <ng-container *ngIf="_meta.node.type === nodeType.folder">
                      <span class="icon icon-bg icon-background-folder icon-bg-pading"><span
                          class="icon icon-folder-blue icon-full"></span></span>
                    </ng-container>
                    <ng-container *ngIf="_meta.node.type === nodeType.link">
                      <ng-container *ngIf="
                            _meta.node.urlInfo && _meta.node.urlInfo.thumbnail;
                            else fallbackIcon
                          ">
                        <span class="icon icon-bg">
                          <img src="{{ _meta.node.urlInfo.thumbnail }}"
                            onError="this.src='../../../assets/images/icons/no-img.png'" />
                        </span>
                      </ng-container>
                      <ng-template #fallbackIcon>
                        <span class="icon icon-bg icon-background-url icon-bg-pading"><span
                            class="icon icon-url-grey icon-lt"></span></span>
                      </ng-template>
                    </ng-container>
                    <ng-container *ngIf="_meta.node.type === nodeType.activity">
                      <span class="icon icon-bg icon-background-activity icon-bg-pading"><span
                          class="icon icon-url-activity-with-bg icon-full"></span></span>
                    </ng-container>

                    <span [ngClass]="[
                          'icon',
                          'icon-bg',
                          _meta.node.fileInfo.getBGClassForList(),
                          'icon-bg-padding'
                        ]" *ngIf="
                          _meta.node.type === nodeType.file &&
                          _meta.node.fileInfo
                        ">
                      <ng-container [ngTemplateOutlet]="
                            _meta.node.fileInfo.getIconClassForList() ==
                            'icon icon-text'
                              ? TextIcon
                              : ImageIcon
                          " [ngTemplateOutletContext]="{
                            iconclass: _meta.node.fileInfo.getIconClassForList()
                          }">
                      </ng-container>

                      <ng-template #ImageIcon let-iconclass="iconclass">
                        <span [ngClass]="iconclass"></span>
                      </ng-template>

                      <ng-template #TextIcon let-iconclass="iconclass">
                        <span [ngClass]="iconclass">{{
                          _meta.node.fileInfo.textForMimeType
                          }}</span>
                      </ng-template>
                    </span>
                  </ng-container>
                </ng-container>
              </div>

            </ng-template>
            <ng-template #PathMetaView>
              <div class="blocks-meta">
                <span class="space_count_wrap">
                  <span class="space_count" *ngIf="_space.spacesMeta && _space.spacesMeta.length > 0">

                    <span class="icon icon-space-count icon-lt"></span>
                    <span class="iconTxt">
                      {{_space.spacesMeta.length}} Learning spaces
                    </span></span>
                </span>
              </div>
            </ng-template> -->

            <div class="views_section">
               <span class="views_desc" [ngClass]="_space.type == typeEnum.learningpath ? 'pathClr':'spaceClr'">{{_space.type == typeEnum.learningpath? 'Learning Path' : 'Learning Space'}}</span>
              <span class="views_count">
                <ng-container *ngIf="showViews && _space.views > 0">
                  <img class="img_visibility" src="../../../../assets/images/icons/ic_viewss_new.png">
                  {{_space.views}} Views
                </ng-container>
              </span>
            </div>

            <!--<mat-chip-list>
                                    <mat-chip 
                                    *ngFor="let singlearea of area">
                                    {{singlearea.title}}
                                </mat-chip>
                    
                                   
                                </mat-chip-list>-->
            <div class="body-main">
              <p class="description-text mb-20 two-lines" [class.two-lines]="!_space.isRemoved">
                <ng-container *ngIf="_space.description && !_space.isRemoved; else noDescription">
                  {{ _space.description }}
                </ng-container>
                <ng-template #noDescription>


                  <ng-container *ngIf="!_space.isRemoved">
                    No Description Available.</ng-container>
                  <ng-container *ngIf="_space.isRemoved"> Learning Space has been removed by the publisher. It will not
                    be visible on your Learning Path any longer.</ng-container>


                </ng-template>
              </p>
              <!--<mat-chip-list>
                                        <mat-chip 
                                        *ngFor="let singlearea of area">
                                        {{singlearea.title}}
                                    </mat-chip>
                        
                                       
                                    </mat-chip-list>-->

              <div *ngIf="_space.published && showActionBar && !canMove && !canRemove" class="views-row" fxLayout="row"
                fxLayoutAlign="start center">
                <app-learning-space-action-bar (click)="$event.stopPropagation()" [readonly]="actionBarReadOnly" [canMarkLSPForWebsiteAccess]="
                        !actionBarReadOnly && canMarkLSPForWebsiteAccess
                      " [space]="_space" (actionBarReadOnlyEvent)="
                        actionBarReadOnlyClickEvent($event)
                      " (bookmarkCompletionEvent)="
                        bookmarkCompletionEvent($event)
                      ">
                </app-learning-space-action-bar>
              </div>



              <div fxLayout="row wrap" fxLayoutWrap="wrap" fxLayoutAlign="center center">

                <button mat-raised-button *ngIf="!_space.published && !_space.isRemoved"
                  class="btn-primary btn-full-width btn-draft roundedBtn"
                  (click)="canSelectCompleteCard ? false : spaceCardTapEvent(_space, _space.type)">
                  <span class="icon icon-edit"></span>
                  Edit Draft {{displayTitle}}
                </button> 
              </div>

              <ng-container *ngIf="canMove || canRemove">
                <div fxLayout="row wrap" fxLayoutWrap="wrap" fxLayoutAlign="space-between center" class="settingsbar">
                  <section><button mat-raised-button class="move_button" [disableRipple]="true" *ngIf="canMove"><span
                        class="iconwrap"></span></button></section>
                  <section><button mat-raised-button class="delete_button" [disableRipple]="true" *ngIf="canRemove"
                      (click)="removeLearningSpace(_space.id,_space.type)"><span class="iconwrap"></span></button>
                  </section>
                </div>
              </ng-container>



            </div>
          </div>
        </div>  
        <!-- /* added */ -->
        </ng-template>

      </ng-template>

      <ng-template #SpacesListView>

        <div cdkDropList class="list-items" [class.searchList]="!canMove" (cdkDropListDropped)="dropComplete($event)">
          <div [ngClass]="['list-box',(selection && selection.isSelected(_space.id)) ? 'selected' : '']"
            *ngFor="let _space of spaces" cdkDrag [cdkDragDisabled]="!canMove" [class.disabled-card]="_space.isRemoved">
            <div class="list-custom-placeholder" *cdkDragPlaceholder></div>
            <ng-container [ngTemplateOutlet]="SpaceListCard" [ngTemplateOutletContext]="{space: _space}"></ng-container>
          </div>
        </div>
        <ng-template #SpaceListCard let-_space="space">
          <ng-container *ngIf="canMove">
            <section><button mat-raised-button class="move_button" [disableRipple]="true"><span
                  class="iconwrap"></span></button></section>
          </ng-container>

          <ng-container *ngIf="!canMove">
            <section class="learningicon"><span class="icon " [matTooltip]="!_space.published ? 'Draft' : 'Published'"
                [class.draftIcon]="!_space.published"
                [ngClass]="{'icon-lp-search': _space.type == 'learningpath', 'icon-ls-search' :  _space.type == 'learningspace' }"></span>
            </section>
          </ng-container>


          <section [ngClass]="['first_flex']" (click)="spaceCardTapEvent(_space, _space.type)">
            <h4 class="list-title" title="{{ _space.title }}">


              {{ _space.title }}
            </h4>
            <p class="list-description">
              <ng-container *ngIf="_space.description; else noDescription">
                {{ _space.description }}
              </ng-container>
              <ng-template #noDescription>
                No Description Available.
              </ng-template>
            </p>

            <div class="profile_section">

              <a *ngIf="_space.createdBy.displayName" [routerLink]="[
                          '/main/profile/u/',
                          _space.createdBy.id
                        ]">
                {{ _space.createdBy.displayName }}</a>

              <p class="p-small-grey14 single-line">
                <ng-container *ngIf="!_space.isRemoved">
                  {{ _space.updatedAt | amTimeAgo }}
                </ng-container>
                <ng-container *ngIf="_space.isRemoved">
                  Space Removed
                </ng-container>

              </p>
            </div>
          </section>


          <section *ngIf="!canMove" class="search-actions">
            <div class="views_section" *ngIf="_space.published  && !_space.isRemoved">

              <span class="views_count">
                <ng-container *ngIf="showViews && _space.views > 0">
                  <img class="img_visibility" src="../../../../assets/images/icons/ic_viewss_new.png">
                  {{_space.views}} Views
                </ng-container>
              </span>
            </div>


            <app-learning-space-action-bar *ngIf="_space.published && showActionBar && !canRemove"
              [readonly]="actionBarReadOnly" [canMarkLSPForWebsiteAccess]="
                      !actionBarReadOnly && canMarkLSPForWebsiteAccess
                    " [space]="_space" (actionBarReadOnlyEvent)="
                      actionBarReadOnlyClickEvent($event)
                    " (bookmarkCompletionEvent)="
                      bookmarkCompletionEvent($event)
                    ">
            </app-learning-space-action-bar>



            <!--       
            <button mat-raised-button class="button-black  btn-draft" *ngIf="!_space.published  && !_space.isRemoved"
              (click)="canSelectCompleteCard ? false : spaceCardTapEvent(_space, _space.type)">
              <span class="icon icon-edit"></span>
              Edit Draft
            </button> -->
          </section>


          <ng-container *ngIf="canRemove">

            <!-- <section><button mat-raised-button class="move_button" [disableRipple]="true" *ngIf="canMove"><span
                          class="iconwrap"></span></button></section> -->
            <section><button mat-raised-button class="delete_button" [disableRipple]="true" *ngIf="canRemove"
                (click)="removeLearningSpace(_space.id,_space.type)"><span class="iconwrap"></span></button>
            </section>

          </ng-container>

          <ng-container *ngIf="showAddSpaceToPath">
            <section><button mat-raised-button class="add_to_path_button" [disableRipple]="true"
                (click)="addSpaceToLearningPathEvent(_space)"><span class="iconwrap"></span></button>
            </section>

          </ng-container>
        </ng-template>


        <ng-container *ngIf="searchresults">
          <section class="flexsecWrap">
            <section class="flexsec">
              <section><span class="icon icon-thought"></span></section>
              <section class="col2">
                <h4>Didn’t find what you’re looking for?</h4>
                <p> We noticed that you couldn’t find any {{displayTitle}} with these keywords. Why don’t you create a
                  {{displayTitle}} on the topic instead?</p>
              </section>
              <section> <button mat-raised-button class="btn-submit" (click)="errorMessageButtonClick('search')">
                  Create {{displayTitle}}
                </button></section>
            </section>
          </section>

        </ng-container>

      </ng-template>

      <ng-template #showMessage>
        <div class="error-main">
          <div class="w-100 error-message" fxLayout="row" fxLayoutAlign="center center"
            *ngIf="showError && !fetchingSpaces">


            <ng-container *ngIf="learningListSpace">
              <span class="icon icon-url-learningspace"></span>
            </ng-container>
            <ng-container *ngIf="learningListPath">
              <span class="icon icon-url-learningpath "></span>
            </ng-container>

          </div>
          <div class="w-100 error-message" fxLayout="row" fxLayoutAlign="center center"
            *ngIf="showError && !fetchingSpaces">
            {{ errorMessage }}
          </div>
        <ng-container *ngIf="!spaceserrormsg">
          <div class="Error-ts" fxLayout="row" fxLayoutAlign="center center"
            *ngIf="showError && !fetchingSpaces && errorMessagesmall">
            <span> {{ errorMessagesmall }}</span>
          </div>
          <div class="Error-tsmall" fxLayout="row" fxLayoutAlign="center center"
            *ngIf="showError && !fetchingSpaces && errorMessagevsmall">
            {{ errorMessagevsmall }}
          </div>
          <div class="w-100" fxLayout="row" fxLayoutAlign="center center">
            <button mat-raised-button class="btn-submit roundedBtn" *ngIf="showError && errorMessageButtonTitle"
              (click)="errorMessageButtonClick()">
              {{ errorMessageButtonTitle }}
            </button>
          </div>
        </ng-container>
        </div>


      </ng-template>
    </div>
    <div fxFlex="30">
      <div *ngIf="mainService.isMySpacesInfo">
        <app-spaces-info></app-spaces-info>
      </div>
    </div>
  </div>
</div>