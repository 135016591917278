import { Injectable } from "@angular/core";
import { Adapter } from "src/app/common/interfaces/adapter";
import { Option, OptionAdapter } from './option.model';

export class QuestionType {
    hasDynamicOptions: Boolean = false;
    optionValueArr: Option[] | undefined;
    label: string = "";
    displayLabel: string = "";
}

@Injectable({
    providedIn: "root"
})

export class QuestionTypeAdapter implements Adapter<QuestionType> {

    adapt(item: any): QuestionType {
        let quetionType = new QuestionType();
        quetionType.hasDynamicOptions = item.hasDynamicOptions;
        quetionType.label = item.label;
        quetionType.displayLabel = item.displayLabel;
        let optionValuesArr: Option[]= [];
        item?.optionValues?.map((option: Option) =>{
            optionValuesArr.push(new OptionAdapter().adapt(option));
        })
        quetionType.optionValueArr = optionValuesArr;
        return quetionType;
    }
}