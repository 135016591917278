<div class="upload-files-main">
    <div class="p-20 pb-10 upload-files-header" fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex fxLayoutAlign="start center">
            <div fxLayout="column">
                <h4 class="h4-24-black">Add web url</h4>
                <h6 class="h6-15-blue" *ngIf="parentNode; else NoParentLabel">                    
                    Library > {{parentNode.title}}
                </h6>

                <ng-template #NoParentLabel>
                </ng-template>
            </div>
        </div>
        <div fxFlex fxLayoutAlign="end start">
            <span class="icon icon-close cursor-pointer" mat-dialog-close></span>
        </div>
    </div>
    <form [formGroup]="formGroup" [ngClass]="uploading === true? 'show-loading' : ''" (ngSubmit)="submitTapped(formGroup.value)">
        <div class="ptl-20 pb-10 pr-20 filename-section" fxLayout="column" fxLayoutAlign="start center">
        
            <div fxLayout="row" class="w-100 block-section">
                <div fxLayout="column" class="w-100">
                    <label></label>
                    <div class="filename-row" fxLayout="row" fxLayoutAlign="start center" >
                        <mat-form-field class="mat-pb-0" appearance="outline">
                            <input matInput placeholder="URL" required formControlName="url">
                        </mat-form-field>
                        <!-- <span class="icon icon-delete cursor-pointer"></span> -->
                    </div>
                </div>
            </div>
            
        </div>
        <div class="p-20 pt-10 buttons-section" fxLayout="row" fxLayoutAlign="start center">
            <!-- <div fxFlex="initial" fxLayoutAlign="start center">
                <button mat-raised-button class="btn-resend">+ Add more</button>
            </div> -->
            
            <div fxFlex fxLayoutAlign="end center">
                <button mat-raised-button class="btn-grey roundedBtn" mat-dialog-close [disabled]="uploading">Cancel</button>
                <button mat-raised-button class="btn-submit roundedBtn" [disabled]="uploading">Save</button>
                <mat-spinner *ngIf="uploading" [color]="color" diameter="25" strokeWidth="3"></mat-spinner>                
            </div>
        </div>

    </form>
   

</div>