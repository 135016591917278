<div fxLayout="column" [ngClass]='[layoutClass]'>
    <ng-container *ngIf="currentUser; else otherUserActions">
        <ng-container *ngIf="screen == SCREEN_PROFILE;else ViewProfileBlock">
            <button mat-raised-button class="btn-primary1 roundedBtn btn-white" (click)="redirectToEditProfile()">
                <mat-icon class="edit-ft">edit</mat-icon> Edit Your Profile</button>
        </ng-container>
        <ng-template #ViewProfileBlock>
            <ng-container [ngTemplateOutlet]="ViewProfile">
            </ng-container>
        </ng-template>

    </ng-container>
    <ng-template #otherUserActions>

        <!-- Primary Button -->
        <ng-container *ngIf="!profile.connected; else ConnectedUserBlock">

            <ng-container *ngIf="profile.hasConnectionRequest; else NotConnected">
                <ng-container *ngIf="profile.requestType === REQUEST_TYPE_RECEIVED">
                    <div class="flex-container w-100 accept-decline-btn">
                    <ng-container [ngTemplateOutlet]="AcceptRequest">
                    </ng-container>

                    <ng-container [ngTemplateOutlet]="DeclineRequest">
                    </ng-container>
                    </div>

                </ng-container>
                <ng-container *ngIf="profile.requestType === REQUEST_TYPE_RAISED">
                    <ng-container [ngTemplateOutlet]="CancelRequest">
                    </ng-container>
                </ng-container>
            </ng-container>
            <ng-template #NotConnected>
                <ng-container [ngTemplateOutlet]="ConnectButton">
                </ng-container>
            </ng-template>
        </ng-container>
        <ng-template #ConnectedUserBlock>
            <ng-container *ngIf="(screen != SCREEN_PROFILE)" [ngTemplateOutlet]="ViewProfile">
            </ng-container>
        </ng-template>

       <!--- <ng-container *ngIf="(screen != SCREEN_REQUESTS)">
            <button [ngClass]="[(screen != SCREEN_PROFILE)?'more-button-icon':'','btn-upload']"
                [matMenuTriggerFor]="menu" aria-label="More icon to Disconnect or Block User">
                <span class="icon icon-ellipsis-horizontal"></span>
                <span class="more-text">More</span>
            </button>

            <mat-menu #menu="matMenu">
                <button mat-menu-item *ngIf="profile.connected" [disabled]="actionSubmissionInProgress" (click)="disconnectUser()">
                    <mat-icon>person_off</mat-icon>
                    <span>Disconnect</span>
                </button>

                <button mat-menu-item [disabled]="actionSubmissionInProgress" (click)="blockUser()">
                    <mat-icon>report</mat-icon>
                    <span>Report</span>
                </button>

                <button mat-menu-item [disabled]="actionSubmissionInProgress" (click)="blockUser()">
                    <mat-icon>block</mat-icon>
                    <span>Block</span>
                </button>

                

            </mat-menu>
        </ng-container>-->


    </ng-template>

    <ng-template #ViewProfile>
        <button mat-raised-button class="roundedBtn btn-submit view_color" [disabled]="actionSubmissionInProgress"
            (click)="redirectToProfile()">View Profile</button>
    </ng-template>
    <ng-template #ConnectButton>
        <button mat-raised-button class="roundedBtn btn-primary btn-cl connect_bt " [disabled]="actionSubmissionInProgress"
            (click)="connectTapped()">Connect</button>
    </ng-template>

    <ng-template #CancelRequest>
        <button mat-raised-button class="roundedBtn btn-cancel btn-tp cancel_req" [disabled]="actionSubmissionInProgress"
            (click)="cancelConnectionRequest()">Cancel Request</button>
    </ng-template>
    
     


    <ng-template #AcceptRequest>
        <div>
        <button *ngIf="screen != 'home'" mat-raised-button class="roundedBtn btn-primary accept_btn_new" [disabled]="actionSubmissionInProgress"
            (click)="acceptConnectionRequest($event)">
            Accept
     </button>
        <button  mat-icon-button class="accept" *ngIf="screen == 'home'" [disabled]="actionSubmissionInProgress"
            (click)="acceptConnectionRequest($event)">
    </button>
    </div>
    </ng-template>

    <ng-template #DeclineRequest>
        <div>
        <button *ngIf="screen != 'home'" mat-raised-button class="btn-cancel btn-dec roundedBtn" [disabled]="actionSubmissionInProgress"
            (click)="declineConnectionRequest($event)">
            Decline
           
        </button>
        <button mat-icon-button class="reject" *ngIf="screen == 'home'" [disabled]="actionSubmissionInProgress"
            (click)="declineConnectionRequest($event)">  </button>
    </div>
    </ng-template>
</div>