import { LibraryNodeResponse } from './../../data-providers/library.service';
import { LibraryNode } from './../../models/library-node.model';
import { LibraryNodeType } from '../../helper/library.constant';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MainService } from '../../../main.service';
import { LibraryService } from '../../data-providers/library.service';


@Component({
  selector: 'app-library-info',
  templateUrl: './library-info.component.html',
  styleUrls: ['./library-info.component.scss']
})
export class LibraryInfoComponent implements OnInit, OnDestroy {


  _node: LibraryNode;
  nodeType = LibraryNodeType;
  loading = false;
  clickTimer: any;

  @Output() nodeDoubleClickEvent = new EventEmitter<LibraryNode>();

  @Output() closeTapped = new EventEmitter<LibraryNode>();

  constructor(
    public mainService: MainService,
    public libraryService: LibraryService) { }
  
  
  ngOnDestroy(): void {
    
    if (this.nodeDoubleClickEvent)
      this.nodeDoubleClickEvent.unsubscribe();

    if (this.closeTapped)
      this.closeTapped.unsubscribe();

  }


  @Input("node") set node(value: LibraryNode) {

    const thisReference = this;
    thisReference.loading = true;
    setTimeout(async () => {

      thisReference._node = value;

      if (thisReference._node.type == LibraryNodeType.link) {

        try {
          thisReference._node = await thisReference.getNodeDetail(thisReference.node.id)
        } catch (error) {
        }
        thisReference.loading = false;
      }
      else {
        thisReference.loading = false;
      }

    }, 100)

  }

  get node(): LibraryNode {
    return this._node;
  }

  async getNodeDetail(nodeId: string): Promise<LibraryNode> {

    return new Promise(async (resolve, reject) => {

      try {
        let response: LibraryNodeResponse = await this.libraryService.getNode(nodeId);

        if (response.success && response.node) {
          resolve(response.node);
          return;
        }
        reject(null);
        return;

      } catch (error) {
        reject(null);
        return;
      }

    })


  }




  ngOnInit(): void {
  }

  openLink() {

    let url: string = '';
    if (!/^http[s]?:\/\//.test(this.node.urlInfo.url)) {
      url += 'https://';
    }

    url += this.node.urlInfo.url;

    window.open(url);
  }

  nodeDoubleClick(node: LibraryNode) {
    clearTimeout(this.clickTimer);
    this.clickTimer = undefined;
    this.nodeDoubleClickEvent.emit(node);
  }

  closeSidebar() {

    this.closeTapped.emit(this.node);
  }

}
