import { APP_PLATFORM, TOUR_STATUS } from 'src/app/common/constants';
import { UserProfileLink, UserProfileLinkAdapter } from './user-profile-link.model';
import { AreaOfInterest, AreaOfInterestAdapter } from './../../../user-actions/areas-of-interest/models/area-of-interest.model';
import { Injectable } from "@angular/core";
import { Adapter } from "src/app/common/interfaces/adapter";
import { ALL_LINKS } from '../helper/user-profile.constant';


export class UserProfile {

    id: string;
    firstName: string;
    lastName: string;
    dob: Date | undefined;
    dobString: string | undefined;
    profileImage?: UserProfilePicture;
    customStatus: string;
    organization: string;
    designation: string;
    connectionsCount: number;
    requestsCount: number;
    learningSpacesCount: number;
    learningPathCount: number;
    citationsCount: number;
    email: string;
    mobile: string;
    canMarkLSPForWebsiteAccess: boolean = false;
    location: string;
    aboutMe: string;
    links: UserProfileLink[] | undefined;
    areasOfInterest: AreaOfInterest[] | undefined;
    disabled: boolean = false;
    hasPassedSSOExpInvite: boolean = false;
    tourStatus: string = TOUR_STATUS.NotVisited;
    newNotificationsCount: number = 0;
    

    // Additional Data
    connected: boolean = false;
    mutualConnectionsCount: number;
    requestId: string | undefined;
    requestType: string | undefined;
    theme:string | undefined;
    expandMenuSidebar:boolean = false;

    get displayName(): string {

        if (this.firstName && this.lastName) {
            return this.firstName + ' ' + this.lastName;
        }
        else if (this.firstName && !this.lastName) {
            return this.firstName;
        }
        else if (!this.firstName && this.lastName) {
            return this.lastName
        }


        return "";
    }
    get displayNamePlaceholder(): string {

        let displayName = '';
        if (this.firstName && this.lastName) {
            displayName =  this.firstName.charAt(0) + '' + this.lastName.charAt(0);
        }
        else if (this.firstName && !this.lastName) {
            displayName =  this.firstName.slice(0, 2);
        }
        else if (!this.firstName && this.lastName) {
            displayName = this.lastName.slice(0, 2);
        }


        return displayName.toLocaleUpperCase();
    }

    updateProfilePicture(pictureUrl: string, avatarId: string | undefined) {
        if (!this.profileImage) {
            this.profileImage = new UserProfilePicture();
        }
        this.profileImage.imageUrl = pictureUrl;
        this.profileImage.avatarId = avatarId;
    }
    
    clearProfilePicture() {
        if (this.profileImage) {
            this.profileImage.avatarId = undefined;
        }
        delete this.profileImage;
        
    }


    getTourVisitStatus(): boolean {
        const status = this.tourStatus; // localStorage.getItem('twbvs')

        if (status && (status == TOUR_STATUS.Visited || status == TOUR_STATUS.Skipped))
            return true;

        return false
    }


    isIntroComplete(): boolean {

        return (this.firstName !== null && this.firstName.length > 0);

    }

    hasAreasofInterest(): boolean {

        return (Array.isArray(this.areasOfInterest) && this.areasOfInterest.length > 0);

    }

    get mutualConnectionsCountText(): string {

        if (this.mutualConnectionsCount > 0) {
            return this.mutualConnectionsCount.toString();
        }
        else {
            return "No";
        }

    }

    get totalConnectionsCountText(): string {

        if (this.connectionsCount > 0) {
            return this.connectionsCount.toString();
        }
        else {
            return "No";
        }

    }


    getPendingExperience() {

        const firstHandExperiences = [
            {
                experience: "intro",
                completed: this.isIntroComplete(),
                route: "intro"
            },
            {
                experience: "areas-of-interest",
                completed: this.hasAreasofInterest(),
                route: "areas-of-interest"
            },
            {
                experience: "welcome-tour",
                completed: this.getTourVisitStatus(),
                route: "tour/welcome"
            },
            // {
            //     experience: "tour-spread",
            //     completed: this.getTourVisitStatus(),
            //     route: "tour/spread"
            // }
        ];


        let pendingExperience = null;

        for (let index = 0; index < firstHandExperiences.length; index++) {
            const element = firstHandExperiences[index];

            if (!element.completed) {
                pendingExperience = element;
                break;
            }
        }

        return pendingExperience;

    }

    getCreateProfilePostBody() {

        let postBody: any = {
            userId: this.id
        }

        if (this.firstName) {
            postBody["firstName"] = this.firstName
        }

        if (this.lastName) {
            postBody["lastName"] = this.lastName
        }

        if (this.customStatus) {
            postBody["customStatus"] = this.customStatus
        }



        return postBody;

    }

    getUpdateProfilePostBody() {

        let postBody: any = {
            userId: this.id
        }

        if (this.firstName) {
            postBody["firstName"] = this.firstName
        }

        if (this.lastName) {
            postBody["lastName"] = this.lastName
        }

        if (this.customStatus) {
            postBody["customStatus"] = this.customStatus
        }

        if (this.organization) {
            postBody['organization'] = this.organization
        }

        if (this.designation) {
            postBody['designation'] = this.designation
        }

        if (this.location) {
            postBody['location'] = this.location
        }

        if (this.aboutMe) {
            postBody['aboutMe'] = this.aboutMe
        }

        if (this.dobString) {
            postBody['dateOfBirth'] = this.dobString
        }

        if (this.areasOfInterest) {
            const areasOfInterestIds = this.areasOfInterest.map(interest => interest.id);
            postBody['areasOfInterest'] = areasOfInterestIds;
        }

        if (this.links) {
            const _links = this.links.map(linkObj => linkObj.getUpdateLinkPostBody())

            postBody['socialMediaLinks'] = _links;
        }

        return postBody;

    }

    get allLinks(): UserProfileLink[] {
        if (this.links) {
            let linksUpdatedArray: UserProfileLink[] = [];
            ALL_LINKS.map(masterLinkObject => {

                const type = masterLinkObject.type

                const matchedLinkObject = this.links?.find(linkObject => (linkObject.type === type))

                if (matchedLinkObject) {
                    masterLinkObject.link = matchedLinkObject?.link;
                }
                else {
                    masterLinkObject.link = ''
                }

                linksUpdatedArray.push(masterLinkObject);
                

            })

            return linksUpdatedArray;
        }
        return [];
    }


    get subText() : string {
        if (this.connected) {
            return this.customStatus;
        }
        else {
            return this.mutualConnectionsCountText + " mutual";
        }
    }

    get hasConnectionRequest(): boolean{
        if(!this.connected && this.requestId && this.requestType && this.requestId.length > 0 && this.requestType.length > 0) {
            return true;
        }

        return false;
    }


}

export class UserProfilePicture {
    type: string;
    imageUrl: string;
    avatarId?: string;
}




@Injectable({
    providedIn: 'root'
})
export class UserProfileAdapter implements Adapter<UserProfile> {
    adapt(profileDataObject: any): UserProfile {

        let profile: UserProfile = new UserProfile();

        try {

            profile.theme=( typeof profileDataObject.theme === 'string') ? profileDataObject.theme : null;

if( typeof profileDataObject.theme === 'string')
{
    if(profileDataObject.theme ==='Dark')
    { profile.theme = 'dark_theme'}
    else if(profileDataObject.theme ==='Light')
    { profile.theme = 'light_theme'}
    else if(profileDataObject.theme ==='Green')
    { profile.theme = 'green_theme'}
    else if(profileDataObject.theme ==='Blue')
    { profile.theme = 'blue_theme'}
}
profile.expandMenuSidebar = (typeof profileDataObject.expandMenuSidebar === 'boolean') ? profileDataObject.expandMenuSidebar : false;
            profile.id = (typeof profileDataObject.userId === 'string') ? profileDataObject.userId : null;
            profile.firstName = (typeof profileDataObject.firstName === 'string') ? profileDataObject.firstName : null;
            profile.lastName = (typeof profileDataObject.lastName === 'string') ? profileDataObject.lastName : null;
            if ((typeof profileDataObject.dateOfBirth === 'string')) {
                profile.dob = new Date(profileDataObject.dateOfBirth);
            }
            profile.customStatus = (typeof profileDataObject.customStatus === 'string') ? profileDataObject.customStatus : null;
            profile.organization = (typeof profileDataObject.organization === 'string') ? profileDataObject.organization : null;
            profile.designation = (typeof profileDataObject.designation === 'string') ? profileDataObject.designation : null;
            profile.email = (typeof profileDataObject.email === 'string') ? profileDataObject.email : null;
            profile.mobile = (typeof profileDataObject.mobile === 'string') ? profileDataObject.mobile : null;


            profile.connectionsCount = (typeof profileDataObject.connections === 'number') ? profileDataObject.connections : parseInt(profileDataObject.connections);
            profile.requestsCount = (typeof profileDataObject.requests === 'number') ? profileDataObject.requests : parseInt(profileDataObject.requests);
            profile.learningSpacesCount = (typeof profileDataObject.learningSpaces === 'number') ? profileDataObject.learningSpaces : parseInt(profileDataObject.learningSpaces);
            profile.learningPathCount = (typeof profileDataObject.learningPaths === 'number') ? profileDataObject.learningPaths : parseInt(profileDataObject.learningPaths);
            profile.citationsCount = (typeof profileDataObject.citations === 'number') ? profileDataObject.citations : parseInt(profileDataObject.citations);
            profile.newNotificationsCount = (typeof profileDataObject.notifications === 'number') ? profileDataObject.notifications : parseInt(profileDataObject.notifications);
            

            profile.location = (typeof profileDataObject.location === 'string') ? profileDataObject.location : null;
            profile.aboutMe = (typeof profileDataObject.aboutMe === 'string') ? profileDataObject.aboutMe : null;
            profile.disabled = (typeof profileDataObject.disabled === 'boolean') ? profileDataObject.disabled : false;
       
            profile.hasPassedSSOExpInvite = (typeof profileDataObject.hasPassedSSOExpInvite === 'boolean') ? profileDataObject.hasPassedSSOExpInvite : false;

            if (profileDataObject.tour && Array.isArray(profileDataObject.tour)) {
                const tourObj =  profileDataObject.tour.filter((a: any) => a.platform == APP_PLATFORM);
                if (tourObj && tourObj.length > 0 && tourObj[0].status) {
                    profile.tourStatus = tourObj[0].status;
                }
            }

            profile.canMarkLSPForWebsiteAccess = (typeof profileDataObject.canMarkLSPForWebsiteAccess === 'boolean') ? profileDataObject.canMarkLSPForWebsiteAccess : false;

            
            if (profileDataObject.profilePicture && (typeof profileDataObject.profilePicture === 'string')) {
                let profilePictureObj = new UserProfilePicture();
                profilePictureObj.imageUrl = profileDataObject.profilePicture;
                profilePictureObj.avatarId = (typeof profileDataObject.avatarId === 'string')? profileDataObject.avatarId : null;

                profile.profileImage = profilePictureObj;
            }
            
            if (profileDataObject.socialMediaLinks && Array.isArray(profileDataObject.socialMediaLinks)) {
                let data: UserProfileLink[] = [];

                profileDataObject.socialMediaLinks.map((linkObject: any) => {

                    let linkAdapter = new UserProfileLinkAdapter();
                    data.push(linkAdapter.adapt(linkObject));

                })

                profile.links = data;
            }

            if (profileDataObject.areasOfInterest && Array.isArray(profileDataObject.areasOfInterest)) {

                let data: AreaOfInterest[] = [];

                profileDataObject.areasOfInterest.map((interestObject: any) => {

                    let areasOfInterestAdapter: AreaOfInterestAdapter = new AreaOfInterestAdapter();
                    const areasOfInterest = areasOfInterestAdapter.adapt(interestObject);
                    data.push(areasOfInterest);
                })

                profile.areasOfInterest = data;

            }

            if (profileDataObject.currentUserContext) {

                if (profileDataObject.currentUserContext.connectionRequest) {

                    profile.requestId= (typeof profileDataObject.currentUserContext.connectionRequest.id === 'string') ? profileDataObject.currentUserContext.connectionRequest.id : null;
                    profile.requestType = (typeof profileDataObject.currentUserContext.connectionRequest.type === 'string') ? profileDataObject.currentUserContext.connectionRequest.type : null;
                    
                }

                profile.connected = (typeof profileDataObject.currentUserContext.isConnected === 'boolean') ? profileDataObject.currentUserContext.isConnected : false;
                profile.mutualConnectionsCount = (typeof profileDataObject.currentUserContext.mutualFriends === 'number') ? profileDataObject.currentUserContext.mutualFriends : parseInt(profileDataObject.currentUserContext.mutualFriends);
            }



        } catch (error) {
            console.error("Unable to convert profile data to profile", error);
        }

        return profile;
    }

}